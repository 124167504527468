const docExtensions = [
  '.pdf',
  '.docx',
  '.docm',
  '.doc',
  '.xls',
  '.xlsx',
  '.xml',
  '.ppt',
  '.pptm',
  '.pptx',
  '.ppsx',
  '.ppsm',
  '.pps'
];
const imageExtensions = ['.jpg', '.jpeg', '.png', '.bmp'];
const videoExtensions = [
  '.gif',
  '.mp4',
  '.avi',
  '.mov',
  '.mkv',
  '.wmv',
  '.flv',
  '.webm',
  '.mpeg',
  '.3gp',
  '.ogg',
  '.asf',
  '.vob',
  '.m4v',
  '.mpg',
  '.rm',
  '.ts',
  '.hevc',
  '.swf',
  '.flv'
];
const allowedExtensionsForRewards = [
  '.pdf',
  '.docx',
  '.doc',
  '.xls',
  '.xlsx',
  '.jpg',
  '.jpeg',
  '.png',
  '.bmp',
  '.gif',
  '.heic',
  '.heif'
];

const allowedExtensionsArray = docExtensions.concat(imageExtensions, videoExtensions);

export const MultiUseVariables = {
  allowedExtensions: allowedExtensionsArray.join(', '),
  allowedExtensionsArray,
  docExtensions,
  imageExtensions,
  videoExtensions,
  allowedExtensionsForRewards
};
