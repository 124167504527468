// import statements
import { Component, OnInit } from '@angular/core';

// @Component decorator with selector, templateUrl, and styleUrls
@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss'],
})

// variable declarations

// onInit() function
export class UnauthorisedComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
  goToHomePage() {
    window.location.href = '/login';
  }
}
