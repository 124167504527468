import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import Utils from '../../Elements/utils';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';
@Component({
  selector: 'app-provider-deal-exceptions',
  templateUrl: './provider-deal-exceptions.component.html',
  styleUrls: ['./provider-deal-exceptions.component.sass'],
})
export class ProviderDealExceptionsComponent implements OnInit {
  _providerServices: any = [];
  _providerService: any = {};
  @Input()
  providerServiceRateLocations: any = [];
  repeatTypes: any;

  @Input()
  providerDealExceptions: any = [];

  @Output() onChange = new EventEmitter();

  get providerServices() {

    return this._providerService;
  }

  @Input()
  set providerServices(data) {

    this._providerServices = data;
    if (data.length > 0) {
      this._providerService = data[0];
    }
  }

  exceptions: any = [];
  constructor(
    private modalService: NgbModal,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.getRepeatTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setupCalendarExceptions();
  }

  getRepeatTypes() {
    this.dataService.getRepeatTypes().subscribe((data: any) => {
      this.repeatTypes = data;
    });
  }

  openDatepicker(exception = null) {
    const modalRef = this.modalService.open(DateRangePickerComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.range = true;
    if (exception) {

      modalRef.componentInstance.selectedFromDate = new Date(
        exception.exception_date_range.start_date
      );
      let endDate = null;
      if (exception.exception_date_range.end_date !== null) {
        endDate = new Date(exception.exception_date_range.end_date);
      }
      modalRef.componentInstance.selectedToDate = endDate;

      let endRepeatDate = null;
      if (exception.exception_date_range.end_repeat_date !== null) {
        endRepeatDate = new Date(
          exception.exception_date_range.end_repeat_date
        );
      }
      modalRef.componentInstance.selectedEndRepeatDate = endRepeatDate;
      modalRef.componentInstance.providerService = _.cloneDeep(exception);
      modalRef.componentInstance.providerServices = this._providerServices;
    } else {
      const selected_additional_services = [];
      for (const j of this._providerService.selected_additional_services) {
        const additional_service =
          j;
        selected_additional_services.push({
          provider_service_additional_service_id:
            additional_service.provider_service_additional_service_id,
          name: additional_service.name,
          rate_type: additional_service.rate_type,
          exception_rate: additional_service.standard_rate,
        });
      }

      const selected_additional_rates = [];
      for (const j of this._providerService.selected_additional_rates) {
        const additional_rate = j;
        selected_additional_rates.push({
          service_additional_rate_option_id:
            additional_rate.service_additional_rate_option_id,
          service_additional_rate_id:
            additional_rate.service_additional_rate_id,
          object_type: additional_rate.object_type,
          name: additional_rate.name,
          sort: additional_rate.sort,
          rate_type: additional_rate.rate_type,
          exception_rate: additional_rate.standard_rate,
        });
      }

      selected_additional_rates.sort((a, b) => a.sort - b.sort);


      const baseObject = {
        provider_service_id: this._providerService.provider_service_id,
        selected_additional_services,
        selected_additional_rates,
        exception_rate: this._providerService.standard_rate,
        rate_type: this._providerService.service_rate_type,
        exception_date_range: {},
      };

      modalRef.componentInstance.providerService = baseObject;
      modalRef.componentInstance.providerServices = this._providerServices;
    }
    modalRef.componentInstance.repeatTypes = this.repeatTypes;
    modalRef.componentInstance.providerLocations =
      this.providerServiceRateLocations;

    modalRef.result.then(
      (result) => {

        if (result.exception_date_range_id) {

          this.providerDealExceptions = this.providerDealExceptions.filter(
            (dealException) => {
              return (
                dealException.exception_date_range_id !==
                result.exception_date_range_id
              );
            }
          );


        } else if (
          result.providerService &&
          result.providerService.index_location >= 0
        ) {

          const serialized = this.getSerializedData(result);
          serialized.index_location = result.providerService.index_location;
          this.providerDealExceptions[result.providerService.index_location] =
            serialized;
        } else {

          const serialized = this.getSerializedData(result);
          serialized.index_location = this.providerDealExceptions.length;
          this.providerDealExceptions.push(serialized);
        }

        this.setupCalendarExceptions();

        this.onChange.emit({
          exceptions: this.exceptions,
          providerDealExceptions: this.providerDealExceptions,
        });
      },
      (reason) => {}
    );
  }

  formatDate(date, format) {


    if (date !== null) {
      const dateObject = moment(date, format).toDate();

      return new NgbDate(
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      );
    } else {
      return '';
    }

  }
  getSerializedData(data) {
    const providerService = data.providerService;
    let endDate = null;
    let endRepeatDate = null;

    if (data.selectedToDate) {
      endDate = data.selectedToDate.toDateString();
    }

    if (data.endRepeatDate) {
      endRepeatDate = data.endRepeatDate.toDateString();
    }
    const serialized: any = {
      start_date: data.selectedFromDate.toDateString(),
      end_date: endDate,
      end_repeat_date: endRepeatDate,
      enabled_locations: data.enabledLocations,
      repeat_type: providerService.exception_date_range.repeat_type,
      exception_date_range_id: providerService.exception_date_range_id,
      provider_service_rate_exception_id: providerService.id,
      provider_service_id: providerService.provider_service_id,
      exception_rate: providerService.exception_rate,
      minimum_rate: providerService.minimum_rate,
      selected_additional_rates: providerService.selected_additional_rates,
      selected_additional_services:
        providerService.selected_additional_services,
      provider_service_rate_id: providerService.provider_service_rate_id,
      rate_type: providerService.rate_type,
    };

    serialized.exception_date_range = {};

    if (providerService.exception_date_range) {
      const obj = providerService.exception_date_range;

      let repeat_type_str = '';
      switch (obj.repeat_type) {
        case 0:
          repeat_type_str = 'weekly';
          break;
        case 1:
          repeat_type_str = 'monthly';
          break;
        case 2:
          repeat_type_str = 'yearly';
          break;
        case 3:
          repeat_type_str = 'no_repeat';
          break;
      }
      serialized.exception_date_range.repeat_type = repeat_type_str;
      if (obj.id) {
        serialized.exception_date_range.id = obj.id;
      }

      if (data.selectedToDate) {

        serialized.exception_date_range.end_date = moment(
          new Date(data.selectedToDate.toDateString())
        ).format('YYYY-MM-DD');
      }

      if (data.endRepeatDate) {

        serialized.exception_date_range.end_repeat_date = moment(
          new Date(data.endRepeatDate.toDateString())
        ).format('YYYY-MM-DD');
      }

      if (data.selectedFromDate) {
        serialized.exception_date_range.start_date = moment(
          new Date(data.selectedFromDate.toDateString())
        ).format('YYYY-MM-DD');
      }

    }
    return serialized;
  }

  eventClicked(event) {

    const exception_obj = event.meta.data;


    this.openDatepicker(exception_obj);
  }

  setupCalendarExceptions() {
    this.exceptions = [];

    const exceptionDateRanges = [];
    for (const currentException of this.providerDealExceptions) {
      if (
        !currentException.exception_date_range_id ||
        (currentException.exception_date_range_id &&
          !exceptionDateRanges.includes(
            currentException.exception_date_range_id
          ))
      ) {
        if (currentException.exception_date_range_id) {
          exceptionDateRanges.push(currentException.exception_date_range_id);
        }
        let currency = 'CAD';
        for (const i of this.providerServiceRateLocations) {
          const location = i;
          if (currentException.enabled_locations.includes(location.id)) {
            currency = location.currency;
            break;
          }
        }

        let additionalDetails = '';
        if (currentException.selected_additional_services !== null) {
          for ( const j of currentException.selected_additional_services) {
            additionalDetails =
              additionalDetails +
              j.name +
              ' ' +
              currency +
              j.exception_rate +
              '\n';
          }
        }

        if (currentException.selected_additional_rates !== null) {
          currentException.selected_additional_rates.sort((a, b) => (a.sort - b.sort));

          for (const j of currentException.selected_additional_rates) {
            additionalDetails =
              additionalDetails +
              j.name +
              ' ' +
              currency +
              j.exception_rate +
              '\n';
          }
        }

        currentException.rate_type = currentException.rate_type;

        let endDate = null;
        let endRepeatDate = null;
        if (currentException.exception_date_range.end_date !== null) {
          endDate = Utils.getDateObjectWithoutTime(
            currentException.exception_date_range.end_date
          );
        }

        if (currentException.exception_date_range.end_repeat_date !== null) {
          endRepeatDate = Utils.getDateObjectWithoutTime(
            currentException.exception_date_range.end_repeat_date
          );
        }

        const startDate = Utils.getDateObjectWithoutTime(
          currentException.exception_date_range.start_date
        );

        this.exceptions.push({
          id: currentException.provider_service_rate_id,
          title:
            'Standard ' +
            currency +
            currentException.exception_rate +
            '\n' +
            additionalDetails,
          start: startDate,
          end: endDate,
          end_repeat_date: endRepeatDate,
          repeat_type: currentException.exception_date_range.repeat_type,
          data: currentException,
        });
      }
    }
  }
}
