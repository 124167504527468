import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
} from 'angular-calendar';
import { ViewPeriod } from 'calendar-utils';
import {
  addDays,
  differenceInDays,
  endOfDay,
  isEqual,
  isSameDay,
  isSameMonth,
} from 'date-fns';
import { RRule } from 'rrule';
import { CustomDateFormatter } from './custom-date-formatter.provider';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarComponent implements OnInit {
  @Input()
  events: any = [];
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  viewPeriod: ViewPeriod;

  calendarEvents: Array<CalendarEvent<{ id: number }>> = [];

  @Output() calendarEventClicked: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    const updatedEvents: SimpleChange = changes.events;
    // When the Input events object changes, update the data displayed on calendar
    if (this.viewPeriod) {
      this.setupData();
    }
  }

  getRecurringWeekday(dayNumber) {
    switch (dayNumber) {
      case 0:
        return RRule.SU;
        break;
      case 1:
        return RRule.MO;
        break;
      case 2:
        return RRule.TU;
        break;
      case 3:
        return RRule.WE;
        break;
      case 4:
        return RRule.TH;
        break;
      case 5:
        return RRule.FR;
        break;
      case 6:
        return RRule.SA;
        break;

      default:
        break;
    }
  }

  setupData() {
    this.calendarEvents = [];
    this.events.forEach((event) => {
      // If the event starts after the current view period ends,
      // Or if the event ends before the current view period starts
      // we do not need to calculate
      if (event.start > this.viewPeriod.end) {
        return;
      }
      if (
        event.end_repeat_date &&
        event.end_repeat_date < this.viewPeriod.start
      ) {
        return;
      }

      if (event.repeat_type === 'no_repeat' || event.repeat_type === 3) {
        this.calendarEvents.push({
          title: event.title,
          start: event.start,
          end: event.end,
          id: event.id,
          meta: event,
        });
      } else {
        // Set Time at 9 am to reduce risk of days changing due to timezones.
        const utcDate = new Date(
          this.viewPeriod.start.getUTCFullYear(),
          this.viewPeriod.start.getUTCMonth(),
          this.viewPeriod.start.getUTCDate(),
          9
        );
        const dtStart = new Date(utcDate);
        let until = new Date(endOfDay(this.viewPeriod.end));

        if (event.start > this.viewPeriod.start) {
          const utcDate = new Date(
            event.start.getUTCFullYear(),
            event.start.getUTCMonth(),
            event.start.getUTCDate(),
            9
          );
          const dtStart = new Date(utcDate);
        }

        if (
          event.end_repeat_date &&
          event.end_repeat_date < this.viewPeriod.end
        ) {
          until = new Date(endOfDay(event.end_repeat_date));
        }

        let rule: RRule;
        if (event.repeat_type === 'weekly' || event.repeat_type === 0) {
          rule = new RRule({
            freq: RRule.WEEKLY,
            byweekday: this.getRecurringWeekday(event.start.getDay()),
            dtstart: dtStart,
            tzid: 'UTC',
            until,
          });
        } else if (event.repeat_type === 'monthly' || event.repeat_type === 1) {
          rule = new RRule({
            freq: RRule.MONTHLY,
            bymonthday: [event.start.getDate()],
            dtstart: dtStart,
            until,
          });
        } else if (event.repeat_type === 'yearly' || event.repeat_type === 2) {
          rule = new RRule({
            freq: RRule.YEARLY,
            bymonthday: event.start.getDate(),
            bymonth: event.start.getMonth() + 1,
            dtstart: dtStart,
            until,
          });
        }

        if (rule !== null) {
          const title = event.title;
          let difference = 0;
          if (event.end !== null) {
            difference = differenceInDays(event.end, event.start);
          }

          rule.all().forEach((date) => {
            this.calendarEvents.push({
              title,
              start: new Date(date),
              end: new Date(addDays(date, difference)),
              id: event.id,
              meta: event,
            });
          });
        }
      }
    });
  }

  updateCalendarEvents(viewRender: CalendarMonthViewBeforeRenderEvent): void {
    if (
      !this.viewPeriod ||
      !isEqual(this.viewPeriod.start, viewRender.period.start) ||
      !isEqual(this.viewPeriod.end, viewRender.period.end)
    ) {
      this.viewPeriod = viewRender.period;
      this.setupData();

      this.cdr.detectChanges();
    }
  }

  ngOnInit() {}

  save() {}

  isEventStart(date1, date2) {
    if (isSameDay(date1, date2)) {
      return true;
    }
    return false;
  }

  eventClicked(event): void {
    this.calendarEventClicked.emit(event);
  }

  dayClicked({ date }: { date: Date }): void {
    this.events[0].start = date;
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
    }
  }
}
