import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';

@Component({
  selector: 'app-ordered-package-item',
  templateUrl: './ordered-package-item.component.html',
  styleUrls: ['./ordered-package-item.component.scss'],
})
export class OrderedPackageItemComponent implements OnInit {
  @Input()
  package: any = {};

  @Input()
  subscription_started: boolean;

  @Output() onCancelledPackage = new EventEmitter();

  selectedCustomer: any;
  constructor(
    private dataService: DataService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();
  }

  cancelPackage() {
    this.onCancelledPackage.emit(true);
  }
}
