import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { environment } from 'src/environments/environment';
import { EventService } from 'src/services/event.service';

@Component({
  selector: 'app-file-cabinet',
  templateUrl: './file-cabinet.component.html',
})
export class FileCabinetComponent implements OnInit, OnChanges {
  constructor(
    private router: Router, // keep router import for onFolderClick function
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    public eventService: EventService
  ) {}

  documents: any;
  folders: any[] = [];
  data: any;

  user: any;
  userId: number;

  isStaging = environment.staging;

  @Input() clientId?: number;
  // clientId should be the id of the client whose file cabinet is being viewed

  @Input() onFolderClick: any;
  // onFolderClick should be a function that takes the folder as an argument and routes to the folder's page

  @Input() dataFilter?: any;
  // dataFilter should be a function that takes a folder array of documents and filters the files to what the user can view
  // This is used to create the file count for each folder

  async ngOnInit() {
    this.user = await this.localStorageService.getUser();
    this.userId = this.user.id;

    if (!this.clientId) {
      this.clientId = this.userId;
    }

    this.getFileHistory(this.clientId);

    this.eventService.emitEvent('menuClicked');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientId && changes.clientId.currentValue) {
      this.getFileHistory(changes.clientId.currentValue);
    }
  }

  // Analytics triggered on folder click
  triggerAnalytics(folder) {
    window.analytics.track('Engage File Cabinet', {
      user_id: this.userId,
      timestamp: moment().format(),
      folder_name: folder.name,
    });
  }

  // Gets the file history for the client
  getFileHistory(id) {
    this.dataService.getFileHistory(id).subscribe((data: any) => {
      this.data = data;
      this.folders = [];
      this.documents = data.documents;
      for (const key in data.all_categories) {
        if (this.isStaging) {
          this.folders.push({
            id: Number([key][0]),
            name: data.all_categories[key],
          });
        } else {
          if (data.all_categories[key] !== 'Reward Claims') {
            this.folders.push({
              id: Number([key][0]),
              name: data.all_categories[key],
            });
          }
        }
      }
    });
  }

  // Counts the amount of files in the folder
  fileCount(folder) {
    let folderDocuments = [];
    let relatedUsers = [];
    if (this.documents[folder.name]) {
      for (const document of this.documents[folder.name]) {
        folderDocuments.push(document);
      }
    }

    if (folder.name === 'Files') {
      if (this.documents[''] && this.documents[''].length > 0) {
        for (const document of this.documents['']) {
          folderDocuments.push(document);
        }
      }
    }

    if (folder.name === 'Moving') {
      if (this.data.signed_documents && this.data.signed_documents.length > 0) {
        for (const document of this.data.signed_documents) {
          folderDocuments.push(document);
        }
      }
    }

    if (this.data.related_users) {
      relatedUsers = this.data.related_users;
    }
    if (this.dataFilter && folderDocuments) {
      folderDocuments = this.dataFilter(folderDocuments, relatedUsers);
    }

    return folderDocuments[0] ? folderDocuments.length : 0;
  }
}
