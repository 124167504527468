import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import Utils from 'src/app/AppPages/Elements/utils';
declare const google: any;
@Component({
  selector: 'app-deal-location',
  templateUrl: './deal-location.component.html',
  styleUrls: ['./deal-location.component.sass'],
})
export class DealLocationComponent implements OnInit {
  @Input() place_details;
  @Input() edit_id;
  zoom = 10;
  loading = false;
  constructor() {}

  @Output() selectPlace = new EventEmitter();

  ngOnInit() {
    if (this.edit_id === -1) {
      this.place_details.latitude = 51.044;
      this.place_details.longitude = -114.0718;
    }
    const flag = JSON.stringify(this.place_details) === '{}';
    if (flag === true) {
      this.place_details.latitude = 51.044;
      this.place_details.longitude = -114.0718;
    }

  }

  handleAddressChange(place: Address) {

    const place_details = Utils.getLocationDetails(place);


    this.place_details = place_details;
    this.zoom = 12;
    if (place_details.place_id) {
      this.selectPlace.emit(place_details);
    }
  }

  markerDragEnd($event: any) {

    const geocoder = new google.maps.Geocoder();
    const lat = $event.coords.lat;
    const lng = $event.coords.lng;
    const lat_lng = { lat, lng };

    this.loading = true;
    geocoder.geocode({ location: lat_lng }, (results, status) => {

      this.loading = false;
      if (status === google.maps.GeocoderStatus.OK) {
        const place = results[0];
        if (place) {

          this.handleMarkerChange(place);
          this.zoom = 12;
          this.place_details.route = place.formatted_address;
        } else {

        }
      } else {

      }
    });
  }

  handleMarkerChange(place: any) {

    const place_details = Utils.getLocationDetails(place);

    this.place_details.route = place.formatted_address;
    delete place_details.display_name;
    this.place_details = place_details;

    if (place_details.place_id) {
      this.selectPlace.emit(place_details);
    }
  }
}
