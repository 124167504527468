import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import HelloSign from 'hellosign-embedded';
import { DataService } from 'src/app/_helpers/data.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-client-signature',
  templateUrl: './client-signature.component.html',
  styleUrls: ['./client-signature.component.sass'],
})
export class ClientSignatureComponent implements OnInit {
  documentId: number;
  documentDetails: any = {};
  openedFormID: any = '';
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private meta: Meta,
    public globals: ThemeOptions
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.meta.updateTag(
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0, maximum-scale=1.0',
        },
        'name=viewport'
      );
      const user_id = +atob(params.get('user_id'));
      this.documentId = +atob(params.get('id'));

      this.dataService
        .getDocumentTemplateDetailsForClient(this.documentId, user_id)
        .subscribe((data: any) => {

          this.documentDetails = data;
        });
    });
  }

  openSignModal() {
    const client = new HelloSign();

    let signature_url = '';
    this.openedFormID = this.documentId;
    this.dataService
      .getClientSignatureUrl(this.openedFormID)
      .subscribe((data: any) => {
        signature_url = data.signature_url;
        client.open(data.signature_url, {
          clientId: this.globals.helloSignApi,
          testMode: false,
          debug: false,
          skipDomainVerification: true,
        });
      });

    client.on('cancel', () => {

    });

    client.on('sign', (data) => {



      this.dataService
        .setClientSigned(this.openedFormID)
        .subscribe((data: any) => {

          this.documentDetails.client_status = 'Waiting For Signed Document';
          this.documentDetails.intermediate_status = data.intermediate_status;
        });
    });
  }
}
