import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/_helpers/data.service';

@Component({
  selector: 'app-password-reset-modal',
  templateUrl: './password-reset-modal.component.html',
  styleUrls: ['./password-reset-modal.component.scss'],
})
export class PasswordResetModalComponent implements OnInit {
  @Input()
  user: any = {};
  emailSent = false;
  error = false;
  errorMessage =
    'There was an error sending your reset password email. Please try again later.';

  @Output() loaded: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    public toasterService: ToastrService
  ) {}

  ngOnInit() {}

  reset() {
    setTimeout(() => {
      this.close();
    }, 1500);

    this.dataService.sendResetPasswordEmail(this.user.email).subscribe(
      (data: any) => {
        if (data.message == 'Successfully') {
          this.emailSent = true;
          this.error = false;
        } else {
          this.error = true;
          this.toasterService.error(this.errorMessage, 'Error', {
            closeButton: true,
          });
        }
        this.loaded.emit('success');
      },
      (error: any) => {
        this.error = true;
        this.toasterService.error(this.errorMessage, 'Error', {
          closeButton: true,
        });
        this.loaded.emit('error');
      }
    );
  }

  close() {
    this.activeModal.close();
  }
}
