import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
} from 'angular-calendar';
import { CalendarEvent, ViewPeriod } from 'calendar-utils';
import { isEqual, isSameDay } from 'date-fns';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import Utils from 'src/app/AppPages/Elements/utils';
import { DealEventsModalComponent } from 'src/app/AppPages/MCPortal/customer/deal-events-modal/deal-events-modal.component';
import { MaintenanceServiceModalComponent } from 'src/app/AppPages/MCPortal/customer/maintenance-service-modal/maintenance-service-modal.component';
import { TodoInfoCommentsModalComponent } from 'src/app/AppPages/MCPortal/dashboard/todo-info-comments-modal/todo-info-comments-modal.component';
import { AppointmentModalComponent } from '../../../MCPortal/customer/appointment-modal/appointment-modal.component';
import { ActivitiesCalendarModalComponent } from './activities-calendar-modal/activities-calendar-modal.component';
@Component({
  selector: 'app-activities-calendar',
  templateUrl: './activities-calendar.component.html',
  styleUrls: ['./activities-calendar.component.sass'],
})
export class ActivitiesCalendarComponent implements OnInit {
  @Input()
  role: string;

  @Input()
  activities: any = [];
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  viewPeriod: ViewPeriod;
  isBuilder = false;
  currentUser: any;
  user: any;
  calendarEvents: Array<CalendarEvent<{ id: number }>> = [];
  @Output() onDatesChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private dataService: DataService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const updatedActivities: SimpleChange = changes.activities;

    // When the Input events object changes, update the data displayed on calendar

    this.currentUser = this.localStorageService.getUser();
    this.user = this.localStorageService.getSelectedCustomer();
    if (
      this.currentUser.roles.includes('builder') ||
      this.currentUser.roles.includes('additional_builder')
    ) {
      this.isBuilder = true;
    }

    if (this.viewPeriod) {
      this.setupData();
    }
  }

  ngOnInit(): void {}

  isEventToday(date) {
    if (isSameDay(date, new Date())) {
      return true;
    } else {
      return false;
    }
  }

  isPastEvent(date) {
    const m_date = moment(date);
    const today = moment();
    if (m_date.isBefore(today, 'day')) {
      return true;
    } else {
      return false;
    }
  }

  isFutureEvent(date) {
    const m_date = moment(date);
    const today = moment();
    if (m_date.isAfter(today, 'day')) {
      return true;
    } else {
      return false;
    }
  }

  isActionRequired(event) {
    if (
      ['Payment Details Pending', 'Signature Required'].includes(
        event.meta.display_status
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  isPending(event) {
    if (!this.isConfirmed(event) && !this.isActionRequired(event)) {
      return true;
    } else {
      return false;
    }
  }

  isConfirmed(event) {
    if (event.meta.type === 'Milestone') {
      if (
        event.meta.display_status === 'Confirmed' ||
        event.meta.display_status === 'Completed'
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  getPossessionIcon(isToday, isPast, isFuture) {
    const icon =
      isToday || isPast
        ? '/assets/images/icons/possession_black.png'
        : isFuture
        ? '/assets/images/icons/possession_white.png'
        : '';
    return icon;
  }

  getMoveIcon(isToday, isPast, isFuture) {
    const icon =
      isToday || isPast
        ? '/assets/images/icons/move-in-small-black.png'
        : isFuture
        ? '/assets/images/icons/move-in-small-white.png'
        : '';
    return icon;
  }

  getTodoIcon(isToday, isPast, isFuture) {
    const icon =
      isToday || isPast
        ? '/assets/images/icons/lg-todo.svg'
        : isFuture
        ? '/assets/images/icons/list-to-do-white.svg'
        : '';
    return icon;
  }

  getMilestoneIcon(isToday, isPast, isFuture) {
    const icon =
      isToday || isPast
        ? '/assets/images/icons/lg-milestones.svg'
        : isFuture
        ? '/assets/images/icons/lg-milestones-white.svg'
        : '';
    return icon;
  }

  getIcon(event) {
    const type = event.meta.type;
    const isToday = this.isEventToday(event.start);
    const isPast = this.isPastEvent(event.start);
    const isFuture = this.isFutureEvent(event.start);
    let icon = '';
    switch (type) {
      case 'Possession':
        icon = this.getPossessionIcon(isToday, isPast, isFuture);
        break;
      case 'Move-In':
      case 'Move-Out':
        icon = this.getMoveIcon(isToday, isPast, isFuture);
        break;

      case 'Milestone':
      case 'ToDo':
      case 'Appointment':
        if (event.meta.service_label) {
          const service_label = event.meta.service_label;
          if (isToday || isPast || (isFuture && this.isPending(event))) {
            icon = Utils.getServiceIcon(service_label);
          } else {
            icon = Utils.getServiceIconWhite(service_label);
          }
        } else {
          icon = this.getTodoIcon(isToday, isPast, isFuture);
        }
        break;
      default:
        icon = this.getTodoIcon(isToday, isPast, isFuture);
    }
    return icon;
  }
  setupData() {
    this.calendarEvents = [];
    if (this.activities) {
      this.activities.forEach((activity: any) => {
        if (activity.should_display_service_date !== false) {
          const title = activity.title;
          this.calendarEvents.push({
            title,
            start: new Date(activity.due_date),
            id: activity.id,
            meta: activity,
          });
        }
      });
    }
  }

  openMaintenanceInfoModal(milestone) {
    const modalRef = this.modalService.open(MaintenanceServiceModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.maintenanceInfo = _.cloneDeep(milestone);
    modalRef.result.then(
      (result) => {
        if (result) {
          const data = {
            start_date: this.viewPeriod.start,
            end_date: this.viewPeriod.end,
          };
          this.onDatesChanged.emit(data);
          this.cdr.detectChanges();
        }
      },
      (reason) => {}
    );
  }

  openDealEventsModal(info) {
    this.dataService
      .getDealDetails(info.user_property_deal_id)
      .subscribe((data: any) => {
        const modalRef = this.modalService.open(DealEventsModalComponent, {
          size: 'lg',
        });

        modalRef.componentInstance.info = _.cloneDeep(data);

        modalRef.result.then(
          (result) => {},
          (reason) => {}
        );
      });
  }

  action(data) {
    if (data.type === 'ToDo') {
      this.openTodoInfoModal(data);
    }

    if (data.type === 'Event') {
      this.openDealEventsModal(data);
    }

    if (data.type === 'HomeMaintenanceTask') {
      this.openMaintenanceInfoModal(data);
    }

    if (data.type === 'Appointment') {
      this.openAppointmentModal(data);
    }

    if (data.type === 'Move-In' || data.type === 'Possession') {
      if (this.role && this.role === 'MC') {
        this.goToCustomerProfile(data.client_id);
      }
    }

    if (data.type === 'Milestone') {
      this.goToServiceDetails(data.client_id, data.user_property_service_id);
    }
  }

  goToServiceDetails(user_id, user_property_service_id) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        user_property_service_id,
      },
    };
    this.router
      .navigate(
        ['customer/' + user_id + '/home-services/home-service-details/'],
        navigationExtras
      )
      .then(() => {
        window.location.reload();
      });
  }

  goToCustomerProfile(client_id) {
    this.router.navigate(['/customer/' + client_id + '/dashboard/']);
  }

  openOverlayModal(data) {}

  openTodoInfoModal(todo) {
    const modalRef = this.modalService.open(TodoInfoCommentsModalComponent, {
      windowClass: 'todoModalClass',
    });
    modalRef.componentInstance.todoInfo = _.cloneDeep(todo);
    modalRef.result.then(
      (result) => {
        const data = {
          start_date: this.viewPeriod.start,
          end_date: this.viewPeriod.end,
        };
        this.onDatesChanged.emit(data);
        this.cdr.detectChanges();
      },
      (reason) => {}
    );
  }

  showModal(day) {
    const modalRef = this.modalService.open(ActivitiesCalendarModalComponent, {
      size: 'sm',
    });
    modalRef.componentInstance.day = _.cloneDeep(day);
    modalRef.componentInstance.isBuilder = _.cloneDeep(this.isBuilder);
    if (this.role) {
      modalRef.componentInstance.role = _.cloneDeep(this.role);
    }
  }

  updateCalendarEvents(viewRender: CalendarMonthViewBeforeRenderEvent): void {
    if (
      !this.viewPeriod ||
      !isEqual(this.viewPeriod.start, viewRender.period.start) ||
      !isEqual(this.viewPeriod.end, viewRender.period.end)
    ) {
      this.viewPeriod = viewRender.period;
      const data = {
        start_date: this.viewPeriod.start,
        end_date: this.viewPeriod.end,
      };
      this.onDatesChanged.emit(data);
      this.cdr.detectChanges();
    }
  }

  openAppointmentModal(appointment) {
    let userID = -1;
    if (
      this.currentUser.roles.includes('customer_user') ||
      this.currentUser.roles.includes('customer_user_additional')
    ) {
      userID = this.currentUser.id;
    } else {
      userID = this.user.id;
    }
    const modalRef = this.modalService.open(AppointmentModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.appointment = _.cloneDeep(appointment);
    modalRef.componentInstance.customerid = _.cloneDeep(userID);
    modalRef.componentInstance.edit_details_flag = false;
    modalRef.result.then(
      (result) => {
        const data = {
          start_date: this.viewPeriod.start,
          end_date: this.viewPeriod.end,
        };
        this.onDatesChanged.emit(data);
        this.cdr.detectChanges();
      },
      (reason) => {}
    );
  }
}
