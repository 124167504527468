import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';

@Component({
  selector: 'app-dynamic-information',
  templateUrl: './dynamic-information.component.html',
  styleUrls: ['./dynamic-information.component.sass'],
})
export class DynamicInformationComponent implements OnInit {
  @Input() is_scheduled: boolean;
  @Input() is_booking_confirmed: boolean;
  @Input() is_invite_sent: boolean;

  @Input() is_referral: boolean;
  @Input() providerLocation: any;
  @Input() user: any;
  picture: any;
  customer_id: any;
  sign_document = false;
  user_property_service_provider_document_templates: any = [];
  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit() {

    if (this.user) {
      if (this.user.hc.avatar) {
        this.picture = this.user.hc.avatar;
      }
    }
    this.getMatchingProvidersDetails();
  }

  onSubmit() {

    if (this.is_booking_confirmed) {
      this.activeModal.close({ is_booking_confirmed: true });
    } else if (this.is_scheduled) {
      this.activeModal.close({ is_scheduled: true });
    } else {
      this.activeModal.close();
    }
  }

  getMatchingProvidersDetails() {
    this.dataService
      .getServiceMatchingProviderDetails(
        this.providerLocation.user_property_service_provider
          .user_property_service_id
      )
      .subscribe((data: any) => {

        const selected_status_array = ['selected', 'accepted', 'completed'];
        let matching_location: any;
        for (const i of data.matching_provider_locations) {
          if (
            selected_status_array.includes(
              i.user_property_service_provider
                .status
            )
          ) {
            matching_location = data.matching_provider_locations[i];
            break;
          }
        }

        this.user_property_service_provider_document_templates =
          matching_location.user_property_service_provider.user_property_service_provider_document_templates;
      });
  }

  signDocuments() {
    this.sign_document = true;
    this.activeModal.close({ sign_document: true });
  }
}
