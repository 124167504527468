import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarDataService {
  private user = new Subject<any>(); // need to create a subject
  private approvalCount = new Subject<any>();

  sendUpdate(user: any) {
    // the component that wants to update something, calls this fn

    this.user.next(user); // next() will feed the value in Subject
  }

  sendApprovalCount(count: any) {
    this.approvalCount.next(count);
  }

  getUpdate(): Observable<any> {
    // the receiver component calls this function
    return this.user.asObservable(); // it returns as an observable to which the receiver funtion will subscribe
  }

  getApprovalCount(): Observable<any> {
    return this.approvalCount.asObservable();
  }
}
