import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewDay,
  CalendarView,
} from 'angular-calendar';
import { ViewPeriod } from 'calendar-utils';
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { DataService } from 'src/app/_helpers/data.service';
import Utils from '../../Elements/utils';
import { CustomDateFormatter } from './custom-date-formatter.provider';
type CalendarPeriod = 'day' | 'week' | 'month';

function addPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: addDays,
    week: addWeeks,
    month: addMonths,
  }[period](date, amount);
}

function subPeriod(period: CalendarPeriod, date: Date, amount: number): Date {
  return {
    day: subDays,
    week: subWeeks,
    month: subMonths,
  }[period](date, amount);
}

function startOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: startOfDay,
    week: startOfWeek,
    month: startOfMonth,
  }[period](date);
}

function endOfPeriod(period: CalendarPeriod, date: Date): Date {
  return {
    day: endOfDay,
    week: endOfWeek,
    month: endOfMonth,
  }[period](date);
}

@Component({
  selector: 'app-calendar-modal',
  templateUrl: './calendar-modal.component.html',
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalendarModalComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  activeDayIsOpen = true;
  viewDate: Date = new Date();
  activeDay: Date = new Date();
  viewPeriod: ViewPeriod;
  @Input()
  selectedDate: Date;

  @Input()
  minDate: Date;

  @Input()
  maxDate: Date;

  @Input()
  loading: boolean;
  selectedMonthViewDay: CalendarMonthViewDay;

  selectedDayViewDate: Date;
  prevBtnDisabled = false;

  nextBtnDisabled = false;
  selectedDays: any = [];
  @Input()
  events: any = [];
  calendarEvents: Array<CalendarEvent<{}>> = [];
  @Output() onDatesChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.selectedDate) {
      this.viewDate = this.selectedDate;
    }
    this.dateOrViewChanged();
  }

  ngOnChanges(changes: SimpleChanges) {
    const updatedEvents: SimpleChange = changes.events;
    // When the Input events object changes, update the data displayed on calendar
    if (this.viewPeriod) {
      this.setupData();
    }
  }

  setEvents(events: []) {
    this.events = events.slice();

    this.setupData();
  }

  setLoading(loading) {
    this.loading = loading;
  }
  dateIsValid(date: Date): boolean {
    if (this.minDate && this.maxDate) {
      return date >= this.minDate && date <= this.maxDate;
    } else if (this.minDate) {
      return date >= this.minDate;
    } else if (this.maxDate) {
      return date <= this.maxDate;
    }
    return true;
  }

  decrement(): void {
    this.changeDate(subPeriod(this.view, this.viewDate, 0));
  }
  increment(): void {
    this.changeDate(addPeriod(this.view, this.viewDate, 0));
  }

  today(): void {
    this.changeDate(new Date());
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    this.prevBtnDisabled = !this.dateIsValid(
      endOfPeriod(this.view, subPeriod(this.view, this.viewDate, 1))
    );

    this.nextBtnDisabled = !this.dateIsValid(
      startOfPeriod(this.view, addPeriod(this.view, this.viewDate, 1))
    );
    if (this.minDate && this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.maxDate && this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  save() {
    this.activeModal.close(this.events[0].start);
  }

  async beforeMonthViewRender(viewRender: CalendarMonthViewBeforeRenderEvent) {
    this.viewPeriod = viewRender.period;

    const data = {
      start_date: this.viewPeriod.start,
      end_date: this.viewPeriod.end,
    };
    await this.onDatesChanged.emit(data);

    viewRender.body.forEach((day) => {
      if (!this.dateIsValid(day.date)) {
        day.cssClass = 'cal-disabled';
      }

      if (this.dateIsValid(day.date)) {
        if (day.events.length > 0) {
          day.cssClass = 'cal-day-green';
        }
      }
      if (
        this.selectedDate &&
        day.date.getTime() === this.selectedDate.getTime()
      ) {
        day.cssClass = 'cal-day-selected';
        this.selectedMonthViewDay = day;
        this.selectedDays[0] = this.selectedMonthViewDay;
      }
    });
  }

  getCount(day_events) {
    return day_events[0].meta.available_providers;
  }

  setupData() {
    this.calendarEvents = [];
    this.events.forEach((obj: any) => {
      const date = Utils.getDateObjectWithoutTime(obj.date);
      this.calendarEvents.push({
        title: '',
        start: date,
        meta: obj,
      });
    });
    this.cdr.detectChanges();
    this.loading = false;
  }

  dayClicked(day: CalendarMonthViewDay): void {
    if (this.dateIsValid(day.date)) {
      this.selectedMonthViewDay = day;
      this.selectedDate = this.selectedMonthViewDay.date;

      for (const selectedDay of this.selectedDays) {
        delete selectedDay.cssClass;
      }
      day.cssClass = 'cal-day-selected';
      this.selectedMonthViewDay = day;
      this.selectedDays[0] = this.selectedMonthViewDay;

      this.activeModal.close(this.selectedDate);
    }
  }
}
