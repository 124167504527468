import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { DataService } from '../../../../_helpers/data.service';
import { UploadService } from '../../../../_helpers/upload.service';

@Component({
  selector: 'app-service-info-modal',
  templateUrl: './service-info-modal.component.html',
})
export class ServiceInfoModalComponent implements OnInit {
  @Input()
  serviceInfo: any;

  additionalServices: any;
  phases: any;
  services: any;
  dropdownSettings: IDropdownSettings = {};
  containerDropdownSettings: IDropdownSettings = {};

  all_services: any = [];

  public Editor: any;
  public config: any;
  constructor(
    private dataService: DataService,
    public activeModal: NgbActiveModal,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    this.getPhases();

    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };

    this.containerDropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'service_details_option_id',
      textField: 'option',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };
    this.config = this.uploadService.config;
    this.Editor = this.uploadService.Editor;
  }

  save() {
    let service = 0;
    let has_referral_service = false;
    for (service = 0; service < this.serviceInfo.services.length; service++) {
      const referral_service = this.serviceInfo.services[
        service
      ].phase_services.filter((phase_service: any) => {
        return (
          this.serviceInfo.services[service].service_id ==
            phase_service.service_id && phase_service.referral === true
        );
      });
      if (referral_service && referral_service.length > 0) {
        has_referral_service = true;
        break;
      }
    }
    this.serviceInfo.has_referral_service = has_referral_service;
    this.activeModal.close(this.serviceInfo);
  }

  addNew() {
    this.serviceInfo.services.push({});
  }

  filterFunction(value, services, i) {


    this.serviceInfo.services[i].phase_services = this.all_services.filter(
      (service) => {
        return (
          service.service_name.toLowerCase().search(value.toLowerCase()) !== -1
        );
      }
    );
  }

  deleteRow(id) {
    const options = {
      type: 'warning',
      title: 'Delete this service?',
      confirmButtonText: 'Delete',
      // tslint:disable-next-line:max-line-length
      text: 'Are you sure you want to permanently delete this service for this provider? This will delete any rate details and blocked dates added for this service',
      showCancelButton: true,
    } as SweetAlertOptions;
    Swal(options).then((result) => {

      if (result.value) {
        this.serviceInfo.services.splice(id, 1);
      }
    });
  }

  onPhaseChanged(activeServiceIndex, phase) {
    this.serviceInfo.services[activeServiceIndex].phase_id = phase.id;
    this.serviceInfo.services[activeServiceIndex].phase_name = phase.phase;
    this.serviceInfo.services[activeServiceIndex].phase_services =
      phase.phase_services;
    this.all_services = phase.phase_services;
  }

  onServiceChanged(activeServiceIndex, phase_service) {



    this.serviceInfo.services[activeServiceIndex].service_id =
      phase_service.service_id;
    this.serviceInfo.services[activeServiceIndex].service_name =
      phase_service.service_name;
    this.serviceInfo.services[activeServiceIndex].service_label =
      phase_service.label;
    this.serviceInfo.services[activeServiceIndex].additional_services =
      phase_service.additional_services;

    const formatted_container_options = [];
    if (phase_service.container_options) {
      for (
        let index = 0;
        index < phase_service.container_options.length;
        index++
      ) {
        const element = phase_service.container_options[index];
        formatted_container_options.push({
          id: element.id,
          service_details_option_id: element.id,
          option: element.option,
        });
      }

      this.serviceInfo.services[activeServiceIndex].container_options =
        formatted_container_options;
    }


  }

  updateServiceNames(activeServiceIndex) {
    this.serviceInfo.services[
      activeServiceIndex
    ].selected_additional_service_names = _.map(
      this.serviceInfo.services[activeServiceIndex]
        .selected_additional_services,
      'name'
    );
  }

  onItemSelect(activeServiceIndex, item: any) {

    this.serviceInfo.services[activeServiceIndex].selected_additional_services =
      this.serviceInfo.services[
        activeServiceIndex
      ].previous_selected_additional_services;

    if (
      this.serviceInfo.services[activeServiceIndex]
        .selected_additional_services === null
    ) {
      this.serviceInfo.services[
        activeServiceIndex
      ].selected_additional_services = [];
    }
    this.serviceInfo.services[
      activeServiceIndex
    ].selected_additional_services.push(item);

    // Now update it with the removed item
    this.serviceInfo.services[
      activeServiceIndex
    ].previous_selected_additional_services =
      this.serviceInfo.services[
        activeServiceIndex
      ].selected_additional_services;


    this.updateServiceNames(activeServiceIndex);
  }

  onItemDeSelect(activeServiceIndex, item: any) {


    // On deselect, the dropdown component is stripping out all the other fields from our array.
    // We need the ids to match with the database ids on our server.
    // Hence, we assign the data we received from our api, and filter out the de-selected item.
    this.serviceInfo.services[activeServiceIndex].selected_additional_services =
      this.serviceInfo.services[
        activeServiceIndex
      ].previous_selected_additional_services.filter(
        (object) => object.id !== item.id
      );

    // Now update it with the removed item
    this.serviceInfo.services[
      activeServiceIndex
    ].previous_selected_additional_services =
      this.serviceInfo.services[
        activeServiceIndex
      ].selected_additional_services;

    this.updateServiceNames(activeServiceIndex);
  }

  onContainerItemSelect(activeServiceIndex, item: any) {

    this.serviceInfo.services[activeServiceIndex].available_containers =
      this.serviceInfo.services[
        activeServiceIndex
      ].previous_selected_available_containers;

    if (
      this.serviceInfo.services[activeServiceIndex].available_containers === null
    ) {
      this.serviceInfo.services[activeServiceIndex].available_containers = [];
    }

    const selected_option = this.serviceInfo.services[
      activeServiceIndex
    ].container_options.filter((optionObject) => {
      return optionObject.id === item.service_details_option_id;
    });

    const formattedItem = {
      id: null,
      service_details_option_id: item.service_details_option_id,
      option: selected_option[0].option,
    };
    this.serviceInfo.services[activeServiceIndex].available_containers.push(
      formattedItem
    );

    // Now update it with the removed item
    this.serviceInfo.services[
      activeServiceIndex
    ].previous_selected_available_containers =
      this.serviceInfo.services[activeServiceIndex].available_containers;
  }

  onContainerItemDeSelect(activeServiceIndex, item: any) {


    // On deselect, the dropdown component is stripping out all the other fields from our array.
    // We need the ids to match with the database ids on our server.
    // Hence, we assign the data we received from our api, and filter out the de-selected item.
    this.serviceInfo.services[activeServiceIndex].available_containers =
      this.serviceInfo.services[
        activeServiceIndex
      ].previous_selected_available_containers.filter(
        (object) =>
          object.service_details_option_id !== item.service_details_option_id
      );

    // Now update it with the removed item
    this.serviceInfo.services[
      activeServiceIndex
    ].previous_selected_available_containers =
      this.serviceInfo.services[activeServiceIndex].available_containers;

  }

  onSelectAll(activeServiceIndex, items: any) {
    // Disabled select all for now

    this.serviceInfo.services[activeServiceIndex].selected_additional_services =
      items;

    this.updateServiceNames(activeServiceIndex);
  }

  onDeSelectAll(activeServiceIndex, items: any) {
    // Disabled select all for now

    this.updateServiceNames(activeServiceIndex);
  }

  onDropDownClose() {

  }

  isDuplicateService() {
    if (this.serviceInfo.services.length > 1) {
      for (let i = 0; i < this.serviceInfo.services.length; i++) {
        const services = this.serviceInfo.services.filter((service) => {
          return service.service_id === this.serviceInfo.services[i].service_id;
        });
        if (services.length > 1) { return true; }
      }
    } else {
      return false;
    }
  }

  getPhases() {


    this.dataService.getPhases().subscribe((data: any) => {

      this.phases = data;

      for (let i = 0; i < this.serviceInfo.services.length; ++i) {
        const current_provider_service = this.serviceInfo.services[i];
        const matched_phase = this.phases.filter((element) => {
          return element.id === current_provider_service.phase_id;
        });
        current_provider_service.phase_services =
          matched_phase[0].phase_services;

        const matched_service = current_provider_service.phase_services.filter(
          (element) => {
            return element.service_id === current_provider_service.service_id;
          }
        );
        if (matched_service && matched_service.length > 0) {
          current_provider_service.additional_services =
            matched_service[0].additional_services;


          if (
            current_provider_service.container_options === null ||
            current_provider_service.container_options.length === 0
          ) {
            current_provider_service.container_options =
              matched_service[0].container_options;
          }

          // The dropdown component strips down the data to keep only id and name. We need to preserve the entire object.
          current_provider_service.previous_selected_additional_services =
            current_provider_service.selected_additional_services;

          const formatted_container_options = [];
          if (current_provider_service.container_options) {
            for (
              let index = 0;
              index < current_provider_service.container_options.length;
              index++
            ) {
              const element = current_provider_service.container_options[index];
              formatted_container_options.push({
                id: element.id,
                service_details_option_id: element.id,
                option: element.option,
              });
            }
            current_provider_service.container_options =
              formatted_container_options;
          }



          const formatted_available_containers = [];
          for (
            let index = 0;
            index < current_provider_service.available_containers.length;
            index++
          ) {
            const element =
              current_provider_service.available_containers[index];
            const selected_option =
              current_provider_service.container_options.filter(
                (optionObject) => {
                  return optionObject.id === element.service_details_option_id;
                }
              );
            formatted_available_containers.push({
              id: element.id,
              service_details_option_id: element.service_details_option_id,
              option: selected_option[0].option,
            });
          }
          current_provider_service.available_containers =
            formatted_available_containers;
          current_provider_service.previous_selected_available_containers =
            current_provider_service.available_containers;
        }
      }


    });
  }
}
