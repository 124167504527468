import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { camelCase, startCase } from 'lodash';
import * as _ from 'lodash';
import { PasswordResetModalComponent } from 'src/app/AppPages/MCPortal/customer/customer-my-profile/my-profile-modals/password-reset-modal/password-reset-modal.component';
import { DataService } from '../../../../../_helpers/data.service';
import { LocalStorageService } from '../../../../../_helpers/local-storage.service';
import { getInitials } from '../../../../../AppPages/MCPortal/customer/customer-helpers.module';
import { ThemeOptions } from '../../../../../theme-options';
@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss'],
})
export class UserBoxComponent implements OnInit {
  user: any;
  selectedCustomer: any = {};
  userRole: string;
  avatarUrl: string;
  is_provider = false;
  is_staff = false;
  is_customer = false;
  isSharedAccess = false;
  currentUrl: string;
  isNotepad = false;

  getInitials = getInitials;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(
    private modalService: NgbModal,
    public globals: ThemeOptions,
    private router: Router,
    private localStorageService: LocalStorageService,
    private dataService: DataService
  ) {}

  async ngOnInit() {
    if (window.location.pathname.includes('/notes')) {
      this.isNotepad = true;
    }
    this.user = this.localStorageService.getUser();
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();

    this.dataService.getUserDetails(this.user.id).subscribe((data: any) => {
      this.user = data;
      // stores newest user data
      this.localStorageService.storeUser(data);
    });

    this.currentUrl = this.router.routerState.snapshot.url;
    if (
      this.user.roles.includes('service_provider') ||
      this.user.roles.includes('additional_service_provider')
    ) {
      this.is_provider = true;
    }

    if (this.user.roles.includes('gm') || this.user.roles.includes('csr')) {
      this.is_staff = true;
    }

    if (this.selectedCustomer && this.selectedCustomer.roles) {
      if (
        !this.user.roles.includes('customer_user') &&
        !this.user.roles.includes('customer_user_additional') &&
        this.selectedCustomer.roles.includes('customer_user_additional')
      ) {
        this.isSharedAccess = true;
      }
    }

    if (
      (this.user.roles.includes('customer_user') ||
        this.user.roles.includes('customer_user_additional')) &&
      this.selectedCustomer.id === this.user.id
    ) {
      this.is_customer = true;
    }

    this.userRole = startCase(camelCase(this.user.role));

    if (this.user.avatar) {
      this.avatarUrl = this.user.avatar;
    }
  }

  openResetPasswordModal() {
    const modalRef = this.modalService.open(PasswordResetModalComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.user = _.cloneDeep(this.user);
  }

  logout() {
    window.analytics.reset();
    this.localStorageService.removeUser();
    this.localStorageService.removeSelectedCustomer();
    this.localStorageService.removeLastActiveTab();
    this.router.navigate(['login']);
    this.globals.isCustomerActive = false;
    this.globals.taskPageBreadcrumb = '';
    this.globals.previousTaskId = '';
    this.globals.isBuilderActive = false;
    sessionStorage.removeItem('isBuilderSession');
    localStorage.removeItem('jobsListDropdownValueSPN');
    this.globals.jobsListDropdownValueSPN = '';
    this.globals.isFirstLogin = true;
    this.localStorageService.clearStorage();
  }
}
