import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import Utils from 'src/app/AppPages/Elements/utils';
declare const google: any;
@Component({
  selector: 'app-select-storage-location-modal',
  templateUrl: './select-storage-location-modal.component.html',
  styleUrls: ['./select-storage-location-modal.component.sass'],
})
export class SelectStorageLocationModalComponent implements OnInit {
  @Input()
  zoom: any;
  @ViewChild('search') searchElementRef: ElementRef;
  @Input()
  location: any = {};
  @Input()
  placeDetails: any = { latitude: 51.044, longitude: -114.071 };
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  save() {
    this.location.place_details = this.placeDetails;
    this.activeModal.close(this.location);
  }

  handleAddressChange(place: Address) {
    const place_details = Utils.getLocationDetails(place);
    this.location.address = this.searchElementRef.nativeElement.value;
    this.zoom = 12;
    delete place_details.display_name;
    this.placeDetails = place_details;
  }

  handleMarkerChange(place: any) {
    const place_details = Utils.getLocationDetails(place);
    this.location.address = place.formatted_address;
    delete place_details.display_name;
    this.placeDetails = place_details;
  }

  markerDragEnd($event: any) {
    const geocoder = new google.maps.Geocoder();
    const lat = $event.coords.lat;
    const lng = $event.coords.lng;
    const lat_lng = { lat, lng };

    geocoder.geocode({ location: lat_lng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const place = results[0];
        if (place) {
          this.zoom = 12;
          this.handleMarkerChange(place);
          this.location.address = place.formatted_address;
        } else {
        }
      }
    });
  }
}
