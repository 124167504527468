import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-mask',
  templateUrl: './input-mask.component.html'
})
export class InputMaskComponent implements OnInit {
    @Input() model: string;

  public mask: Array<string | RegExp>;



  @Output() modelChange: EventEmitter<string> = new EventEmitter<string>();
  constructor() {
    this.mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }

  ngOnInit() {
  }

  onModelChange(newModel: string) {
    this.model = newModel;
    this.modelChange.emit(this.model);
  }

}
