import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isSameDay } from 'date-fns';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { AppointmentModalComponent } from 'src/app/AppPages/MCPortal/customer/appointment-modal/appointment-modal.component';
import { DealEventsModalComponent } from 'src/app/AppPages/MCPortal/customer/deal-events-modal/deal-events-modal.component';
import { MaintenanceServiceModalComponent } from 'src/app/AppPages/MCPortal/customer/maintenance-service-modal/maintenance-service-modal.component';
import { TodoInfoCommentsModalComponent } from 'src/app/AppPages/MCPortal/dashboard/todo-info-comments-modal/todo-info-comments-modal.component';
import Utils from '../../../../Elements/utils';
@Component({
  selector: 'app-activities-calendar-modal',
  templateUrl: './activities-calendar-modal.component.html',
  styleUrls: ['./activities-calendar-modal.component.sass'],
})
export class ActivitiesCalendarModalComponent implements OnInit {
  @Input()
  day: any = {};

  @Input()
  isBuilder: boolean;

  @Input()
  role: string;

  currentUser: any;
  user: any;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private dataService: DataService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.currentUser = this.localStorageService.getUser();
    this.user = this.localStorageService.getSelectedCustomer();
  }

  formattedDate() {
    return moment(this.day.date).format('MMM DD YYYY');
  }

  isEventToday(date) {
    if (isSameDay(date, new Date())) {
      return true;
    } else {
      return false;
    }
  }
  isPastEvent(date) {
    const m_date = moment(date);
    const today = moment();
    if (m_date.isBefore(today, 'day')) {
      return true;
    } else {
      return false;
    }
  }

  isFutureEvent(date) {
    const m_date = moment(date);
    const today = moment();
    if (m_date.isAfter(today, 'day')) {
      return true;
    } else {
      return false;
    }
  }

  action(data) {
    if (data.type === 'ToDo') {
      this.openTodoInfoModal(data);
    }

    if (data.type === 'Event') {
      this.openDealEventsModal(data);
    }

    if (data.type === 'HomeMaintenanceTask') {
      this.openMaintenanceInfoModal(data);
    }

    if (data.type === 'Appointment') {
      this.openAppointmentModal(data);
    }
    if (data.type === 'Move-In' || data.type === 'Possession') {
      if (this.role && this.role === 'MC') {
        this.goToCustomerProfile(data.client_id);
      }
    }

    if (data.type === 'Milestone') {
      this.goToServiceDetails(data.client_id, data.user_property_service_id);
    }
  }

  goToServiceDetails(user_id, user_property_service_id) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        user_property_service_id,
      },
    };
    this.router.navigate(['customer/' + user_id + '/home-services/home-service-details/'], navigationExtras).then(() => {
      window.location.reload();
    });
  }

  openMaintenanceInfoModal(milestone) {
    const modalRef = this.modalService.open(MaintenanceServiceModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.maintenanceInfo = _.cloneDeep(milestone);
    // TODO/FIXME: And this maybe needs to be gone too?
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  openDealEventsModal(info) {
    this.dataService.getDealDetails(info.user_property_deal_id).subscribe((data: any) => {
      const modalRef = this.modalService.open(DealEventsModalComponent, {
        size: 'lg',
      });

      modalRef.componentInstance.info = _.cloneDeep(data);

      modalRef.result.then(
        (result) => {},
        (reason) => {}
      );
    });
  }

  goToCustomerProfile(client_id) {
    this.router.navigate(['/customer/' + client_id + '/dashboard/']);
    this.activeModal.close();
  }

  openTodoInfoModal(todo) {
    const modalRef = this.modalService.open(TodoInfoCommentsModalComponent, {
      windowClass: 'todoModalClass',
    });
    modalRef.componentInstance.todoInfo = _.cloneDeep(todo);
    modalRef.componentInstance.calendarModal = true;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.router.navigate(['/customer/' + result + '/dashboard/']);
          this.activeModal.close();
        }
      },
      (reason) => {}
    );
  }

  isHighlightEvent(event) {
    if (event.meta.type === 'Possession' || event.meta.type === 'ToDo' || event.meta.type === 'Appointment') {
      return true;
    } else {
      return false;
    }
  }

  isActionRequired(event) {
    if (['Payment Details Pending', 'Signature Required'].includes(event.meta.display_status)) {
      return true;
    } else {
      return false;
    }
  }

  isPending(event) {
    if (!this.isConfirmed(event) && !this.isActionRequired(event)) {
      return true;
    } else {
      return false;
    }
  }

  isConfirmed(event) {
    if (event.meta.type === 'Milestone') {
      if (event.meta.display_status === 'Confirmed' || event.meta.display_status === 'Completed') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  openAppointmentModal(appointment) {
    let userID = -1;
    if (this.currentUser.roles.includes('customer_user') || this.currentUser.roles.includes('customer_user_additional')) {
      userID = this.currentUser.id;
    } else {
      userID = this.user.id;
    }
    const modalRef = this.modalService.open(AppointmentModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.appointment = _.cloneDeep(appointment);
    modalRef.componentInstance.customerid = _.cloneDeep(userID);
    modalRef.componentInstance.edit_details_flag = false;
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  getIcon(event) {
    const type = event.meta.type;
    const isToday = this.isEventToday(event.start);
    const isPast = this.isPastEvent(event.start);
    const isFuture = this.isFutureEvent(event.start);
    let icon = '';
    switch (type) {
      case 'Possession':
        icon = icon = this.getPossessionIcon(isToday, isPast, isFuture);
        break;
      case 'Move-In':
      case 'Move-Out':
        icon = this.getMoveIcon(isToday, isPast, isFuture);
        break;

      case 'Milestone':
      case 'ToDo':
      case 'Appointment':
        if (event.meta.service_label) {
          icon = Utils.getServiceIcon(event.meta.service_label);
        } else {
          icon = this.getTodoIcon(isToday, isPast, isFuture);
        }
        break;
      default:
        icon = this.getTodoIcon(isToday, isPast, isFuture);
    }
    return icon;
  }

  getPossessionIcon(isToday, isPast, isFuture) {
    const icon = '/assets/images/icons/possession_black.png';
    return icon;
  }

  getMoveIcon(isToday, isPast, isFuture) {
    const icon =
      isToday || isPast ? '/assets/images/icons/move-in-small-black.png' : isFuture ? '/assets/images/icons/move-in-small-white.png' : '';
    return icon;
  }

  getTodoIcon(isToday, isPast, isFuture) {
    const icon = '/assets/images/icons/lg-todo.svg';
    return icon;
  }
}
