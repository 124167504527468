import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
@Component({
  selector: 'app-activation-status',
  templateUrl: './activation-status.component.html',
  styleUrls: ['./activation-status.component.sass'],
})
export class ActivationStatusComponent implements OnInit {
  statusList: any = [];

  @Input() activation_status;
  selectedCustomer: any;
  constructor(
    private localStorageService: LocalStorageService,
    private dataService: DataService
  ) {}

  ngOnInit() {

    this.statusList = this.activation_status;
  }

  getStatusIcon(status: any) {
    if (
      status.task === 'FIRST_EMAIL' ||
      status.task === 'WELCOME_CALL_RESCHEDULE_EMAIL'
    ) {
      if (status.pending === false && status.success === true) {
        return '/assets/images/icons/message-icon.svg';
      } else if (status.pending === false && status.success === false) {
        return '/assets/images/icons/message-icon-red.svg';
      } else {
        return '/assets/images/icons/message-icon-blue.svg';
      }
    }

    if (
      status.task === 'WELCOME_CALL' ||
      status.task === 'WELCOME_CALL_RESCHEDULED'
    ) {
      if (status.pending === false && status.success === true) {
        return '/assets/images/icons/phone-icon.svg';
      } else if (status.pending === false && status.success === false) {
        return '/assets/images/icons/phone-icon-red.svg';
      } else {
        return '/assets/images/icons/phone-icon-blue.svg';
      }
    }

    if (status.task === 'CREATE_TICKET' || status.task === 'CREATE_TICKET_2') {
      if (status.pending === false && status.success === true) {
        return '/assets/images/icons/chat-24px-white.svg';
      }
      if (status.pending === false && status.success === false) {
        return '/assets/images/icons/chat-24px-red.svg';
      } else {
        return '/assets/images/icons/chat-24px.svg';
      }
    }

    if (status.task === 'ACTIVE') {
      if (status.pending === false && status.success === true) {
        return '/assets/images/icons/flag-check-black-white.svg';
      }
      if (status.pending === false && status.success === false) {
        return '/assets/images/icons/flag-check-red.svg';
      } else {
        return '/assets/images/icons/flag-check-black.svg';
      }
    }
    return '/assets/images/icons/phone-icon.svg';
  }
}
