import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { Subject } from 'rxjs-compat/Subject';
import { DataService } from './data.service';
@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(
    private dataService: DataService,
    private httpClient: HttpClient
  ) {}

  public uploadUserDocument(file_body, document): Observable<any> {
    const fileData: any = new Subject<any>();
    this.dataService.uploadUserDocument(document).subscribe((data: any) => {
      const presignedUrl = data;
      let filetype = 'application/pdf';
      if (file_body && file_body.type) {
        filetype = file_body.type;
      }
      const headers = new HttpHeaders().set('Content-Type', filetype);

      return this.httpClient
        .put(presignedUrl, file_body, { headers })
        .subscribe((data: any) => {
          fileData.next(true);
        });
    });
    return fileData.asObservable();
  }
  public uploadFile(
    filename,
    body,
    upload_type,
    object_id,
    file_size
  ): Observable<any> {
    const params = new HttpParams()
      .set('filename', filename)
      .set('upload_type', upload_type)
      .set('object_id', object_id)
      .set('file_size', file_size);
    const urlData: any = new Subject<any>();
    this.dataService.getPresignedUrl(params).subscribe((data: any) => {
      const presignedUrl = data.presigned_url;
      urlData.next(data.public_url);
      let filetype = 'application/pdf';
      if (body && body.type) {
        filetype = body.type;
      }
      const headers = new HttpHeaders().set('Content-Type', filetype);

      return this.httpClient
        .put(presignedUrl, body, { headers })
        .subscribe((data: any) => {});
    });
    return urlData.asObservable();
  }
}
