import { select } from '@angular-redux/store';
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { ThemeOptions } from '../../theme-options';
import { ConfigActions } from '../../ThemeOptions/store/config.actions';

@Component({
  selector: 'app-apps-layout',
  templateUrl: './apps-layout.component.html',
  animations: [
    trigger('architectUIAnimation', [
      transition('* <=> *', [
        query(':enter, :leave', [
          style({
            opacity: 0,
            display: 'flex',
            flex: '1',
            transform: 'translateY(-20px)',
            flexDirection: 'column',
          }),
        ]),
        query(':enter', [
          animate(
            '600ms ease',
            style({ opacity: 1, transform: 'translateY(0)' })
          ),
        ]),

        query(
          ':leave',
          [
            animate(
              '600ms ease',
              style({ opacity: 0, transform: 'translateY(-20px)' })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class AppsLayoutComponent {
  @select('config') public config$: Observable<any>;
  user: any = {};
  selectedCustomer: any = {};
  selectedCustomerID: any;
  show_accounting_flag = false;
  isBuilder = false;
  paddingValue = 0;
  notFound = false;
  color: string;
  isNotepad = false;

  constructor(
    public globals: ThemeOptions,
    private router: Router,
    public configActions: ConfigActions,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute
  ) {
    if (window.location.pathname.includes('/notes')) {
      this.isNotepad = true;
    }

    this.user = this.localStorageService.getUser();

    const parts = this.router.url.split('/');
    this.selectedCustomerID = parts[2];

    if (
      this.user.roles.includes('csr') ||
      this.user.roles.includes('gm') ||
      this.user.roles.includes('administrator')
    ) {
      this.show_accounting_flag = true;
    }

    if (
      this.user.roles.includes('customer_user') ||
      this.user.roles.includes('customer_user_additional')
    ) {
      const isBuilderSession = sessionStorage.getItem('isBuilderSession');
      if (isBuilderSession === 'true') {
        this.globals.isBuilderActive = true;
        this.globals.isCustomerActive = false;
      } else {
        this.globals.isCustomerActive = true;
      }
    }

    if (
      this.user.roles.includes('builder') &&
      this.user.roles.includes('customer_user')
    ) {
      if (this.globals.isBuilderActive) {
        this.globals.isCustomerActive = false;
      }
    }

    if (
      this.user.roles.includes('builder') ||
      this.user.roles.includes('additional_builder')
    ) {
      this.isBuilder = true;
    }
  }

  async ngOnInit() {
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();
    this.localStorageService.storeSelectedCustomer(this.selectedCustomer);
    const currentUrl = this.router.url;

    if (currentUrl.includes('not-found')) {
      this.notFound = true;
    }
    const style = getComputedStyle(document.documentElement);
    this.color = style.getPropertyValue('--blue');
  }

  ngAfterViewInit() {
    // Access window.innerHeight here, as the view is now initialized
    this.paddingValue = this.calculatePadding();
  }

  calculatePadding(): number {
    return window.innerHeight;
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  closeSidebar() {
    this.globals._opened = false;
  }
}
