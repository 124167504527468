import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import HelloSign from 'hellosign-embedded';
import { DataService } from 'src/app/_helpers/data.service';
import { ThemeOptions } from 'src/app/theme-options';
@Component({
  selector: 'app-customer-invoice',
  templateUrl: './customer-invoice.component.html',
  styleUrls: ['./customer-invoice.component.sass'],
})
export class CustomerInvoiceComponent implements OnInit {
  invoiceId: number;
  invoiceDetails: any = {};

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private meta: Meta,
    public globals: ThemeOptions
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.meta.updateTag(
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0, maximum-scale=1.0',
        },
        'name=viewport'
      );
      this.invoiceId = +atob(params.get('invoice_id'));
      const user_id = +atob(params.get('user_id'));

      this.dataService
        .getInvoiceDetails(this.invoiceId, user_id)
        .subscribe((data: any) => {

          this.invoiceDetails = data;

        });
    });
  }

  openSignModal() {
    this.dataService
      .getClientInvoiceSignatureUrl(
        this.invoiceDetails.user_property_service_provider_id
      )
      .subscribe((data: any) => {
        const signature_url = data.signature_url;
        const client = new HelloSign();
        client.open(signature_url, {
          clientId: this.globals.helloSignApi,
          testMode: false,
          debug: false,
          skipDomainVerification: true,
        });

        client.on('sign', (data) => {



          this.dataService
            .setClientInvoiceSignedStatus(this.invoiceId)
            .subscribe((data: any) => {

              this.invoiceDetails.status = 'Waiting for signed invoice';
              this.invoiceDetails.intermediate_status_client =
                this.invoiceDetails.status;
            });
        });
      });
  }
}
