import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { FileUploadService } from 'src/app/_helpers/file-upload.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { UploadService } from 'src/app/_helpers/upload.service';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-new-to-do-modal',
  templateUrl: './new-to-do-modal.component.html',
  styleUrls: ['./new-to-do-modal.component.sass'],
})
export class NewToDoModalComponent implements OnInit {
  Editor: any;
  config: any;

  @Input() customerid = -1;

  @Input() admin_flag: boolean;
  hc_list: any = [];
  modified_hc_list: any = {};
  selectedAssignee: any = {};

  selectedCustomer: any = {};
  user: any;
  fullname: any;
  user_properties: any;
  to_do: any = {};
  id: any;
  selectedAddress: any = {};
  // user_property_id: any;
  customer_id: any;
  activeUser: any = [];
  customer_flag = false;
  users: any = [];

  privacyList: any;
  selectedPrivacy: any;

  serviceList: any = [];
  selectedService: any = {};
  activeServices: any = [];
  selectedActiveService: any = {};

  disableTypeEdit = true;
  severityList: any = [];
  selectedSeverity: any;
  customer: any = {};
  concierge: any = {};
  admin: any = {};
  admin_List: any = [];
  disable_for_customer = false;
  selectedAssigneeName: any;

  // Before/after Trigger date
  beforeAftertriggerDateList: any = [];
  selectedBeforeAfterTdate: any = {};

  // Trigger Date
  triggerDateList: any;
  selectedTriggerDate: any = {};
  isCustomer = true;
  displayTriggerDate = false;

  htmlContent = '';
  htmlToAdd: any;
  uploadFile: any = [];
  allServices: any = [];
  customer_info: any;
  admin_info: any;
  concierge_info: any;
  allHcList: any;
  allAdminList: any;
  @ViewChild('description') description: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
    public uploadService: UploadService,
    private fileUploadService: FileUploadService
  ) {}

  async ngOnInit() {
    this.user = this.localStorageService.getUser();

    this.id = this.user.id;

    this.to_do.description = '';
    this.config = this.uploadService.config;
    this.Editor = this.uploadService.Editor;

    this.getOptionList();
    if (this.customerid === -1) {
      this.getActiveUser();
    }

    this.serviceList = [];
    this.serviceList.push({
      service: {
        id: -1,
        name: 'General Todo',
        label: 'general_lbl',
      },
    });

    if (this.customerid !== -1) {
      const selected_user =
        await this.localStorageService.getSelectedCustomer();
      this.customer_id = selected_user.id;
      this.to_do.assignee_id = selected_user.hc.hc_id;
      if (this.disable_for_customer) {
        this.to_do.assignee_id = this.user.id;
      }
      this.user_properties = selected_user.user_properties;
      this.fullname = selected_user.fullname;
      this.customer_flag = true;
      this.selectedAssigneeName = selected_user.hc.fullname;
      this.onCustomerSelect(selected_user);
    } else {
      this.to_do.assignee_id = this.user.id;
      this.selectedAssigneeName = this.user.fullname;
      this.customer_flag = false;
    }

    if (
      this.user.roles.includes('customer_user') ||
      this.user.roles.includes('customer_user_additional')
    ) {
      this.isCustomer = true;
    } else {
      this.isCustomer = false;
    }
  }

  public onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  getAddress() {
    if (this.customer_flag) {
      this.selectedAddress = this.selectedCustomer.active_primary_user_property;
      this.getServices();
      return this.selectedAddress.property.formatted_address;
    } else {
      return 'Select';
    }
  }

  getService() {
    this.selectedService = this.serviceList[0];
    return this.selectedAssignee.fullname;
  }

  onFileChanged(event) {
    const file = event.target.files[0];

    const body = {
      filename: file.name,
    };

    this.dataService.upload_todo_image(body).subscribe((data: any) => {
      const presignedUrl = data.presigned_url;
      const public_url = data.public_url;
      const headers = new HttpHeaders().set('Content-Type', 'image/png');

      return this.httpClient
        .put(presignedUrl, file, { headers })
        .subscribe((data: any) => {
          const image = public_url;
          this.to_do.description = `
          <div style='position: relative'>
              <img src='${image}' />
          </div>`;
        });
    });
  }

  getOptionList() {
    this.dataService.getOptionList().subscribe((data: any) => {
      this.severityList = data.severity_list;
      this.privacyList = data.privacy_list;
      this.beforeAftertriggerDateList = data.days_before_after;
      this.triggerDateList = data.trigger_dates;

      if (
        this.user.roles.includes('customer_user') ||
        this.user.roles.includes('customer_user_additional')
      ) {
        const selectedSeverity = this.severityList.filter((severity) => {
          return severity.label === 'Normal';
        });
        this.selectedSeverity = selectedSeverity[0];

        const selectedPrivacy = this.privacyList.filter((privacy) => {
          return privacy.label === 'Public';
        });
        this.selectedPrivacy = selectedPrivacy[0];
        this.to_do.assignee_id = this.user.id;
        this.disable_for_customer = true;
      }

      if (this.user.roles.includes('csr')) {
        const selectedSeverity = this.severityList.filter((severity) => {
          return severity.label === 'Normal';
        });
        this.selectedSeverity = selectedSeverity[0];

        const selectedPrivacy = this.privacyList.filter((privacy) => {
          return privacy.label === 'Only hc and admin';
        });
        this.selectedPrivacy = selectedPrivacy[0];
      }
    });
  }

  setUrl() {}

  getServices() {
    this.dataService
      .getAvailableServices(this.selectedAddress.id)
      .subscribe((data: any) => {
        for (const obj of data) {
          let service = [];
          service = this.serviceList.filter((service_details: any) => {
            return service_details.service.id === obj.service.id;
          });
          if (service && service.length === 0) {
            this.serviceList.push(obj);
          }
        }
        this.allServices = this.serviceList;

        if (this.serviceList) {
          this.disableTypeEdit = false;
        }
      });
  }

  filterServices(value) {
    this.serviceList = this.allServices.filter((service_detail: any) => {
      return (
        service_detail.service.name
          .toLowerCase()
          .search(value.toLowerCase()) !== -1
      );
    });
  }

  filterAssignedFunction(value) {
    if (
      !(
        this.customer_info.fullname
          .toLowerCase()
          .search(value.toLowerCase()) !== -1
      )
    ) {
      this.customer = '';
    } else {
      this.customer = this.customer_info;
    }
    if (
      !(
        this.concierge_info.fullname
          .toLowerCase()
          .search(value.toLowerCase()) !== -1
      )
    ) {
      this.concierge = '';
    } else {
      this.concierge = this.concierge_info;
    }
    if (
      !(
        this.admin_info.fullname.toLowerCase().search(value.toLowerCase()) !==
        -1
      )
    ) {
      this.admin = '';
    } else {
      this.admin = this.admin_info;
    }
    this.admin_List = this.allAdminList.filter((admin: any) => {
      return admin.fullname.toLowerCase().search(value.toLowerCase()) !== -1;
    });
    this.hc_list = this.allHcList.filter((hc: any) => {
      return hc.fullname.toLowerCase().search(value.toLowerCase()) !== -1;
    });
  }

  getActiveServices() {
    this.activeServices = [];
    this.dataService
      .getActiveServices(
        this.selectedAddress.id,
        this.selectedService.service.id
      )
      .subscribe((data: any) => {
        if (data && data.length > 0) {
          data.forEach((element) => {
            const service = {
              id: element.id,
              display_name: element.service_name,
              display_date: '',
            };
            if (element.service_dates && element.service_dates.length > 0) {
              service.display_date = element.service_dates[0];
            }

            if (
              element.recurrence_details &&
              element.recurrence_details.recurrence_dates &&
              element.recurrence_details.recurrence_dates.length > 0
            ) {
              service.display_date =
                element.recurrence_details.recurrence_dates[0].date;
            }

            this.activeServices.push(service);
          });
          this.selectedActiveService = this.activeServices[0];
        }
      });
  }

  onSelectedActiveService(service) {
    this.selectedActiveService = service;
  }

  onSelectedService(service) {
    this.selectedService = service;
    this.selectedActiveService = null;

    this.getActiveServices();
  }

  onSelectedPrivacy(privacy) {
    this.selectedPrivacy = privacy;
  }

  onCustomerSelect(user) {
    if (this.selectedCustomer.id !== user.id) {
      this.selectedCustomer = user;
      this.user_properties = user.user_properties;

      this.selectedAssignee = {};
      this.selectedAddress = {};
      this.selectedService = {};

      this.getAssigneeList(user);
    }
  }

  getActiveUser() {
    if (this.admin_flag) {
      this.dataService
        .getUsersForAdmin(this.user.id, 'Active')
        .subscribe((data: any) => {
          this.activeUser = data;
          this.users = data;
        });
    } else {
      const body: any = {
        user_id: this.user.id,
        status: 'Active',
      };
      this.dataService.getUsersForMC(body).subscribe((data: any) => {
        this.activeUser = data;
        this.users = data;
      });
    }
    function compare(a, b) {
      a = a.fullname.toUpperCase();
      b = b.fullname.toUpperCase();

      return a > b ? 1 : b > a ? -1 : 0;
    }

    this.activeUser.sort(compare);
  }

  filterFunction(value) {
    this.activeUser = this.users.filter((user) => {
      return user.fullname.toLowerCase().search(value.toLowerCase()) !== -1;
    });
  }

  onCancel() {
    this.activeModal.close(false);
  }

  onAssigneeSelect(value) {
    this.selectedAssignee = value;
    if (value.type && value.type === 'customer') {
      this.to_do.assignee_id = value.id;
    } else if (value.admin_id) {
      this.to_do.assignee_id = value.admin_id;
    } else {
      this.to_do.assignee_id = value.hc_id;
    }
  }

  onSelectedTriggerDate(value) {
    this.selectedTriggerDate = value;
  }

  onSelectedBeforeAfterTriggerDate(value) {
    this.selectedBeforeAfterTdate = value;
  }

  onAddressSelect(info) {
    if (info.id !== this.selectedAddress) {
      this.selectedAddress = info;
      if (this.selectedAddress.id) {
        this.selectedService = {};
        this.getServices();
      }
    }
  }

  getHcList(user) {
    this.dataService.getHcList().subscribe((data: any) => {
      this.hc_list = data;
      this.allHcList = data;

      if (this.selectedCustomer.id) {
        this.customer.id = this.selectedCustomer.id;
        this.customer.type = 'customer';
        this.customer.fullname = this.selectedCustomer.fullname;
        this.customer_info = this.customer;
      }

      if (user.hc) {
        this.concierge = user.hc;
        this.concierge_info = this.concierge;
        for (let i = 0; i < this.hc_list.length; i++) {
          if (this.concierge.hc_id === this.hc_list[i].hc_id) {
            this.hc_list.splice(i, 1);
          }
        }
      }
    });
  }

  getAssigneeList(user) {
    this.dataService.getUserDetails(user.id).subscribe((data: any) => {
      const user_info = data;
      this.selectedAddress = data.active_primary_user_property;
      this.getServices();
      this.dataService.getAdminList().subscribe((data: any) => {
        this.admin_List = data;
        this.allAdminList = data;
        if (user_info.admin_id) {
          for (let i = 0; i < this.admin_List.length; i++) {
            if (this.admin_List[i].admin_id === user_info.admin_id) {
              this.admin = this.admin_List[i];
              this.admin_info = this.admin;
              this.admin_List.splice(i, 1);
            }
          }
        }

        this.getHcList(user_info);
      });
    });
  }

  changeDate(value) {
    const date = value.year + '-' + value.month + '-' + value.day;
    this.to_do.due_date = date;
  }

  closeFunction() {
    this.activeModal.close(true);
  }

  onSelectedSeverity(severity) {
    this.selectedSeverity = severity;
  }

  addNewTodo() {
    if (this.to_do.due_date) {
      const date =
        this.to_do.due_date.year +
        '-' +
        this.to_do.due_date.month +
        '-' +
        this.to_do.due_date.day;
      this.to_do.due_date = date;
    }

    if (this.selectedPrivacy) {
      this.to_do.privacy = this.selectedPrivacy.value;
    }

    if (this.selectedService && this.selectedService.service) {
      this.to_do.service_id = this.selectedService.service.id;
    } else {
      this.to_do.service_id = -1;
    }

    if (this.selectedActiveService && this.selectedActiveService.display_name) {
      this.to_do.user_property_service_id = this.selectedActiveService.id;
    } else {
      this.to_do.user_property_service_id = null;
    }

    if (this.selectedSeverity) {
      this.to_do.severity = this.selectedSeverity.value;
    }

    if (this.selectedTriggerDate.label) {
      this.to_do.default_trigger_date = this.selectedTriggerDate.value;
    }

    if (this.selectedBeforeAfterTdate.label) {
      this.to_do.days_before_after = this.selectedBeforeAfterTdate.value;
    }

    if (this.selectedAddress.id) {
      this.to_do.user_property_id = this.selectedAddress.id;
    }

    const body = {
      task: this.to_do,
    };

    this.dataService.createNewTask(body).subscribe((data: any) => {
      if (this.uploadFile && this.uploadFile.length > 0) {
        this.uploadFileUrl(data.id);
      }
      window.analytics.track('New ToDo', {
        user_id: this.selectedCustomer.id,
        id: data.id,
        due_date: this.to_do.due_date,
        title: this.to_do.title,
        user_property_id: this.selectedAddress.id,
        timestamp: moment().format(),
      });
      this.closeFunction();
    });
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      for (const i of event.target.files) {
        this.uploadFile.push(i);
      }
    }
  }

  removeFile(index) {
    this.uploadFile.splice(index, 1);
    if (this.uploadFile.length === 0) {
      this.uploadFile = [];
      const fileInput = document.getElementById(
        'validatedCustomFile'
      ) as HTMLInputElement;
      fileInput.value = ''; // Reset the input value
    }
  }

  uploadFileUrl(id) {
    for (const i of this.uploadFile) {
      this.fileUploadService.uploadFile(i.name, i, 'TASK_DOCUMENT', id, i.size);
    }
  }

  getProperty(userProperty) {
    let phases = '';
    let status = 0;
    for (
      status = 0;
      status < userProperty.user_property_status.length;
      status++
    ) {
      if (userProperty.user_property_status[status].status === 'Active') {
        phases = phases + userProperty.user_property_status[status].phase;
      }
      if (status !== userProperty.user_property_status.length - 1) {
        phases = phases + ' , ';
      }
    }
    return ' - ' + phases;
  }
}
