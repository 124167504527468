import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';

@Component({
  selector: 'app-property-info',
  templateUrl: './property-info.component.html',
  styleUrls: ['./property-info.component.sass'],
})
export class PropertyInfoComponent implements OnInit {
  @Input() isCustomer: boolean;
  @Input() edit_user_property: boolean;
  @Input() edit_only_property_details: boolean;
  @Input() property: any = {};
  @Input() community_flag = false;
  @Input() community = '';
  @Input() disabledFields = false;

  @Input() property_vertical_id: any;
  @Input() selectedBuilder: any;
  @Input() selectedProject: any;
  @Input() selectedProduct: any;
  @Input() status_obj: any;
  @Input() phases: any;

  @Input() statusList: any = [];
  @Input() buildersList: any = [];
  @Input() projectsList: any = [];
  @Input() productsList: any = [];
  @Input() user_property_status: any = [];

  @Input() additional_projects: any = [];
  @Output() builderSelect = new EventEmitter();
  @Output() additionalBuilderSelect = new EventEmitter();
  @Output() projectSelect = new EventEmitter();
  @Output() productSelect = new EventEmitter();
  @Output() statusSelect = new EventEmitter();
  @Output() phaseSelect = new EventEmitter();
  @Output() communitySelect = new EventEmitter();
  @Output() builderSignalUrlChange = new EventEmitter();
  @Output() additionalProjectSelect = new EventEmitter();
  @Output() removeStatus = new EventEmitter();
  @Output() removeAdditionalProject = new EventEmitter();
  myControl = new FormControl();
  @Input() options: any = [];
  filteredOptions: Observable<string[]>;
  all_builders: any = [];
  allProjects: any = [];
  is_builder_selected = false;
  is_project_selected = false;

  @Input() builder_signal_url = '';
  user: any = {};
  constructor(
    public dataService: DataService,
    public localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.user = this.localStorageService.getUser();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => (value.length >= 1 ? this._filter(value) : []))
    );
  }

  ngOnChanges() {
    if (!this.is_builder_selected) {
      this.all_builders = this.buildersList;
    }

    if (!this.is_project_selected) {
      this.allProjects = this.projectsList;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(
      (option) => option.community.toLowerCase().indexOf(filterValue) === 0
    );
  }

  canEditBuilderSignalURL() {
    return (
      this.user.roles.includes('gm') || this.user.roles.includes('builder')
    );
  }

  isMc() {
    return (
      this.user.roles.includes('csr') ||
      this.user.roles.includes('lead_concierge')
    );
  }

  onBuilderSelect(builder) {
    this.is_builder_selected = true;
    this.builderSelect.emit(builder);
  }
  onProjectSelect(project) {
    this.is_project_selected = true;
    this.projectSelect.emit(project);
  }

  onProductSelect(product) {
    this.productSelect.emit(product);
  }
  onPhaseSelect(phase, index) {
    this.phaseSelect.emit({ phase, index });
  }

  onAdditionalBuilderSelect(builder_account, index) {
    this.additionalBuilderSelect.emit({ builder_account, index });
  }

  onAdditionalProjectSelect(project, index) {
    this.additionalProjectSelect.emit({ project, index });
  }

  onStatusSelect(status, index) {
    this.statusSelect.emit({ status, index });
  }

  selectCommunity(option) {

    this.community = option.community;
    this.communitySelect.emit(option);
  }

  onChangeCommunity() {

    this.communitySelect.emit(this.community);
  }

  onChangeBuilderSignalUrl() {
    this.builderSignalUrlChange.emit(this.builder_signal_url);
  }

  removePropertyStatus(index) {
    this.user_property_status.splice(index, 1);
    this.removeStatus.emit(index);
  }

  removeProject(value, index) {
    this.additional_projects.splice(index, 1);
    this.removeAdditionalProject.emit(value);
  }

  filterFunction(value) {
    this.buildersList = this.all_builders.filter((builder: any) => {
      return (
        builder.builder_name.toLowerCase().search(value.toLowerCase()) !== -1
      );
    });
  }

  filterProjectFunction(value) {
    this.projectsList = this.allProjects.filter((project: any) => {
      return project.name.toLowerCase().search(value.toLowerCase()) !== -1;
    });
  }
}
