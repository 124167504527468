import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';

@Component({
  selector: 'app-referral-lead-info-modal',
  templateUrl: './referral-lead-info-modal.component.html',
  styleUrls: ['./referral-lead-info-modal.component.sass'],
})
export class ReferralLeadInfoModalComponent implements OnInit {
  @Input() item: any = {};
  constructor(
    public activeModal: NgbActiveModal,
    public dataService: DataService
  ) {}

  ngOnInit() {

  }

  approveReferralLead(approve) {
    const body = {
      approved: approve,
      credits: this.item.credits,
    };
    this.dataService
      .approveReferralLead(this.item.id, body)
      .subscribe((data: any) => {
        this.activeModal.close({ update_data: true });
      });
  }
}
