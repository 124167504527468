import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from '../../../../_helpers/local-storage.service';
import Utils from '../../../Elements/utils';
@Component({
  selector: 'app-maintenance-service-modal',
  templateUrl: './maintenance-service-modal.component.html',
  styleUrls: ['./maintenance-service-modal.component.sass'],
})
export class MaintenanceServiceModalComponent implements OnInit {
  maintenanceInfo: any;
  booleanCondition: 'true';
  user_property_id: any;
  video_url: any;
  isCustomer: boolean;
  loggedInUser: any;
  isBuilder = false;
  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  getActivities() {
    if (this.maintenanceInfo.url) {
      this.video_url = this.maintenanceInfo.url;
    }
    this.user_property_id = this.maintenanceInfo.user_property_id;
    const id = this.maintenanceInfo.id;

    const params = new HttpParams().set(
      'user_property_id',
      this.maintenanceInfo.user_property_id
    );
    this.dataService.getActivitiesDetails(params, id).subscribe((data: any) => {
      this.maintenanceInfo = data;
    });
  }

  closeFunction(needsRefresh) {
    this.activeModal.close(needsRefresh);
  }

  showURL() {
    window.open(this.video_url, '_blank');
  }

  goToCustomerProfile() {
    this.router.navigate([
      '/customer/' + this.maintenanceInfo.client_id + '/dashboard/',
    ]);
    this.activeModal.close();
  }

  isToday(due_date) {
    if (due_date) {
      return Utils.isTodoDueToday(due_date);
    } else {
      return false;
    }
  }

  isTodoOverDue(due_date) {
    if (due_date) {
      return Utils.isTodoOverDue(due_date);
    } else {
      return false;
    }
  }

  activitiesCompleted() {
    const body = {
      is_completed: true,
      user_property_id: this.maintenanceInfo.user_property_id
        ? this.maintenanceInfo.user_property_id
        : 1,
      master_todo_id: this.maintenanceInfo.id,
      is_master_todo: this.maintenanceInfo.is_master_todo,
      due_date: this.maintenanceInfo.due_date,
    };
  }

  ngOnInit() {
    this.loggedInUser = this.localStorageService.getUser();
    if (
      this.loggedInUser.roles.includes('builder') ||
      this.loggedInUser.roles.includes('additional_builder')
    ) {
      this.isBuilder = true;
    }

    if (
      this.loggedInUser.roles.includes('customer_user') ||
      this.loggedInUser.roles.includes('customer_user_additional')
    ) {
      this.isCustomer = true;
    }
    this.getActivities();
  }

  onServiceDetails() {
    if (this.maintenanceInfo.service_id) {
      const repeating = false;
      const type = 'on_demand';
      const body = {
        user_property_id: this.user_property_id,
        service_id: this.maintenanceInfo.service_id,
        repeating,
      };
      this.dataService.addService(body).subscribe((data: any) => {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            user_property_service_id: data.id,
            new_service: true,
          },
        };
        this.router.navigate(
          [
            'customer/' +
              this.maintenanceInfo.client_id +
              '/home-services/home-service-details/',
          ],
          navigationExtras
        );

        this.activeModal.close();
      });
    }
  }
}
