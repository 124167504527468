import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import swal, { SweetAlertOptions } from 'sweetalert2';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-nps-provider',
  templateUrl: './nps-provider.component.html',
  styleUrls: ['./nps-provider.component.sass'],
})
export class NpsProviderComponent implements OnInit {
  user_property_service_id: number;
  user_id: number;
  provider_location_id: number;
  selectedRating: number;
  provider: any = {};
  ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  comments: string;
  submitted: boolean;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.user_property_service_id = +atob(
        params.get('user_property_service_id')
      );
      this.user_id = +atob(params.get('user_id'));
      this.provider_location_id = +atob(params.get('provider_location_id'));
      this.selectedRating = +params.get('rating');
      this.getProvider();
    });
  }

  getProvider() {
    this.dataService
      .getProviderLocationDetails(this.provider_location_id)
      .subscribe((data: any) => {
        this.provider = data;
      });
  }

  changeRating(i) {
    if (this.selectedRating !== i) {
      this.selectedRating = i;
    }
  }

  submitRating() {
    const body = {
      user_property_service_id: this.user_property_service_id,
      provider_location_id: this.provider_location_id,
      score: this.selectedRating,
      key_factors: this.comments,
    };
    this.dataService.submitProviderNps(body).subscribe(
      (data: any) => {
        const options = {
          text: 'Rating submitted!',
          type: 'success',
          showCancelButton: false,
          allowOutsideClick: false,
        } as SweetAlertOptions;

        swal(options).then((result) => {});

        window.analytics.track('Service Provider NPS Submitted', {
          user_id: this.user_id,
          user_property_service_id: this.user_property_service_id,
          score: this.selectedRating,
          comments: this.comments,
          provider_name: this.provider.name,
          provider_email: this.provider.user.email,
          service_type: this.provider.provider_services[0].service_name,
          timestamp: moment().format(),
        });
        this.submitted = true;
      },
      (err: any) => {}
    );
  }
}
