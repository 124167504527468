import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import swal, { SweetAlertOptions } from 'sweetalert2';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-nps-virtuo',
  templateUrl: './nps-virtuo.component.html',
  styleUrls: ['./nps-virtuo.component.sass'],
})
export class NpsVirtuoComponent implements OnInit {
  ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  user_property_id: number;
  user_id: number;
  comments: string;
  selectedRating: number;
  homeServicesNps = false;
  currentUser: any = {};
  submitted: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {

      if (this.router.url.includes('home-services')) {
        this.homeServicesNps = true;
      }
      this.user_property_id = +atob(params.get('user_property_id'));
      this.user_id = +atob(params.get('user_id'));
      this.selectedRating = +params.get('rating');
    });
  }

  changeRating(i) {
    if (this.selectedRating !== i) { this.selectedRating = i; }
  }

  submitRating() {
    const body = {
      user_property_id: this.user_property_id,
      score: this.selectedRating,
      comments: this.comments,
      is_home_service_rating: this.homeServicesNps,
    };

    this.dataService.submitVirtuoNps(body).subscribe((data: any) => {
      const options = {
        text: 'Rating submitted!',
        type: 'success',
        showCancelButton: false,
      } as SweetAlertOptions;

      swal(options).then((result) => {});

      window.analytics.track('NPS Submitted', {
        user_id: this.user_id,
        user_property_id: this.user_property_id,
        score: this.selectedRating,
        comments: this.comments,
        timestamp: moment().format(),
      });
      this.submitted = true;
    });
  }
}
