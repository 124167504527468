import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxFileDropModule } from 'ngx-file-drop';
import { apiProvider } from './_helpers';
import { loaderProvider } from './_helpers';
import { LocalStorageService } from './_helpers/local-storage.service';
import { ThemeOptions } from './theme-options';
import { ConfigActions } from './ThemeOptions/store/config.actions';

// HEADER

import { HeaderComponent } from './Layout/Components/header/header.component';

import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';

// FOOTER

import { FooterComponent } from './Layout/Components/footer/footer.component';

import { McCustomerSidebarComponent } from './Layout/Components/mc-sidebar/customer/mc-sidebar.component';

import { McMainSidebarComponent } from './Layout/Components/mc-sidebar/mc/mc-main-sidebar/mc-main-sidebar.component';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { ChatModalComponent } from './AppPages/MCPortal/customer/chat-modal/chat-modal.component';

import { NewToDoModalComponent } from './AppPages/MCPortal/customer/new-to-do-modal/new-to-do-modal.component';
import { RateCalculatorModalComponent } from './AppPages/ServiceProviderPortal/rates/rate-calculator-modal/rate-calculator-modal.component';
import { StandardRatesModalComponent } from './AppPages/ServiceProviderPortal/rates/standard-rates-modal/standard-rates-modal.component';

import { CalendarModalComponent } from './AppPages/Elements/calendar/calendar-modal.component';
import { CalendarComponent } from './AppPages/Elements/calendar/calendar.component';
import { DateRangePickerComponent } from './AppPages/Elements/date-range-picker/date-range-picker.component';
import { DatepickerModalComponent } from './AppPages/Elements/datepicker/datepicker-modal.component';
import { DropzoneModalComponent } from './AppPages/Elements/dropzone/dropzone-modal.component';
import { DynamicInformationComponent } from './AppPages/Elements/dynamic-information/dynamic-information.component';
import { DynamicInputComponent } from './AppPages/Elements/dynamic-input/dynamic-input.component';
import { ImageCropperModalComponent } from './AppPages/Elements/image-cropper/image-cropper-modal.component';
import { InputMaskComponent } from './AppPages/Elements/input-mask/input-mask.component';
import { LoaderComponent } from './AppPages/Elements/loader/loader.component';
import { StarsRatingComponent } from './AppPages/Elements/stars-rating/stars-rating.component';
import { TimelineComponent } from './AppPages/Elements/timeline/timeline.component';
import { ServiceAreasModalComponent } from './AppPages/ServiceProviderPortal/profile/service-areas-modal/service-areas-modal.component';
import { ServiceInfoModalComponent } from './AppPages/ServiceProviderPortal/profile/service-info-modal/service-info-modal.component';

import { AwardsModalComponent } from './AppPages/ServiceProviderPortal/profile/awards-modal/awards-modal.component';
import { BillingModalComponent } from './AppPages/ServiceProviderPortal/profile/billing-modal/billing-modal.component';
import { GeneralInfoModalComponent } from './AppPages/ServiceProviderPortal/profile/general-info-modal.component';
import { PrimaryContactModalComponent } from './AppPages/ServiceProviderPortal/profile/primary-contact-modal.component';
import { DayExceptionComponent } from './AppPages/ServiceProviderPortal/rates/day-exception/day-exception.component';

import { AddressDetailsComponent } from './AppPages/Elements/address-details/address-details.component';
import { FloatingButtonsComponent } from './AppPages/Elements/floating-buttons/floating-buttons.component';
import { KeyDatesComponent } from './AppPages/Elements/key-dates/key-dates.component';
import { MatchingProviderLocationsComponent } from './AppPages/Elements/matching-provider-locations/matching-provider-locations.component';
import { OrderedPackageItemComponent } from './AppPages/Elements/ordered-package-item/ordered-package-item.component';
import { PackageItemComponent } from './AppPages/Elements/package-item/package-item.component';
import { PropertyInfoComponent } from './AppPages/Elements/property-info/property-info.component';
import { ChatWidgetComponent } from './Layout/Components/chat-widget/chat-widget.component';

import { ActivationStatusComponent } from './AppPages/Elements/activation-status/activation-status.component';
import { BuilderProjectInputComponent } from './AppPages/Elements/builder-project-input/builder-project-input/builder-project-input.component';
import { ActivitiesCalendarModalComponent } from './AppPages/Elements/calendar/activities-calendar/activities-calendar-modal/activities-calendar-modal.component';
import { ActivitiesCalendarComponent } from './AppPages/Elements/calendar/activities-calendar/activities-calendar.component';
import { DateInputComponent } from './AppPages/Elements/Date-input/date-input/date-input.component';
import { DealLocationComponent } from './AppPages/Elements/DealLocation/deal-location/deal-location.component';
import { ServiceAreaInputComponent } from './AppPages/Elements/service-area-input/service-area-input.component';
import { TimepickerModalComponent } from './AppPages/Elements/timepicker-modal/timepicker-modal.component';
import { UploadInputComponent } from './AppPages/Elements/upload-input/upload-input/upload-input.component';
import { AppsLayoutComponent } from './Layout/apps-layout/apps-layout.component';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { HomeLayoutComponent } from './Layout/home-layout/home-layout.component';

import { SidebarModule } from 'ng-sidebar';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { DealPreviewComponent } from './AppPages/Elements/deal-preview/deal-preview.component';
// BOOTSTRAP COMPONENTS

import { AgmCoreModule } from '@agm/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { LaddaModule } from 'angular2-ladda';
import { TextMaskModule } from 'angular2-text-mask';
import { CountUpModule } from 'countup.js-angular2';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { NouisliderModule } from 'ng2-nouislider';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxLoadingModule } from 'ngx-loading';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ToastrModule } from 'ngx-toastr';

// ANGULAR MATERIAL COMPONENTS

import { DatePipe } from '@angular/common';
import { MatCheckboxModule, MatRippleModule } from '@angular/material';
import { MatBottomSheetModule, MatButtonModule } from '@angular/material';
import { MatNativeDateModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { DropdownComponent } from './AppPages/Elements/dropdown/dropdown.component';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// CHART
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SafeHtmlPipe } from './_helpers/safeHtml.pipe';
import { InviteAdditionalBuilderModalComponent } from './AppPages/BuilderPortal/invite-additional-builder-modal/invite-additional-builder-modal.component';
import { ReferralLeadInfoModalComponent } from './AppPages/BuilderPortal/referral-lead-info-modal/referral-lead-info-modal.component';
import { CustomSnackbarComponent } from './AppPages/Elements/custom-snackbar/custom-snackbar.component';
import { CustomToastComponent } from './AppPages/Elements/custom-toast/custom-toast.component';
import { DeleteCampaignModalComponent } from './AppPages/Elements/delete-campaign-modal/delete-campaign-modal.component';
import { ProviderBlockedDatesPickerComponent } from './AppPages/Elements/provider-blocked-dates-picker/provider-blocked-dates-picker.component';
import { ProviderDealExceptionsComponent } from './AppPages/Elements/provider-deal-exceptions/provider-deal-exceptions.component';
import { UndoBottomSheetComponent } from './AppPages/Elements/undo-bottom-sheet/undo-bottom-sheet.component';
import { AppointmentModalComponent } from './AppPages/MCPortal/customer/appointment-modal/appointment-modal.component';
import { RecordVideoModal } from './AppPages/MCPortal/customer/record-video-modal/record-video-modal.component';
import { ProviderAvailabilityComponent } from './AppPages/provider-availability/provider-availability.component';

// multi-use-components
import { FileCabinetComponent } from 'src/app/AppPages/multi-use-components/file-cabinet/file-cabinet.component';
import { FolderDocumentsComponent } from 'src/app/AppPages/multi-use-components/file-cabinet/folder-documents/folder-documents.component';
import { UploadDocumentModalComponent } from 'src/app/AppPages/multi-use-components/file-cabinet/upload-document-modal/upload-document-modal.component';
import { TimelinePageComponent } from 'src/app/AppPages/multi-use-components/timeline/timeline.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*',
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    NgbModule,
    AngularFontAwesomeModule,
    LaddaModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    RoundProgressModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn',
    }),
    ToastrModule.forRoot({}),
    SlickCarouselModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    CountUpModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyDRmyYiSq2v8FUVh91rJeqOcSeruNWl-aI',
      libraries: ['drawing'],
    }),
    ImageCropperModule,
    AngularStickyThingsModule,
    NouisliderModule,
    NgSelectModule,
    SelectDropDownModule,
    NgMultiSelectDropDownModule.forRoot(),
    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    NgxFileDropModule,
    TextMaskModule,
    ClipboardModule,
    TextareaAutosizeModule,
    ColorPickerModule,
    DropzoneModule,
    CKEditorModule,

    // Charts

    ChartsModule,
    // NgApexchartsModule,
    // GaugeModule.forRoot(),
    // TrendModule,

    // Angular Material Components

    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTreeModule,
    MatBadgeModule,
    MatRippleModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    CommonModule,
    LoadingBarRouterModule,
    GooglePlaceModule,
    NgxPowerBiModule,
    InternationalPhoneNumberModule,
    NgxMaterialTimepickerModule,
    UiSwitchModule,
    NgxChartsModule,
    MatBottomSheetModule,
    SidebarModule.forRoot(),
  ],
  declarations: [
    HomeLayoutComponent,
    AppsLayoutComponent,
    BaseLayoutComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,

    // FOOTER

    FooterComponent,

    McCustomerSidebarComponent,

    McMainSidebarComponent,

    PageTitleComponent,
    DropdownComponent,

    CalendarModalComponent,
    CalendarComponent,
    DealPreviewComponent,
    DatepickerModalComponent,
    DropzoneModalComponent,
    ImageCropperModalComponent,
    LoaderComponent,
    TimelineComponent,
    InputMaskComponent,
    DateRangePickerComponent,
    StarsRatingComponent,
    DynamicInputComponent,
    DynamicInformationComponent,
    NewToDoModalComponent,
    ChatModalComponent,

    StandardRatesModalComponent,
    RateCalculatorModalComponent,
    DatepickerModalComponent,

    GeneralInfoModalComponent,
    PrimaryContactModalComponent,
    AwardsModalComponent,
    DayExceptionComponent,
    BillingModalComponent,
    ServiceInfoModalComponent,
    ServiceAreasModalComponent,
    ActivitiesCalendarComponent,
    UndoBottomSheetComponent,
    ActivitiesCalendarModalComponent,

    TimepickerModalComponent,
    DateInputComponent,
    UploadInputComponent,
    DealLocationComponent,
    ActivationStatusComponent,
    BuilderProjectInputComponent,
    ServiceAreaInputComponent,
    MatchingProviderLocationsComponent,

    PackageItemComponent,
    OrderedPackageItemComponent,
    FloatingButtonsComponent,
    ChatWidgetComponent,
    AddressDetailsComponent,
    KeyDatesComponent,
    PropertyInfoComponent,
    SafeHtmlPipe,
    ProviderDealExceptionsComponent,
    ProviderAvailabilityComponent,
    UndoBottomSheetComponent,
    CustomToastComponent,
    AppointmentModalComponent,
    RecordVideoModal,
    ReferralLeadInfoModalComponent,
    ProviderBlockedDatesPickerComponent,
    InviteAdditionalBuilderModalComponent,
    DeleteCampaignModalComponent,
    CustomSnackbarComponent,

    // Angular Bootstrap Components

    // multi-use-components
    FileCabinetComponent,
    FolderDocumentsComponent,
    UploadDocumentModalComponent,
    TimelinePageComponent,
  ],
  exports: [
    HomeLayoutComponent,
    AppsLayoutComponent,
    BaseLayoutComponent,
    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,
    CKEditorModule,
    // SIDEBAR

    SidebarComponent,

    // FOOTER

    FooterComponent,

    McCustomerSidebarComponent,

    McMainSidebarComponent,

    PageTitleComponent,
    DropdownComponent,

    CalendarModalComponent,
    CalendarComponent,
    DatepickerModalComponent,
    DropzoneModalComponent,
    ImageCropperModalComponent,
    LoaderComponent,
    TimelineComponent,
    InputMaskComponent,
    DateRangePickerComponent,
    StarsRatingComponent,
    DynamicInputComponent,
    DynamicInformationComponent,
    ActivitiesCalendarComponent,
    DealPreviewComponent,

    ActivitiesCalendarModalComponent,
    ReferralLeadInfoModalComponent,
    InviteAdditionalBuilderModalComponent,
    ProviderBlockedDatesPickerComponent,
    TimepickerModalComponent,
    DateInputComponent,
    UploadInputComponent,
    DealLocationComponent,
    ActivationStatusComponent,
    BuilderProjectInputComponent,
    ServiceAreaInputComponent,
    MatchingProviderLocationsComponent,

    PackageItemComponent,
    OrderedPackageItemComponent,
    FloatingButtonsComponent,
    ChatWidgetComponent,
    AddressDetailsComponent,
    KeyDatesComponent,
    PropertyInfoComponent,
    ProviderDealExceptionsComponent,
    ProviderAvailabilityComponent,
    CommonModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    NgbModule,
    AngularFontAwesomeModule,
    LaddaModule,
    FormsModule,
    ReactiveFormsModule,

    PerfectScrollbarModule,
    NgbModule,
    AngularFontAwesomeModule,
    LaddaModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule,
    NgxLoadingModule,
    RoundProgressModule,
    SweetAlert2Module,
    ToastrModule,
    SlickCarouselModule,
    CalendarModule,
    CountUpModule,
    AgmCoreModule,
    ImageCropperModule,
    AngularStickyThingsModule,
    NouisliderModule,
    NgSelectModule,
    SelectDropDownModule,
    NgMultiSelectDropDownModule,

    RoundProgressModule,

    SlickCarouselModule,

    CountUpModule,

    ImageCropperModule,
    AngularStickyThingsModule,
    NouisliderModule,
    NgSelectModule,
    SelectDropDownModule,

    JwBootstrapSwitchNg2Module,
    AngularEditorModule,
    HttpClientModule,
    NgxFileDropModule,
    TextMaskModule,
    ClipboardModule,

    TextareaAutosizeModule,
    ColorPickerModule,
    DropzoneModule,

    // Charts

    ChartsModule,
    // NgApexchartsModule,
    // GaugeModule.forRoot(),
    // TrendModule,

    // Angular Material Components

    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTreeModule,
    MatBadgeModule,
    MatRippleModule,
    CommonModule,
    LoadingBarRouterModule,
    GooglePlaceModule,
    NgxPowerBiModule,
    InternationalPhoneNumberModule,
    NgxMaterialTimepickerModule,
    UiSwitchModule,
    NgxChartsModule,
    SafeHtmlPipe,
    SidebarModule,
    MatBottomSheetModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    DeleteCampaignModalComponent,
    CustomSnackbarComponent,

    // multi-use-components
    FileCabinetComponent,
    FolderDocumentsComponent,
    UploadDocumentModalComponent,
    TimelinePageComponent,
  ],

  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
    ThemeOptions,
    LocalStorageService,
    DatePipe,
    apiProvider,
    loaderProvider,
  ],
  entryComponents: [
    CalendarModalComponent,
    NewToDoModalComponent,
    ChatModalComponent,
    StandardRatesModalComponent,
    RateCalculatorModalComponent,
    DatepickerModalComponent,
    DateRangePickerComponent,
    DropzoneModalComponent,
    ImageCropperModalComponent,
    GeneralInfoModalComponent,
    PrimaryContactModalComponent,
    AwardsModalComponent,
    DayExceptionComponent,
    BillingModalComponent,
    ServiceInfoModalComponent,
    ServiceAreasModalComponent,
    ActivitiesCalendarModalComponent,
    TimepickerModalComponent,
    DynamicInformationComponent,
    UndoBottomSheetComponent,
    CustomToastComponent,
    AppointmentModalComponent,
    RecordVideoModal,
    ReferralLeadInfoModalComponent,
    ProviderBlockedDatesPickerComponent,
    InviteAdditionalBuilderModalComponent,
    DeleteCampaignModalComponent,
    CustomSnackbarComponent,

    // multi-use-components
    UploadDocumentModalComponent,
  ],
})
export class VirtuoCommonModule {}
