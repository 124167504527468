import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-standard-rates-modal',
  templateUrl: './standard-rates-modal.component.html',
})
export class StandardRatesModalComponent implements OnInit {
  @Input()
  providerServiceRate: any;

  @Input()
  currency: any;

  @Input()
  service_rate_description: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {


  }

  save() {
    this.activeModal.close(this.providerServiceRate);
  }

  isEmpty() {
    let disabled_flag = false;
    if (
      this.providerServiceRate.selected_additional_rates.length > 0 ||
      this.providerServiceRate.selected_additional_services.length > 0
    ) {
      for (const rate of this.providerServiceRate.selected_additional_rates) {
        disabled_flag = rate.standard_rate !== null ? true : false;
        if (!disabled_flag) { return !disabled_flag; }
      }
      for (const service of this.providerServiceRate
        .selected_additional_services) {
        disabled_flag = service.standard_rate !== null ? true : false;
        if (!disabled_flag) { return !disabled_flag; }
      }
      return !disabled_flag;
    } else {
      return false;
    }
  }
}
