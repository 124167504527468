import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import Utils from 'src/app/AppPages/Elements/utils';
declare const google: any;

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.sass'],
})
export class AddressDetailsComponent implements OnInit {
  @Input()
  property: any = {};

  @Input()
  zoom: any;

  @Input()
  placeDetails: any = {};

  @Input()
  loading: boolean;

  @ViewChild('search') searchElementRef: ElementRef;
  @Output() selectPlace = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  handleMarkerChange(place: any) {
    const place_details = Utils.getLocationDetails(place);
    this.property.formatted_address = place.formatted_address;
    delete place_details.display_name;
    this.placeDetails = place_details;
    if (place_details.place_id) {
      this.selectPlace.emit({
        placeDetails: this.placeDetails,
        property: this.property,
      });
    }
  }

  handleAddressChange(place: Address) {
    const place_details = Utils.getLocationDetails(place);
    this.property.formatted_address = this.searchElementRef.nativeElement.value;
    this.zoom = 12;
    delete place_details.display_name;
    this.placeDetails = place_details;
    if (place_details.place_id) {
      this.selectPlace.emit({
        placeDetails: this.placeDetails,
        property: this.property,
      });
    }
  }

  markerDragEnd($event: any) {

    const geocoder = new google.maps.Geocoder();
    const lat = $event.coords.lat;
    const lng = $event.coords.lng;
    const lat_lng = { lat, lng };

    this.loading = true;
    geocoder.geocode({ location: lat_lng }, (results, status) => {
      this.loading = false;
      if (status === google.maps.GeocoderStatus.OK) {
        const place = results[0];
        if (place) {
          this.zoom = 12;
          this.handleMarkerChange(place);
          this.property.formatted_address = place.formatted_address;
        } else {

        }
      } else {

      }
    });
  }
}
