import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
@Component({
  selector: 'app-confirm-service-details-modal',
  templateUrl: './confirm-service-details-modal.component.html',
  styleUrls: ['./confirm-service-details-modal.component.sass'],
})
export class ConfirmServiceDetailsModalComponent implements OnInit {
  details: any = {};
  user_property_service_provider: any = {};
  @Input() provider_location: any;
  notes: any = [];

  agreeToTerms = false;

  constructor(
    public activeModal: NgbActiveModal,
    public dataService: DataService
  ) {}

  ngOnInit() {
    this.user_property_service_provider =
      this.provider_location.user_property_service_provider;

    this.notes = this.user_property_service_provider.service_details.filter(
      (note: any) => {
        return note.label === 'notes';
      }
    );
    if (this.notes[0].detail) {
      this.details.detail = this.notes[0].detail;
    }
    if (this.notes[0].id) {
      this.details.id = this.notes[0].id;
    }
    this.details.service_detail_id = this.notes[0].service_detail_id;
  }

  save() {
    this.activeModal.close(this.details);
  }
}
