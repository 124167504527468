import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-awards-modal',
  templateUrl: './awards-modal.component.html',
  styleUrls: ['./awards-modal.component.sass'],
})
export class AwardsModalComponent implements OnInit {
  @Input()
  awards: any;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  save() {
    this.activeModal.close(this.awards);
  }

  addNew() {
    this.awards.push({});
  }

  deleteRow(id) {
    this.awards.splice(id, 1);
  }
}
