import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datepicker-modal',
  templateUrl: './datepicker-modal.component.html',
  styles: [
    `
      .bg-light {
        background-color: var(--white) !important;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }

      .custom-day.focused {
        background-color: var(--l-grey);
      }

      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: var(--white);
      }

      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
})
export class DatepickerModalComponent implements OnInit {
  @Input()
  selectedDate: Date;

  @Input()
  range: boolean;

  @Input()
  fromDate: NgbDate;

  @Input()
  toDate: NgbDate;

  @Input()
  rate: string;

  dateRange: {};

  model: NgbDateStruct;

  date: { year: number; month: number };
  public isCollapsed = false;
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;

  hoveredDate: NgbDate;

  constructor(public activeModal: NgbActiveModal, calendar: NgbCalendar) {}

  ngOnInit() {
    if (this.selectedDate) {
      this.model = {
        day: this.selectedDate.getUTCDay(),
        month: this.selectedDate.getUTCMonth() + 1,
        year: this.selectedDate.getUTCFullYear(),
      };
    }
  }

  save() {
    if (this.range) {
      this.dateRange = {
        fromDate: new Date(
          this.fromDate.year,
          this.fromDate.month - 1,
          this.fromDate.day
        ),
        toDate: new Date(
          this.toDate.year,
          this.toDate.month - 1,
          this.toDate.day
        ),
        rate: this.rate,
      };
      this.activeModal.close(this.dateRange);
    } else {
      this.activeModal.close(
        new Date(this.model.year, this.model.month - 1, this.model.day)
      );
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }
}
