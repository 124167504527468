import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { DataService } from 'src/app/_helpers/data.service';

@Component({
  selector: 'app-invite-additional-builder-modal',
  templateUrl: './invite-additional-builder-modal.component.html',
  styleUrls: ['./invite-additional-builder-modal.component.sass'],
})
export class InviteAdditionalBuilderModalComponent implements OnInit {
  additionalUser: any = {};
  showSecondEmail: boolean;
  newPhoneList: any = [];
  dropdownSettings: IDropdownSettings = {};
  phone_number_types: any = ['Mobile', 'Office', 'Home'];
  phone_no_flag = true;
  builderRolesList: any = [];
  selectedBuilderRole: any = { id: -1, role_name: '' };
  isEdit = false;

  error_message: string;
  @Input()
  provider_user_id = -1;

  @Input()
  builder_user_id = -1;

  @Input() userDetails: any;
  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'value',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      enableCheckAll: false,
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };

    this.additionalUser.number_type = 'Mobile';
    this.additionalUser.second_number_type = 'Mobile';
    this.additionalUser.third_number_type = 'Mobile';
    if (this.builder_user_id > 0) {
      this.getBuilderRoles();
    }
  }

  setDetails() {
    this.additionalUser.first_name = this.userDetails.first_name;
    this.additionalUser.last_name = this.userDetails.last_name;
    this.additionalUser.email = this.userDetails.email;
    this.additionalUser.phone_number = this.userDetails.phone_number;
    this.additionalUser.number_type = this.userDetails.number_type;
    if (this.userDetails.builder_role) {
      const userRole = this.builderRolesList.find(
        (role) => role.role_name == this.userDetails.builder_role
      );
      this.selectedBuilderRole = userRole;
    } else {
      this.selectedBuilderRole = { id: 1, role_name: 'Admin' };
    }
  }

  getBuilderRoles() {
    this.dataService.getBuilderRoles().subscribe((data: any) => {
      this.builderRolesList = data;
      if (this.userDetails) {
        this.setDetails();
        this.isEdit = true;
      }
    });
  }

  removePhoneNumber(index) {
    if (index === 0) {
      if (
        this.additionalUser.third_number !== null &&
        this.additionalUser.third_number !== ''
      ) {
        this.additionalUser.second_phone_number =
          this.additionalUser.third_number;
        this.additionalUser.second_number_type =
          this.additionalUser.third_number_type;
        this.additionalUser.third_number = '';
        this.additionalUser.third_number_type = 'Mobile';
      } else {
        this.additionalUser.second_phone_number = '';
        this.additionalUser.second_number_type = 'Mobile';
      }
    }
    if (index === 1) {
      this.additionalUser.third_number = '';
      this.additionalUser.third_number_type = 'Mobile';
    }
    this.newPhoneList.splice(index, 1);
  }

  onSubmit() {
    this.error_message = null;
    if (this.builder_user_id > 0) {
      this.additionalUser.builder_role_id = this.selectedBuilderRole.id;
    }
    const body: any = {
      additional_user: this.additionalUser,
    };
    if (!this.isEdit) {
      if (this.provider_user_id !== -1) {
        body.primary_user_id = this.provider_user_id;
        this.dataService.createAdditionalProvider(body).subscribe(
          (data: any) => {
            this.activeModal.close(true);
          },
          (e: any) => {
            this.error_message = e.error.error;
          }
        );
      } else if (this.builder_user_id !== -1) {
        body.primary_user_id = this.builder_user_id;
        this.dataService.createAdditionalBuilder(body).subscribe(
          (data: any) => {
            this.activeModal.close(true);
          },
          (e: any) => {
            this.error_message = e.error.error;
          }
        );
      }
    } else {
      body.additional_user.id = this.userDetails.id;
      this.dataService.updateAdditionalBuilder(body).subscribe(
        (data: any) => {
          this.activeModal.close(true);
        },
        (e: any) => {
          this.error_message = e.error.error;
        }
      );
    }
  }
}
