import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timepicker-modal',
  templateUrl: './timepicker-modal.component.html',
  styleUrls: ['./timepicker-modal.component.sass']
})
export class TimepickerModalComponent implements OnInit {
  @Input()
  serviceTime: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  save() {
    this.activeModal.close(this.serviceTime);
  }
}
