import { Component, HostListener, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import HelloSign from 'hellosign-embedded';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { ThemeOptions } from 'src/app/theme-options';
@Component({
  selector: 'app-mobile-signature',
  templateUrl: './mobile-signature.component.html',
  styleUrls: ['./mobile-signature.component.sass'],
})
export class MobileSignatureComponent implements OnInit {
  user_document_template_id: any = '';
  user_property_service_provider_id: any = '';
  user_id: any = '';
  user_property_service_id: any = '';
  auth_token: any = '';

  dataReceived: any = [];
  showLoading = true;

  @HostListener('window:message', ['$event']) onMessage(event) {
    // do something meaningful with it

    const data_str = JSON.stringify(event.data);
    if (data_str.includes('virtuo_sign_data')) {
      this.dataReceived.push(JSON.stringify(event.data));
      const elements = data_str.split(';');
      for (let i = 0; i < elements.length; ++i) {
        const key_value = elements[i].split('=');
        if (key_value && key_value.length > 1) {
          if (key_value[0] === 'id') {
            this.user_document_template_id = key_value[1];
          }

          if (key_value[0] === 'user_property_service_provider_id') {
            this.user_property_service_provider_id = key_value[1];
          }

          if (key_value[0] === 'token') {
            const token = key_value[1];
            this.auth_token = token.replace('"', '');
          }
        }
      }




      if (this.user_document_template_id && this.auth_token) {
        this.openSignModal();
      } else if (this.user_property_service_provider_id && this.auth_token) {
        this.openInvoiceSignModal();
      } else {
        window.alert('Something went wrong');
      }
    }
  }

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    public globals: ThemeOptions,
    private router: Router,
    private meta: Meta,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {

    this.meta.updateTag({
      name: 'viewport',
      content: 'width=device-width,initial-scale=1.0, maximum-scale=1.0',
    });

    this.route.queryParams.subscribe((params) => {
      this.auth_token = params.auth_token;
      this.user_document_template_id = params.user_document_template_id;
      this.user_property_service_provider_id =
        params.user_property_service_provider_id;
      if (this.user_document_template_id && this.auth_token) {
        this.openSignModal();
      } else if (this.user_property_service_provider_id && this.auth_token) {
        this.openInvoiceSignModal();
      }
    });

  }

  openInvoiceSignModal() {
    this.dataService
      .getMobileInvoiceSignatureUrl(
        this.auth_token,
        this.user_property_service_provider_id
      )
      .subscribe((data: any) => {
        const signature_url = data.signature_url;
        const client = new HelloSign();
        client.open(signature_url, {
          clientId: this.globals.helloSignApi,
          testMode: false,
          debug: false,
          skipDomainVerification: true,
        });

        client.on('sign', (data) => {



          (window as any).ReactNativeWebView.postMessage('signed');
        });
      });
  }

  openSignModal() {
    const client = new HelloSign();
    const signature_url = '';
    this.localStorageService.removeUser();
    this.localStorageService.removeSelectedCustomer();
    this.localStorageService.removeLastActiveTab();
    this.dataService
      .getSignatureUrlForMobile(this.auth_token, this.user_document_template_id)
      .subscribe(
        (data: any) => {

          if (data.signature_url) {
            this.showLoading = false;
            client.open(data.signature_url, {
              clientId: this.globals.helloSignApi,
              testMode: false,
              debug: false,
              skipDomainVerification: true,
            });
          } else {
            (window as any).ReactNativeWebView.postMessage('close');
          }
        },
        (error: any) => {
          (window as any).ReactNativeWebView.postMessage('close');
        }
      );

    client.on('sign', (data) => {


      this.dataService
        .setSignedForMobile(this.auth_token, this.user_document_template_id)
        .subscribe((data: any) => {
          (window as any).ReactNativeWebView.postMessage('signed');
        });
    });

    client.on('close', (data) => {
      (window as any).ReactNativeWebView.postMessage('close');
    });

  }
}
