import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FileUploadService } from 'src/app/_helpers/file-upload.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { UploadService } from 'src/app/_helpers/upload.service';
import { CustomToastComponent } from 'src/app/AppPages/Elements/custom-toast/custom-toast.component';
import { ThemeOptions } from 'src/app/theme-options';
import { DataService } from '../../../../_helpers/data.service';
import Utils from '../../../Elements/utils';
import { urlify } from '../../../multi-use-components/multi-use-helpers';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-todo-info-comments-modal',
  templateUrl: './todo-info-comments-modal.component.html',
  styleUrls: ['./todo-info-comments-modal.component.scss'],
})
export class TodoInfoCommentsModalComponent implements OnInit {
  public Editor: any;
  public config: any;

  @Input()
  todoInfo: any;
  user: any;
  selectedCustomer: any;
  editFields = false;
  due_date: any;
  possession_date: any;

  hc_list: any = [];
  notesList = ['1', '2'];

  serviceList: any = [];
  selectedService: any;
  serviceObject: any;

  activeServices: any = [];
  selectedActiveService: any = {};

  privacyList: any;
  selectedPrivacy: any;
  privacyObject: any;

  edit_id: any = -1;
  user_property_id: any;
  displayString: any;

  severityList: any = [];
  selectedSeverity: any = {};
  customer_flag = false;
  customer: any = {};
  concierge: any = {};
  admin: any = {};
  admin_List: any = [];
  todo_user: any = {};
  isBuilder: any = false;
  isAdmin = false;
  is_customer = false;
  isCsr = false;
  isGm = false;
  todoDocument: any;
  uploadFile: any = [];
  commentDocuments: any = [];
  documentList: any = [];

  taskLink = '';

  commentText: any = '';
  commentList: any = [];

  // Before/after Trigger date
  beforeAftertriggerDateList: any = [];
  selectedBeforeAfterTdate: any = {};

  // Trigger Date
  triggerDateList: any;
  selectedTriggerDate: any = {};

  save_and_close = false;
  all_services: any = [];
  allHcList: any = [];
  allAdminList: any = [];

  urlify = urlify;

  @Input() calendarModal = false;
  @Output() getUpdatedTodos: EventEmitter<any> = new EventEmitter();
  @Output() updateTasks: EventEmitter<any> = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    public globals: ThemeOptions,
    public uploadService: UploadService,
    private toastr: ToastrService,
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer
  ) {}

  closeFunction() {
    this.activeModal.close(true);
  }

  isToday(due_date) {
    if (due_date) {
      return Utils.isTodoDueToday(due_date);
    } else {
      return false;
    }
  }

  isTodoOverDue(due_date) {
    if (due_date) {
      return Utils.isTodoOverDue(due_date);
    } else {
      return false;
    }
  }

  onChangeSwitchActivity() {
    this.dataService
      .onChangeActivityToggleButton(this.todoInfo.id)
      .subscribe((data: any) => {});
  }

  taskComplete(task, completed) {
    const body = {
      is_completed: completed,
      user_property_id: task.user_property_id ? task.user_property_id : 1,
      master_todo_id: task.id,
      is_master_todo: task.is_master_todo,
      due_date: task.due_date,
    };

    let undoClicked = false;
    const toastRef = this.toastr.show(this.todoInfo.title, null, {
      toastComponent: CustomToastComponent,
      positionClass: 'toast-bottom-right',
      timeOut: 2000,
    });

    toastRef.onAction.subscribe((x) => {
      undoClicked = true;
    });

    toastRef.onHidden.subscribe((x) => {
      if (!undoClicked) {
        this.dataService.taskComplete(body).subscribe((data: any) => {
          this.activeModal.close(true);
        });
      }
    });
  }

  onSelectedTriggerDate(value) {
    this.selectedTriggerDate = value;
  }

  onSelectedBeforeAfterTriggerDate(value) {
    this.selectedBeforeAfterTdate = value;
  }

  onAssigneeSelect(value) {
    this.todoInfo.assignee_name = value.fullname;
    if (value.type && value.type === 'customer') {
      this.todoInfo.assignee_id = value.client_id;
    } else if (value.admin_id) {
      this.todoInfo.assignee_id = value.admin_id;
    } else {
      this.todoInfo.assignee_id = value.hc_id;
    }
  }

  getHcList() {
    this.dataService.getHcList().subscribe((data: any) => {
      this.hc_list = data;
      this.allHcList = _.cloneDeep(this.hc_list);

      this.customer.client_id = this.todoInfo.client_id;
      this.customer.fullname = this.todoInfo.client_name;
      this.customer.type = 'customer';

      this.dataService
        .getUserDetails(this.todoInfo.client_id)
        .subscribe((data: any) => {
          const user_info = data;
          if (user_info.hc) {
            this.concierge = user_info.hc;

            for (let i = 0; i < this.hc_list.length; i++) {
              if (this.concierge.hc_id === this.hc_list[i].hc_id) {
                this.hc_list.splice(i, 1);
              }
            }
          }

          this.dataService.getAdminList().subscribe((data: any) => {
            this.admin_List = data;
            this.allAdminList = _.cloneDeep(this.admin_List);
            if (user_info) {
              for (let i = 0; i < this.admin_List.length; i++) {
                if (this.admin_List[i].admin_id === user_info.admin_id) {
                  this.admin = this.admin_List[i];
                  this.admin_List.splice(i, 1);
                }
              }
            }
          });
        });
    });
  }

  loadActivityComments() {
    const body = {};
    this.dataService
      .loadActivityComments(this.todoInfo.id, body)
      .subscribe((data: any) => {
        this.commentList = data;
      });
  }

  uploadCommentFileUrl(doc, id) {
    this.fileUploadService.uploadFile(
      doc.name,
      doc,
      'COMMENT_DOCUMENT',
      id,
      doc.size
    );
  }

  sendMessage() {
    if (this.commentDocuments && this.commentDocuments.length > 0) {
      const body = {
        comment_text: 'COMMENT_DOCUMENT',
        is_document: true,
      };
      for (let i = 0; i < this.commentDocuments.length; i++) {
        this.dataService
          .addActivitiesComment(this.todoInfo.id, body)
          .subscribe((data: any) => {
            this.uploadCommentFileUrl(
              this.commentDocuments[i],
              data.comment.id
            );
          });
      }
    }

    const body = {
      comment_text: this.commentText,
    };

    if (this.commentText !== '') {
      this.dataService
        .addActivitiesComment(this.todoInfo.id, body)
        .subscribe((data: any) => {
          this.loadActivityComments();
        });
      this.commentText = '';
    }

    const that = this;
    setTimeout(() => {
      that.loadActivityComments();
      that.commentList = [];
      that.commentDocuments = [];
    }, 1000);
  }

  uploadDocument(id) {
    const element = document.getElementById(id);
    element.click();
  }

  commentFileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.commentDocuments.push(event.target.files[i]);
      }
    }
  }

  getPrivacies() {
    this.dataService.getOptionList().subscribe((data: any) => {
      this.privacyList = data.privacy_list;
      this.severityList = data.severity_list;
      this.beforeAftertriggerDateList = data.days_before_after;
      this.triggerDateList = data.trigger_dates;

      if (this.todoInfo.privacy) {
        const privacy = this.privacyList.filter((privacy) => {
          return this.todoInfo.privacy === privacy.value;
        });
        this.privacyObject = privacy[0];
        this.selectedPrivacy = this.privacyObject;
      }

      if (this.todoInfo.days_before_after !== null) {
        const daysBeforeAfter = this.beforeAftertriggerDateList.filter(
          (daysBeforeAfterObject) => {
            return (
              this.todoInfo.days_before_after === daysBeforeAfterObject.value
            );
          }
        );
        this.selectedBeforeAfterTdate = daysBeforeAfter[0];
      }

      if (this.todoInfo.default_trigger_date !== null) {
        const triggerDate = this.triggerDateList.filter((triggerDateobject) => {
          return this.todoInfo.default_trigger_date === triggerDateobject.value;
        });
        this.selectedTriggerDate = triggerDate[0];
      }

      if (this.todoInfo.user_property_id) {
        this.user_property_id = this.todoInfo.user_property_id;
        this.getServices();
      }
    });
  }

  filterServices(value) {
    this.serviceList = this.all_services.filter((service: any) => {
      return (
        service.service.name.toLowerCase().search(value.toLowerCase()) !== -1
      );
    });
  }

  filterAssignedTo(value) {
    this.hc_list = this.allHcList.filter((hc: any) => {
      return hc.fullname.toLowerCase().search(value.toLowerCase()) !== -1;
    });
    this.admin_List = this.allAdminList.filter((admin: any) => {
      return admin.fullname.toLowerCase().search(value.toLowerCase()) !== -1;
    });
  }

  getServices() {
    this.dataService
      .getAvailableServices(this.user_property_id)
      .subscribe((data: any) => {
        this.serviceList = [];
        this.serviceList.push({
          service: {
            id: -1,
            name: 'General Todo',
            label: 'general_lbl',
          },
        });
        for (const obj of data) {
          this.serviceList.push(obj);
        }
        this.all_services = _.cloneDeep(this.serviceList);

        if (this.todoInfo.service_id) {
          const service = this.serviceList.filter((service) => {
            return this.todoInfo.service_id === service.service.id;
          });
          this.serviceObject = service[0];

          this.selectedService = this.serviceObject;

          this.getActiveServices();
        } else {
          this.serviceObject = this.serviceList[0];
          this.selectedService = this.serviceList[0];
        }
      });
  }

  getActiveServices() {
    this.activeServices = [];
    if (
      this.user_property_id &&
      this.selectedService &&
      this.selectedService.service
    ) {
      this.dataService
        .getActiveServices(
          this.user_property_id,
          this.selectedService.service.id
        )
        .subscribe((data: any) => {
          if (data && data.length > 0) {
            data.forEach((element) => {
              const service = {
                id: element.id,
                display_name: element.service_name,
                display_date: 'No date',
              };
              if (element.service_dates && element.service_dates.length > 0) {
                service.display_date = element.service_dates[0];
              }

              if (
                element.recurrence_details &&
                element.recurrence_details.recurrence_dates &&
                element.recurrence_details.recurrence_dates.length > 0
              ) {
                service.display_date =
                  element.recurrence_details.recurrence_dates[0].date;
              }

              this.activeServices.push(service);
            });
            if (this.todoInfo.user_property_service_id) {
              const selectedActiveservice = this.activeServices.filter(
                (service) => {
                  return this.todoInfo.user_property_service_id === service.id;
                }
              );

              if (selectedActiveservice) {
                this.selectedActiveService = selectedActiveservice[0];
              }
            } else {
              this.selectedActiveService = this.activeServices[0];
            }
          }
        });
    }
  }

  onSelectedActiveService(service) {
    this.selectedActiveService = service;
  }

  onSelectedService(service) {
    this.selectedService = service;
    this.selectedActiveService = null;
    this.getActiveServices();
  }

  onSelectedPrivacy(privacy) {
    this.selectedPrivacy = privacy;
  }

  onPaste(e: any) {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
        this.commentDocuments.push(blob);
      }
    }
  }

  getTodo() {
    const params = new HttpParams()
      .set('user_property_id', this.todoInfo.user_property_id)
      .set('is_master_todo', this.todoInfo.is_master_todo);

    const passedId = this.todoInfo.id
      ? this.todoInfo.id
      : this.todoInfo.master_todo_id;
    this.dataService
      .getTodoForUserProperty(passedId, params)
      .subscribe((data: any) => {
        this.todoInfo = data;
        this.getPrivacies();
      });
  }

  editTodo() {
    if (this.uploadFile && this.uploadFile.length > 0) {
      this.uploadFileUrl(this.todoInfo.id);
    }
    if (
      this.due_date.year !== null &&
      this.due_date.month !== null &&
      this.due_date.day !== null
    ) {
      const date = new Date(
        this.due_date.year,
        this.due_date.month - 1,
        this.due_date.day
      );

      this.todoInfo.due_date = moment(date).format('YYYY-MM-DD');
    } else {
      this.todoInfo.due_date = null;
    }

    if (this.selectedPrivacy) {
      this.todoInfo.privacy = this.selectedPrivacy.value;
    }

    if (this.selectedService && this.selectedService.service) {
      this.todoInfo.service_id = this.selectedService.service.id;
    } else {
      this.todoInfo.service_id = -1;
    }

    if (this.selectedActiveService && this.selectedActiveService.display_name) {
      this.todoInfo.user_property_service_id = this.selectedActiveService.id;
    } else {
      this.todoInfo.user_property_service_id = null;
    }

    if (this.selectedTriggerDate.label) {
      this.todoInfo.default_trigger_date = this.selectedTriggerDate.value;
    }

    if (this.selectedBeforeAfterTdate.label) {
      this.todoInfo.days_before_after = this.selectedBeforeAfterTdate.value;
    }

    if (this.selectedSeverity) {
      this.todoInfo.severity = this.selectedSeverity.value;
    }

    const body = {
      task: {
        id: this.todoInfo.id,
        is_master_todo: this.todoInfo.is_master_todo,
        title: this.todoInfo.title,
        description: this.todoInfo.description,
        user_property_id: this.todoInfo.user_property_id,
        due_date: this.todoInfo.due_date,
        privacy: this.todoInfo.privacy,
        owner_id: this.todoInfo.owner_id,
        assignee_id: this.todoInfo.assignee_id,
        service_id: this.todoInfo.service_id,
        user_property_service_id: this.todoInfo.user_property_service_id,
        severity: this.todoInfo.severity,
        notes: this.todoInfo.notes,
        default_trigger_date: this.todoInfo.default_trigger_date,
        days_before_after: this.todoInfo.days_before_after,
        days_count: this.todoInfo.days_count,
        category_name: this.todoInfo.category_name,
      },
    };

    this.dataService.updateTask(body).subscribe((data: any) => {
      if (data.document_url && data.document_url !== null) {
        this.todoDocument = '';
        this.todoDocument = data.document_url.split('/').pop();
      }
      this.getUpdatedTodos.emit();
      window.analytics.track('Edit ToDo', {
        id: data.id,
        user_id: this.todoInfo.client_id,
        title: this.todoInfo.title,
        due_date: this.todoInfo.due_date,
        user_property_id: this.todoInfo.user_property_id,
        timestamp: moment().format(),
      });
      if (this.save_and_close) {
        this.closeFunction();
      }
    });
  }

  saveAndClose() {
    this.save_and_close = true;
    this.editTodo();
  }

  uploadFileUrl(id) {
    for (let i = 0; i < this.uploadFile.length; i++) {
      this.fileUploadService.uploadFile(
        this.uploadFile[i].name,
        this.uploadFile[i],
        'TASK_DOCUMENT',
        id,
        this.uploadFile[i].size
      );
    }
  }

  onChangeSwitch(check) {
    this.dataService
      .changeTodoRemindHcStatus(this.todoInfo.id, check)
      .subscribe((data: any) => {
        this.todoInfo.remind_hc = check;
      });
  }
  goToCustomerProfile(client_id) {
    if (!this.calendarModal) {
      this.router.navigate(['/customer/' + client_id + '/profile/']);
      this.activeModal.close(false);
    } else {
      this.activeModal.close(client_id);
    }
  }

  onSelectedSeverity(severity) {
    this.selectedSeverity = severity;
  }

  setupData() {
    this.editFields = true;
    if (this.todoInfo.severity_label.length > 0) {
      this.selectedSeverity.label = this.todoInfo.severity_label;
      this.selectedSeverity.value = this.todoInfo.severity;
    }

    if (this.todoInfo.document_url) {
      this.todoDocument = this.todoInfo.document_url.split('/').pop();
    }

    if (this.todoInfo.documents && this.todoInfo.documents.length > 0) {
      this.documentList = this.todoInfo.documents;
    }

    this.getHcList();
    this.getPrivacies();

    const dateObject = new Date(this.todoInfo.due_date);
    this.due_date = new NgbDate(
      dateObject.getFullYear(),
      dateObject.getMonth() + 1,
      dateObject.getDate()
    );
  }

  showURL(url) {
    window.open(url, '_blank');
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.uploadFile.push(event.target.files[i]);
      }
    }
  }

  removeFile(index) {
    this.commentDocuments.splice(index, 1);
  }

  removeUploadedFile(index) {
    this.uploadFile.splice(index, 1);
    if (this.uploadFile.length === 0) {
      this.uploadFile = [];
      const fileInput = document.getElementById(
        'validatedCustomFile'
      ) as HTMLInputElement;
      fileInput.value = ''; // Reset the input value
    }
  }

  copied($event) {
    this.toastr.success('Copied');
  }

  markCommentsRead() {
    this.dataService
      .markCommentsRead(this.todoInfo.id)
      .subscribe((data: any) => {});
  }

  sanitizedDescription(description) {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  handleClick(event: Event) {
    const target = event.target as HTMLElement;

    if (target.tagName === 'A') {
      const href = target.getAttribute('href');

      if (href && href.startsWith('http')) {
        event.preventDefault();
        window.open(href, '_blank');
      }
    }
  }

  async ngOnInit() {
    this.getTodo();
    this.user = this.localStorageService.getUser();
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();
    this.taskLink =
      this.globals.baseUrl +
      'customer/' +
      this.todoInfo.user_id +
      '/quotes-matches/' +
      this.todoInfo.id +
      '/task?todo=' +
      this.todoInfo.is_master_todo +
      '&user_property_id=' +
      this.todoInfo.user_property_id +
      '&task_route=true';
    if (
      this.user.roles.includes('builder') ||
      this.user.roles.includes('additional_builder')
    ) {
      this.isBuilder = true;
    }
    if (
      this.user.roles.includes('customer_user') ||
      this.user.roles.includes('customer_user_additional')
    ) {
      this.is_customer = true;
    }
    if (this.user.roles.includes('administrator')) {
      this.isAdmin = true;
    }
    if (this.user.roles.includes('csr')) {
      this.isCsr = true;
    }
    if (this.user.roles.includes('gm')) {
      this.isGm = true;
    }
    this.config = this.uploadService.config;
    this.Editor = this.uploadService.Editor;

    if (this.todoInfo.possession_date) {
      this.possession_date = this.todoInfo.possession_date;
    }

    if (
      (this.user.roles.includes('customer_user') ||
        this.user.roles.includes('customer_user_additional')) &&
      this.selectedCustomer.id === this.user.id
    ) {
      this.customer_flag = true;
      this.todo_user = this.user;
    } else {
      this.todo_user = this.selectedCustomer;
    }
  }
}
