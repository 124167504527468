import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable } from 'rxjs-compat/Observable';
import { Subject } from 'rxjs/Subject';
import { DataService } from 'src/app/_helpers/data.service';
// key that is used to access the data in local storage
const STORAGE_KEY_CURRENT = 'current_user';
const STORAGE_KEY_SELECTED_CUSTOMER = 'selected_customer';
const STORAGE_KEY_SELECTED_HS_TAB = 'selected_hs_tab';
const STORAGE_KEY_LAST_TAB = 'last_active_tab';

const STORAGE_KEY_ZENDESK_COOKIES = 'zendesk_cookies';
@Injectable()
export class LocalStorageService {
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private dataService: DataService,
    private router: Router
  ) {}

  private storageSub = new Subject<string>();
  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }
  public storeUser(user) {
    // store updated user
    this.storage.set(STORAGE_KEY_CURRENT, user);
  }

  public getUser() {
    // get user from local storage
    return this.storage.get(STORAGE_KEY_CURRENT) || [];
  }

  public removeUser() {
    this.storage.remove(STORAGE_KEY_CURRENT);
  }

  public clearStorage() {
    this.storage.clear();
  }

  public storeSelectedCustomer(user) {
    // store updated user
    this.storage.set(STORAGE_KEY_SELECTED_CUSTOMER, user);
    this.storageSub.next('changed');
  }

  public setZendeskCookie(flag) {
    this.storage.set(STORAGE_KEY_ZENDESK_COOKIES, flag);
  }

  public removeZendeskCookie() {
    this.storage.remove(STORAGE_KEY_ZENDESK_COOKIES);
  }

  public hasSetZendeskCookies() {
    // get user from local storage
    return this.storage.get(STORAGE_KEY_ZENDESK_COOKIES) || false;
  }

  public async getSelectedCustomer(): Promise<any> {
    // get user from local storage

    const parts = this.router.url.split('/');
    const userID = Number(parts[2]);
    if (parts[1] !== 'customer' || !userID || isNaN(userID)) {
      return [];
    }

    const storedSelectedCustomer = this.storage.get(
      STORAGE_KEY_SELECTED_CUSTOMER
    );
    if (storedSelectedCustomer && storedSelectedCustomer.id === userID) {
      return storedSelectedCustomer;
    } else {
      return await this.dataService.getUserDetails(userID).toPromise();
    }
  }

  public removeSelectedCustomer() {
    this.storage.remove(STORAGE_KEY_SELECTED_CUSTOMER);
  }

  public storeSelectedHSTab(tabID) {
    // store updated user
    this.storage.set(STORAGE_KEY_SELECTED_HS_TAB, tabID);

    this.storageSub.next('changed');
  }

  public getSelectedHSTab() {
    // get user from local storage
    return this.storage.get(STORAGE_KEY_SELECTED_HS_TAB) || '';
  }

  public storeLastActiveTab(tabID) {
    this.storage.set(STORAGE_KEY_LAST_TAB, tabID);
    this.storageSub.next('changed');
  }

  getLastActiveTab() {
    return this.storage.get(STORAGE_KEY_LAST_TAB) || '';
  }

  public removeSelectedHSTab() {
    this.storage.remove(STORAGE_KEY_SELECTED_HS_TAB);
  }

  public removeLastActiveTab() {
    this.storage.remove(STORAGE_KEY_LAST_TAB);
  }
}
