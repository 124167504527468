import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import Utils from '../../Elements/utils';

@Component({
  selector: 'app-primary-contact',
  templateUrl: './primary-contact-modal.component.html',
})
export class PrimaryContactModalComponent implements OnInit {
  @Input()
  primaryContact: any;

  heading = 'Profile';
  icon = 'virtuo-icon-title icon-profile icon-gradient';
  phone_no_flag = true;
  flag = true;

  mask: Array<string | RegExp>;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.mask = Utils.getPhoneMask();
  }

  save() {
    this.activeModal.close(this.primaryContact);
  }
}
