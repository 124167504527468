import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import swal from 'sweetalert2';
import {
  HomeServicePropertyModalComponent
} from '../../MCPortal/customer/customer-home-services/home-service-property-modal/home-service-property-modal.component';

@Component({
  selector: 'app-package-item',
  templateUrl: './package-item.component.html',
  styleUrls: ['./package-item.component.sass'],
})
export class PackageItemComponent implements OnInit {
  @Input()
  package: any = {};
  @Output() ngOnChangeAction = new EventEmitter();
  selectedCustomer: any;
  constructor(
    private dataService: DataService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {

    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();
  }

  orderPackage(packageData) {

    this.dataService
      .getPackageMatchingUserProperties(
        this.selectedCustomer.id,
        packageData.id
      )
      .subscribe((data: any) => {
        const modalRef = this.modalService.open(
          HomeServicePropertyModalComponent,
          {
            size: 'lg',
          }
        );
        modalRef.componentInstance.packageId = _.cloneDeep(packageData.id);
        modalRef.componentInstance.package = _.cloneDeep(packageData);
        modalRef.componentInstance.userPropertiesList = _.cloneDeep(data);

        modalRef.result.then(
          (result) => {

            if (result.navigate_to_profile) {
              const body = { navigate_to_bundle_packages: true };
              const navigationExtras: NavigationExtras = {
                state: { data: body },
              };
              this.router
                .navigateByUrl('/', {
                  skipLocationChange: true,
                })
                .then(() => {
                  this.router.navigate(
                    ['/customer/' + this.selectedCustomer.id + '/profile/'],
                    navigationExtras
                  );
                });
            } else {
              this.ngOnChangeAction.emit();
              swal({
                type: 'success',
                text: 'Package ordered successfully!',
              });
            }
          },
          (reason) => {
          }
        );
      });
  }
}
