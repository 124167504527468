import { select } from '@angular-redux/store';
import { HttpParams } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from '../../../_helpers/local-storage.service';
import { ThemeOptions } from '../../../theme-options';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  user: any;
  firstTabTitle = 'Jobs';
  showRates = true;
  isBuilder = false;
  isProvider = false;

  hasProfessionalService = false;
  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dataService: DataService
  ) {}

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  public innerHeight: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    this.user = this.localStorageService.getUser();

    if (
      this.user.roles.includes('builder') ||
      this.user.roles.includes('additional_builder')
    ) {
      this.isBuilder = true;
      this.showRates = false;
    }

    if (this.user.provider_is_referral === true) {
      this.firstTabTitle = 'Referrals';
      this.showRates = false;
    }

    if (
      this.user.roles.includes('service_provider') ||
      this.user.roles.includes('additional_service_provider')
    ) {
      this.isProvider = true;
      this.hasProfessionalServices();
    }

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1000) {
        this.globals.toggleSidebar = true;
      }
      this.innerHeight = window.innerHeight;
    });

    this.extraParameter =
      this.activatedRoute.snapshot.firstChild.data.extraParameter;
  }

  hasProfessionalServices() {
    const params = new HttpParams().set('provider_id', this.user.providers[0]);
    this.dataService.hasProfessionalServices(params).subscribe((data: any) => {
      this.hasProfessionalService = data.has_professional_service;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1000) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }
}
