import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DataService } from 'src/app/_helpers/data.service';
import { ThemeOptions } from 'src/app/theme-options';
import { EventService } from 'src/services/event.service';
import { NewTaskModal } from '../../MCPortal/customer/customer-tasks/new-task-modal/new-task-modal.component';
import { TodoInfoCommentsModalComponent } from '../../MCPortal/dashboard/todo-info-comments-modal/todo-info-comments-modal.component';
import { filterUserProperties } from '../multi-use-helpers';

@Component({
  selector: 'app-timeline-page',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelinePageComponent implements OnInit, OnChanges {
  // vars

  @Input() clientId: number;

  @Input() userRole: string;
  // Options: 'builder', 'customer', 'hc'

  user: any;
  userId: any;

  tasks: any;
  today = new Date();

  todayDate: any;
  todayMonth: any;
  todayYear: any;

  noPreviousContent: boolean;
  timeout: any;
  appointments: any = [];
  years = {};

  icons = [
    // Update Circle
    { status: 'Review New Matches', icon: 'update-circle/blue.svg' },
    { status: 'Review New Estimates', icon: 'update-circle/blue.svg' },
    // Pen Tip
    { status: 'Signature Needed', icon: 'pen-tip/blue.svg' },
    { status: 'Provider Signature Needed', icon: 'pen-tip/blue.svg' },
    // Watch
    { status: 'Concierge to contact', icon: 'wrist-watch/blue.svg' },
    { status: 'Provider to contact', icon: 'wrist-watch/blue.svg' },
    { status: 'Waiting for Estimates', icon: 'wrist-watch/blue.svg' },
    // Credit Card
    { status: 'Payment Auth Needed', icon: 'credit-card/blue.svg' },
    { status: 'Payment Auth Completed', icon: 'credit-card/blue.svg' },
    // Individual Icons
    { status: 'Request For Information', icon: 'exclamation-outline/blue.svg' },
    { status: 'DIY', icon: 'angle-ruler/blue.svg' },
    { status: 'Wishlist', icon: 'scroll/blue.svg' },
    { status: 'To Do', icon: 'user-check/blue.svg' },
    { status: 'Completed', icon: 'check-circle/green.svg' },
    { status: 'Service', icon: 'calendar/blue.svg' },
  ];

  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(
    public globals: ThemeOptions,
    private dataService: DataService,
    private route: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    private eventService: EventService
  ) {}
  /*
    Post MVP
    - Page start on today banner
    - Hide completed toggle styling and logic
    - only show one date bubble when there are two tasks for the same day
    - focus on today banner when today/month buttons are clicked
  */

  async ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('userId');
    });

    if (this.clientId) {
      this.userId = this.clientId;
    }

    // sets today's date values
    const monthNum = this.today.getMonth();
    this.todayMonth = this.monthNames[monthNum];
    this.todayYear = this.today.getFullYear();
    this.todayDate = this.getDate(this.today);

    // loads page data
    if (this.clientId) {
      this.getMilestones(this.clientId);
    }

    this.eventService.emitEvent('menuClicked');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientId) {
      this.userId = changes.clientId.currentValue;
      if (changes.clientId.currentValue) {
        this.dataService.getUserDetails(this.userId).subscribe((data: any) => {
          this.user = data;
          this.getMilestones(this.user.id);
        });
      }
    }
  }

  getMilestones(id) {
    this.dataService.getMilestones(id, false).subscribe((data: any) => {
      this.initFunction(data.milestones);
      this.previousContent();
    });
  }

  initFunction(data) {
    this.insertToday();
    this.filterArray(this.getKeyDates());
    this.filterArray(data);
    for (const year in this.years) {
      this.years[year] = this.filterYear(this.years[year]);
    }
    this.formatToday();
  }

  formatToday() {
    // finds the first task matching today's date. if it is the today card no alterations needed
    const monthIndex = this.years[this.todayYear].findIndex((month) => {
      for (const day of month) {
        if (day.today) {
          return day;
        }
      }
    });
    // finds the first task matching today's date.
    // if it is not the today card the index is taken to insert the today card at the top of the list
    const index = this.years[this.todayYear][monthIndex].findIndex((task) => {
      return task.currentMonthDay == true;
    });
    if (index !== -1) {
      const todayIndex = this.years[this.todayYear][monthIndex].findIndex(
        (task) => {
          return task.today == true;
        }
      );
      this.years[this.todayYear][monthIndex].splice(todayIndex, 1);

      this.years[this.todayYear][monthIndex].splice(index, 0, {
        milestone_date: this.today,
        today: true,
      });
    }
  }

  insertToday() {
    // inserts today's date into the timeline in the correct position.
    // Normal chronological order does not work because today must always be at the start of the tasks that share today's date
    if (!this.years[this.todayYear]) {
      this.years[this.todayYear] = {};
    }
    if (!this.years[this.todayYear][this.todayMonth]) {
      this.years[this.todayYear][this.todayMonth] = [];
    }
    this.years[this.todayYear][this.todayMonth].push({
      milestone_date: this.today,
      today: true,
    });
  }

  filterArray(array) {
    this.getIcons(array);
    this.markOverdues(array);

    for (const task of array) {
      task.milestone_date = new Date(task.milestone_date);

      // if there isn't an object for the current year create one
      if (!this.years[task.milestone_date.getFullYear()]) {
        this.years[task.milestone_date.getFullYear()] = {};
      }

      // get the task date information
      const monthNum = task.milestone_date.getMonth();
      const month = this.monthNames[monthNum];
      if (
        this.years[task.milestone_date.getFullYear()] &&
        !this.years[task.milestone_date.getFullYear()][month]
      ) {
        this.years[task.milestone_date.getFullYear()][month] = [];
      }

      // push task info to correct year object
      if (this.years[task.milestone_date.getFullYear()][month]) {
        this.years[task.milestone_date.getFullYear()][month].push(task);
      }
    }
  }

  markOverdues(array) {
    // marks tasks as overdue based on today's date
    for (const task of array) {
      task.milestone_date = new Date(task.milestone_date);
      if (
        this.getMonthName(task.milestone_date) ==
          this.getMonthName(this.today) &&
        task.milestone_date.getFullYear() == this.today.getFullYear()
      ) {
        if (this.getDate(this.today) == this.getDate(task.milestone_date)) {
          task.currentMonthDay = true;
          task.overdue = false;
        } else {
          if (this.getDate(this.today) < this.getDate(task.milestone_date)) {
            task.currentMonth = true;
            task.overdue = false;
          } else {
            task.currentMonth = true;
            task.overdue = true;
          }
        }
      } else {
        if (task.milestone_date < this.today) {
          task.overdue = true;
        } else {
          task.overdue = false;
        }
      }
    }
  }

  onTaskClick(task) {
    if (this.userRole == 'customer') {
      // if the card is a task route to task page
      if (!task.today && task.status_mvp !== 'Service') {
        this.router.navigate(
          ['/customer/' + this.userId + '/quotes-matches/' + task.id + '/task'],
          {
            queryParams: {
              todo: task.is_master_todo,
              user_property_id: task.user_property_id,
            },
          }
        );
      }
      // if the card is a service rout to service details page
      if (task.status_mvp == 'Service') {
        this.router.navigate([
          '/customer/' +
            this.userId +
            '/service-details/' +
            task.user_property_service_id,
        ]);
      }
    }

    if (this.userRole == 'hc' && task.status_mvp !== 'Service') {
      const modalRef = this.modalService.open(TodoInfoCommentsModalComponent, {
        windowClass: 'todoModalClass',
      });
      modalRef.componentInstance.todoInfo = _.cloneDeep(task);
    }
  }

  onMilestoneClick(element) {
    // sets parent element z-index so confetti is on top
    const parentElement = element.parentNode;
    // Get the current z-index value
    const currentZIndex = parseInt(getComputedStyle(parentElement).zIndex, 10);
    // Increment the z-index value (you can customize this logic)
    const newZIndex = currentZIndex + 1;
    // Set the new z-index value to the parent element
    parentElement.style.zIndex = newZIndex;

    // creates confetti
    const c = document.createDocumentFragment();
    for (let i = 0; i < 100; i++) {
      const styles =
        'transform: translate3d(' +
        (this.random(1000) - this.random(1000)) +
        'px, ' +
        (this.random(1000) - this.random(1000)) +
        'px, 0) rotate(' +
        this.random(360) +
        'deg);\
                      background: ' +
        this.getRandomVirtuoColor() +
        ';\
                      animation: bang 700ms ease-out forwards;\
                      opacity: 0;\
                      position: absolute;\
                      display: block;\
                      width: 6px;\
                      height: 16px;\
                      z-index: 10000; ';

      const e = document.createElement('i');
      e.style.cssText = styles.toString();
      c.appendChild(e);
    }
    element.append(c);

    // Removes the appended elements after 700ms
    clearTimeout(this.timeout);
    this.setConfettiTimeout(element);
  }

  setConfettiTimeout(element) {
    // sets the timeout for the confetti
    this.timeout = setTimeout(() => {
      const childNodes = element.childNodes;
      // Iterate through the child nodes and remove <i> tags
      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i].nodeName === 'I') {
          // Check if the current node is an <i> tag
          element.removeChild(childNodes[i]);
          // Decrement the counter to properly check the next node
          i--;
        }
      }
    }, 700);
  }

  getRandomVirtuoColor() {
    // gets a random color for confetti
    const colors = [
      'var(--blue)',
      'var(--l-blue)',
      'var(--yellow)',
      'var(--pink)',
      'var(--l-pink)',
    ];
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
  }

  random(max) {
    // gets a random number for confetti
    return Math.random() * (max - 0) + 0;
  }

  formatTodayDate() {
    // gets formatted today's date
    const monthNum = this.today.getMonth();
    const month = this.monthNames[monthNum];
    const year = this.today.getFullYear();
    const day = this.getDate(this.today);
    return month + ' ' + day + ', ' + year;
  }

  getDate(date) {
    // if the day is less than 10 add a 0 in front of it for styling
    const d = new Date(date);
    const day = d.getDate();
    if (day < 10) {
      const newDate = '0' + day;
      return newDate;
    } else {
      return day;
    }
  }

  getMonthName(date) {
    // gets the month number and returns the month name
    const d = new Date(date).getMonth();
    const name = this.monthNames[d];
    return name;
  }

  getIcons(array) {
    // adds icons to the tasks
    for (const task of array) {
      for (const icon of this.icons) {
        if (task.status_mvp == icon.status) {
          task.icon = icon.icon;
        }

        if (task.activity_status !== 'Completed' && !task.icon) {
          task.icon = 'user-check/blue.svg';
        }
      }
    }
  }

  filterYear(year): any {
    // formats data for the timeline
    const yearArray = [];
    for (const name of this.monthNames) {
      if (year[name]) {
        yearArray.push(year[name]);
      }
    }
    for (const month of yearArray) {
      this.sortDays(month);
    }
    return yearArray;
  }

  sortDays(array) {
    // sorts the days chronologically
    if (array) {
      const newArray = array.sort((a, b) => {
        return a.milestone_date - b.milestone_date;
      });
      return newArray;
    } else {
      return array;
    }
  }

  openAddTaskModal() {
    this.eventService.emitEvent('menuClicked');
    const modalRef = this.modalService.open(NewTaskModal, {
      size: 'lg',
    });
    modalRef.componentInstance.userId = this.userId;
    modalRef.result.then((result) => {
      if (result === 'taskAdded') {
        this.eventService.emitEvent('menuClicked');
        // Refresh the data
        this.getMilestones(this.userId);
      }
    });
  }

  getPillColor(status, task) {
    if (status.includes('Review New') || status == 'Request For Information') {
      return '#FDAC82'; // peach
    }

    if (
      status == 'Completed' ||
      status == 'Payment Auth Complete' ||
      status == 'Confirmed' ||
      status == 'Service'
    ) {
      return 'rgb(179, 208, 0, 0.8)'; // green
    }

    if (status == 'Payment Auth Needed' || status == 'Signature Needed') {
      return 'rgb(255, 61, 61, 0.9)'; // red
    }

    return '#f7f7f7'; // grey
  }

  getPillTextColor(status, task) {
    if (
      status == 'Completed' ||
      status == 'Payment Auth Complete' ||
      status == 'Confirmed' ||
      status.includes('Review New') ||
      status == 'Request For Information' ||
      status == 'Payment Auth Needed' ||
      status == 'Signature Needed' ||
      status == 'Service'
    ) {
      return 'white';
    }

    return '#5b5b5b'; // dark grey
  }

  getBubbleColor(task) {
    if (task.currentMonthDay) {
      return 'blue';
    }
    if (
      task.status_mvp == 'Payment Auth Complete' ||
      task.status_mvp == 'Completed' ||
      task.status_mvp == 'Confirmed' ||
      task.status_mvp == 'Service'
    ) {
      return 'light-green';
    }
    if (task.type == 'milestone') {
      return 'yellow';
    }
    if (task.overdue) {
      return 'light-peach';
    }
    if (task.currentMonth) {
      return 'light-blue';
    }
  }

  previousContent() {
    // checks if there is a previous year
    for (const year in this.years) {
      if (Number(year) < this.today.getFullYear()) {
        this.noPreviousContent = false;
        return;
      }
    }
    const firstDay = this.years[this.today.getFullYear()][0][0].milestone_date;
    // checks if there is a previous month
    if (firstDay.getMonth() !== this.today.getMonth()) {
      this.noPreviousContent = false;
      return;
    }

    this.noPreviousContent = true;
    return;
  }

  getKeyDates() {
    const keyDates = [];
    if (this.user && this.user.user_properties) {
      const filteredProperties = filterUserProperties(
        this.user.user_properties
      );
      for (const property of filteredProperties) {
        for (const keyDate in property.key_dates) {
          if (property.key_dates[keyDate]) {
            const date = new Date(property.key_dates[keyDate] + 'T00:00:00');
            const address = property.formatted_address.split(',');
            // if there is a move in date add it to the timeline
            if (keyDate == 'move_in_date') {
              keyDates.push({
                milestone_date: date,
                service_name_mvp: 'Move In Day! Let\'s get you settled in!',
                address: address[0],
                type: 'milestone',
              });
            }
            // if there is a possession date and it does not have the same date as the move in date add it to the timeline
            if (
              keyDate == 'possession_date' &&
              property.key_dates.move_in_date !==
                property.key_dates.possession_date
            ) {
              keyDates.push({
                milestone_date: date.toUTCString(),
                service_name_mvp:
                  'Possession day! Congratulations on your new home!',
                type: 'milestone',
                address: address[0],
              });
            }
          }
        }
      }
    }
    return keyDates;
  }
}
