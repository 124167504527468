import { Injectable } from '@angular/core';
import ClassicEditor from 'ckeditor5-build-classic-simple-upload-adapter';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  public config;
  public Editor;

  constructor(private dataService: DataService) {
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'undo',
          'redo'
        ]
      },
      image: {
        toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
        ]
      },
      table: {
        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
      },
      language: 'en'
    };
    this.Editor = ClassicEditor;
    const REST_API_SERVER = dataService.getRestApiServerAddress();

    this.config = {
      simpleUpload: {
        // The URL that the images are uploaded to.
        // uploadUrl: 'http://localhost:3000/api/v1/uploads/upload_todo_image',
        uploadUrl: REST_API_SERVER + '/uploads/upload_todo_image',
      },
    };
  }
}
