import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Utils from '../../../Elements/utils';

declare const google: any;

@Component({
  selector: 'app-service-areas-modal',
  templateUrl: './service-areas-modal.component.html',
})
export class ServiceAreasModalComponent implements OnInit {
  @Input()
  provider_locations: any;

  @Input()
  provider_services: any = [];
  polygons: any;

  zoom = 4;
  markers: marker[] = [];
  radius: any;
  has_service_radius = false;
  radius_points: any = [];
  is_provider_location = false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {



    this.polygons = [];
    for (let i = 0; i < this.provider_locations.length; ++i) {
      const provider_location = this.provider_locations[i];
      if (!provider_location.has_service_radius) {
        this.polygons.push({
          service_area_polygon: provider_location.service_area_polygon,
        });
      }

      if (!provider_location.services) {
        const services = [];
        for (let j = 0; j < this.provider_services.length; j++) {
          const selected = provider_location.service_ids.includes(
            this.provider_services[j].service_id
          )
            ? true
            : false;
          services.push({
            service_name: this.provider_services[j].service_name,
            selected,
            service_id: this.provider_services[j].service_id,
          });
        }

        if (services && services.length > 0) {
          this.provider_locations[i].services = services;
        }
      }
      this.markers.push({
        lat: provider_location.place_details.latitude,
        lng: provider_location.place_details.longitude,
        label: i.toString(),
        draggable: true,
      });
    }



  }

  setServices() {
    for (let i = 0; i < this.provider_locations.length; i++) {
      const services = this.provider_locations[i].services;
      let filteredService;
      this.provider_locations[i].services = [];
      for (let j = 0; j < this.provider_services.length; j++) {
        if (services) {
          filteredService = services.filter((element) => {
            return element.service_id === this.provider_services[j].service_id;
          });
        }

        let selected = false;
        if (filteredService && filteredService.length > 0) {
          selected = true;
        }

        this.provider_locations[i].services.push({
          service_name: this.provider_services[j].service_name,
          selected,
          service_id: this.provider_services[j].service_id,
        });
      }
    }
  }

  save() {

    this.activeModal.close(this.provider_locations);
  }

  toggle($event, location_index, index) {

    this.provider_locations[location_index].services[index].selected =
      $event.checked;
  }

  addNewLocation() {
    const newLocation: any = {
      place_details: {},
    };
    const services = [];
    for (let j = 0; j < this.provider_services.length; j++) {
      services.push({
        service_name: this.provider_services[j].service_name,
        selected: false,
        service_id: this.provider_services[j].service_id,
      });
    }

    if (services && services.length > 0) {
      newLocation.services = services;
    }
    if (this.is_provider_location) {
      newLocation.has_service_radius = false;
    }
    this.provider_locations.push(newLocation);
  }

  onPolyPathChanged(index, $event) {


    this.provider_locations[index].service_area_polygon = $event.newArr[0];
  }

  markerDragEnd(m: marker, $event: MouseEvent) {

  }

  deleteLocation(id) {
    this.provider_locations.splice(id, 1);
    this.polygons.splice(id, 1);
  }

  radioChange($event, location, index) {


    if ($event.value && !location.service_radius) {
      location.service_radius = 100000;
      if (this.polygons[index] && !this.polygons[index].has_service_radius) {
        this.polygons.splice(index, 1);
      }
    } else if (!$event.value && location.service_radius) {
      location.service_radius = null;
    }
  }

  onCenterChange($event, location) {


    location.place_details.latitude = $event.lat;
    location.place_details.longitude = $event.lng;
  }

  handleAddressChange(id, place) {


    const place_details = Utils.getLocationDetails(place);
    if (place_details.city !== undefined) {
      this.provider_locations[id].address =
        place_details.city + ', ' + place_details.province;
      this.provider_locations[id].name =
        place_details.city + ', ' + place_details.province;
      delete place_details.display_name;
      this.provider_locations[id].place_details = place_details;

      const polygon_points = [];
      polygon_points.push(
        new google.maps.LatLng(
          place_details.latitude - 1,
          place_details.longitude
        )
      );
      polygon_points.push(
        new google.maps.LatLng(
          place_details.latitude,
          place_details.longitude + 1
        )
      );
      polygon_points.push(
        new google.maps.LatLng(
          place_details.latitude + 1,
          place_details.longitude
        )
      );
      polygon_points.push(
        new google.maps.LatLng(
          place_details.latitude,
          place_details.longitude - 1
        )
      );

      this.provider_locations[id].service_area_polygon = polygon_points;

      if (
        this.provider_locations[id].has_service_radius &&
        !this.provider_locations[id].service_radius
      ) {
        this.provider_locations[id].service_radius = 100000;
      }

      this.polygons.push({
        service_area_polygon: polygon_points,
        has_service_radius: this.provider_locations[id].has_service_radius,
      });



    } else {
      this.provider_locations[id].address = '';
      this.provider_locations[id].name = '';
      delete place_details.display_name;
      this.provider_locations[id].place_details = '';
    }
  }

  onRadiusChanged(location, type, $event) {
    location.service_radius = $event;

  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
