// takes a string and a separator and returns an array of strings, trimmed of whitespace
export function splitAndTrim(inputString: string, separator: string) {
  const array = inputString.split(separator);
  for (let i = 0; i < array.length; i++) {
    array[i] = array[i].trim();
  }
  return array;
}

// validates array has values and converts language arrays into strings for submission
export function languagesToStrings(languageArray) {
  if (languageArray && languageArray.length > 0) {
    return languageArray.join(', ');
  } else {
    return null;
  }
}

// validates the sting has a value and converts language string into an array for display
export function languagesToArrays(languageString: string) {
  // data formatting is inconsistent in string formatting, so we need to check for three possible cases

  if (languageString && languageString !== '') {
    // if the string has no commas, return it as a sigle item array
    if (!languageString.includes(',') && !languageString.includes(', ')) {
      return [languageString];
    }

    // if the string has a comma with a space, split it by ', '
    if (languageString.includes(', ')) {
      return splitAndTrim(languageString, ', ');
    }

    // if the string has a comma without a space, split it by ','
    if (languageString.includes(',')) {
      return splitAndTrim(languageString, ',');
    }
  } else {
    return null;
  }
}

export function formatName(name: string) {
  name = name.trim();
  name = name.charAt(0).toUpperCase() + name.slice(1);
  return name;
}

export function formatEmail(email: string) {
  email = email.trim();
  email = email.toLowerCase();
  return email;
}

export function formatLanguageOptions(languages) {
  let index = languages.indexOf('French');
  languages.splice(index, 1);
  languages.unshift('French');
  index = languages.indexOf('English');
  languages.splice(index, 1);
  languages.unshift('English');
  return languages;
}

export function internationalPhoneClick(target, phoneNumber, extension) {
  if (target.value !== undefined && target.value.includes(' ')) {
    const numberArray = target.value.split(' ');
    phoneNumber = numberArray[1];
    extension = numberArray[0];
  }

  if (
    target.value === undefined &&
    target.innerText &&
    target.innerText.includes(' ')
  ) {
    const extensionArray = target.innerText.split(' ');

    if (extensionArray[2]) {
      extension = extensionArray[2];
    } else {
      extension = extensionArray[1];
    }
  }

  const valid = validatePhoneNumber(phoneNumber, extension);
  return [valid, phoneNumber, extension];
}

export function internationalPhoneChange(event, phoneNumber, extension) {
  if (event.target.value !== undefined && event.target.value.includes(' ')) {
    const numberArray = event.target.value.split(' ');
    phoneNumber = numberArray[1];
    extension = numberArray[0];
  }

  const valid = validatePhoneNumber(phoneNumber, extension);
  return [valid, phoneNumber, extension];
}

export function validatePhoneNumber(phoneNumber, extension) {
  if ((extension === '1' || extension === '+1') && phoneNumber.length === 10) {
    return true;
  }

  if ((extension === '1' || extension === '+1') && phoneNumber.length !== 10) {
    return false;
  }

  if (extension !== '1' && extension !== '+1') {
    return true;
  }
}

export function internationalPhoneKeyStroke(
  eventValue,
  phoneNumber,
  extension
) {
  if (eventValue !== undefined && eventValue.includes(' ')) {
    const numberArray = eventValue.split(' ');
    phoneNumber = numberArray[1];
    extension = numberArray[0];
  }

  const valid = validatePhoneNumber(phoneNumber, extension);
  return [valid, phoneNumber, extension];
}

export function getInitials(firstName: string, LastName: string) {
  if (firstName && LastName) {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = LastName.charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  }
}

export function getAccountCredits(accountCredits) {
  let credits = 0;
  if (accountCredits !== 0 && accountCredits.length > 0) {
    for (const account of accountCredits) {
      credits = credits + Number(account.account_credit_remaining);
    }
  }
  return Number(credits);
}

export function newNoteDatetimeString() {
  // Formats time and date
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const timePart = date.toLocaleString('en-US', options);
  const dayPart = `0${date.getDate()}`.slice(-2);

  const datePart = `${date.toLocaleString('en-US', {
    month: 'short',
  })} ${dayPart} ${date.getFullYear()}`;

  // Output format: 12:00pm Jan 01 2020
  return `${timePart.toLowerCase().replace(' ', '')} ${datePart}`;
}
