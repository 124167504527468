import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventService {
  private myEventSubject = new Subject<any>();
  myEvent$ = this.myEventSubject.asObservable();

  emitEvent(event: any) {
    this.myEventSubject.next(event);
  }
}
