import { select } from '@angular-redux/store';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { SidebarDataService } from 'src/app/_helpers/sidebar-data.service';
import { ThemeOptions } from 'src/app/theme-options';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-mc-main-sidebar',
  templateUrl: './mc-main-sidebar.component.html',
  styleUrls: ['./mc-main-sidebar.component.scss'],
})
export class McMainSidebarComponent implements OnInit, OnDestroy {
  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private sidebarDataService: SidebarDataService
  ) {
    this.pendingApprovalCounter = this.sidebarDataService
      .getApprovalCount()
      .subscribe((count) => {
        // message contains the data sent from service
        this.approvalsCount = count;
      });
  }
  public extraParameter: any;
  private pendingApprovalCounter: Subscription;

  @select('config') public config$: Observable<any>;
  private newInnerWidth: number;
  private innerWidth: number;
  private innerHeight: number;
  activeId = 'dashboardsMenu';
  user: any;
  currentJustify = 'fill';
  isGM = false;
  isAdmin = false;
  isMC = false;
  isLC = false;
  isProjectManager = false;
  approvalsCount = 0;
  leadsCount = 0;
  temp = 0;
  isStaging = false;
  ngOnDestroy(): void {
    this.pendingApprovalCounter.unsubscribe();
  }

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    this.user = this.localStorageService.getUser();
    this.isStaging = environment.staging;
    if (this.user.roles.includes('gm')) {
      this.isGM = true;
    }

    if (this.user.roles.includes('csr')) {
      this.isMC = true;
    }

    if (this.user.roles.includes('lead_concierge')) {
      this.isLC = true;
    }

    if (this.user.roles.includes('administrator')) {
      this.isAdmin = true;
    }

    if (this.user.roles.includes('project_manager')) {
      this.isProjectManager = true;
    }
    this.pendingApprovalsCount();

    if (this.globals.isGMActive) {
      this.activeId = 'GM';
    } else if (this.globals.isAdminActive) {
      this.activeId = 'Admin';
    } else {
      const id = this.localStorageService.getLastActiveTab();
      if (id !== '') {
        this.activeId = id;
      } else {
        this.activeId = 'MC';
      }
    }
    this.localStorageService.storeLastActiveTab(this.activeId);

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1000) {
        this.globals.toggleSidebar = true;
      }
      this.innerHeight = window.innerHeight;
    });

    this.extraParameter =
      this.activatedRoute.snapshot.firstChild.data.extraParameter;
  }

  public openGMDashboard() {
    this.router.navigate(['/gm-dashboard']);
  }

  public openMCDashboard() {
    this.router.navigate(['/dashboard']);
  }

  pendingApprovalsCount() {
    this.dataService.getPendingApprovalsCount().subscribe((data: any) => {
      this.approvalsCount = data.approvals_count;
      this.leadsCount = data.leads_count;
    });
  }

  public openAdminDashboard() {
    this.router.navigate(['/admin-dashboard']);
  }
  public onChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'GM') {
      this.globals.isGMActive = true;
      this.globals.isAdminActive = false;
      this.router.navigate(['/gm-dashboard']);
    } else if ($event.nextId === 'MC') {
      this.globals.isGMActive = false;
      this.globals.isAdminActive = false;
      this.router.navigate(['/dashboard']);
    } else {
      this.globals.isGMActive = false;
      this.globals.isAdminActive = true;
      this.router.navigate(['/admin-dashboard']);
    }
    this.localStorageService.storeLastActiveTab($event.nextId);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1000) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }
}
