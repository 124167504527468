import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ngbd-dropdown-basic',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent implements OnInit {
  @Input()
  label = 'sort';

  @Input()
  dropwdownOptions: string[] = ['Year', 'Title', 'Author'];
  @Input()
  selectedOption = 'Select';

  @Output() selectedOptionChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
    if (!this.selectedOption) {
      this.selectedOption = this.dropwdownOptions[0];
    }
  }

  changeOption(newOptions: string) {
    this.selectedOption = newOptions;
    this.selectedOptionChange.emit(this.selectedOption);
  }

}
