import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FileUploadService } from 'src/app/_helpers/file-upload.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { EventService } from 'src/services/event.service';
import { DataService } from '../../../../_helpers/data.service';
import Utils from '../../../Elements/utils';
import { urlify } from '../../../multi-use-components/multi-use-helpers';
import { TodoInfoCommentsModalComponent } from '../../dashboard/todo-info-comments-modal/todo-info-comments-modal.component';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.scss'],
})
export class ChatModalComponent implements OnInit, AfterViewChecked {
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private fileUploadService: FileUploadService,
    public modalService: NgbModal,
    private eventService: EventService,
    private renderer: Renderer2,
    @ViewChild('scrollMe') private myScrollContainer: ElementRef
  ) {}

  @ViewChild('commentTextarea') commentTextarea: ElementRef;
  @ViewChild('comments') comments: ElementRef;

  @Input()
  user: any;
  messages: any = [];
  currentUser: any;
  attachments: any = [];
  isNotification = false;
  commentDocuments: any = [];
  commentText: any = '';
  commentList: any = [];
  is_customer = false;
  urlify = urlify;

  async ngOnInit() {
    this.currentUser = await this.localStorageService.getUser();
    if (
      this.currentUser.roles.includes('customer_user') ||
      this.currentUser.roles.includes('customer_user_additional')
    ) {
      this.is_customer = true;
    }
    this.loadMessages();
    await this.markCommentsAsRead();
    await this.scrollToBottom();
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  ngAfterViewInit(): void {
    this.renderer.selectRootElement(this.commentTextarea.nativeElement).focus();
    setTimeout(() => {
      this.sendMessage(new KeyboardEvent('keypress', { key: 'Enter' }));
    }, 1000);
  }

  clientInitials(name: string): string {
    if (name) {
      const words = name.split(' ');
      const firstTwoWords = words.slice(0, 2);
      const initials = firstTwoWords.map((word) =>
        word.charAt(0).toUpperCase()
      );
      return initials.join('');
    }
  }

  markCommentsAsRead() {
    const body = {
      board_id: this.user.message_board.id,
    };
    this.dataService.markCommentsAsRead(body).subscribe((data: any) => {
      this.messages = data.comments;
      this.attachments = data.attachments;
    });
  }

  onPaste(e: any) {
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        blob = item.getAsFile();
        this.commentDocuments.push(blob);
      }
    }
  }

  showURL(url) {
    window.open(url, '_blank');
  }

  isToday(due_date) {
    if (due_date) {
      return Utils.isTodoDueToday(due_date);
    } else {
      return false;
    }
  }

  isTodoOverDue(due_date) {
    if (due_date) {
      return Utils.isTodoOverDue(due_date);
    } else {
      return false;
    }
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  loadMessages() {
    this.dataService
      .loadMessageBoardComments(this.user.message_board.id)
      .subscribe((data: any) => {
        this.messages = data.comments;
        this.attachments = data.attachments;
        this.isNotification = data.is_notification;
      });
  }

  onChangeSwitch() {
    this.dataService
      .onChangeChatToggleButton(this.user.message_board.id)
      .subscribe((data: any) => {});
  }

  uploadCommentFileUrl(doc, id) {
    this.fileUploadService.uploadFile(
      doc.name,
      doc,
      'MESSAGE_DOCUMENT',
      id,
      doc.size
    );
  }

  uploadDocument(id) {
    const element = document.getElementById(id);
    element.click();
  }

  removeFile(index) {
    this.commentDocuments.splice(index, 1);
    if (this.commentDocuments.length === 0) {
      this.commentDocuments = [];
      const fileInput = document.getElementById(
        'upload_to_comment'
      ) as HTMLInputElement;
      fileInput.value = ''; // Reset the input value
    }
  }

  commentFileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      for (const file of event.target.files) {
        this.commentDocuments.push(file);
      }
    }
  }

  sendMessage(event: KeyboardEvent) {
    if (this.commentDocuments && this.commentDocuments.length > 0) {
      const body = {
        comment: 'MESSAGE_DOCUMENT',
        is_document: true,
        board_id: this.user.message_board.id,
      };
      for (const document of this.commentDocuments) {
        this.dataService.addMessagesComment(body).subscribe((data: any) => {
          this.uploadCommentFileUrl(document, data.latest_comment.id);
        });
      }
    }

    const body = {
      comment: this.commentText,
      is_document: false,
      board_id: this.user.message_board.id,
    };

    if (this.commentText.trim() !== '') {
      this.dataService.addMessagesComment(body).subscribe((data: any) => {
        this.loadMessages();
        this.markCommentsAsRead();
      });
      this.commentText = '';
    } else {
      if (this.commentDocuments.length > 0) {
        setTimeout(() => {
          this.loadMessages();
          this.markCommentsAsRead();
          this.commentDocuments = [];
        }, 1000);
      } else {
        event.preventDefault();
        this.commentText = '';
        this.commentText.trim();
        return;
      }
    }

    const that = this;
    setTimeout(() => {
      that.loadMessages();
      this.markCommentsAsRead();
      that.commentList = [];
      that.commentDocuments = [];
    }, 1000);
  }

  openTodoInfoModal(todo) {
    this.dataService
      .getMatchesById(todo.task_id, false)
      .subscribe((data: any) => {
        const modalRef = this.modalService.open(
          TodoInfoCommentsModalComponent,
          {
            windowClass: 'todoModalClass',
          }
        );
        modalRef.componentInstance.todoInfo = _.cloneDeep(data);
        modalRef.componentInstance.updateTasks.subscribe((data) => {
          todo.hidden = data.is_completed;
        });
        window.analytics.track('Todo Clicked', {
          user_id: todo.client_id,
          id: todo.id,
          user_property_id: data.user_property_id,
          title: todo.title,
          timestamp: moment().format(),
        });
      });
  }

  redirectToServicePage(comment) {
    this.router.navigate(
      ['customer', this.user.id, 'home-services', 'home-service-details'],
      {
        queryParams: {
          user_property_service_id: comment.user_property_service_id,
          sign: false,
        },
      }
    );
    this.activeModal.close();
  }

  loadCommentCount() {
    this.dataService
      .loadMessageBoardCount(this.user.message_board.id)
      .subscribe((data: any) => {});
  }

  onCancel() {
    this.activeModal.close();
    this.loadCommentCount();
    this.eventService.emitEvent('message_icon_clicked');
  }
}
