import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from '../../../_helpers/local-storage.service';
import { UploadService } from '../../../_helpers/upload.service';
import Utils from '../../Elements/utils';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info-modal.component.html',
})
export class GeneralInfoModalComponent implements OnInit {
  public Editor: any;
  public config: any;

  @Input()
  generalInfo: any;

  @Input()
  edit_id: any;

  @Input()
  provider_billings: any = [];

  heading = 'Profile';
  icon = 'virtuo-icon-title icon-profile icon-gradient';
  user: any;
  companyList: any = [];

  mask: Array<string | RegExp>;
  referral: any;
  options = ['$', '%'];
  selectedType: any;
  referral_flag = false;
  new_provider_referral_flag = false;
  phone_no_flag = true;

  referral_rate: any = [];
  constructor(
    public activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private uploadService: UploadService
  ) {}

  ngOnInit() {
    this.config = this.uploadService.config;
    this.Editor = this.uploadService.Editor;

    if (!this.generalInfo.description) {
      this.generalInfo.description = '';
    }

    this.getCompanies();
    this.mask = Utils.getPhoneMask();
    this.user = this.localStorageService.getUser();

    if (this.generalInfo.active === null) {
      this.generalInfo.active = false;
    }

    if (
      this.generalInfo.provider_services &&
      this.generalInfo.provider_services[0].phase_services
    ) {
      for (let i = 0; i < this.generalInfo.provider_services.length; i++) {
        const phase_services =
          this.generalInfo.provider_services[i].phase_services;
        for (const phase of phase_services) {
          if (
            phase.service_name ==
            this.generalInfo.provider_services[i].service_name
          ) {
            if (phase.referral) {
              this.new_provider_referral_flag = true;
              this.generalInfo.provider_services[i].is_referral = true;
            }
          }
        }
      }
    } else {
      const referral_services = this.generalInfo.provider_services.filter(
        (provider_service: any) => {
          return provider_service.is_referral === true;
        }
      );
      if (referral_services && referral_services.length > 0) {
        this.new_provider_referral_flag = true;
      }
    }




    if (this.user.roles.includes('gm')) {
      this.referral_flag = true;
    }

    if (
      this.generalInfo.provider_services &&
      this.generalInfo.provider_services.length > 0
    ) {
      for (let i = 0; i < this.generalInfo.provider_services.length; i++) {
        const obj = { value: '', referral_amount: false, referral_rate: false };
        if (this.generalInfo.provider_services[i].referral_amount) {
          obj.value = this.generalInfo.provider_services[i].referral_amount;
          obj.referral_amount = true;
          obj.referral_rate = false;
        } else if (this.generalInfo.provider_services[i].referral_rate) {
          obj.value = this.generalInfo.provider_services[i].referral_rate;
          obj.referral_amount = false;
          obj.referral_rate = true;
        }
        this.referral_rate.push(obj);
      }

    }

    if (
      this.generalInfo.provider_is_referral ||
      this.new_provider_referral_flag
    ) {
      this.selectedType = this.options[0];
    }
  }

  save() {
    for (let i = 0; i < this.referral_rate.length; i++) {
      if (this.generalInfo.provider_services[i].is_referral) {
        this.generalInfo.provider_services[i].referral_amount =
          this.referral_rate[i].value;
        this.generalInfo.provider_services[i].referral_rate = null;
      } else {
        if (this.referral_rate[i].referral_amount === true) {
          this.generalInfo.provider_services[i].referral_amount =
            this.referral_rate[i].value;
          this.generalInfo.provider_services[i].referral_rate = null;
        } else if (this.referral_rate[i].referral_amount === false) {
          this.generalInfo.provider_services[i].referral_amount = null;
          this.generalInfo.provider_services[i].referral_rate =
            this.referral_rate[i].value;
        }
      }
    }

    this.activeModal.close(this.generalInfo);
  }

  modelChanged($event, index) {




    if (this.generalInfo.provider_services[index].referral_amount) {
      this.generalInfo.provider_services[index].referral_amount = $event;
    } else if (this.generalInfo.provider_services[index].referral_rate) {
      this.generalInfo.provider_services[index].referral_rate = $event;
    }

  }

  selectedReferralType(option, index, service) {
    if (option === this.options[0]) {
      this.referral_rate[index].referral_amount = true;
      this.referral_rate[index].referral_rate = false;
    } else {
      this.referral_rate[index].referral_amount = false;
      this.referral_rate[index].referral_rate = true;
    }
  }

  handleAddressChange(place) {
    const place_details = Utils.getLocationDetails(place);
    this.generalInfo.address = place.formatted_address;
    this.generalInfo.provider_address = place_details;
  }

  getCompanies() {
    this.dataService.getCompanies().subscribe((data: any) => {
      this.companyList = data;

    });
  }
  getCompanyName(company_id) {
    for (const company of this.companyList) {
      if (company.id === company_id) { return company.company_name; }
    }
  }
}
