import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/_helpers/data.service';
import { FileUploadService } from 'src/app/_helpers/file-upload.service';
import { LocalStorageService } from '../../../../_helpers/local-storage.service';
import { formatName } from '../../../MCPortal/customer/customer-helpers.module';
import { getFileIcon } from '../../multi-use-helpers';
import { MultiUseVariables } from '../../multi-use-variables';

@Component({
  selector: 'app-upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.scss'],
})

// Does not support reciepts yet
export class UploadDocumentModalComponent implements OnInit {
  userId: number;
  user: any;
  userDocument: any = {};
  clientId: number;

  folders: any[] = [];
  files: any[] = [];

  selectedProperty: any;
  selectedFolder: any = {};

  description: string;
  title: string;

  error = false;
  errorMessage =
    'There was an error uploading your file. Please try again later.';

  fileUploaded = false;
  allowedExtensionsArray = MultiUseVariables.allowedExtensionsArray;
  allowedExtensions = MultiUseVariables.allowedExtensions;
  getFileIcon = getFileIcon;

  // Passed Vars from parent component
  client: any;
  userType: string; // customer, builder or hc.
  folderName: string; // Optional folder name to set as default
  properties: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private fileUploadService: FileUploadService,
    public toasterService: ToastrService
  ) {}

  async ngOnInit() {
    this.user = await this.localStorageService.getUser();
    this.userId = this.user.id;

    // Sets the client id based on the user type
    if (this.userType === 'customer') {
      this.client = this.user;
    }

    if (this.client) {
      this.clientId = this.client.id;
    }

    if (this.client.client_id && this.userType === 'builder') {
      this.clientId = this.client.client_id;
      this.client.first_name = this.client.fullname.split(' ')[0];
    }

    this.getFolders();

    if (this.folderName === 'Rewards') {
      this.folderName = null;
    }

    // Formats the property object for address capturing on the builder client list
    for (const property of this.properties) {
      if (!property.property && this.userType === 'builder') {
        property.id = this.client.id;
        property.property = {
          formatted_address: property.formatted_address,
        };
      }
    }

    // If there is only one property, set it as the selected property
    if (this.properties.length === 1) {
      this.selectedProperty = this.properties[0];
    }
  }

  // Closes the modal
  close() {
    this.activeModal.close();
  }

  // Removes a file from the files array
  removeFile(index, event) {
    event.preventDefault();
    this.files.splice(index, 1);
  }

  // Gets the folder names for display and sets the initial folder
  getFolders() {
    this.dataService.getFileHistory(this.clientId).subscribe((data: any) => {
      this.folders = data.all_categories;
      delete this.folders['9'];
      this.getInitialFolder();
    });
  }

  // Handles the files that are selected from the file browser
  browseFileSelection(event) {
    for (const file of event.target.files) {
      if (this.validateFile(file)) {
        this.files.push(file);
      }
    }
  }

  validateFile(file) {
    if (this.validateExtension(file) && this.validateFileSize(file.size)) {
      this.error = false;
      return true;
    }

    if (!this.validateExtension(file) && this.validateFileSize(file.size)) {
      this.error = true;
      this.errorMessage =
        'Invalid file type. Please upload a video, image, or document.';
      return false;
    }

    if (this.validateExtension(file) && !this.validateFileSize(file.size)) {
      this.error = true;
      this.errorMessage = 'File size must be 1.5GB or less.';
      return false;
    }

    if (!this.validateExtension(file) && !this.validateFileSize(file.size)) {
      this.error = true;
      this.errorMessage =
        'Invalid file type. Please upload a video, image, or document. File size must be 1.5GB or less.';
      return false;
    }
  }

  // Checks that the selected file has a valid extension
  validateExtension(file) {
    for (const extension of this.allowedExtensionsArray) {
      if (
        (file.fullPath && file.fullPath.toLowerCase().includes(extension)) ||
        file.name.toLowerCase().includes(extension)
      ) {
        return true;
      }
    }
    return false;
  }

  // Checks that the selected file has a valid size below 1.5GB, size is in bytes
  validateFileSize(file_size) {
    if (Number(file_size) > 1500000000) {
      return false;
    }
    return true;
  }

  // Handles the file upload to the api
  onSave() {
    for (const file of this.files) {
      // Sets up userDocument object and trims the title and description
      this.userDocument.user_property_id = this.selectedProperty.id;
      this.userDocument.category = this.selectedFolder.key;
      this.userDocument.title = formatName(this.title);
      if (this.description && this.description.trim() != '') {
        this.userDocument.description = this.description.trim();
      }

      const uploadType = 'USER_DOCUMENT';

      // Sets up data for api call
      const data = {
        file_size: file.size,
        filename: file.name,
        object_id: this.clientId,
        upload_type: uploadType,
        user_document: this.userDocument,
      };

      // Disables save button
      this.fileUploaded = true;

      // Closes modal after 1.5 seconds
      setTimeout(() => {
        this.close();
      }, 1500);

      // Uploads file to api and handles the response
      this.fileUploadService.uploadUserDocument(file, data).subscribe(
        (data) => {
          this.error = false;

          window.analytics.track('Update File Cabinet', {
            user_id: this.userId,
            timestamp: moment().format(),
            file_name: this.userDocument.title,
          });
        },
        (error) => {
          this.error = true;
          this.fileUploaded = false;
          this.errorMessage =
            'There was an error uploading your file. Please try again later.';
          this.toasterService.error(this.errorMessage, 'Error', {
            closeButton: true,
          });
        }
      );
    }
  }

  // Gets the current folder that the user is in and sets it as the default folder
  getInitialFolder() {
    if (this.folderName) {
      let folderId;
      if (this.folderName === 'Files') {
        folderId = Object.keys(this.folders).find(
          (key) => this.folders[key] === ''
        );
      }
      folderId = Object.keys(this.folders).find(
        (key) => this.folders[key] === this.folderName
      );
      this.selectedFolder = { key: folderId, value: this.folderName };
    } else {
      this.selectedFolder = null;
    }
  }
}
