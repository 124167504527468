import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DropzoneConfigInterface,
} from 'ngx-dropzone-wrapper';

@Component({
  selector: 'app-dropzone-modal',
  templateUrl: './dropzone-modal.component.html',
})
export class DropzoneModalComponent implements OnInit {
  public disabled = false;

  public config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    resizeWidth: 100,
    createImageThumbnails: false,
    resizeMethod: 'contain',
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  save() {}

  public onUploadInit(args: any): void {

  }

  public onUploadError(args: any): void {

  }

  public onUploadSuccess(args: any): void {

  }
}
