import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/_helpers/data.service';

@Component({
  selector: 'app-deal-redeemed',
  templateUrl: './deal-redeemed.component.html',
  styleUrls: ['./deal-redeemed.component.sass'],
})
export class DealRedeemedComponent implements OnInit {
  message: any = '';
  user_property_deal_id: any;
  constructor(
    public dataService: DataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.user_property_deal_id = +atob(params.get('user_property_deal_id'));
      const body = {
        user_property_deal_id: this.user_property_deal_id,
      };
      this.dataService
        .markRedeemedUserPropertyDeal(body)
        .subscribe((data: any) => {

          this.message = data.message;
        });
    });
  }
}
