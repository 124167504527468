import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-upload-video-modal',
  templateUrl: './upload-video-modal.component.html',
  styleUrls: ['./upload-video-modal.component.sass'],
})
export class UploadVideoModalComponent implements OnInit {
  selectedCustomer: any = {};
  videoData: any = {};
  constructor(
    public activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private dataService: DataService
  ) {}

  async ngOnInit() {
    this.selectedCustomer = await this.localStorageService.getSelectedCustomer();
    this.videoData.user_id = this.selectedCustomer.id;
  }
  uploadVideoUrl() {
    const body = {
      video_data: this.videoData,
    };
    this.dataService.uploadVideUrl(body).subscribe((data: any) => {
      this.activeModal.close(true);
      window.analytics.track('Add Video', {
        user_id: this.selectedCustomer.id,
        video_title: this.videoData.title,
        video_url: this.videoData.video_url,
        timestamp: moment().format(),
      });
    });
  }
}
