import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import Utils from 'src/app/AppPages/Elements/utils';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { DataService } from '../../_helpers/data.service';
import { LocalStorageService } from '../../_helpers/local-storage.service';
import { EnterTokenModalComponent } from './enter-token-modal/enter-token-modal.component';
import { ForgotPasswordModalComponent } from './forgot-password-modal/forgot-password-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  body = {
    email: '',
    password: '',
  };
  data: any;
  ipAddress: any;
  initialize = false;
  constructor(
    private dataService: DataService,
    private modalService: NgbModal,
    private router: Router,
    private localStorageService: LocalStorageService,
    public toasterService: ToastrService
  ) {}

  ngOnInit() {
    this.checkLoggedIn();
  }

  checkLoggedIn() {
    if (this.localStorageService) {
      const user = this.localStorageService.getUser();
      if (
        user &&
        user.roles &&
        (user.roles.includes('customer_user') ||
          user.roles.includes('customer_user_additional'))
      ) {
        this.router.navigate(['/customer', user.id, 'new-home']);
      } else if (
        user &&
        user.roles &&
        (user.roles.includes('gm') ||
          user.roles.includes('csr') ||
          user.roles.includes('lead_concierge'))
      ) {
        this.router.navigate(['/dashboard']);
      } else if (
        user &&
        user.roles &&
        (user.roles.includes('builder') ||
          user.roles.includes('additional_builder'))
      ) {
        this.router.navigate(['/clients']);
      } else if (
        user &&
        user.roles &&
        (user.roles.includes('service_provider') ||
          user.roles.includes('additional_service_provider'))
      ) {
        this.router.navigate(['/jobs']);
      } else {
        this.initialize = true;
      }
    } else {
      this.initialize = true;
    }
  }

  forgotPasswordModal() {
    const modalRef = this.modalService.open(ForgotPasswordModalComponent, {
      size: 'sm',
    });
  }

  enterTokenModal() {
    const modalRef = this.modalService.open(EnterTokenModalComponent, {
      size: 'sm',
    });
    modalRef.result.then(
      (result) => {
        const body = {
          email: this.data.email,
          authy_token: result.authy_token,
        };

        this.dataService.verifyAuthyToken(body).subscribe((data) => {
          this.LoginToDashboard();
        });
      },
      (reason) => {}
    );
  }

  login() {
    this.dataService.login(this.body).subscribe((data: any) => {
      this.data = data;
      this.LoginToDashboard();
    });
  }

  async LoginToDashboard() {
    if (
      (this.data.roles.includes('customer_user') ||
        this.data.roles.includes('customer_user_additional')) &&
      (this.data.roles.includes('builder') ||
        this.data.roles.includes('additional_builder'))
    ) {
      const options = {
        type: 'info',
        title: 'Welcome ' + this.data.first_name + '!',
        text: 'Where would you like to go today?',
        confirmButtonText:
          this.data.builder_accounts[0].builder_name + ' Portal',
        cancelButtonText: 'My Personal App',
        showCancelButton: true,
      } as SweetAlertOptions;
      this.localStorageService.storeUser(this.data);
      Swal(options).then((result) => {
        if (result.value) {
          sessionStorage.setItem('isBuilderSession', 'true');
          this.data.current_builder = this.data.builder_accounts[0];
          this.router.navigate(['/clients']);
        } else {
          if (!this.localStorageService.hasSetZendeskCookies()) {
            this.localStorageService.setZendeskCookie(true);
          }
          Utils.identify(this.data, '');
          this.router.navigate(['/customer/' + this.data.id + '/new-home/']);
        }
      });
    } else {
      this.localStorageService.storeUser(this.data);
      if (
        this.data.roles.includes('customer_user') ||
        this.data.roles.includes('customer_user_additional')
      ) {
        if (!this.localStorageService.hasSetZendeskCookies()) {
          this.localStorageService.setZendeskCookie(true);
        }
        Utils.identify(this.data, '');
        this.router.navigate(['/customer/' + this.data.id + '/new-home/']);
      } else {
        this.localStorageService.removeZendeskCookie();
        if (
          this.data.roles.includes('service_provider') ||
          this.data.roles.includes('additional_service_provider')
        ) {
          this.data.current_provider = this.data.providers[0];
          this.router.navigate(['/jobs']);
        } else if (
          this.data.roles.includes('builder') ||
          this.data.roles.includes('additional_builder')
        ) {
          sessionStorage.setItem('isBuilderSession', 'true');
          this.data.current_builder = this.data.builder_accounts[0];
          this.router.navigate(['/clients']);
        } else if (
          this.data.roles.includes('gm') ||
          this.data.roles.includes('csr') ||
          this.data.roles.includes('administrator')
        ) {
          await Utils.identifyStaffLogin(this.data);
          this.router.navigate(['/dashboard']);
          this.localStorageService.storeLastActiveTab('MC');
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    }
  }
}
