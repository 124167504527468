import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal, NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
import { FileUploadService } from 'src/app/_helpers/file-upload.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { ThemeOptions } from 'src/app/theme-options';

import * as moment from 'moment';

import { DomSanitizer } from '@angular/platform-browser';
import { endOfDay } from 'date-fns';
import HelloSign from 'hellosign-embedded';
import * as _ from 'lodash';
import RRule from 'rrule';
import { LoaderService } from 'src/app/_helpers/loader.service';
import { CalendarModalComponent } from 'src/app/AppPages/Elements/calendar/calendar-modal.component';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Utils from '../../../../Elements/utils';
import { SelectStorageLocationModalComponent } from '../select-storage-location-modal/select-storage-location-modal.component';

@Component({
  selector: 'app-provider-selection-modal',
  templateUrl: './provider-selection-modal.component.html',
  styleUrls: ['./provider-selection-modal.component.sass'],
})
export class ProviderSelectionModalComponent implements OnInit {
  @Input()
  allowEdit = true;

  @Input()
  data: any;

  @Input()
  selectedCustomer: any;

  @Input()
  estimate_service_flag: any;

  @Input()
  flat_rate_move_flag: any;
  @Input() isCustomer: boolean;
  providerLocation: any = {};
  loading: boolean;
  screen_number = 1;
  userPropertyService: any = {};
  dataChanged: boolean;
  @Output() updateLocations: EventEmitter<any> = new EventEmitter();
  @Output() serviceDetailsChanged: EventEmitter<any> = new EventEmitter();
  agreeToTerms = false;
  isProviderSelected = false;
  disable_button = false;
  selectedOnDemand = false;
  minDate: any = {};
  isPackageService = false;
  rates: any = [];
  special_instructions = '';

  recurrenceDates: any = [];

  totalRate = '0';
  checked_availability = false;
  openedFormID: any = '';
  deal: any = {};
  uploadFile: any;
  has_card_details = true;
  calendarStartDate: Date;
  calendarEndDate: Date;
  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private modalService: NgbModal,
    private loaderService: LoaderService,
    public globals: ThemeOptions,
    private router: Router,
    private fileUploadService: FileUploadService,
    private localStorageService: LocalStorageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.providerLocation = this.data.provider_location;

    this.userPropertyService = this.data.userPropertyService;

    if (
      this.providerLocation &&
      this.providerLocation.user_property_service_provider &&
      this.providerLocation.user_property_service_provider.sign_invoices &&
      this.providerLocation.user_property_service_provider.sign_invoices
        .length > 0
    ) {
      this.screen_number = 4;
    }

    if (
      this.providerLocation.user_property_service_provider
        .user_property_service_provider_document_templates &&
      this.providerLocation.user_property_service_provider
        .user_property_service_provider_document_templates.length > 0
    ) {
      this.screen_number = 5;
    }

    if (
      this.providerLocation.user_property_service_provider &&
      this.providerLocation.should_show_action_button &&
      ['selected', 'accepted', 'completed'].includes(
        this.providerLocation.user_property_service_provider.status
      )
    ) {
      this.isProviderSelected = true;
    }

    if (
      this.userPropertyService &&
      this.userPropertyService.label === 'flat_rate_move'
    ) {
      const notes_service_details =
        this.userPropertyService.service_details.filter((detail: any) => {
          return detail.label === 'notes';
        });
      this.special_instructions = notes_service_details[0].detail
        ? notes_service_details[0].detail
        : '';
    }

    if (this.userPropertyService.package_id) {
      this.isPackageService = true;

      const userPropertyPackage =
        this.userPropertyService.user_property_package;
      if (userPropertyPackage) {
        const startDate = new Date(userPropertyPackage.start_date);
        this.minDate = {
          year: startDate.getFullYear(),
          month: startDate.getMonth() + 1,
          day: startDate.getDate(),
        };
      }
    } else {
      const date = new Date();
      date.setDate(
        new Date().getDate() + this.userPropertyService.blackout_days
      );
      this.minDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
    }

    this.checkServiceDetails();
    const user_property_service_provider =
      this.providerLocation.user_property_service_provider;
    if (user_property_service_provider) {
      this.rates = this.providerLocation.user_property_service_provider.rates;
    }

    if (user_property_service_provider.can_apply_account_credits) {
      this.totalRate = user_property_service_provider.payable_price;
    } else {
      this.totalRate = user_property_service_provider.display_rate;
    }

    if (this.selectedCustomer && !this.selectedCustomer.card_details_added) {
      this.has_card_details = false;
    }
  }

  openMatchingProviderDealModal(deal) {
    this.deal = deal;

    deal.show_deal = true;
  }

  getFile(file) {
    return file.split('/').pop();
  }

  showURL(url) {
    window.open(url, '_blank');
  }
  onGetDetails(deal) {
    const body = {
      user_id: this.selectedCustomer.id,
      deal_id: deal.id,
    };

    this.dataService.getUserDealDetails(body).subscribe((data: any) => {
      if (
        deal.url &&
        deal.pdf_link === null &&
        deal.deal_rate_exceptions.length === 0
      ) {
        const url = deal.url;
        window.open(url, '_blank');
      } else {
        if (data.already_redeemed_deal) {
          Swal({
            type: 'success',
            text: data.message,
          });
        } else {
          Swal({
            type: 'success',
            text: 'Details have been emailed to your registered email address!',
          });
        }
      }
    });
  }

  addToCalendar(deal) {
    const body = {
      user_id: this.selectedCustomer.id,
      deal_id: deal.id,
    };

    this.dataService.addEventToCalendar(body).subscribe((data: any) => {
      Swal({
        type: 'success',
        text: 'Event has been added to your calendar!',
      });
    });
  }
  closeModal() {
    if (this.dataChanged) {
      this.serviceDetailsChanged.emit(this.userPropertyService);
    }
    this.activeModal.close();
  }

  onNotInterested(deal) {
    const body = {
      user_id: this.selectedCustomer.id,
      deal_id: deal.id,
    };

    const options = {
      type: 'warning',
      title: 'Are you sure you are not interested?',
      confirmButtonText: 'Yes',
      showCancelButton: true,
    } as SweetAlertOptions;

    Swal(options).then((result) => {
      if (result.value) {
        this.dataService
          .hideUserPropertyDeal(body)
          .subscribe((data: any) => {});
      }
    });
  }

  gotoReviews() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        name: this.providerLocation.provider.name,
        id: this.providerLocation.provider.id,
      },
    };
    this.activeModal.close();
    this.router.navigate(
      ['/customer/' + this.selectedCustomer.id + '/partner-reviews/'],
      navigationExtras
    );
  }

  checkAvailability(result) {
    this.checkProviderAvailability(this.providerLocation);
  }

  includeBoxCredit() {
    return (
      this.providerLocation.user_property_service_provider.service_label ===
        'virtuo_eco_friendly_boxes' && this.totalRate === 'FREE'
    );
  }
  checkProviderAvailability(provider_location) {
    const masterObject = this.data;
    const user_property_service_id = masterObject.userPropertyService.id;
    const matching_provider_location_id = provider_location.id;
    const special_instructions = this.special_instructions;
    this.dataService
      .checkProviderAvailability(
        user_property_service_id,
        matching_provider_location_id,
        special_instructions
      )
      .subscribe((data: any) => {
        if (this.uploadFile && this.uploadFile.name) {
          this.uploadFileUrl(data.id);
        }
        this.checked_availability = true;
        this.updateLocations.emit();
        this.activeModal.close();
      });
  }

  fileChangeEvent(event: any): void {
    if (event.target.files.length > 0) {
      this.uploadFile = event.target.files[0];
    }
  }

  uploadFileUrl(id) {
    this.fileUploadService.uploadFile(
      this.uploadFile.name,
      this.uploadFile,
      'INVENTORY_LIST_PDF',
      id,
      this.uploadFile.size
    );
  }

  checkSelectedDatesAvailability(datesArr) {
    const body = {
      service_dates: datesArr,
      user_property_service_id: this.userPropertyService.id,
      provider_location_id: this.providerLocation.id,
    };
    this.dataService
      .checkSelectedDatesAvailability(body)
      .subscribe((data: any) => {
        if (data.is_provider_available) {
          this.saveServiceDetails();
          this.checkServiceDetails();
        } else {
          Swal({
            type: 'error',
            text: 'Provider is not availble for selected dates ',
          });
          this.userPropertyService = data.user_property_service;
          this.setUpData(false);
        }
      });
  }

  fillPaymentDetails() {
    this.activeModal.close('Payment Details Pending');
  }

  serviceDatesChanged(details) {
    if (details.label === 'move_out_date') {
      const service_details = this.userPropertyService.service_details.filter(
        (detail: any) => {
          return ['move_in_date'].includes(detail.label);
        }
      );
      service_details[0].detail = details.detail;
    }

    this.onValueChanged(details);
  }

  onValueChanged(detail) {
    if (
      detail.service_detail_data_type === 'MilestoneDate' ||
      detail.service_detail_data_type === 'MilestoneDateArray'
    ) {
      const serviceDates = this.userPropertyService.service_details.filter(
        (data) => {
          return (
            (data.service_detail_data_type === 'MilestoneDate' ||
              data.service_detail_data_type === 'MilestoneDateArray') &&
            data.detail !== null
          );
        }
      );

      if (
        detail.service_detail_data_type === 'MilestoneDateArray' ||
        detail.service_detail_data_type === 'DateArray'
      ) {
        for (let k = 0; k < detail.detail.length; ++k) {
          detail.detail[k] = this.formatDate(detail.detail[k], 'MMM DD YYYY');
        }
      }

      const datesArr = [];
      for (const detail_object of serviceDates) {
        if (detail_object.service_detail_data_type === 'MilestoneDate') {
          const date = new Date(
            detail_object.detail.year,
            detail_object.detail.month - 1,
            detail_object.detail.day
          );
          datesArr.push(moment(date).format('MMM DD YYYY'));
        } else if (
          detail_object.service_detail_data_type === 'MilestoneDateArray'
        ) {
          for (const k of detail_object.detail) {
            datesArr.push(this.formatDate(k, 'MMM DD YYYY'));
          }
        }
      }
      this.checkSelectedDatesAvailability(datesArr);
    } else {
      if (
        detail.detail === 'Other' &&
        detail.service_detail_data_type === 'UserPropertySelect'
      ) {
        const modalRef = this.modalService.open(
          SelectStorageLocationModalComponent,
          {
            size: 'lg',
          }
        );

        if (
          detail.label === 'origin_address' &&
          this.userPropertyService.origin_storage_location !== null
        ) {
          modalRef.componentInstance.location =
            this.userPropertyService.origin_storage_location;
          modalRef.componentInstance.placeDetails =
            this.userPropertyService.origin_storage_location.place_details;
        }

        if (
          detail.label === 'destination_address' &&
          this.userPropertyService.destination_storage_location !== null
        ) {
          modalRef.componentInstance.location =
            this.userPropertyService.destination_storage_location;
          modalRef.componentInstance.placeDetails =
            this.userPropertyService.destination_storage_location.place_details;
        }

        modalRef.result.then(
          (result) => {
            if (detail.label === 'origin_address') {
              this.userPropertyService.origin_storage_location = result;
              this.userPropertyService.origin_storage_location.location_type =
                'origin_address';
            } else if (detail.label === 'destination_address') {
              this.userPropertyService.destination_storage_location = result;
              this.userPropertyService.destination_storage_location.location_type =
                'destination_address';
            }

            this.saveServiceDetails();
            this.checkServiceDetails();
          },
          (reason) => {}
        );
      } else {
        this.saveServiceDetails();
        this.checkServiceDetails();
      }
    }
  }

  onRecurrenceDatesChanged(data) {
    this.userPropertyService.recurrence_details = data.recurrence_details;
    if (data.recurrence_details.start_date && data.start_date_changed) {
      const start_date = new Date(
        data.recurrence_details.start_date.year,
        data.recurrence_details.start_date.month - 1,
        data.recurrence_details.start_date.day
      );
      const serviceDates = [];
      serviceDates.push(moment(start_date).format('MMM DD YYYY'));
      this.checkSelectedDatesAvailability(serviceDates);
    } else {
      this.saveServiceDetails();
    }
  }

  saveServiceDetails() {
    const service = this.userPropertyService;

    if (this.isProviderSelected) {
      const options = {
        type: 'warning',
        title: 'Update service details?',
        text: 'Updating the service details may update the service price. Are you sure you want to continue?',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
      } as SweetAlertOptions;

      Swal(options).then((result) => {
        if (result.value) {
          this.updatePropertyServiceDetails();
          this.addRecurrence();
        }
      });
    } else {
      this.updatePropertyServiceDetails();
      this.addRecurrence();
    }
  }

  updatePropertyServiceDetails() {
    const service = this.userPropertyService;

    const details = [];
    for (const i of service.service_details) {
      const detail_object = i;
      let detail = detail_object.detail;

      const detailArray = [];

      if (
        detail_object.service_detail_data_type === 'MilestoneDate' ||
        detail_object.service_detail_data_type === 'Date'
      ) {
        if (
          detail_object.detail !== null &&
          detail_object.detail !== '' &&
          detail_object.detail.day !== null
        ) {
          const date = new Date(
            detail_object.detail.year,
            detail_object.detail.month - 1,
            detail_object.detail.day
          );
          detail = moment(date).format('MMM DD YYYY');
        } else {
          detail = null;
        }
      }

      if (
        detail_object.service_detail_data_type === 'AdditionalServices' ||
        detail_object.service_detail_data_type === 'MultiSelect'
      ) {
        if (detail_object.detail !== null && detail_object.detail !== '') {
          for (const k of detail_object.detail) {
            const additional_service = k;
            if (additional_service !== null) {
              detailArray.push(additional_service.id);
            }
          }

          if (detailArray.length > 0) {
            detail = detailArray.toString();
          } else {
            detail = '';
          }
        } else {
          detail = '';
        }
      }

      if (
        detail_object.service_detail_data_type === 'MilestoneDateArray' ||
        detail_object.service_detail_data_type === 'DateArray'
      ) {
        if (
          detail_object.detail !== null &&
          detail_object.detail !== '' &&
          detail_object.detail.length > 0
        ) {
          for (const k of detail_object.detail) {
            const date_object = k;

            if (date_object !== null) {
              const date = new Date(
                date_object.year,
                date_object.month - 1,
                date_object.day
              );
              detailArray.push(moment(date).format('MMM DD YYYY'));
            }
          }

          if (detailArray.length > 0) {
            detail = detailArray.toString();
          } else {
            detail = null;
          }
        } else {
          detail = null;
        }
      }

      // Null detail should not be pushed.
      // If object has an id, detail was already present. Hence, empty can be pushed.
      // If object does not have id, do not push

      if (
        detail !== null &&
        ((detail_object.id === null && detail !== '') ||
          detail_object.id !== null)
      ) {
        details.push({
          service_detail_id: detail_object.service_detail_id,
          detail,
          user_property_service_id: detail_object.user_property_service_id,
        });
      }
    }

    const body = {
      flexible_dates: this.userPropertyService.flexible_dates,
      details,
      skip_card_auth: this.userPropertyService.skip_card_auth,
    };

    this.dataService
      .updatePropertyServiceDetails(body)
      .subscribe((data: any) => {
        this.dataService
          .getUserPropertySeviceDetails(this.userPropertyService.id)
          .subscribe((data: any) => {
            this.dataChanged = true;
            this.userPropertyService = data;
            this.setUpData(true);
          });
      });
  }
  formatDate(date, format) {
    if (date !== null) {
      const dateObject = moment(date, format).toDate();

      return new NgbDate(
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      );
    } else {
      return '';
    }
  }

  setUpData(get_rates) {
    for (const j of this.userPropertyService.service_details) {
      const detail = j;

      if (
        detail.service_detail_data_type === 'MilestoneDate' ||
        detail.service_detail_data_type === 'Date'
      ) {
        detail.detail = this.formatDate(detail.detail, 'MMM DD YYYY');
      }
      if (
        detail.service_detail_data_type === 'MilestoneDateArray' ||
        detail.service_detail_data_type === 'DateArray'
      ) {
        for (let k = 0; k < detail.detail.length; ++k) {
          detail.detail[k] = this.formatDate(detail.detail[k], 'MMM DD YYYY');
        }
      }
    }

    this.userPropertyService.service_details =
      this.userPropertyService.service_details.sort((a, b) => {
        return a.service_detail_sort - b.service_detail_sort;
      });
    let date;
    if (
      this.userPropertyService.recurrence_details &&
      this.userPropertyService.recurrence_details.start_date
    ) {
      date = this.userPropertyService.recurrence_details.start_date;
      this.userPropertyService.recurrence_details.start_date = this.formatDate(
        date,
        'YYYY-MM-DD'
      );
    }

    if (
      this.userPropertyService.recurrence_details &&
      this.userPropertyService.recurrence_details.end_date
    ) {
      date = this.userPropertyService.recurrence_details.end_date;
      this.userPropertyService.recurrence_details.end_date = this.formatDate(
        date,
        'YYYY-MM-DD'
      );
    }

    if (get_rates) {
      this.getRateDetails();
    }
  }

  addRecurrence() {
    const service = _.cloneDeep(this.userPropertyService);

    if (service.recurring === true) {
      const obj = service.recurrence_details;
      let date;

      if (obj.start_date) {
        date = new Date(
          obj.start_date.year,
          obj.start_date.month - 1,
          obj.start_date.day
        );

        obj.start_date = moment(date).format('YYYY-MM-DD');
      }

      if (obj.end_date !== null) {
        date = new Date(
          obj.end_date.year,
          obj.end_date.month - 1,
          obj.end_date.day
        );

        obj.end_date = moment(date).format('YYYY-MM-DD');
      }
      obj.user_property_service_id = service.id;

      const recurrenceDates = obj.recurrence_dates;
      obj.recurrence_dates = [];

      for (const i of recurrenceDates) {
        obj.recurrence_dates.push(i.date);
      }
      const recurrence_details_body = {
        recurrence_details: obj,
      };

      this.dataService
        .createRecurrenceDetails(recurrence_details_body)
        .subscribe((data: any) => {});
    }
  }

  getRateDetails() {
    if (!this.userPropertyService.referral) {
      const isRecurrence = this.userPropertyService.recurring;
      const recurrence_dates = [];

      this.dataService
        .getProviderLocationRates(
          this.providerLocation.id,
          this.userPropertyService.id
        )
        .subscribe((data: any) => {
          const subscription_rates = data.subscription_rates;
          if (subscription_rates.rates) {
            this.rates = subscription_rates.rates;
          }

          if (isRecurrence) {
            for (const i of this.rates) {
              const rateObj = i;
              if (rateObj.can_display_payable_rate && rateObj.payable_rate) {
                recurrence_dates.push({
                  date: rateObj.date,
                  rate: rateObj.payable_rate,
                  status: rateObj.status,
                });
              } else {
                recurrence_dates.push({
                  date: rateObj.date,
                  rate: rateObj.rate,
                  status: rateObj.status,
                });
              }
            }

            this.totalRate = subscription_rates.total_rate;
            this.recurrenceDates = recurrence_dates;
          } else {
            const on_demand_rates = data.on_demand_rates;
            if (on_demand_rates.can_apply_account_credits) {
              this.totalRate = on_demand_rates.payable_rate;
            } else {
              this.totalRate = on_demand_rates.display_rate;
            }
          }
        });
    }
  }

  onAddressChanged(detail) {
    if (detail.detail === 'Other') {
      const modalRef = this.modalService.open(
        SelectStorageLocationModalComponent,
        {
          size: 'lg',
        }
      );

      if (
        detail.label === 'origin_address' &&
        this.userPropertyService.origin_storage_location !== null
      ) {
        modalRef.componentInstance.location =
          this.userPropertyService.origin_storage_location;
        modalRef.componentInstance.placeDetails =
          this.userPropertyService.origin_storage_location.place_details;
      }

      if (
        detail.label === 'destination_address' &&
        this.userPropertyService.destination_storage_location !== null
      ) {
        modalRef.componentInstance.location =
          this.userPropertyService.destination_storage_location;
        modalRef.componentInstance.placeDetails =
          this.userPropertyService.destination_storage_location.place_details;
      }

      modalRef.result.then(
        (result) => {
          if (detail.label === 'origin_address') {
            this.userPropertyService.origin_storage_location = result;
            this.userPropertyService.origin_storage_location.location_type =
              'origin_address';
          } else if (detail.label === 'destination_address') {
            this.userPropertyService.destination_storage_location = result;
            this.userPropertyService.destination_storage_location.location_type =
              'destination_address';
          }
        },
        (reason) => {}
      );
    }
  }

  proceed(price_option) {
    this.updateServiceDates();
    if (
      price_option.type === 'subscription' ||
      price_option.type === 'recurring'
    ) {
      this.screen_number = 2;
      this.selectedOnDemand = false;
      if (
        this.userPropertyService.recurring &&
        this.userPropertyService.recurrence_details
      ) {
        if (
          !this.userPropertyService.recurrence_details.recurrence_type ||
          this.userPropertyService.recurrence_details.recurrence_type === 5
        ) {
          this.userPropertyService.recurrence_details.recurrence_type = 2;
        }

        this.generateDates(true);
      }
    }

    if (price_option.type === 'on_demand') {
      this.screen_number = 2;
      this.selectedOnDemand = true;
      if (
        this.userPropertyService.recurring &&
        this.userPropertyService.recurrence_details
      ) {
        this.userPropertyService.recurrence_details.recurrence_type = 5;
        this.generateDates(true);
      }
    }

    this.saveServiceDetails();
  }

  onDemand() {
    this.screen_number = 2;
    this.selectedOnDemand = true;
  }

  updateServiceDates() {
    let date;
    let formattedDate;
    if (!this.isProviderSelected && this.userPropertyService.flexible_dates) {
      for (const j of this.userPropertyService.service_details.length) {
        const detail = j;

        if (detail.service_detail_data_type === 'MilestoneDate') {
          if (
            detail.detail !== null &&
            detail.detail !== '' &&
            detail.detail.day !== null
          ) {
            date = new Date(
              detail.detail.year,
              detail.detail.month - 1,
              detail.detail.day
            );
            formattedDate = moment(date).format('MMM DD YYYY');
            for (const d of this.providerLocation.available_dates) {
              if (d.selected_date === formattedDate) {
                detail.detail = this.formatDate(
                  d.available_dates[0],
                  'MMM DD YYYY'
                );

                break;
              }
            }
          }
        } else if (detail.service_detail_data_type === 'MilestoneDateArray') {
          for (let k = 0; k < detail.detail.length; ++k) {
            date = new Date(
              detail.detail[k].year,
              detail.detail[k].month - 1,
              detail.detail[k].day
            );
            formattedDate = moment(date).format('MMM DD YYYY');
            for (const d of this.providerLocation.available_dates) {
              if (
                d.selected_date === formattedDate &&
                d.available_date !== formattedDate
              ) {
                detail.detail[k] = this.formatDate(
                  d.available_date,
                  'MMM DD YYYY'
                );

                break;
              }
            }
          }
        }
      }

      if (
        this.userPropertyService.recurrence_details &&
        this.userPropertyService.recurrence_details.start_date
      ) {
        const start_date =
          this.userPropertyService.recurrence_details.start_date;
        date = new Date(start_date.year, start_date.month - 1, start_date.day);

        formattedDate = moment(date).format('MMM DD YYYY');

        for (const d of this.providerLocation.available_dates) {
          if (
            d.selected_date === formattedDate &&
            d.available_date !== formattedDate
          ) {
            this.userPropertyService.recurrence_details.start_date =
              this.formatDate(d.available_date, 'MMM DD YYYY');

            break;
          }
        }
      }
    }
  }

  onCalendarClicked(event) {
    this.openCalendarModal();
  }
  openCalendarModal() {
    this.calendarStartDate = null;
    this.calendarEndDate = null;
    const modalRef = this.modalService.open(CalendarModalComponent, {
      size: 'lg',
    });
    const start_date = this.userPropertyService.recurrence_details.start_date;

    const date = new Date(
      start_date.year,
      start_date.month - 1,
      start_date.day
    );

    modalRef.componentInstance.selectedDate = date;

    if (this.minDate) {
      const minDate = new Date(
        this.minDate.year,
        this.minDate.month - 1,
        this.minDate.day
      );
      modalRef.componentInstance.minDate = minDate;
    }

    modalRef.componentInstance.userPropertyServiceId =
      this.userPropertyService.id;
    modalRef.componentInstance.onDatesChanged.subscribe((data) => {
      const startDate = new Date(data.start_date);
      const endDate = new Date(data.end_date);
      if (
        !this.calendarStartDate ||
        !this.calendarEndDate ||
        startDate.getTime() !== this.calendarStartDate.getTime() ||
        endDate.getTime() !== this.calendarEndDate.getTime()
      ) {
        modalRef.componentInstance.setLoading(true);
        this.calendarStartDate = startDate;
        this.calendarEndDate = endDate;
        this.dataService
          .getCalendarProviderAvailability(
            this.userPropertyService.id,
            startDate,
            endDate
          )
          .subscribe((result: any) => {
            modalRef.componentInstance.setEvents(result);
          });
      }
    });
    modalRef.result.then(
      (result) => {
        const dateObject = moment(result).toDate();

        const newDate = new NgbDate(
          dateObject.getFullYear(),
          dateObject.getMonth() + 1,
          dateObject.getDate()
        );

        this.userPropertyService.recurrence_details.start_date = newDate;
        this.generateDates();
      },
      (reason) => {}
    );
  }

  generateDates(generatingFirstTime = false) {
    const obj = _.cloneDeep(this.userPropertyService.recurrence_details);
    const dates_arr: any = [];
    const serviceDates: any = [];
    let start_date: Date;
    let end_date: Date;

    if (obj.recurrence_type !== 5) {
      if (obj.start_date) {
        start_date = new Date(
          obj.start_date.year,
          obj.start_date.month - 1,
          obj.start_date.day
        );
      }

      if (obj.end_date) {
        end_date = new Date(
          obj.end_date.year,
          obj.end_date.month - 1,
          obj.end_date.day
        );
      }

      let rule: RRule;
      const utcDate = new Date(
        start_date.getUTCFullYear(),
        start_date.getUTCMonth(),
        start_date.getUTCDate() + 1,
        9
      );
      const dtStart = new Date(utcDate);
      const until = new Date(endOfDay(end_date));
      const rRuleBody: any = {
        dtstart: dtStart,
        tzid: 'UTC',
      };

      if (!obj.recurrence_every_weekday && obj.frequency) {
        rRuleBody.interval = obj.frequency;
      }
      if (obj.recurrence_type === 1) {
        rRuleBody.freq = RRule.DAILY;
      } else if (obj.recurrence_type === 2 || obj.recurrence_type === 6) {
        const days_arr = [];
        for (const day of obj.days_of_week) {
          days_arr.push(Utils.getRecurringWeekday(day));
        }
        rRuleBody.freq = RRule.WEEKLY;
        rRuleBody.byweekday = days_arr;
      } else if (obj.recurrence_type === 3) {
        rRuleBody.freq = RRule.MONTHLY;

        if (obj.recurrence_by_day) {
          if (obj.days_of_week.length > 0 && obj.week_number) {
            const day = Utils.getRecurringWeekday(obj.days_of_week[0]);
            rRuleBody.byweekday = day.nth(obj.week_number);
          }
        } else if (obj.recurrence_by_date) {
          if (obj.day) {
            rRuleBody.bymonthday = obj.day;
          }
        }
      } else if (obj.recurrence_type === 4) {
        rRuleBody.freq = RRule.YEARLY;

        if (obj.recurrence_by_day) {
          if (obj.day && obj.week_number) {
            rRuleBody.byweekday = Utils.getRecurringWeekday(obj.day).nth(
              obj.week_number
            );
          }
          if (obj.month) {
            rRuleBody.bymonth = obj.month;
          }
        } else if (obj.recurrence_by_date) {
          if (obj.day) {
            rRuleBody.bymonthday = obj.day;
          }

          if (obj.month) {
            rRuleBody.bymonth = obj.month;
          }
        }
      }

      if (obj.recurrence_end_type) {
        // End by Date
        if (obj.recurrence_end_type) {
          switch (obj.recurrence_end_type) {
            case 1:
              rRuleBody.until = until;
              break;
            case 2:
              if (obj.end_after_occurrences) {
                rRuleBody.count = obj.end_after_occurrences;
              }
              break;
            case 3:
              if (obj.recurrence_type) {
                if (
                  obj.recurrence_type === 1 ||
                  obj.recurrence_type === 2 ||
                  obj.recurrence_type === 6
                ) {
                  rRuleBody.count = 150;
                } else if (obj.recurrence_type === 3) {
                  rRuleBody.count = 24;
                } else if (obj.recurrence_type === 4) {
                  rRuleBody.count = 10;
                }
              }
          }
        } else {
          rRuleBody.count = 10;
        }
      }
      rule = new RRule(rRuleBody);

      if (rule !== null) {
        if (rule !== null) {
          if (this.isPackageService) {
            const sd = new Date(
              this.userPropertyService.user_property_package.start_date
            );
            const ed = new Date(
              this.userPropertyService.user_property_package.end_date
            );

            rule.between(sd, ed).forEach((date) => {
              dates_arr.push({
                date: moment(date).format('MMM DD YYYY'),
                status: 'Scheduled',
              });
              serviceDates.push(moment(date).format('MMM DD YYYY'));
            });
          } else {
            rule.all().forEach((date) => {
              dates_arr.push({
                date: moment(date).format('MMM DD YYYY'),
                status: 'Scheduled',
              });
              serviceDates.push(moment(date).format('MMM DD YYYY'));
            });
          }
        }
      }
    } else {
      start_date = new Date(
        obj.start_date.year,
        obj.start_date.month - 1,
        obj.start_date.day
      );
      dates_arr.push({
        date: moment(start_date).format('MMM DD YYYY'),
        status: 'Scheduled',
      });
      serviceDates.push(moment(start_date).format('MMM DD YYYY'));
    }
    this.userPropertyService.recurrence_details_changed = false;
    this.userPropertyService.recurrence_details.recurrence_dates = dates_arr;
    if (!generatingFirstTime) {
      this.checkSelectedDatesAvailability(serviceDates);
    }
  }

  unselectServiceProvider() {
    this.activeModal.close('unselect_provider');
  }

  bookServiceProvider() {
    this.loaderService.isLoading.next(true);
    this.loading = true;
    const body = {
      provider_location_id: this.providerLocation.id,
      is_chosen: true,
    };

    this.dataService
      .selectServiceProvider(this.userPropertyService.id, body)
      .subscribe(
        (data: any) => {
          this.providerLocation.user_property_service_provider.status =
            'selected';

          this.updateLocations.emit();
          this.loading = false;
          this.loaderService.isLoading.next(false);
          this.activeModal.close({ is_booking_confirmed: true });
        },
        (error: any) => {
          this.loading = false;
          this.loaderService.isLoading.next(false);
        }
      );
  }

  checkServiceDetails() {
    const service_details = this.userPropertyService.service_details;
    this.disable_button = false;

    for (const service of service_details) {
      if (
        service.can_write === true &&
        service.is_required === true &&
        (!service.detail ||
          (Array.isArray(service.detail) && service.detail.length === 0))
      ) {
        this.disable_button = true;
        break;
      }
    }
  }

  doSomething(event) {
    if (event === 'true') {
      this.userPropertyService.recurrence_details_changed = true;
    } else if (event === 'false') {
      this.userPropertyService.recurrence_details_changed = false;
    }
  }

  previewInvoiceSignModal(invoice, index) {
    const signature_request_id = invoice.signature_request_id;
    this.dataService
      .previewDocument(signature_request_id)
      .subscribe((data: any) => {
        this.activeModal.dismiss('Cross click');
        const url = data.file_url;
        window.open(url, '_blank');
      });
  }

  openInvoiceSignModal(invoice, index) {
    this.dataService
      .getInvoiceSignatureUrl(
        this.providerLocation.user_property_service_provider.id
      )
      .subscribe((data: any) => {
        this.activeModal.dismiss('Cross click');
        const signature_url = data.signature_url;
        const client = new HelloSign();
        client.open(signature_url, {
          clientId: this.globals.helloSignApi,
          testMode: false,
          debug: false,
          skipDomainVerification: true,
        });

        client.on('sign', (data) => {
          this.dataService
            .setClientInvoiceSigned(invoice.id)
            .subscribe((data: any) => {
              this.providerLocation.user_property_service_provider.sign_invoices[
                index
              ].client_status = 'Waiting for signed invoice';
              this.updateLocations.emit();
            });
        });
      });
  }

  referClient() {
    this.activeModal.close('referClient');
  }

  isTrasferOrCancel(user_property_service_provider) {
    if (!user_property_service_provider) {
      return false;
    }

    return [
      'utilities_cancel',
      'utilities_transfer',
      'internet_and_tv_cancel',
      'internet_and_tv_transfer',
      'gas_transfer',
      'electricity_transfer',
    ].includes(user_property_service_provider.service_label);
  }

  previewSignModal(user_property_service_provider_form) {
    const signature_request_id =
      user_property_service_provider_form.signature_request_id;
    this.dataService
      .previewDocument(signature_request_id)
      .subscribe((data: any) => {
        this.activeModal.dismiss('Cross click');
        const url = data.file_url;
        window.open(url, '_blank');
      });
  }

  openSignModal(user_property_service_provider_form) {
    const client = new HelloSign();

    let signature_url = '';
    this.openedFormID = user_property_service_provider_form.id;
    this.dataService
      .getSignatureUrl(this.openedFormID)
      .subscribe((data: any) => {
        this.activeModal.dismiss('Cross click');
        signature_url = data.signature_url;
        client.open(data.signature_url, {
          clientId: this.globals.helloSignApi,
          testMode: false,
          debug: false,
          skipDomainVerification: true,
        });
      });

    client.on('cancel', () => {
      this.updateLocations.emit();
    });

    client.on('sign', (data) => {
      this.dataService.setSigned(this.openedFormID).subscribe((data: any) => {
        user_property_service_provider_form.status = data.status;
        user_property_service_provider_form.intermediate_status =
          data.intermediate_status;
        let refresh = true;
        if (
          this.providerLocation.user_property_service_provider
            .user_property_service_provider_document_templates &&
          this.providerLocation.user_property_service_provider
            .user_property_service_provider_document_templates.length > 0
        ) {
          for (
            let i = 0;
            i <
            this.providerLocation.user_property_service_provider
              .user_property_service_provider_document_templates.length;
            i++
          ) {
            const document =
              this.providerLocation.user_property_service_provider
                .user_property_service_provider_document_templates[i];
            if (
              document.id !== this.openedFormID &&
              document.status !== 'signed' &&
              document.status !== 'Waiting for signed document' &&
              document.intermediate_status !== 'Signed by customer' &&
              document.intermediate_status !== 'Waiting for signed document' &&
              (document.status === 'created' || document.status === '')
            ) {
              refresh = false;
              this.openSignModal(document);
              break;
            }
          }
        }

        if (refresh) {
          this.updateLocations.emit();
        }
      });
    });
  }

  sanitizedDescription(description) {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  handleClick(event: Event) {
    const target = event.target as HTMLElement;

    if (target.tagName === 'A') {
      const href = target.getAttribute('href');

      if (href && href.startsWith('http')) {
        event.preventDefault();
        window.open(href, '_blank');
      }
    }
  }
}
