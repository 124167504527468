import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { DataService } from 'src/app/_helpers/data.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-preset-user-password',
  templateUrl: './preset-user-password.component.html',
})
export class PresetUserPasswordComponent implements OnInit {
  body: any = {
    new_password: '',
    new_password_confirmation: '',
  };
  user: any = {};
  is_builder = false;
  innerWidth: any;
  avatarUrl = '';
  text_phone = '';
  validation_error = '';
  showMessage = false;
  message = '';
  privacyAccepted = false;
  subscribed = false;
  formGroup: FormGroup;
  builder_name: any;
  success = false;
  loading = false;
  builder: any = {};
  minDate: any = { year: new Date().getFullYear() - 100, month: 1, day: 1 };
  maxDate: any = { year: new Date().getFullYear() - 18, month: 12, day: 31 };
  dropdownSettings: IDropdownSettings = {};
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.is_builder = false;
    this.formGroup = this.formBuilder.group(
      {
        new_password: ['', [Validators.required, Validators.minLength(8)]],
        new_password_confirmation: [
          '',
          [Validators.required, Validators.minLength(8)],
        ],
      },
      {
        validator: MustMatch('new_password', 'new_password_confirmation'),
      }
    );
    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'value',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      enableCheckAll: false,
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.route.paramMap.subscribe((params) => {
      const email = atob(params.get('email'));
      const token = params.get('token');

      this.body.new_user_token = token;
      this.body.email = email;
      this.checkNewUserToken(token, email);
    });
  }

  formatDate(date, format) {
    if (date !== null) {
      const dateObject = moment(date, format).toDate();

      return new NgbDate(
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      );
    } else {
      return '';
    }
  }

  showURL(url) {
    window.open(url, '_blank');
  }

  signInToApp() {
    this.router.navigate(['login']);
  }

  checkNewUserToken(token, email) {
    this.dataService.checkNewUserToken(token, email).subscribe(
      (data: any) => {
        this.user = data.user;
        if (this.user.builder_account) {
          this.builder = this.user.builder_account;
        }
        const roles = this.user.roles;

        if (
          roles.includes('builder') ||
          roles.includes('additional_builder') ||
          roles.includes('service_provider') ||
          roles.includes('additional_service_provider') ||
          roles.includes('sales') ||
          roles.includes('gm') ||
          roles.includes('csr')
        ) {
          this.avatarUrl = '/assets/images/logo-icon.png';
        } else if (this.user.roles.includes('customer_user')) {
          if (
            this.user.builder_account.logo !== null &&
            this.user.builder_account.logo !== ''
          ) {
            this.avatarUrl = this.user.builder_account.logo;
          } else {
            this.avatarUrl = '/assets/images/logo-icon.png';
          }

          this.builder_name = this.user.builder_account.builder_name;
        } else {
          this.avatarUrl = '/assets/images/logo-icon.png';
        }
        this.showMessage = false;
      },
      (err) => {
        this.showMessage = true;
        this.message = 'Invalid URL or URL has expired';
      }
    );
  }

  textLink() {
    const body = {
      email: this.body.email,
      phone: this.text_phone,
      new_user_token: this.body.new_user_token,
    };
    this.dataService.sendAppLink(body).subscribe(
      (data: any) => {
        this.validation_error = '';
        this.success = true;
        swal({
          text: 'Text sent!',
        });
      },
      (err) => {
        this.validation_error = err.error.error;
      }
    );
  }

  save() {
    this.loading = true;

    this.dataService.resetPassword(this.body).subscribe(
      (data: any) => {
        if (
          this.user.roles.includes('customer_user') ||
          this.user.roles.includes('customer_user_additional')
        ) {
          this.loading = false;
          this.validation_error = '';

          this.success = true;
        } else {
          Swal({
            type: 'success',
            text: 'Password has been set successfully!',
          }).then((result) => {
            this.signInToApp();
          });
        }
      },
      (err) => {
        this.loading = false;

        this.validation_error = err.error.error;
      }
    );
  }
}
