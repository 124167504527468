import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { DataService } from 'src/app/_helpers/data.service';
import {
  SelectStorageLocationModalComponent
} from '../../MCPortal/customer/customer-home-services/select-storage-location-modal/select-storage-location-modal.component';
import { CalendarModalComponent } from '../calendar/calendar-modal.component';
@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
})
export class DynamicInputComponent implements OnInit {
  @Input() minDate: any = {};
  @Input()
  serviceDetails: any = [];

  @Input()
  isCustomer = false;
  @Input()
  index: any;

  @Input()
  allowEdit = true;

  @Input()
  isPackageService = false;

  @Input()
  userPropertyPackage: any = {};

  @Input()
  user_property_service_id: number;
  @Input()
  showInline: boolean;
  maxDate: any;
  @Output() onTypeChanged: EventEmitter<any> = new EventEmitter();
  @Output() onValueChanged: EventEmitter<any> = new EventEmitter();
  @Output() serviceDatesChanged: EventEmitter<any> = new EventEmitter();
  @Output() onAddressChanged: EventEmitter<any> = new EventEmitter();
  dropdownSettings: IDropdownSettings = {};

  @Input() userPropertyService: any;
  calendarStartDate: Date;
  calendarEndDate: Date;
  selectedLocationString: string;
  constructor(
    public dataService: DataService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal
  ) {}

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };

    if (this.isPackageService && this.userPropertyPackage) {
      const endDate = new Date(this.userPropertyPackage.end_date);
      const startDate = new Date(this.userPropertyPackage.start_date);
      this.minDate = {
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
      };
      this.maxDate = {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
      };
    } else {
      this.maxDate = null;
    }

    if (this.serviceDetails.service_detail_data_type === 'CheckBox') {

      if (this.serviceDetails.detail === 'true') {
        this.serviceDetails.detail = true;
      } else {
        this.serviceDetails.detail = false;
      }
    } else if (this.serviceDetails.service_detail_data_type === 'Time') {
      if (this.serviceDetails.detail === 'Not confirmed') {
        this.serviceDetails.detail = null;
      }
    }
    if (this.serviceDetails.service_detail_data_type === 'UserPropertySelect') {
      this.getAddress();

    }
  }

  onDateChange(detailArray, id, date) {
    detailArray[id] = date;
    this.onValueChanged.emit();

  }

  onServiceDateChanged() {

    this.serviceDatesChanged.emit(this.serviceDetails);
  }

  async onOptionChanged(detail, option) {
    detail.detail = option.option;


    if (detail.label === 'cleaning_type') {
      if (detail.detail !== 'One-time/Recurring') {
        this.onTypeChanged.emit([detail, this.index]);
      }
    } else if (
      detail.label === 'origin_address' ||
      detail.label === 'destination_address'
    ) {
      this.geLocationName();

      await this.cd.detectChanges();
      await this.cd.detectChanges();
      if (
        this.serviceDetails.service_detail_data_type === 'UserPropertySelect'
      ) {
        this.getAddress();

      }
    }
    this.onValueChanged.emit();
  }

  geLocationName() {
    if (this.userPropertyService) {
      if (this.serviceDetails.detail === 'Other') {
        const modalRef = this.modalService.open(
          SelectStorageLocationModalComponent,
          {
            size: 'lg',
          }
        );

        if (
          this.serviceDetails.label === 'origin_address' &&
          this.userPropertyService.origin_storage_location !== null
        ) {
          modalRef.componentInstance.location =
            this.userPropertyService.origin_storage_location;
          modalRef.componentInstance.placeDetails =
            this.userPropertyService.origin_storage_location.place_details;
        }

        if (
          this.serviceDetails.label === 'destination_address' &&
          this.userPropertyService.destination_storage_location !== null
        ) {

          modalRef.componentInstance.location =
            this.userPropertyService.destination_storage_location;
          modalRef.componentInstance.placeDetails =
            this.userPropertyService.destination_storage_location.place_details;
        }

        modalRef.result.then(
          (result) => {

            if (this.serviceDetails.label === 'origin_address') {
              this.userPropertyService.origin_storage_location = result;
              this.userPropertyService.origin_storage_location.location_type =
                'origin_address';
              this.serviceDetails.storage_address = result.address;
            } else if (this.serviceDetails.label === 'destination_address') {
              this.userPropertyService.destination_storage_location = result;
              this.userPropertyService.destination_storage_location.location_type =
                'destination_address';
              this.serviceDetails.storage_address = result.address;
            }

            this.serviceDetails.location_name = result.location_name;
            this.serviceDetails.service_details_options[
              this.serviceDetails.service_details_options.length - 1
            ].storage_address = result.address;
            this.serviceDetails.service_details_options[
              this.serviceDetails.service_details_options.length - 1
            ].location_name = result.location_name;


            this.getAddress();
            this.onValueChanged.emit();
          },
          (reason) => {


            if (
              (this.serviceDetails.label === 'origin_address' &&
                (this.userPropertyService.origin_storage_location === null ||
                  this.userPropertyService.origin_storage_location === {})) ||
              (this.serviceDetails.label === 'destination_address' &&
                (this.userPropertyService.destination_storage_location ==
                  null ||
                  this.userPropertyService.destination_storage_location === {}))
            ) {
              const option =
                this.serviceDetails.service_details_options[0].option;
              this.serviceDetails.detail = option;
            }
            this.onValueChanged.emit();
          }
        );
      }
    }
  }

  getAddress() {
    if (this.serviceDetails.detail === 'Other') {
      let location_name = '';
      if (this.serviceDetails.location_name) {
        location_name = ' - ' + this.serviceDetails.location_name;
      }
      this.selectedLocationString = 'Other' + location_name;
    } else {
      this.selectedLocationString = this.serviceDetails.detail;
    }
  }

  getOptionName(item) {
    if (item.option === 'Other') {
      let location_name = '';
      if (item.location_name) {
        location_name = ' - ' + item.location_name;
      }
      return 'Other' + location_name;
    } else {
      return item.option;
    }
  }

  onChange() {


    this.onValueChanged.emit();
  }

  addNew(detailArray) {
    detailArray.push({});
  }

  deleteRow(detailArray, id) {
    detailArray.splice(id, 1);
  }

  onItemSelect(detailArray, item: any) {

    this.onValueChanged.emit();
  }

  onItemDeSelect(item: any) {

    this.onValueChanged.emit();
  }

  onMonthChange(event: number) {

    if (event > 11) {
      this.serviceDetails.detail = '11';
    } else if (event < 0) {
      this.serviceDetails.detail = '0';
    } else {
      this.serviceDetails.detail = event;
    }

    this.cd.detectChanges();
  }

  openCalendarModal(selectedDate) {
    this.calendarStartDate = null;
    this.calendarEndDate = null;
    const modalRef = this.modalService.open(CalendarModalComponent, {
      size: 'lg',
    });

    const date = new Date(
      selectedDate.year,
      selectedDate.month - 1,
      selectedDate.day
    );

    modalRef.componentInstance.selectedDate = date;

    if (this.minDate) {
      const minDate = new Date(
        this.minDate.year,
        this.minDate.month - 1,
        this.minDate.day
      );
      modalRef.componentInstance.minDate = minDate;
    }

    if (this.maxDate) {
      const maxDate = new Date(
        this.maxDate.year,
        this.maxDate.month - 1,
        this.maxDate.day
      );
      modalRef.componentInstance.maxDate = maxDate;
    }
    modalRef.componentInstance.userPropertyServiceId =
      this.user_property_service_id;
    modalRef.componentInstance.onDatesChanged.subscribe((data) => {



      const startDate = new Date(data.start_date);
      const endDate = new Date(data.end_date);
      if (
        !this.calendarStartDate ||
        !this.calendarEndDate ||
        startDate.getTime() !== this.calendarStartDate.getTime() ||
        endDate.getTime() !== this.calendarEndDate.getTime()
      ) {
        modalRef.componentInstance.setLoading(true);
        this.calendarStartDate = startDate;
        this.calendarEndDate = endDate;
        this.dataService
          .getCalendarProviderAvailability(
            this.user_property_service_id,
            startDate,
            endDate
          )
          .subscribe((result: any) => {
            modalRef.componentInstance.setEvents(result);
          });
      }
    });
    modalRef.result.then(
      (result) => {

        const dateObject = moment(result).toDate();

        const newDate = new NgbDate(
          dateObject.getFullYear(),
          dateObject.getMonth() + 1,
          dateObject.getDate()
        );

        this.serviceDetails.detail = newDate;
        this.onServiceDateChanged();
      },
      (reason) => {
      }
    );
  }
}
