import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private localStorageService: LocalStorageService,
    public router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const user = this.localStorageService.getUser();

    if (!user || !user.authentication_token) {
      this.router.navigate(['login']);
      return false;
    }

    // Check for the 'User' builder_role only for specific routes
    const restrictedRoutes = [
      'campaigns',
      'campaigns/create-campaign',
      'campaigns/edit-campaign/:id',
      'clients/new-client/:id',
      'builder-referrals',
      'builder-customer-care',
      'community-content',
      'community-add',
      'builder-users',
      'new-broadcast',
      'new-broadcast/:id',
      'broadcast-list',
    ];

    if (
      route.routeConfig &&
      restrictedRoutes.includes(route.routeConfig.path) &&
      user.builder_role === 'User'
    ) {
      // Redirect to an unauthorized page or handle access denied as needed
      this.router.navigate(['/unauthorised']);
      return false;
    }

    if (!environment.staging) {
      const onlyForStaging = [
        'customer/:userId/rewards',
        'gm/settings/reward-configuration',
        'gm/settings/rewards-manager',
        'gm/settings/rewards-manager/rewards-detail/:reward_claim_id',
        'gm/settings/rewards-manager/rewards-create',
      ];

      if (
        route.routeConfig &&
        onlyForStaging.includes(route.routeConfig.path) &&
        !environment.staging
      ) {
        // Redirect to an unauthorized page or handle access denied as needed
        this.router.navigate(['/unauthorised']);
        return false;
      }
    }

    const expectedRoles = route.data.expectedRoles as string[];

    // Check if the user has any of the expected roles
    if (this.checkRoles(user.roles, expectedRoles)) {
      return true;
    } else {
      // Redirect to an unauthorized page or handle access denied as needed

      this.router.navigate(['/unauthorised']);
      return false;
    }
  }

  private checkRoles(userRoles: string[], expectedRoles: string[]): boolean {
    if (expectedRoles === undefined || expectedRoles.length === 0) {
      return true;
    } else {
      return userRoles.some((role) => expectedRoles.includes(role));
    }
  }
}
