import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.sass'],
})
export class ResetPasswordModalComponent implements OnInit {
  @Input()
  user: any = {};

  showMessage: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService
  ) {}

  ngOnInit() {

  }

  reset() {
    this.dataService.sendResetPasswordEmail(this.user.email).subscribe(
      (data: any) => {

        this.showMessage = true;
      },
      (error: any) => {

      }
    );
  }
}
