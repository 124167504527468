import * as moment from "moment";
import { RRule } from "rrule";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export default class Utils {
  static getPhoneMask() {
    return [
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }

  static getRecurringWeekday(dayCode) {
    switch (dayCode) {
      case "SU":
        return RRule.SU;
        break;
      case "MO":
        return RRule.MO;
        break;
      case "TU":
        return RRule.TU;
        break;
      case "WE":
        return RRule.WE;
        break;
      case "TH":
        return RRule.TH;
        break;
      case "FR":
        return RRule.FR;
        break;
      case "SA":
        return RRule.SA;
        break;

      default:
        break;
    }
  }

  static trackPage(title) {
    window.analytics.page({
      title: title,
    });
  }

  static identifyStaffLogin(staff) {
    window.analytics.identify(staff.id, {
      email: staff.email,
      first_name: staff.first_name,
      initiation_date: staff.created_at,
      created_at: staff.created_at,
      last_name: staff.last_name,
      is_staff: true,
      roles: staff.roles,
    });
  }

  static identify(data, ipAddress) {
    var account = "";
    var destination_address = "";
    var target_possession_date = "";
    var opt_out_date = "";
    var possession_date = "";
    var move_in_date = "";
    var organizationId = "";
    var hc_first_name = "";
    var hc_last_name = "";
    var hc_phone = "";
    var hc_booking_link = "";
    var hc_profile_image = "";
    var hc_email = "";
    var project_name = "";
    var project_alias_name = "";
    var project_contact = "";
    var project_logo = "";
    var product_type = "";
    var buyer_type = "";
    var destination_city = "";
    var destination_country = "";
    var age = 0;
    var additional_user_ids = "";
    var sms_notification = false;
    var email_notification = false;
    var push_notification = false;
    if (data.builder_account) {
      account = data.builder_account.builder_name;
    }

    if (data.active_primary_user_property) {
      destination_address =
        data.active_primary_user_property.property.formatted_address;

      if (data.active_primary_user_property.property.place_details) {
        destination_city =
          data.active_primary_user_property.property.place_details.city;
        destination_country =
          data.active_primary_user_property.property.place_details.country;
      }
      if (data.active_primary_user_property.key_dates) {
        possession_date =
          data.active_primary_user_property.key_dates.possession_date;
        target_possession_date =
          data.active_primary_user_property.key_dates.target_possession_date;
        move_in_date = data.active_primary_user_property.key_dates.move_in_date;
      }
      if (data.active_primary_user_property.property.product_type) {
        product_type =
          data.active_primary_user_property.property.product_type.product_type;
      }
      buyer_type = data.buyer_type;
    }

    if (data.project_details) {
      project_name = data.project_details.name;
      project_alias_name = data.project_details.alias_name;
      project_contact = data.project_details.project_contact;
      project_logo = data.project_details.project_image;
      if (project_logo == null || project_logo == "") {
        if (
          data.builder_account &&
          data.builder_account.logo &&
          data.builder_account.logo != ""
        ) {
          project_logo = data.builder_account.logo;
        } else {
          project_logo =
            "https://virtuo-production.s3-us-west-2.amazonaws.com/uploads/virtuo-icon-v2.png";
        }
      }
    }

    if (data.additional_users && data.additional_users.length > 0) {
      for (var i = 0; i < data.additional_users.length; ++i) {
        if (additional_user_ids.length > 0) {
          additional_user_ids.concat(",");
          additional_user_ids.concat(
            data.additional_users[i].additional_user_id
          );
        } else {
          additional_user_ids.concat(
            data.additional_users[i].additional_user_id
          );
        }
      }
    }

    if (data.notification_preference) {
      sms_notification = data.notification_preference.sms;
      email_notification = data.notification_preference.email;
      push_notification = data.notification_preference.push;
    }

    if (data.hc) {
      if (data.hc.zen_org_id) {
        organizationId = data.hc.zen_org_id;
      }

      hc_first_name = data.hc.first_name;
      hc_last_name = data.hc.last_name;
      hc_email = data.hc.email;
      hc_phone = data.hc.phone_number;
      hc_profile_image = data.hc.avatar;
      hc_booking_link = data.hc.mc_booking_link;
    }


    window.analytics.identify(data.id, {
      ip: ipAddress,
      userId: data.id,
      first_name: data.first_name,
      alias_name: data.alias_name,
      last_name: data.last_name,
      email: data.email,
      account_name: account,
      project_name: project_name,
      project_alias_name: project_alias_name,
      project_contact: project_contact,
      project_logo: project_logo,
      product_type: product_type,
      buyer_type: buyer_type,
      adults_in_household: data.no_of_adults,
      children_in_household: data.no_of_children,
      home_maintenance_type: data.style_type_name,
      number_dogs: data.no_of_dogs,
      number_cats: data.no_of_cats,
      number_other_pets: data.no_of_other_pets,
      additional_user_ids: additional_user_ids,
      vip: data.vip,
      phone: data.phone_number,
      destination_address: destination_address,
      welcome_call_date: data.welcome_call_date,
      initiation_date: data.created_at,
      possession_date: possession_date,
      target_possession_date: target_possession_date,
      move_in_date: move_in_date,
      organizationId: organizationId,
      customer_id: data.active_primary_user_property.customer_id,
      phone_type: data.number_type,
      preferred_language: data.languages,
      hc_first_name: hc_first_name,
      hc_last_name: hc_last_name,
      hc_email: hc_email,
      hc_phone: hc_phone,
      hc_profile_image: hc_profile_image,
      hc_booking_link: hc_booking_link,
      status: data.user_status.status,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      age: age,
      sms_notification: sms_notification,
      email_notification: email_notification,
      push_notification: push_notification,
      destination_city: destination_city,
      destination_country: destination_country,
    });

  }

  static isTodoDueToday(todo_date) {
    var m_date = moment(todo_date, "ddd, MMM DD YYYY");
    var today = moment();
    if (m_date.isSame(today, "day")) return true;
    else return false;
  }

  static isTodoDueTodayV2(todo_date) {
    var m_date = moment(todo_date, "YYYY-MM-DD");
    var today = moment();
    if (m_date.isSame(today, "day")) return true;
    else return false;
  }

  static isTodoOverDue(todo_date) {
    var m_date = moment(todo_date, "ddd, MMM DD YYYY");
    var today = moment();
    if (m_date.isBefore(today, "day")) return true;
    else return false;
  }

  static isTodoOverDueV2(todo_date) {
    var m_date = moment(todo_date, "YYYY-MM-DD");
    var today = moment();
    if (m_date.isBefore(today, "day")) return true;
    else return false;
  }

  static isServiceDueToday(date) {
    var m_date = moment(date, "MMM DD YYYY");
    var today = moment();
    if (m_date.isSame(today, "day")) return true;
    else return false;
  }

  static isSeviceOverDue(date) {
    var m_date = moment(date, "MMM DD YYYY");
    var today = moment();

    if (m_date.isBefore(today, "day")) return true;
    else return false;
  }

  // TODO/FIXME: Why are we doing a switch case this big for icons? the right
  // structure should be an array as:
  //
  // WHITE_ICONS = {"window_washing": "window-cleaner-white.svg"}
  //
  // and this function should be
  //
  // function getServiceIconWhite(label) {
  //  return `../../../assets/images/icons/services/white/${WHITE_ICONS[label]}`
  // }
  //
  // The current structure is suboptimal.
  static getServiceIconWhite(label) {
    var url = "";
    switch (label) {
      case "window_washing":
      case "power_washing":
        url =
          "../../../assets/images/icons/services/white/window-cleaner-white.svg";
        break;
      case "furnace_care":
      case "furnace_cleaning":
        url = "../../../assets/images/icons/services/white/furnace-white.svg";
        break;
      case "cleaner":
      case "move_out_clean":
      case "house_cleaning":
      case "commercial_clean":
        url =
          "../../../assets/images/icons/services/white/new-cleaning-white.svg";
        break;

      case "lawn_care":
      case "lawn_maintenance":
        url =
          "../../../assets/images/icons/services/white/gardening-lawn-white.svg";
        break;
      case "snow_removal":
        url =
          "../../../assets/images/icons/services/white/snow-removal-white.svg";
        break;
      case "spring_clean":
        url =
          "../../../assets/images/icons/services/white/spring-clean-white.svg";
        break;
      case "moving":
      case "professional_move_in":
      case "professional_move_out":
      case "professional_moving_full":
      case "flat_rate_move":
      case "commercial_moving":
        url =
          "../../../assets/images/icons/services/white/new-moving-white.svg";
        break;
      case "self_storage":
      case "commercial_storage":
      case "storage":
        url = "../../../assets/images/icons/services/white/storage-white.svg";
        break;
      case "junk_removal":
      case "commercial_junk_removal":
        url =
          "../../../assets/images/icons/services/white/new-junk-removal-white.svg";
        break;
      case "realtors":
      case "trusted_partners":
      case "commercial_architect":
        url = "../../../assets/images/icons/services/white/realtors.-white.svg";
        break;
      case "mortgage_brokers":
      case "commercial_legal_services":
        url =
          "../../../assets/images/icons/services/white/new-mortgage-white.svg";
        break;
      case "home_appraisal":
      case "commercial_construction_contractor":
        url =
          "../../../assets/images/icons/services/white/home-appraisal-white.svg";
        break;
      case "virtuo_eco_friendly_boxes":
        url = "../../../assets/images/icons/services/white/boxes.-white.svg";
        break;
      case "internet_and_tv":
      case "internet_and_tv_setup":
      case "internet_and_tv_cancel":
      case "internet_and_tv_transfer":
      case "commercial_it_data":
        url =
          "../../../assets/images/icons/services/white/new-internet-white.svg";
        break;

      case "portable_container":
        url = "../../../assets/images/icons/services/white/container-white.svg";
        break;
      case "truck_rental":
      case "commercial_furniture":
      case "commercial_parking":
        url =
          "../../../assets/images/icons/services/white/new-truck-rental-white.svg";
        break;

      case "utilities":
      case "utilities_setup":
      case "utilities_cancel":
      case "utilities_transfer":
      case "gas_setup":
      case "gas_transfer":
      case "electricity_setup":
      case "electricity_transfer":
      case "municipal_services_setup":
      case "municipal_services_transfer":
      case "water_setup":
      case "water_transfer":
        url = "../../../assets/images/icons/services/white/utilities-white.svg";
        break;
      case "home_care":
      case "handyman":
      case "fence_and_deck":
      case "repairs":
        url = "../../../assets/images/icons/services/white/repairs-white.svg";
        break;
      case "buying_selling":
        url =
          "../../../assets/images/icons/services/white/buying-selling-white.svg";
        break;
      default:
        url =
          "../../../assets/images/icons/services/white/window-cleaner-white.svg";
    }

    return url;
  }

  static getServiceIconGrey(label) {
    var url = "";

    switch (label) {
      case "window_washing":
      case "power_washing":
        url = "../../../assets/images/icons/services/grey/cleaning.svg";
        break;
      case "furnace_care":
      case "furnace_cleaning":
        url = "../../../assets/images/icons/services/grey/furnace.svg";
        break;
      case "cleaner":
      case "move_out_clean":
      case "house_cleaning":
      case "commercial_clean":
        url = "../../../assets/images/icons/services/grey/cleaning.svg";
        break;

      case "lawn_care":
      case "lawn_maintenance":
        url = "../../../assets/images/icons/services/grey/gardening-lawn.svg";
        break;
      case "snow_removal":
        url = "../../../assets/images/icons/services/grey/ic_snow_removal.png";
        break;
      case "moving":
      case "professional_move_in":
      case "professional_move_out":
      case "professional_moving_full":
      case "flat_rate_move":
      case "commercial_moving":
        url = "../../../assets/images/icons/services/grey/moving.svg";
        break;
      case "self_storage":
      case "commercial_storage":
      case "storage":
        url = "../../../assets/images/icons/services/grey/storage.svg";
        break;
      case "junk_removal":
      case "commercial_junk_removal":
        url = "../../../assets/images/icons/services/grey/junk-removal.svg";
        break;
      case "realtors":
      case "trusted_partners":
      case "commercial_architect":
        url = "../../../assets/images/icons/services/grey/realtors.svg";
        break;
      case "mortgage_brokers":
      case "commercial_legal_services":
        url = "../../../assets/images/icons/services/grey/mortgage.svg";
        break;
      case "home_appraisal":
      case "commercial_construction_contractor":
        url =
          "../../../assets/images/icons/services/white/home-appraisal-blue.svg";
        break;
      case "virtuo_eco_friendly_boxes":
        url = "../../../assets/images/icons/services/grey/boxes.svg";
        break;
      case "internet_and_tv":
      case "internet_and_tv_setup":
      case "internet_and_tv_cancel":
      case "internet_and_tv_transfer":
      case "commercial_it_data":
        url = "../../../assets/images/icons/services/grey/internet.svg";
        break;

      case "portable_container":
        url = "../../../assets/images/icons/services/grey/packing.svg";
        break;
      case "truck_rental":
      case "commercial_furniture":
      case "commercial_parking":
        url = "../../../assets/images/icons/services/grey/truck-rental.svg";
        break;

      case "utilities":
      case "utilities_setup":
      case "utilities_cancel":
      case "utilities_transfer":
      case "gas_setup":
      case "gas_transfer":
      case "electricity_setup":
      case "electricity_transfer":
      case "municipal_services_setup":
      case "municipal_services_transfer":
      case "water_setup":
      case "water_transfer":
        url = "../../../assets/images/icons/services/grey/utilities.svg";
        break;
      case "home_care":
        url = "../../../assets/images/icons/services/grey/home-care.svg";
        break;
      case "handyman":
      case "fence_and_deck":
      case "repairs":
        url = "../../../assets/images/icons/services/grey/repairs.svg";
        break;
      case "buying_selling":
        url = "../../../assets/images/icons/services/grey/buying-selling.svg";
        break;
      default:
        url = "../../../assets/images/icons/services/grey/cleaning.svg";
    }

    return url;
  }

  static getServiceIcon(service_label) {
    var label = service_label.toLowerCase();
    var url = "";
    switch (label) {
      case "power_washing":
      case "window_washing":
        url = "../../../assets/images/icons/services/window-cleaner.svg";
        break;
      case "furnace_care":
      case "furnace_cleaning":
        url = "../../../assets/images/icons/services/furnace.svg";
        break;
      case "cleaner":
      case "move_out_clean":
      case "house_cleaning":
      case "commercial_clean":
        url = "../../../assets/images/icons/services/cleaning.svg";
        break;
      case "lawn_maintenance":
      case "lawn_care":
        url = "../../../assets/images/icons/services/gardening-lawn.svg";
        break;
      case "snow_removal":
        url = "../../../assets/images/icons/services/snow-removal.svg";
        break;
      case "spring_clean":
        url = "../../../assets/images/icons/services/spring-clean.svg";
        break;
      case "moving":
      case "professional_move_in":
      case "professional_move_out":
      case "professional_moving_full":
      case "flat_rate_move":
      case "commercial_moving":
        url = "../../../assets/images/icons/services/professionalmovers.svg";
        break;
      case "self_storage":
      case "commercial_storage":
      case "storage":
        url = "../../../../../../assets/images/icons/services/storage.svg";
        break;
      case "junk_removal":
      case "commercial_junk_removal":
        url = "../../../assets/images/icons/services/junk-removal.svg";
        break;
      case "realtors":
      case "trusted_partners":
      case "commercial_architect":
        url = "../../../assets/images/icons/services/realtors.svg";
        break;
      case "mortgage_brokers":
      case "commercial_legal_services":
        url = "../../../assets/images/icons/services/mortgage.svg";
        break;
      case "home_appraisal":
      case "commercial_construction_contractor":
        url = "../../../assets/images/icons/services/home-appraisal.svg";
        break;
      case "virtuo_eco_friendly_boxes":
        url = "../../../assets/images/icons/services/boxes.svg";
        break;
      case "internet_and_tv":
      case "internet_and_tv_setup":
      case "internet_and_tv_cancel":
      case "internet_and_tv_transfer":
      case "commercial_it_data":
        url = "../../../assets/images/icons/services/internet-tv.svg";
        break;
      case "portable_container":
        url = "../../../assets/images/icons/services/container.svg";
        break;
      case "truck_rental":
      case "commercial_furniture":
      case "commercial_parking":
        url = "../../../assets/images/icons/services/truck-rental.svg";
        break;
      case "utilities":
      case "utilities_setup":
      case "utilities_cancel":
      case "utilities_transfer":
      case "gas_setup":
      case "gas_transfer":
      case "electricity_setup":
      case "electricity_transfer":
      case "municipal_services_setup":
      case "municipal_services_transfer":
      case "water_setup":
      case "water_transfer":
        url = "../../../assets/images/icons/services/utilities.svg";
        break;
      default:
        url = "../../../assets/images/icons/services/window-cleaner.svg";
    }

    return url;
  }

  static getLocationDetails(place) {
    for (var i = 0; i < place.address_components.length; i++) {
      var component = place.address_components[i];
      if (component.types.includes("locality")) {
        var city_name = component.long_name;

      }

      if (component.types.includes("administrative_area_level_1")) {
        var province_name = component.long_name;

      }

      if (component.types.includes("postal_code")) {
        var postal_code = component.long_name;

      }

      if (component.types.includes("country")) {
        var country = component.long_name;

      }

      if (component.types.includes("street_number")) {
        var street_number = component.long_name;

      }

      if (component.types.includes("route")) {
        var route = component.long_name;

      }

      if (component.types.includes("locality")) {
        var locality = component.long_name;

      }
    }

    var place_details = {};

    place_details.latitude = place.geometry.location.lat();
    place_details.longitude = place.geometry.location.lng();
    place_details.city = city_name;
    place_details.province = province_name;
    place_details.administrative_area_level_1 = province_name;
    place_details.postal_code = postal_code;
    place_details.country = country;
    place_details.street_number = street_number;
    place_details.route = route;
    place_details.locality = locality;
    place_details.display_name = place.name + " " + place.formatted_address;
    place_details.place_id = place.place_id;
    return place_details;
  }

  static getDateObjectWithoutTime(date) {
    var parts = date.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  static formatDateToNgbDate(date, format) {


    if (date != null) {
      var dateObject = moment(date, format).toDate();

      return new NgbDate(
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      );
    } else {
      return "";
    }
  }

  static getDateFromNgbDate(ngbDate) {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }
}
