import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import Utils from 'src/app/AppPages/Elements/utils';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit, AfterViewInit {
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  @Input()
  milestones: any = [];

  @Input()
  filteredMilestones: any = [];
  @Output() onMilestoneAction = new EventEmitter();
  @Output() onAddNewToDo = new EventEmitter();
  @Output() filterMilestones = new EventEmitter();
  @ViewChildren('todayElement') todayElement: QueryList<ElementRef>;

  today = new Date();
  selectedCustomer: any;
  y_value: any;
  services: any = [];

  showCompleted = false;
  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild(PerfectScrollbarComponent)
  componentRef?: PerfectScrollbarComponent;
  isBuilder = false;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.selectedCustomer = this.localStorageService.getSelectedCustomer();
    const currentUser = this.localStorageService.getUser();
    if (
      currentUser.roles.includes('builder') ||
      currentUser.roles.includes('additional_builder')
    ) {
      this.isBuilder = true;
    }
    this.getAllServices();
  }

  getAllServices() {
    this.dataService.getAllServices().subscribe((data: any) => {
      this.services = data;
    });
  }

  filterData(completed) {
    if (this.showCompleted !== completed) {
      this.showCompleted = completed;
      this.filterMilestones.emit(completed);
    }
  }
  ngAfterViewInit() {
    this.todayElement.changes.subscribe((ref: QueryList<ElementRef>) => {
      if (this.todayElement.first.nativeElement) {
        const { x, y } =
          this.todayElement.first.nativeElement.getBoundingClientRect();
        this.y_value = y - 160;
        this.scrollToBottom();
      }
    });
  }

  milestoneAction(milestone) {
    window.analytics.track('Milestone Timeline', {
      user_id: this.selectedCustomer.id,
      user_property_id: milestone.user_property_id,
      timestamp: moment().format(),
    });
    if (
      milestone.user_property_service_id ||
      milestone.status === 'ToDo' ||
      milestone.status === 'Deal' ||
      milestone.status === 'HomeMaintenanceTask' ||
      milestone.status === 'Appointment'
    ) {
      this.onMilestoneAction.emit(milestone);
    }
  }

  openBuilderSignalUrl(milestone) {
    this.onMilestoneAction.emit(milestone);
  }

  hasBuilderSignal(milestone) {
    return (
      milestone.type === 'project_milestone' &&
      milestone.is_builder_signal_enabled === true &&
      milestone.builder_signal_url &&
      milestone.builder_signal_url !== ''
    );
  }
  AddNewToDo() {
    const body = {
      new_tofo: true,
    };
    this.onAddNewToDo.emit(body);
  }

  public scrollToBottom(): void {
    if (this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.scrollToY(this.y_value);
    }
  }

  getIcon(milestone) {
    if (this.services.length > 0) {
      if (
        milestone.service_name === 'Possession' ||
        milestone.service_name === 'Move In' ||
        milestone.service_name === 'Move Out'
      ) {
        return '/assets/images/icons/possesion-star.svg';
      } else if (milestone.status === 'Deal') {
        return '/assets/images/icons/dollar-sign.svg';
      } else if (this.isAfterToday(milestone.milestone_date)) {
        if (milestone.status === 'ToDo' || milestone.status === 'Appointment') {
          return '/assets/images/icons/services/grey/documents.svg';
        } else if (
          milestone.type === 'project_milestone' ||
          milestone.status === 'HomeMaintenanceTask'
        ) {
          return '/assets/images/icons/milestone-home-maintenance.svg';
        } else {
          for (const service of this.services) {
            if (service.name === milestone.service_name) {
              if (
                milestone.status === 'Missing Details' ||
                milestone.status === 'Review Matches' ||
                milestone.status === 'Invoice Available' ||
                milestone.status === 'Signature Required' ||
                milestone.status === 'Payment Details Pending'
              ) {
                return '/assets/images/icons/check-task.svg';
              } else {
                const icon = Utils.getServiceIconGrey(service.label);
                return icon;
              }
            }
          }
        }
      } else {
        if (
          milestone.status === 'HomeMaintenanceTask' ||
          milestone.type === 'project_milestone' ||
          milestone.status === 'ToDo' ||
          milestone.status === 'Appointment'
        ) {
          if (
            milestone.activity_status === 'Pending' ||
            milestone.status === 'Pending'
          ) {
            if (
              milestone.type === 'project_milestone' ||
              milestone.status === 'ToDo' ||
              milestone.status === 'Appointment'
            ) {
              return '/assets/images/icons/services/grey/documents.svg';
            } else if (milestone.status === 'HomeMaintenanceTask') {
              return '/assets/images/icons/milestone-home-maintenance.svg';
            }
          } else {
            return '/assets/images/icons/new-completed.svg';
          }
        } else {
          for (const service of this.services) {
            if (service.name === milestone.service_name) {
              if (
                milestone.status === 'Missing Details' ||
                milestone.status === 'Review Matches' ||
                milestone.status === 'Invoice Available' ||
                milestone.status === 'Signature Required' ||
                milestone.status === 'Payment Details Pending'
              ) {
                return '/assets/images/icons/check-task.svg';
              } else {
                return '/assets/images/icons/new-completed.svg';
              }
            }
          }
        }
      }
    }
  }

  isAfterToday(milestone_date) {
    if (new Date(milestone_date) > this.today) {
      return true;
    } else {
      return false;
    }
  }

  getMonth(date_string, position) {
    if (position > 0) {
      const prev_month = new Date(
        this.filteredMilestones[position - 1].milestone_date
      );
      const current_month = new Date(
        this.filteredMilestones[position].milestone_date
      );
      if (prev_month.getMonth() !== current_month.getMonth()) {
        return this.monthNames[current_month.getMonth()];
      } else {
        return null;
      }
    } else {
      const d = new Date(date_string);
      return this.monthNames[d.getMonth()];
    }
  }

  getDate(date) {
    const d = new Date(date);
    const day = d.getDate();
    if (day < 10) {
      date = '0' + day;
      return day;
    } else {
      return day;
    }
  }
}
