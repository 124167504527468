import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { ChatModalComponent } from 'src/app/AppPages/MCPortal/customer/chat-modal/chat-modal.component';
import { NewToDoModalComponent } from 'src/app/AppPages/MCPortal/customer/new-to-do-modal/new-to-do-modal.component';
import { UploadFileModalComponent } from 'src/app/AppPages/MCPortal/customer/upload-file-modal/upload-file-modal.component';
import { EventService } from 'src/services/event.service';
import { AppointmentModalComponent } from '../../MCPortal/customer/appointment-modal/appointment-modal.component';
import { UploadVideoModalComponent } from '../../MCPortal/customer/upload-video-modal/upload-video-modal.component';
import { UploadDocumentModalComponent } from '../../multi-use-components/file-cabinet/upload-document-modal/upload-document-modal.component';
import { filterUserProperties } from '../../multi-use-components/multi-use-helpers';

declare global {
  interface Window {
    analytics: any;
  }
}

function getWindow(): any {
  return window;
}
@Component({
  selector: 'app-floating-buttons',
  templateUrl: './floating-buttons.component.html',
  styleUrls: ['./floating-buttons.component.scss'],
})
export class FloatingButtonsComponent implements OnInit {
  isCustomer: boolean;
  private window;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private eventService: EventService,
    private localStorageService: LocalStorageService
  ) {
    this.window = getWindow();
  }

  buttonFlag = false;
  userId: any;
  selectedCustomer: any;

  isBuilder = false;
  isConcierge = false;

  isBuilderActivated = false;

  // Count Badge for message board comment
  commentCount = 0;
  @Output() change = new EventEmitter();
  @Output() historyChange = new EventEmitter();

  async ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get('userId');
    });
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();
    const currentUser = await this.localStorageService.getUser();
    if (
      currentUser.roles.includes('builder') ||
      currentUser.roles.includes('additional_builder')
    ) {
      this.isBuilder = true;
    }

    if (
      currentUser.roles.includes('csr') ||
      currentUser.roles.includes('lead_concierge') ||
      currentUser.roles.includes('gm')
    ) {
      this.isConcierge = true;
    }

    this.eventService.myEvent$.subscribe((event) => {
      if (event === 'message_icon_clicked') {
        this.markCommentsAsRead();
        this.loadCommentCount();
      }
    });

    this.checkCustomer();
    this.loadCommentCount();
  }

  loadCommentCount() {
    this.dataService
      .loadMessageBoardCount(this.selectedCustomer.message_board.id)
      .subscribe((data: any) => {
        this.commentCount = data.unread_count;
      });
  }

  markCommentsAsRead() {
    const body = {
      board_id: this.selectedCustomer.message_board.id,
    };
    this.dataService.markCommentsAsRead(body).subscribe((data: any) => {});
  }

  checkCustomer() {
    const user = this.localStorageService.getUser();

    if (
      (user.roles.includes('customer_user') ||
        user.roles.includes('customer_user_additional')) &&
      this.selectedCustomer.id === user.id
    ) {
      this.isCustomer = true;
    } else {
      this.isCustomer = false;
    }
  }
  ngOnDestroy() {}

  initZendesk() {}

  openChat() {}

  hideLauncher() {
    const launcher = window.document.getElementById('launcher');
    if (launcher) {
      launcher.setAttribute('hidden', 'true');
    }
  }

  onClick() {
    this.buttonFlag = !this.buttonFlag;
    if (this.buttonFlag) {
      window.analytics.track('Quick Launch', {
        user_id: this.selectedCustomer.id,
        timestamp: moment().format(),
      });
    }
  }

  onChatClick() {
    const modalRef = this.modalService.open(ChatModalComponent, {
      windowClass: 'todoModalClass',
    });
    modalRef.componentInstance.user = _.cloneDeep(this.selectedCustomer);
  }

  onClickNewTodo() {
    window.analytics.track('Quick Launch - ToDo', {
      user_id: this.selectedCustomer.id,
      timestamp: moment().format(),
    });
    const modalRef = this.modalService.open(NewToDoModalComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.customerid = _.cloneDeep(this.userId);
    modalRef.result.then(
      (result) => {
        this.change.emit(result);
      },
      (reason) => {}
    );
  }

  onClickUploadFile(upload_type) {
    if (upload_type === 'USER_RECEIPT') {
      window.analytics.track('Quick Launch - Receipt', {
        user_id: this.selectedCustomer.id,
        timestamp: moment().format(),
      });
      const modalRef = this.modalService.open(UploadFileModalComponent, {
        size: 'lg',
      });
      modalRef.componentInstance.upload_type = upload_type;
      modalRef.componentInstance.updateFiles.subscribe((data) => {
        this.change.emit(true);
      });
      modalRef.result.then(
        (result) => {
          this.change.emit();
        },
        (reason) => {}
      );
    } else {
      window.analytics.track('Quick Launch - Document', {
        user_id: this.selectedCustomer.id,
        timestamp: moment().format(),
      });

      const modalRef = this.modalService.open(UploadDocumentModalComponent, {
        size: 'lg',
      });

      modalRef.componentInstance.userType = 'hc';

      const filteredProperties = filterUserProperties(
        this.selectedCustomer.user_properties
      );

      modalRef.componentInstance.properties = filteredProperties;
      modalRef.componentInstance.client = _.cloneDeep(this.selectedCustomer);

      modalRef.result.then(
        (result) => {
          this.change.emit();
        },
        (reason) => {}
      );
    }
  }

  onClickVAppointment() {
    const modalRef = this.modalService.open(AppointmentModalComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.customerid = _.cloneDeep(this.userId);
    modalRef.componentInstance.edit_details_flag = true;
    modalRef.result.then(
      (result) => {
        this.change.emit(result);
      },
      (reason) => {}
    );
  }

  onClickVideo() {
    window.analytics.track('Quick Launch - Video', {
      user_id: this.selectedCustomer.id,
      timestamp: moment().format(),
    });
    const modalRef = this.modalService.open(UploadVideoModalComponent, {
      size: 'lg',
    });

    modalRef.result.then(
      (result) => {
        this.historyChange.emit(result);
      },
      (reason) => {}
    );
  }

  onNewService() {
    window.analytics.track('Quick Launch - Service', {
      user_id: this.selectedCustomer.id,
      timestamp: moment().format(),
    });

    this.router.navigate([
      '/customer/' + this.userId + '/home-services/explore-services',
    ]);
  }
}
