import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.sass'],
})
export class DateInputComponent implements OnInit {
  @Input() event;
  @Input() dateDetails;
  @Output() change = new EventEmitter();

  dealDetails: any = {};
  ngOnInit() {
    this.dealDetails = this.dateDetails;

  }

  changeDate(value) {

    const d = value.year + '-' + value.month + '-' + value.day;

    this.change.emit({ date: d });
  }

  changeUntilDate(value) {
    const d = value.year + '-' + value.month + '-' + value.day;

    this.change.emit({ until_date: d });
  }
}
