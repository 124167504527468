import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { DynamicInformationComponent } from 'src/app/AppPages/Elements/dynamic-information/dynamic-information.component';
import { ConfirmServiceDetailsModalComponent } from 'src/app/AppPages/MCPortal/customer/customer-home-services/confirm-service-details-modal/confirm-service-details-modal.component';
import { ThemeOptions } from 'src/app/theme-options';
import swal from 'sweetalert2';
import { ProviderSelectionModalComponent } from '../../MCPortal/customer/customer-home-services/provider-selection-modal/provider-selection-modal.component';
import { DealEventsModalComponent } from '../../MCPortal/customer/deal-events-modal/deal-events-modal.component';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-matching-provider-locations',
  templateUrl: './matching-provider-locations.component.html',
  styleUrls: ['./matching-provider-locations.component.scss'],
})
export class MatchingProviderLocationsComponent implements OnInit {
  @Input()
  data: any = {};
  @Input()
  selectedUserProperty: any = {};
  @Input()
  allowEdit = true;

  showSignModal = false;

  @Input()
  userPropertyService: any = {};

  @Output() updateLocations: EventEmitter<any> = new EventEmitter();

  sortType = 1;

  selectedCustomer: any;

  @Output() serviceDetailsChanged: EventEmitter<any> = new EventEmitter();
  info: any = {};
  notes_details = {};
  per_hour_flag = false;
  matching_providers_list: any = [];
  customer_flag: any;
  sign_document = false;
  signed_document_array: any = [];
  signed_document = true;
  sorting_list = [
    { id: 1, type: 'Virtuo Ranking' },
    { id: 2, type: 'Review Score' },
    { id: 3, type: 'Price (Low to High)' },
    { id: 4, type: 'Price (High to Low)' },
  ];

  movingServicesArr: string[] = [
    'professional_moving_full',
    'professional_move_out',
    'professional_move_in',
    'junk_removal',
    'custom_quote_service',
    'flat_rate_move',
  ];
  estimate_service_flag = false;
  flat_rate_move_flag = false;
  selectedFilter: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private dataService: DataService,
    private _location: Location,
    public globals: ThemeOptions
  ) {}

  async ngOnInit() {
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();
    const user = await this.localStorageService.getUser();
    this.checkCustomer();
    this.route.queryParams.subscribe((params) => {
      this.route.queryParams.subscribe((params) => {
        const signParam = params.sign;

        if (signParam === 'true') {
          this.showSignModal = true;
          const queryParams = {
            user_property_service_id: params.user_property_service_id,
          };

          this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            replaceUrl: true,
          });
        }
        this.init();
      });
    });
  }

  init() {
    const selectedProviderLocationID = this.getSelectedProviderLocationId(
      this.data
    );

    if (selectedProviderLocationID !== -1) {
      this.data.selected_provider = selectedProviderLocationID;
    }
    if (
      (this.data.matching_provider_locations[0]
        .user_property_service_provider &&
        this.data.matching_provider_locations[0].user_property_service_provider
          .user_property_service_provider_document_templates.length > 0) ||
      (this.data.matching_provider_locations[0]
        .user_property_service_provider &&
        this.data.matching_provider_locations[0].user_property_service_provider
          .sign_invoices.length > 0)
    ) {
      this.sign_document = true;
      this.data.matching_provider_locations[0].user_property_service_provider.user_property_service_provider_document_templates.forEach(
        (template) => {
          if (template.intermediate_status === 'Signed by customer') {
            this.signed_document_array.push(true);
          } else {
            this.signed_document_array.push(false);
          }
        }
      );
      if (this.signed_document_array.includes(false)) {
        this.signed_document = false;
      }
      if (this.showSignModal) {
        this.openDetailsModal(this.data.matching_provider_locations[0]);
        this.showSignModal = false;
      }
    }
  }
  radioChange(event) {}

  gotoReviews(provider) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        name: provider.company_service_alias_name
          ? provider.company_service_alias_name
          : provider.provider.name,
        id: provider.provider.id,
      },
    };
    this.router.navigate(
      ['/customer/' + this.selectedCustomer.id + '/partner-reviews/'],
      navigationExtras
    );
  }

  openDetailsModal(selected_provider_location) {
    const masterObject = this.data;

    this.dataService
      .getUserPropertyServiceProviderDetails(
        this.data.user_property_service_id,
        selected_provider_location.id
      )
      .subscribe((response: any) => {
        const provider_location = response;
        provider_location.should_show_check_availability_button =
          selected_provider_location.should_show_check_availability_button;
        provider_location.should_show_refer_button =
          selected_provider_location.should_show_refer_button;
        const is_current_provider_selected = ['selected', 'accepted'].includes(
          provider_location.user_property_service_provider.status
        );

        const selectedProviderLocationID =
          this.getSelectedProviderLocationId(masterObject);

        const show_action_button = this.should_show_action_button(
          masterObject,
          selectedProviderLocationID,
          provider_location
        );

        if (
          this.movingServicesArr.includes(
            provider_location.user_property_service_provider.service_label
          )
        ) {
          this.estimate_service_flag = true;
        }

        if (
          ['flat_rate_move'].includes(
            provider_location.user_property_service_provider.service_label
          )
        ) {
          this.flat_rate_move_flag = true;
        }

        const modalRef = this.modalService.open(
          ProviderSelectionModalComponent,
          {
            size: 'lg',
          }
        );

        provider_location.should_show_action_button = show_action_button;
        const data = {
          provider_location,
          show_action_button,
          is_current_provider_selected,
          customer_flag: this.customer_flag,
          userPropertyService: this.userPropertyService,
        };

        modalRef.componentInstance.data = _.cloneDeep(data);
        modalRef.componentInstance.selectedCustomer = _.cloneDeep(
          this.selectedCustomer
        );
        modalRef.componentInstance.allowEdit = this.allowEdit;
        modalRef.componentInstance.estimate_service_flag =
          this.estimate_service_flag;
        modalRef.componentInstance.flat_rate_move_flag =
          this.flat_rate_move_flag;
        modalRef.componentInstance.isCustomer = this.customer_flag;
        modalRef.componentInstance.updateLocations.subscribe((data) => {
          this.updateLocations.emit();
        });

        modalRef.componentInstance.serviceDetailsChanged.subscribe((data) => {
          this.serviceDetailsChanged.emit(data);
        });

        modalRef.result.then(
          (result) => {
            if (result === 'referClient') {
              this.referClient(provider_location);
            }
            if (result === 'unselect_provider') {
              this.selectServiceProvider(provider_location);
            } else if (result === 'Payment Details Pending') {
              const body = { navigate_to_service_details_page: true };
              const navigationExtras: NavigationExtras = {
                state: { data: body },
              };
              this.router
                .navigateByUrl('/', {
                  skipLocationChange: true,
                })
                .then(() => {
                  this.router.navigate(
                    ['/customer/' + this.selectedCustomer.id + '/profile/'],
                    navigationExtras
                  );
                });
            } else {
              if (result && result.is_booking_confirmed) {
                this.openDynamicInfoModal(provider_location);
              }
            }
          },
          (reason) => {}
        );
      });
  }

  openDynamicInfoModal(provider_location) {
    let user: any;
    if (this.selectedCustomer) {
      user = this.selectedCustomer;
    } else {
      user = this.localStorageService.getUser();
    }

    const modalRef = this.modalService.open(DynamicInformationComponent, {
      size: 'lg',
    });

    if (this.userPropertyService.referral) {
      modalRef.componentInstance.is_scheduled = _.cloneDeep(
        this.userPropertyService.referral
      );
    } else {
      modalRef.componentInstance.is_booking_confirmed = _.cloneDeep(true);
    }

    modalRef.componentInstance.providerLocation =
      _.cloneDeep(provider_location);

    modalRef.componentInstance.user = _.cloneDeep(user);
    modalRef.result.then(
      (result) => {
        if (result.is_booking_confirmed || result.is_scheduled) {
          this.router.navigate([
            'customer/' +
              this.selectedCustomer.id +
              '/home-services/upcoming-services',
          ]);
        } else if (result.sign_document) {
          const href = this.router.url;
          this.router.navigateByUrl(href).then(() => {});
        } else {
        }
      },
      (reason) => {}
    );
  }

  openConfirmServiceModal(provider_location) {
    const modalRef = this.modalService.open(
      ConfirmServiceDetailsModalComponent,
      {
        size: 'lg',
      }
    );

    modalRef.componentInstance.provider_location =
      _.cloneDeep(provider_location);
    modalRef.result.then(
      (result) => {
        result.user_property_service_id = this.data.user_property_service_id;
        this.notes_details = result;

        const body = {
          provider_location_id: provider_location.id,
          is_chosen: true,
          notes: this.notes_details,
        };

        this.dataService
          .selectServiceProvider(this.data.user_property_service_id, body)
          .subscribe((data: any) => {
            provider_location.user_property_service_provider.status =
              'selected';

            swal({
              text: 'Great Choice',
              type: 'success',
            });
            this.updateLocations.emit();
            this.router.navigate([
              'customer/' +
                this.selectedCustomer.id +
                '/home-services/upcoming-services',
            ]);
          });
      },
      (reason) => {}
    );
  }

  checkCustomer() {
    const user = this.localStorageService.getUser();

    if (
      (user.roles.includes('customer_user') ||
        user.roles.includes('customer_user_additional')) &&
      this.selectedCustomer.id === user.id
    ) {
      this.customer_flag = true;
    } else {
      this.customer_flag = false;
    }
  }

  trackProviderUnselected(provider_location) {
    const user_property_service = this.userPropertyService;
    const trackData: any = {
      user_id: this.selectedCustomer.primary_user_id,
      user_property_service_id: user_property_service.id,
      service_type: user_property_service.service_name,
      details_complete: user_property_service.details_complete,
      address:
        provider_location.user_property_service_provider.user_property.property
          .formatted_address,
      timestamp: moment().format(),
    };

    if (provider_location) {
      const providerObj = provider_location.provider;
      trackData.provider = provider_location.company_service_alias_name
        ? provider_location.company_service_alias_name
        : providerObj.name;
      trackData.provider_user_name = providerObj.user.first_name;
      trackData.provider_email = providerObj.user.email;
      const service_label = this.data.service.service_label;

      const user_property_service_provider =
        provider_location.user_property_service_provider;
      if (user_property_service_provider) {
        trackData.user_property_service_provider_id =
          user_property_service_provider.id;
        if (!this.movingServicesArr.includes(service_label)) {
          trackData.price = user_property_service_provider.display_rate;
        } else {
          trackData.estimate = user_property_service_provider.display_rate;
        }
        trackData.so_number = user_property_service_provider.so_number;
      }

      if (provider_location.deals.length > 0) {
        const deal = provider_location.deals[0];
        trackData.deal_name = deal.title;
        if (deal.deal_rate_exceptions.length > 0) {
          trackData.deal_price = user_property_service_provider.display_rate;
        }

        trackData.deal_savings = deal.savings;
        trackData.deal_url = deal.url;
      }
    }

    if (
      user_property_service.recurring &&
      user_property_service.recurrence_details !== null
    ) {
      trackData.start_date =
        user_property_service.recurrence_details.start_date;
    } else if (
      !user_property_service.recurring &&
      user_property_service.service_dates.length > 0
    ) {
      trackData.start_date = user_property_service.service_dates[0];
    }

    // Only consider start date as service date
    trackData.service_date = trackData.start_date;

    if (
      user_property_service.service_details &&
      user_property_service.service_details.length > 0
    ) {
      for (const i of user_property_service.service_details) {
        if (
          i.service_detail_data_type === 'Time' &&
          i.detail !== null &&
          i.detail !== ''
        ) {
          trackData.service_time = i.detail;
          break;
        }
      }
    }

    window.analytics.track('Provider Unselected', trackData);
  }

  selectServiceProvider(provider_location) {
    const masterObject = this.data;
    const user_property_service_id = masterObject.user_property_service_id;

    const body = {
      provider_location_id: provider_location.id,
      is_chosen: true,
      notes: this.notes_details,
    };

    // If status is not selected or accepted, select
    // Else unselect

    if (
      !['selected', 'accepted'].includes(
        provider_location.user_property_service_provider.status
      )
    ) {
      this.openConfirmServiceModal(provider_location);
    } else {
      const body = {
        provider_location_id: provider_location.id,
        is_chosen: false,
        notes: this.notes_details,
      };
      this.dataService
        .selectServiceProvider(user_property_service_id, body)
        .subscribe((data: any) => {
          provider_location.user_property_service_provider.status =
            'unselected';
          this.trackProviderUnselected(provider_location);
          this.updateLocations.emit();
        });
    }
  }

  getSelectedProviderLocationId(userPropertyService) {
    let locationId = -1;
    if (
      userPropertyService &&
      userPropertyService.matching_provider_locations
    ) {
      const all_service_providers =
        userPropertyService.matching_provider_locations;
      const arr = ['selected', 'accepted', 'completed'];

      for (const obj of all_service_providers) {
        if (arr.includes(obj.user_property_service_provider.status)) {
          locationId = obj.user_property_service_provider.provider_location_id;
          break;
        }
      }
    }

    return locationId;
  }

  checkProviderAvailability(provider_location) {
    const masterObject = this.data;
    const user_property_service_id = masterObject.user_property_service_id;
    const matching_provider_location_id = provider_location.id;
    this.dataService
      .checkProviderAvailability(
        user_property_service_id,
        matching_provider_location_id
      )
      .subscribe((data: any) => {
        swal({
          type: 'info',
          text: 'Checking Availability',
        });
        this.updateLocations.emit();
      });
  }

  referClient(provider_location) {
    const masterObject = this.data;

    const user_property_service_id = masterObject.user_property_service_id;
    const matching_provider_location_id = provider_location.id;
    this.dataService
      .referClient(user_property_service_id, matching_provider_location_id)
      .subscribe((data: any) => {
        swal({
          type: 'info',
          text: 'Client referred',
        });
        this.updateLocations.emit();
      });
  }

  should_show_action_button(
    user_property_service,
    selected_location_id,
    current_location
  ) {
    let show_action_button = false;

    if (selected_location_id === -1) {
      // If no other providers selected

      if (
        !this.movingServicesArr.includes(
          user_property_service.service.service_label
        )
      ) {
        // For other services, if no other provider selected, user should be able to select
        show_action_button = true;
      } else {
        // For moving, only show if provider is available or recommended
        if (
          ['recommended', 'available'].includes(
            current_location.user_property_service_provider.status
          )
        ) {
          show_action_button = true;
        }
      }
    } else {
      if (current_location.id === selected_location_id) {
        // If current provider, selected need to show 'Unselect'
        show_action_button = true;
      }
    }
    return show_action_button;
  }

  openMatchingProviderDealModal(deal) {
    this.info.deal = deal;
    const modalRef = this.modalService.open(DealEventsModalComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.info = _.cloneDeep(this.info);
    // TODO/FIXME: Delete this maybe?
    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  onOptionChanged(option) {
    this.selectedFilter = option;
    if (option.id === 1) {
      this.data.matching_provider_locations.sort((a, b) =>
        a.user_property_service_provider.rank_score >
        b.user_property_service_provider.rank_score
          ? 1
          : b.user_property_service_provider.rank_score >
            a.user_property_service_provider.rank_score
          ? -1
          : 0
      );
    }

    if (option.id === 2) {
      this.data.matching_provider_locations.sort((a, b) =>
        a.user_property_service_provider.ratings_score >
        b.user_property_service_provider.ratings_score
          ? 1
          : b.user_property_service_provider.ratings_score >
            a.user_property_service_provider.ratings_score
          ? -1
          : 0
      );
    }

    if (option.id === 3) {
      this.data.matching_provider_locations.sort((a, b) =>
        a.user_property_service_provider.display_rate >
        b.user_property_service_provider.display_rate
          ? 1
          : b.user_property_service_provider.display_rate >
            a.user_property_service_provider.display_rate
          ? -1
          : 0
      );
    }

    if (option.id === 4) {
      this.data.matching_provider_locations.sort((a, b) =>
        a.user_property_service_provider.display_rate >
        b.user_property_service_provider.display_rate
          ? -1
          : b.user_property_service_provider.display_rate >
            a.user_property_service_provider.display_rate
          ? 1
          : 0
      );
    }
  }
}
