import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { UploadService } from 'src/app/_helpers/upload.service';
import Utils from 'src/app/AppPages/Elements/utils';
import Swal, { SweetAlertOptions } from 'sweetalert2';
declare const google: any;
@Component({
  selector: 'app-appointment-modal',
  templateUrl: './appointment-modal.component.html',
  styleUrls: ['./appointment-modal.component.sass'],
})
export class AppointmentModalComponent implements OnInit {
  @Input() customerid: any;
  appointment: any = {};

  @Input() update_appointment: false;
  @Input() edit_details_flag: boolean;
  selectedCustomer: any = {};
  userProperties: any = {};
  @Input() selectedUserProperty: any = {};
  serviceList: any = [];
  selectedService: any = {};
  place_details: any = {};
  zoom: any = 12;
  isCustomer = false;
  display_buttons = false;
  Editor: any;
  config: any;
  loggedInUser: any;

  user_property: any = {};

  @Input() builder_add_flag = false;
  constructor(
    public activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private uploadService: UploadService
  ) {}

  async ngOnInit() {
    this.config = this.uploadService.config;
    this.Editor = this.uploadService.Editor;
    if (!this.appointment.description) {
      this.appointment.description = '';
    }
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();

    this.place_details.latitude = 51.044;
    this.place_details.longitude = -114.0718;
    this.userProperties = this.selectedCustomer.user_properties;
    this.loggedInUser = this.localStorageService.getUser();
    if (
      this.loggedInUser.roles.includes('customer_user') ||
      this.loggedInUser.roles.includes('customer_user_additional')
    ) {
      this.isCustomer = true;
    }

    if (
      this.loggedInUser.roles.includes('gm') ||
      this.loggedInUser.roles.includes('csr') ||
      this.loggedInUser.roles.includes('administrator') ||
      (this.isCustomer && this.appointment.owner_id === this.loggedInUser.id)
    ) {
      this.display_buttons = true;
    }

    if (this.edit_details_flag && this.appointment) {
      if (this.appointment.place_details) {
        this.place_details = this.appointment.place_details;
      }

      this.selectedService = {
        id: this.appointment.service_id,
        name: this.appointment.service_name,
      };
    }
  }

  handleAddressChange(place: any) {
    const place_details = Utils.getLocationDetails(place);

    this.zoom = 12;
    this.place_details = place_details;
    this.appointment.location = this.place_details.display_name;
  }

  markerDragEnd($event: any) {
    const geocoder = new google.maps.Geocoder();
    const lat = $event.coords.lat;
    const lng = $event.coords.lng;
    const lat_lng = { lat, lng };

    geocoder.geocode({ location: lat_lng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const place = results[0];
        if (place) {
          this.handleAddressChange(place);
        } else {
        }
      } else {
      }
    });
  }

  onCancel() {
    this.activeModal.close();
  }

  addAppointment() {
    this.appointment.place_details = this.place_details;
    this.appointment.service_id = this.selectedService.id;
    const date = this.appointment.due_date;
    this.appointment.due_date = date.year + '-' + date.month + '-' + date.day;
    if (this.appointment.location === '') {
      delete this.appointment.location;
    }
    if (this.builder_add_flag) {
    }
    const body: any = {
      user_property_id: this.selectedUserProperty.id,
      appointment: this.appointment,
    };
    if (this.selectedService === null) {
      this.appointment.service_id = -1;
    }

    this.dataService.addAppointmentforUser(body).subscribe((data: any) => {
      this.activeModal.close(true);
    });
  }

  updateAppointment() {
    const date = this.appointment.due_date;
    this.appointment.place_details = this.place_details;
    this.appointment.service_id = this.selectedService.id;
    this.appointment.due_date = date.year + '-' + date.month + '-' + date.day;
    const body = {
      user_property_id: this.selectedUserProperty.id,
      appointment: this.appointment,
    };

    this.dataService
      .UpdateAppointmentforUser(this.appointment.id, body)
      .subscribe((data: any) => {
        this.activeModal.close(true);
      });
  }

  onSave() {
    if (!this.appointment.id) {
      this.addAppointment();
    } else {
      this.updateAppointment();
    }
  }

  onEditDetails() {
    if (this.appointment.user_property_id) {
      this.selectedUserProperty.id = this.appointment.user_property_id;
      this.user_property.id = this.appointment.user_property_id;
    }

    this.edit_details_flag = true;

    if (this.appointment.place_details) {
      this.place_details = this.appointment.place_details;
      this.place_details.latitude = this.appointment.place_details.latitude;
      this.place_details.longitude = this.appointment.place_details.longitude;
    }

    if (
      this.edit_details_flag &&
      this.appointment &&
      this.appointment.due_date
    ) {
      const dateObject = moment(
        this.appointment.due_date,
        'ddd, MMM DD YYYY'
      ).toDate();
      this.appointment.due_date = new NgbDate(
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      );
    }
  }

  onDelete() {
    const options = {
      type: 'warning',
      title: 'Do you want to delete the appointment?',
      confirmButtonText: 'Yes',
      showCancelButton: true,
    } as SweetAlertOptions;

    Swal(options).then((result) => {
      if (result.value) {
        this.dataService
          .deleteAppointment(this.appointment.id)
          .subscribe((data: any) => {
            this.activeModal.close(true);
          });
      }
    });
  }

  getProperty(userProperty) {
    let phases = '';
    for (
      let status = 0;
      status < userProperty.user_property_status.length;
      status++
    ) {
      if (userProperty.user_property_status[status].status === 'Active') {
        phases = phases + userProperty.user_property_status[status].phase;
      }
      if (status !== userProperty.user_property_status.length - 1) {
        phases = phases + ' , ';
      }
    }
    return ' - ' + phases;
  }

  onPropertySelect(user_property) {
    this.selectedUserProperty = user_property;
    this.user_property = user_property;
  }
}
