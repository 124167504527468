import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {
  ServiceAreasModalComponent
} from 'src/app/AppPages/ServiceProviderPortal/profile/service-areas-modal/service-areas-modal.component';
@Component({
  selector: 'app-service-area-input',
  templateUrl: './service-area-input.component.html',
  styleUrls: ['./service-area-input.component.sass'],
})
export class ServiceAreaInputComponent implements OnInit {
  @Input() provider_deal_location;
  @Input() edit_id;

  deal_area_polygon: any = [];
  deal_locations_placedeatil: any = [];
  closeResult: string;

  deal_locations: any = [];
  @Output() change = new EventEmitter();

  place_details: any = {};
  location: any = [];
  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.place_details.latitude = 51.044;
    this.place_details.longitude = -114.0718;


    if (this.edit_id !== -1) {
      this.location = this.provider_deal_location;
      this.deal_locations = this.provider_deal_location;

      this.getService();

    } else {
      this.deal_locations = this.provider_deal_location;
      this.location = this.provider_deal_location;
    }



  }

  getService() {
    for (const i of this.deal_locations) {
      i.service_area_polygon = i.deal_area_polygon;
    }
  }

  openServiceAreasModal() {
    const modalRef = this.modalService.open(ServiceAreasModalComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.provider_locations = _.cloneDeep(
      this.deal_locations
    );

    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.deal_locations = result;


        if (result && result.length === 0) {
          this.location = [];

          this.change.emit(this.location);
        } else {
          this.getLocation(result);
        }

      },
      (reason) => {
        this.closeResult = `Dismissed ${reason}`;
      }
    );
  }

  getLocation(deal_locations) {

    for (const i of deal_locations) {
      if (!i.id) {

        const obj: any = {};
        obj.name = i.name;
        obj.address = i.address;
        this.getCordinates(i.place_details);
        obj.deal_area_polygon = this.deal_area_polygon;
        obj.place_details = i.place_details;

        this.location.push(obj);
      }
    }

    const deal_location_ids = [];
    for (let i = 0; i < this.deal_locations.length; i++) {
      if (this.deal_locations[i].id) {
        deal_location_ids[i] = this.deal_locations[i].id;
      }
    }

    const location_arra = JSON.parse(JSON.stringify(this.location));

    for (const i of location_arra) {
      if (i.id) {
        const id = deal_location_ids.includes(location_arra[i].id);

        if (id === false) {

          const index = this.location.findIndex(
            (item) => item.id === location_arra[i].id
          );

          this.location.splice(index, 1);

        }
      }
    }
    this.change.emit(this.location);
  }

  getCordinates(i) {

    const deal_place_detail = i;
    this.deal_area_polygon = [];
    this.deal_area_polygon.push({
      lat: deal_place_detail.latitude - 1,
      lng: deal_place_detail.longitude,
    });
    this.deal_area_polygon.push({
      lat: deal_place_detail.latitude,
      lng: deal_place_detail.longitude + 1,
    });
    this.deal_area_polygon.push({
      lat: deal_place_detail.latitude + 1,
      lng: deal_place_detail.longitude,
    });
    this.deal_area_polygon.push({
      lat: deal_place_detail.latitude,
      lng: deal_place_detail.longitude - 1,
    });


  }
}
