import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { DataService } from '../../../../_helpers/data.service';
import Utils from '../../../Elements/utils';
@Component({
  selector: 'app-billing-modal',
  templateUrl: './billing-modal.component.html',
  styleUrls: ['./billing-modal.component.sass'],
})
export class BillingModalComponent implements OnInit {
  @Input()
  provider_billings: any;

  @Input()
  provider_locations: any;

  @Input()
  gl_accounts: any;
  currencies = ['CAD', 'USD'];
  accountInfo: any = {};
  accounts: any = [];
  all_accounts_list: any = [];
  gl_account: any;
  show_account_flag = false;
  provider_locations_list: any = [];
  constructor(
    private dataService: DataService,
    public activeModal: NgbActiveModal,
    private localStrorageService: LocalStorageService
  ) {}

  ngOnInit() {
    const user = this.localStrorageService.getUser();
    if (user.roles.includes('gm')) {
      this.show_account_flag = true;
    }


    this.all_accounts_list = this.gl_accounts;
    this.accounts = this.gl_accounts;
    this.provider_locations_list = JSON.parse(
      JSON.stringify(this.provider_locations)
    );


    this.getBillingServiceAreas();
  }

  getBillingServiceAreas() {




    for (const bill_location of this.provider_billings) {
      for (let i = 0; i < this.provider_locations_list.length; i++) {
        if (
          this.provider_locations_list[i].id ==
          bill_location.provider_location_id
        ) {
          this.provider_locations_list.splice(i, 1);
        }
      }
    }
  }

  getSelectedAccount(gl_code) {
    for (const account of this.gl_accounts) {
      if (account.Code === gl_code) {
        const name = account.Name + ',' + account.Code;
        return name;
      }
    }
  }

  filterFunction(value) {
    this.accounts = this.all_accounts_list.filter((account) => {
      return (
        account.Name.toLowerCase().search(value.toLowerCase()) !== -1 ||
        (account.Code ? account.Code.search(value) !== -1 : false)
      );
    });
  }

  onSelectedAccount(index, account) {
    this.accountInfo = account;
    this.gl_account = account.Name + ',' + account.Code;
    this.provider_billings[index].gl_account_code = this.accountInfo.Code;

  }

  getDefaultCode(index) {
    const account = this.accounts.filter((account) => {
      return account.Code === 6600;
    });
    let accountName = '';

    if (account.length > 0) {
      this.provider_billings[index].gl_account_code = account[0].Code;
      accountName = account[0].Name + ',' + account[0].Code;
    }
    return accountName;
  }

  addNew() {
    this.provider_billings.push({
      billing_contact: {},
    });
  }

  deleteRow(id) {
    this.provider_billings.splice(id, 1);
  }

  save() {
    this.activeModal.close(this.provider_billings);
  }

  saveRow(index) {
    // Prepare the object for server
    this.provider_billings[index].billing_contact.provider_location_id =
      this.provider_billings[index].provider_location_id;
    this.provider_billings[index].billing_contact.currency =
      this.provider_billings[index].currency;
    this.provider_billings[index].billing_contact.gl_account_code =
      this.provider_billings[index].gl_account_code;
    this.provider_billings[index].billing_contact.tax_number =
      this.provider_billings[index].tax_number;
    this.updateBillingInfo(this.provider_billings[index].billing_contact);
  }

  getProviderLocation(provider_location_id) {
    const location = this.provider_locations.filter((element) => {
      return element.id === provider_location_id;
    });
    return location[0].address;
  }

  getBillingContact(provider_location_id) {

    const provider_billing = this.provider_billings.filter((element) => {
      return element.provider_location_id === provider_location_id;
    });
    return provider_billing[0].billing_contact;
  }

  onLocationChanged(index, location) {

    this.provider_billings[index].provider_location_id = location.id;
    this.getBillingServiceAreas();
  }

  onCurrencyChanged(index, currency) {
    this.provider_billings[index].currency = currency;
  }
  onCopyLocationChanged(index, location) {
    this.provider_billings[index].copy_provider_location_id = location.id;
    this.provider_billings[index].billing_contact = this.getBillingContact(
      location.id
    );
    // Xero contact ID should not be copied
    this.provider_billings[index].billing_contact.xero_contact_id = null;


  }

  handleAddressChange(place, index) {


    const place_details = Utils.getLocationDetails(place);
    this.provider_billings[index].billing_contact.destination_place_details =
      place_details;
    this.provider_billings[index].billing_contact.destination_location_id =
      place_details.location_id;

    this.provider_billings[index].billing_contact.address =
      place_details.display_name;
    this.provider_billings[index].billing_contact.city = place_details.city;
    this.provider_billings[index].billing_contact.state =
      place_details.administrative_area_level_1;
    this.provider_billings[index].billing_contact.postal_code =
      place_details.postal_code;
  }

  updateBillingInfo(billing_info) {
    const body = {
      billing_info,
    };

    this.dataService.updateBillingInfo(body).subscribe((data: any) => {

    });
  }
}
