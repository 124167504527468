import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { DataService } from 'src/app/_helpers/data.service';
import { FileUploadService } from 'src/app/_helpers/file-upload.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { ThemeOptions } from 'src/app/theme-options';
import swal from 'sweetalert2';
import { MultiUseVariables } from '../../../multi-use-components/multi-use-variables';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss'],
})
export class UploadFileModalComponent implements OnInit {
  @Input() upload_type = 'USER_DOCUMENT';
  uploadFile: any = [];
  selectedCustomer: any;
  userDocument: any = {};
  selectedUserProperty: any = {};
  userProperties: any = [];
  categories: any = [];
  selectedCategory: any = {};
  isCustomer: boolean;
  public files: NgxFileDropEntry[] = [];
  dragDropUploadFile: File;
  vars = MultiUseVariables;
  allowedExtensions = this.vars.allowedExtensions;

  @Output() updateFiles: EventEmitter<any> = new EventEmitter();
  constructor(
    public activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private fileUploadService: FileUploadService,
    private dateService: DataService,
    public globals: ThemeOptions,
    private router: Router
  ) {}

  async ngOnInit() {
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();
    this.userProperties = this.selectedCustomer.user_properties;
    this.getDocumentCategories();
    const loggedInUser = this.localStorageService.getUser();
    if (
      loggedInUser.roles.includes('customer_user') ||
      loggedInUser.roles.includes('customer_user_additional')
    ) {
      this.isCustomer = true;
    }
  }

  fileChangeEvent(event: any): void {
    const allowedExtensionsArray = this.vars.allowedExtensionsArray;
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
        if (allowedExtensionsArray.indexOf(fileExtension) === -1) {
          // Invalid file type, do not process or upload

          const text =
            'Invalid file type. Please select a PDF, DOCX, or image file.';
          swal({
            type: 'warning',
            text,
          });
          event.target.value = ''; // Clear the file input
          const fileInput = document.getElementById(
            'validatedCustomFile'
          ) as HTMLInputElement;
          fileInput.value = ''; // Reset the input value
          return;
        } else {
          this.uploadFile.push(event.target.files[i]);
        }
      }
    }

    if (this.dragDropUploadFile && this.dragDropUploadFile.name) {
      this.dragDropUploadFile = null;
      this.files = null;
    }
  }

  getDocumentCategories() {
    this.dateService.getDocumentCategories().subscribe((data: any) => {
      this.categories = data;
    });
  }

  onSave() {
    if (this.selectedUserProperty.id) {
      this.userDocument.user_property_id = this.selectedUserProperty.id;
    }

    if (this.selectedCategory.value || this.selectedCategory.value === 0) {
      this.userDocument.category = this.selectedCategory.value;
    }

    const upload_type = 'USER_DOCUMENT';
    for (let i = 0; i < this.uploadFile.length; i++) {
      const data: any = {
        filename: this.uploadFile[i].name,
        upload_type,
        file_size: this.uploadFile[i].size,
        user_document: this.userDocument,
      };

      if (this.selectedUserProperty.client_id) {
        data.object_id = this.selectedUserProperty.client_id;
      } else if (this.selectedUserProperty.user_id) {
        data.object_id = this.selectedUserProperty.user_id;
      }

      this.fileUploadService
        .uploadUserDocument(this.uploadFile[i], data)
        .subscribe((value) => {
          this.updateFiles.emit(true);
        });

      window.analytics.track('Update File Cabinet', {
        user_id: data.object_id,
        timestamp: moment().format(),
        file_name: this.userDocument.title,
      });
    }
    if (this.isCustomer) {
      if (this.upload_type == 'USER_RECEIPT') {
        const receipt_url =
          this.globals.baseUrl +
          'customer/' +
          this.selectedCustomer.id +
          '/customer-receipts';
        window.analytics.track('Receipt Uploaded', {
          user_id: this.selectedCustomer.id,
          receipt_name: this.userDocument.title,
          receipt_amount: this.userDocument.amount,
          receipt_description: this.userDocument.description,
          receipt_category: this.userDocument.category,
          receipt_url,
          receipt_deep_link: receipt_url,
          timestamp: moment().format(),
        });
      }
      this.router.navigate([
        '/customer/' +
          this.selectedCustomer.id +
          '/file-cabinet/' +
          this.selectedCategory.value,
      ]);
    }
    this.onCancel();
  }

  onCancel() {
    this.activeModal.close(true);
  }

  removeFile(index) {
    this.uploadFile.splice(index, 1);
    if (this.uploadFile.length === 0) {
      this.uploadFile = [];
      const fileInput = document.getElementById(
        'validatedCustomFile'
      ) as HTMLInputElement;
      fileInput.value = ''; // Reset the input value
    }
  }

  getProperty(userProperty) {
    let phases = '';
    let status = 0;
    for (
      status = 0;
      status < userProperty.user_property_status.length;
      status++
    ) {
      if (userProperty.user_property_status[status].status == 'Active') {
        phases = phases + userProperty.user_property_status[status].phase;
      }
      if (status != userProperty.user_property_status.length - 1) {
        phases = phases + ' , ';
      }
    }
    return ' - ' + phases;
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.dragDropUploadFile = file;
          this.uploadFile.push(file);
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public fileOver(event) {}

  public fileLeave(event) {}
}
