import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styles: [
    `
      .bg-light {
        background-color: var(--white) !important;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }

      .custom-day.focused {
        background-color: var(--l-grey);
      }

      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: var(--white);
      }

      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
})
export class DateRangePickerComponent implements OnInit {
  @Input()
  selectedDate: Date;

  @Input()
  selectedFromDate: Date;

  @Input()
  selectedToDate: Date;

  @Input()
  selectedEndRepeatDate: Date;

  @Input()
  range: boolean;

  @Input()
  repeatTypes: any;

  @Input()
  providerService: any;

  @Input()
  providerServices: any;

  @Input()
  providerLocations: any = [];

  fromDate: NgbDate;

  toDate: NgbDate;
  endRepeatDate: NgbDate;

  dateRange: {};

  model: NgbDateStruct;

  @Input() show_rates = false;
  @Input() isProvider = false;

  showCurrencyError = false;
  step1 = true;
  step2 = false;
  step3 = false;
  date: { year: number; month: number };
  public isCollapsed = false;
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  delete_flag = false;
  hoveredDate: NgbDate;

  disableFlag = false;
  constructor(
    public activeModal: NgbActiveModal,
    calendar: NgbCalendar,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.providerService.exception_date_range_id) {
      this.delete_flag = true;
    }
    if (this.selectedDate) {
      this.model = {
        day: this.selectedDate.getUTCDate(),
        month: this.selectedDate.getUTCMonth() + 1,
        year: this.selectedDate.getUTCFullYear(),
      };
    }
    if (this.selectedFromDate !== null) {
      this.fromDate = new NgbDate(
        this.selectedFromDate.getUTCFullYear(),
        this.selectedFromDate.getUTCMonth() + 1,
        this.selectedFromDate.getUTCDate()
      );
    }

    if (this.selectedToDate !== null) {
      this.toDate = new NgbDate(
        this.selectedToDate.getUTCFullYear(),
        this.selectedToDate.getUTCMonth() + 1,
        this.selectedToDate.getUTCDate()
      );
    } else {
      this.toDate = null;
    }

    if (this.selectedEndRepeatDate !== null) {
      this.endRepeatDate = new NgbDate(
        this.selectedEndRepeatDate.getUTCFullYear(),
        this.selectedEndRepeatDate.getUTCMonth() + 1,
        this.selectedEndRepeatDate.getUTCDate()
      );
    } else {
      this.endRepeatDate = null;
    }

    this.step1 = true;

    if (this.providerLocations) {
      for (const i of this.providerLocations) {
        if (
          this.providerService &&
          this.providerService.enabled_locations &&
          this.providerService.enabled_locations.includes(i.id)
        ) {
          i.enabled = true;
        } else {
          i.enabled = false;
        }
      }
    }

    if (this.providerService !== null) {
      // Set value of enum instead of string
      if (this.providerService && this.providerService.exception_date_range) {
        this.providerService.exception_date_range.repeat_type =
          this.repeatTypes[
            this.providerService.exception_date_range.repeat_type
          ];
      }
    }

    if (this.isProvider) {
      this.hasBoxService();
    }
  }

  firstNext() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;

    this.getRate(this.providerService.provider_service_id);
  }
  next() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
  }

  backToFirst() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
  }

  back() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
  }

  connector(date) {
    if (date) {
      return '-';
    } else {
      return '';
    }
  }

  getDateString(date, format) {
    if (date !== null) {
      return this.datePipe.transform(this.getDisplayObj(date), format);
    } else {
      return '';
    }
  }

  getDisplayObj(date) {
    if (date !== null) {
      return new Date(date.year, date.month - 1, date.day);
    } else {
      return '';
    }
  }

  save() {
    if (this.range) {
      const enabledLocations = [];
      if (this.providerLocations) {
        for (const i of this.providerLocations) {
          if (i.enabled === true) {
            enabledLocations.push(i.id);
          }
        }
      }
      this.dateRange = {
        enabledLocations,
        selectedFromDate: this.getDisplayObj(this.fromDate),
        selectedToDate: this.getDisplayObj(this.toDate),
        endRepeatDate: this.getDisplayObj(this.endRepeatDate),
        providerService: this.providerService,
      };
      this.activeModal.close(this.dateRange);
    } else {
      this.activeModal.close(this.getDisplayObj(this.model));
    }
  }

  getRate(provider_service_id) {
    for (const location of this.providerLocations) {
      if (location.enabled === true) {
        if (
          location.exception_rates.length === 0 ||
          !this.providerService.exception_rate
        ) {
          for (const rate of location.service_rates) {
            if (rate.provider_service_id === provider_service_id) {
              this.providerService.exception_rate = rate.standard_rate;
              this.providerService.minimum_rate = rate.minimum_rate;

              this.getAdditionalServices(
                rate.selected_additional_rates,
                rate.selected_additional_services
              );
            }
          }
        }
      }
    }
  }

  getAdditionalServices(
    selected_additional_rates,
    selected_additional_services
  ) {
    for (const additional_service_rate of this.providerService
      .selected_additional_services) {
      for (const service of selected_additional_services) {
        if (
          additional_service_rate.provider_service_additional_service_id ===
          service.provider_service_additional_service_id
        ) {
          additional_service_rate.exception_rate = service.standard_rate;
        }
      }
    }

    for (const service_rate of this.providerService.selected_additional_rates) {
      for (const rate of selected_additional_rates) {
        if (
          service_rate.service_additional_rate_id ===
          rate.service_additional_rate_id
        ) {
          service_rate.exception_rate = rate.standard_rate;
        }
      }
    }
  }

  getService(provider_service_id) {
    if (this.providerServices) {
      const service = this.providerServices.filter((element) => {
        return element.provider_service_id === provider_service_id;
      });
      return service[0].phase_name + ' - ' + service[0].service_name;
    } else {
      return (
        this.providerService.service.phase_name +
        ' - ' +
        this.providerService.service.name
      );
    }
  }

  onServiceChanged(index) {
    const selectedService = this.providerServices[index];

    const selected_additional_services = [];
    for (const j of selectedService.selected_additional_services) {
      const additional_service = j;
      selected_additional_services.push({
        provider_service_additional_service_id:
          additional_service.provider_service_additional_service_id,
        name: additional_service.name,
        rate_type: additional_service.rate_type,
        exception_rate: additional_service.standard_rate,
      });
    }

    const selected_additional_rates = [];
    for (const j of selectedService.selected_additional_rates) {
      const additional_service = j;
      selected_additional_rates.push({
        service_additional_rate_option_id:
          additional_service.service_additional_rate_option_id,
        service_additional_rate_id:
          additional_service.service_additional_rate_id,
        object_type: additional_service.object_type,
        name: additional_service.name,
        sort: additional_service.sort,
        rate_type: additional_service.rate_type,
        exception_rate: additional_service.standard_rate,
      });
    }

    selected_additional_rates.sort((a, b) => a.sort - b.sort);

    const baseObject = {
      provider_service_id: selectedService.provider_service_id,
      selected_additional_services,
      selected_additional_rates,
      exception_rate: selectedService.standard_rate,
      rate_type: selectedService.service_rate_type,
      exception_date_range: {},
    };

    this.providerService = baseObject;

    this.getRate(selectedService.provider_service_id);
  }

  toggle($event, index) {
    this.providerLocations[index].enabled =
      !this.providerLocations[index].enabled;
  }

  checkCurrency(currency) {
    const enabledCurrencies = this.getEnabledAreCurrencies();
    if (
      enabledCurrencies.length === 0 ||
      enabledCurrencies.includes(currency)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getEnabledAreCurrencies() {
    const arr = [];
    for (const area of this.providerLocations) {
      if (area.enabled) {
        arr.push(area.currency);
      }
    }
    return arr;
  }
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isEmpty() {
    if (
      this.providerService.selected_additional_rates.length > 0 ||
      this.providerService.selected_additional_services.length > 0
    ) {
      let disabled_flag = false;
      for (const rate of this.providerService.selected_additional_rates) {
        disabled_flag = rate.exception_rate !== null ? true : false;
        if (!disabled_flag) {
          return !disabled_flag;
        }
      }
      for (const service of this.providerService.selected_additional_services) {
        disabled_flag = service.exception_rate !== null ? true : false;
        if (!disabled_flag) {
          return !disabled_flag;
        }
      }
      return !disabled_flag;
    } else {
      return false;
    }
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  disableDate() {
    if (!this.show_rates) {
      return false;
    }
  }

  onDeleteException() {
    const options = {
      type: 'warning',
      title: 'Delete this exception rates?',
      confirmButtonText: 'Delete',
      showCancelButton: true,
    } as SweetAlertOptions;
    Swal(options).then((result) => {
      if (result.value) {
        this.activeModal.close({
          exception_date_range_id: this.providerService.exception_date_range_id,
        });
      }
    });
  }

  hasBoxService() {
    if (
      this.providerService !== null &&
      this.providerService.provider_service_id &&
      this.providerService.service &&
      this.providerService.service.label
    ) {
      if (this.providerService.service.label === 'virtuo_eco_friendly_boxes') {
        this.disableFlag = true;
      }
    }
  }
}
