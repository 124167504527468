import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbDate,
  NgbInputDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
import { EventService } from 'src/services/event.service';
@Component({
  selector: 'app-new-task-modal',
  templateUrl: './new-task-modal.component.html',
  styleUrls: ['./new-task-modal.component.scss'],
})
export class NewTaskModal implements OnInit {
  @Input() userId: any;

  userAddresses = [];
  filterAddresses = [];
  title: any;
  description: any;
  selectedCustomer: any = {};
  user_property: any;
  selectedAddress: any;
  isSelectedAddress = false;
  dueDate: NgbDate | Date;
  selectedPropertyId: any;
  formattedDate: any;

  selectedCategory: any;
  isSelectedCategory = false;
  Categories = ['To Do', 'DIY', 'Wishlist'];

  missingInput = false;
  missingInputs: any = {
    title: false,
    description: false,
    address: false,
    category: false,
    date: false,
  };

  @ViewChild('date') dp: NgbInputDatepicker;
  today: any;

  constructor(
    public router: Router,
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private eventService: EventService
  ) {}

  ngOnInit() {
    if (this.userId) {
      this.getUserAddresses(this.userId);
    }
    const date = new Date();
    this.today = {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // JavaScript's months are 0-based
      day: date.getDate(),
    };
  }

  onCloseClick() {
    this.activeModal.dismiss();
    this.eventService.emitEvent('menuClicked');
  }

  selectAddress(address) {
    this.isSelectedAddress = true;
    this.selectedAddress = address.property.formatted_address;
    this.selectedPropertyId = address.id;
  }

  selectCategory(category) {
    this.isSelectedCategory = true;
    this.selectedCategory = category;
  }

  getUserAddresses(user_id) {
    this.dataService.getUserDetails(user_id).subscribe((data: any) => {
      this.userAddresses = data.user_properties;
      this.filterAddresses = this.userAddresses.filter(
        (item) => item.archived === false && item.is_approved === true
      );
    });
  }

  onDateSelect(event: any) {
    this.dueDate = event;

    if (this.dueDate) {
      this.closeDatePicker();
    }
  }

  openDatePicker() {
    this.dp.open();
  }

  closeDatePicker() {
    setTimeout(() => {
      this.dp.close();
    }, 0);
  }

  onSaveClick() {
    if (
      this.title &&
      this.description &&
      this.selectedPropertyId &&
      this.selectedCategory &&
      this.dueDate &&
      this.title.trim() !== '' &&
      this.description.trim() !== ''
    ) {
      let date;
      this.missingInput = false;
      if (this.dueDate instanceof NgbDate) {
        const ngbDate: NgbDate = this.dueDate;
        this.dueDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
        const year = this.dueDate.getFullYear();
        const month = (this.dueDate.getMonth() + 1).toString().padStart(2, '0');
        const day = this.dueDate.getDate().toString().padStart(2, '0');
        date = `${year}/${month}/${day}`;
      }
      const body = {
        task: {
          title: this.title,
          description: this.description,
          user_property_id: this.selectedPropertyId,
          category_name: this.selectedCategory,
          due_date: date,
        },
      };

      this.dataService.createNewTask(body).subscribe((data: any) => {
        this.activeModal.close('taskAdded');
      });
    } else {
      this.missingInput = true;
      this.missingInputs = {
        title: this.title && this.title.trim() !== '' ? false : true,
        description:
          this.description && this.description.trim() !== '' ? false : true,
        address: this.selectedPropertyId ? false : true,
        category: this.selectedCategory ? false : true,
        date: this.dueDate ? false : true,
      };
    }
  }
}
