import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import Utils from 'src/app/AppPages/Elements/utils';
import { EnterTokenModalComponent } from 'src/app/AppPages/login/enter-token-modal/enter-token-modal.component';
import { ForgotPasswordModalComponent } from 'src/app/AppPages/login/forgot-password-modal/forgot-password-modal.component';
import { ThemeOptions } from 'src/app/theme-options';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-test-login',
  templateUrl: './test-login.component.html',
  styleUrls: ['./test-login.component.scss'],
})
export class TestLoginComponent implements OnInit {
  body = {
    email: '',
    password: '',
  };
  data: any;
  ipAddress: any;
  constructor(
    private dataService: DataService,
    private modalService: NgbModal,
    private router: Router,
    private localStorageService: LocalStorageService,
    public toasterService: ToastrService,
    public globals: ThemeOptions
  ) {}

  ngOnInit() {}

  forgotPasswordModal() {
    const modalRef = this.modalService.open(ForgotPasswordModalComponent, {
      size: 'sm',
    });
  }

  enterTokenModal() {
    const modalRef = this.modalService.open(EnterTokenModalComponent, {
      size: 'sm',
    });
    modalRef.result.then(
      (result) => {
        const body = {
          email: this.data.email,
          authy_token: result.authy_token,
        };

        this.dataService.verifyAuthyToken(body).subscribe((data) => {
          this.LoginToDashboard();
        });
      },
      (reason) => {}
    );
  }

  login() {
    this.dataService.login(this.body).subscribe((data: any) => {
      this.data = data;

      this.LoginToDashboard();
    });
  }

  async LoginToDashboard() {
    if (
      (this.data.roles.includes('customer_user') ||
        this.data.roles.includes('customer_user_additional')) &&
      (this.data.roles.includes('builder') ||
        this.data.roles.includes('additional_builder'))
    ) {
      const options = {
        type: 'info',
        title: 'Welcome ' + this.data.first_name + '!',
        text: 'Where would you like to go today?',
        confirmButtonText:
          this.data.builder_accounts[0].builder_name + ' Portal',
        cancelButtonText: 'My Personal App',
        showCancelButton: true,
      } as SweetAlertOptions;

      Swal(options).then((result) => {
        if (result.value) {
          this.data.current_builder = this.data.builder_accounts[0];
          this.globals.isBuilderActive = true;
          this.globals.isCustomerActive = false;
          this.router.navigate(['/clients']);
        } else {
          if (!this.localStorageService.hasSetZendeskCookies()) {
            this.localStorageService.setZendeskCookie(true);
            this.globals.isCustomerActive = true;
            this.globals.isBuilderActive = false;
          }
          Utils.identify(this.data, '');
          this.router.navigate(['/customer/' + this.data.id + '/dashboard/']);
        }
        this.localStorageService.storeUser(this.data);
      });
    } else {
      if (
        this.data.roles.includes('customer_user') ||
        this.data.roles.includes('customer_user_additional')
      ) {
        if (!this.localStorageService.hasSetZendeskCookies()) {
          this.localStorageService.setZendeskCookie(true);
        }
        Utils.identify(this.data, '');
        this.router.navigate(['/customer/' + this.data.id + '/dashboard/']);
      } else {
        this.localStorageService.removeZendeskCookie();
        if (
          this.data.roles.includes('service_provider') ||
          this.data.roles.includes('additional_service_provider')
        ) {
          this.data.current_provider = this.data.providers[0];
          this.router.navigate(['/jobs']);
        } else if (
          this.data.roles.includes('builder') ||
          this.data.roles.includes('additional_builder')
        ) {
          this.data.current_builder = this.data.builder_accounts[0];
          this.router.navigate(['/clients']);
        } else if (
          this.data.roles.includes('gm') ||
          this.data.roles.includes('csr') ||
          this.data.roles.includes('administrator')
        ) {
          await Utils.identifyStaffLogin(this.data);
          this.router.navigate(['/dashboard']);
          this.localStorageService.storeLastActiveTab('MC');
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
      this.localStorageService.storeUser(this.data);
    }
  }
}
