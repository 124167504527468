import { Injectable } from '@angular/core';

@Injectable()
export class ThemeOptions {
  sidebarHover = false;
  toggleSidebar = false;
  toggleSidebarMobile = false;
  toggleHeaderMobile = false;
  toggleThemeOptions = false;
  toggleDrawer = false;
  toggleFixedFooter = false;
  isGMActive = false;
  isAdminActive = false;
  _opened = false;
  isCustomerActive = false;
  isBuilderActive = false;
  jobsListDropdownValueSPN = '';
  // baseUrl = 'https://virtuo-backend-new.nuywpfvhxf.us-west-2.elasticbeanstalk.com/';
  // Demo URL
  baseUrl = 'https://virtuo-demo.firebaseapp.com/';
  // Staging URL
  // baseUrl = 'https://app-test.virtuo.com/';
  // Local URL
  // baseUrl = 'http://localhost:4200/';
  // Production URL
  // baseUrl = 'https://portal.virtuo.com/';
  // helloSignApi = '3dd87a4acdd7f234817e1b8d28e5f36e'; //portal
  helloSignApi = '0c04bbc45b16cc515f1e45042b393b3a'; // demo
  isSidebarToggleFlag = false;
  isNotFoundPage = false;
  taskPageBreadcrumb = '';
  previousTaskId: any;
  isFirstLogin = true;

  constructor() {
    this.jobsListDropdownValueSPN =
      localStorage.getItem('jobsListDropdownValueSPN') || '';
  }
}
