import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {
  private currentURL = new BehaviorSubject<string>('');
  currentURL$ = this.currentURL.asObservable();

  setCurrentURL(url: string) {
    this.currentURL.next(url);
  }
}
