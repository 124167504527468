import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/_helpers/data.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import swal from 'sweetalert2';

@Component({
  selector: 'app-reset-forgot-password',
  templateUrl: './reset-forgot-password.component.html',
  styleUrls: ['./reset-forgot-password.component.sass'],
})
export class ResetForgotPasswordComponent implements OnInit {
  body: any = {
    new_password: '',
    new_password_confirmation: '',
  };
  validation_error = '';
  showMessage = false;
  message = '';
  formGroup: FormGroup;
  constructor(private dataService: DataService, private route: ActivatedRoute, private formBuilder: FormBuilder, private router: Router) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group(
      {
        new_password: ['', [Validators.required, Validators.minLength(8)]],
        new_password_confirmation: ['', [Validators.required, Validators.minLength(8)]],
      },
      {
        validator: MustMatch('new_password', 'new_password_confirmation'),
      }
    );

    this.route.paramMap.subscribe((params) => {
      const email = atob(params.get('email'));
      const token = params.get('token');

      this.body.forgot_password_token = token;
      this.body.email = email;
      this.checkForgotPasswordToken(token, email);
    });
  }

  checkForgotPasswordToken(token, email) {
    this.dataService.checkForgotPasswordToken(token, email).subscribe(
      (data: any) => {
        this.showMessage = false;
      },
      (err) => {
        this.showMessage = true;
        this.message = 'Invalid URL';
      }
    );
  }

  save() {
    this.dataService.resetPassword(this.body).subscribe(
      (data: any) => {
        this.validation_error = '';

        this.showMessage = true;
        this.message = data.message;
        swal({
          text: data.message,
        });
        this.router.navigate(['login']);
      },
      (err) => {
        this.validation_error = err.error.error;
      }
    );
  }
}
