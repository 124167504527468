import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  constructor(
    private dataService: DataService,
    private httpClient: HttpClient
  ) {}

  public uploadImage(filename, body, upload_type, object_id) {
    const params = new HttpParams()
      .set('filename', filename)
      .set('upload_type', upload_type)
      .set('object_id', object_id);
    this.dataService.getPresignedUrl(params).subscribe((data: any) => {
      const presignedUrl = data.presigned_url;
      const headers = new HttpHeaders().set('Content-Type', 'image/jpeg');

      return this.httpClient
        .put(presignedUrl, body, { headers })
        .subscribe((data: any) => {});
    });
  }
}
