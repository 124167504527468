import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { DataService } from 'src/app/_helpers/data.service';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import Utils from 'src/app/AppPages/Elements/utils';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
@Component({
  selector: 'app-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.scss'],
})
export class ResetUserPasswordComponent implements OnInit {
  body: any = {
    new_password: '',
    new_password_confirmation: '',
    possession_date: '',
    target_possession_date: '',
    preferred_languages: '',
  };
  user: any = {};
  is_builder = false;
  innerWidth: any;
  avatarUrl = '';
  text_phone = '';
  validation_error = '';
  showMessage = false;
  message = '';
  privacyAccepted = false;
  subscribed = false;
  formGroup: FormGroup;
  builder_name: any;
  success = false;
  loading = false;
  builder: any = {};
  model: any = {};
  buyerTypes: any = [
    { value: 'move-down', label: 'Downsizing' },
    { value: 'investment', label: 'Investing' },
    { value: 'move-up', label: 'Buying another home' },
  ];
  buyer_type: any = '';
  maxDate: any = { year: 2023, month: 1, day: 31 };

  minPossessionDate: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  dropdownSettings: IDropdownSettings = {};
  isFirstBuyer = true;
  isFirmPossession = true;
  languages: any = [];
  selectedPreferredLanguages: any = '';
  possessionDate: any = {};
  targetPossessionDate: any = '';
  buyerType: any = '';
  hasFirmPossession = false;
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.onResize();
    const current = new Date();
    const max = new Date();
    max.setDate(current.getDate() + 60);

    this.maxDate = {
      year: max.getFullYear(),
      month: max.getMonth() + 1,
      day: max.getDate(),
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.is_builder = false;
    this.formGroup = this.formBuilder.group(
      {
        new_password: ['', [Validators.required, Validators.minLength(8)]],
        new_password_confirmation: [
          '',
          [Validators.required, Validators.minLength(8)],
        ],
        preferred_languages: [''],
      },
      {
        validator: MustMatch('new_password', 'new_password_confirmation'),
      }
    );
    this.dropdownSettings = {
      singleSelection: true,
      defaultOpen: false,
      idField: 'code',
      textField: 'language',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      enableCheckAll: false,
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };
    this.route.paramMap.subscribe((params) => {
      const email = atob(params.get('email'));
      const token = params.get('token');

      this.body.new_user_token = token;
      this.body.email = email;
      this.checkNewUserToken(token, email);
    });
    this.getLanguages();
  }

  firmPossession(is_set) {
    this.isFirmPossession = is_set;
    if (is_set) {
      this.formGroup.get('possession_date').setValidators(Validators.required);
      this.formGroup.get('target_possession_date').clearValidators();
      const current = new Date();
      const max = new Date();
      max.setDate(current.getDate() + 60);
      this.maxDate = {
        year: max.getFullYear(),
        month: max.getMonth() + 1,
        day: max.getDate(),
      };
    } else {
      this.formGroup.get('possession_date').clearValidators();
      this.formGroup
        .get('target_possession_date')
        .setValidators(Validators.required);
      this.maxDate = {};
    }
  }

  possessionDateChanged() {
    this.body.possession_date = Utils.getDateFromNgbDate(this.possessionDate);
  }

  targetPossessionDateChanged() {
    this.body.target_possession_date = Utils.getDateFromNgbDate(
      this.targetPossessionDate
    );
  }

  formatDate(date, format) {
    if (date !== null) {
      const dateObject = moment(date, format).toDate();

      return new NgbDate(
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      );
    } else {
      return '';
    }
  }

  showURL(url) {
    window.open(url, '_blank');
  }

  getLanguages() {
    this.dataService.getLookupLanguages().subscribe((data: any) => {
      this.languages = data.languages;
    });
  }

  signInToApp() {
    this.router.navigate(['login']);
  }

  checkNewUserToken(token, email) {
    this.dataService.checkNewUserToken(token, email).subscribe(
      (data: any) => {
        this.user = data.user;
        if (this.user.builder_account) {
          this.builder = this.user.builder_account;
        }
        const roles = this.user.roles;
        if (this.user.key_dates.possession_date) {
          this.possessionDate = this.formatDate(
            this.user.key_dates.possession_date,
            'YYYY-MM-DD'
          );
        }
        if (
          roles.includes('builder') ||
          roles.includes('additional_builder') ||
          roles.includes('service_provider') ||
          roles.includes('additional_service_provider') ||
          roles.includes('sales') ||
          roles.includes('gm') ||
          roles.includes('csr')
        ) {
          this.avatarUrl = '/assets/images/logo-icon.png';
          this.is_builder = true;
          this.formGroup.get('possession_date').disable();
          this.formGroup.get('target_possession_date').disable();
          this.formGroup.get('preferred_languages').disable();
        } else if (this.user.roles.includes('customer_user')) {
          if (
            this.user.builder_account.logo !== null &&
            this.user.builder_account.logo !== ''
          ) {
            this.avatarUrl = this.user.builder_account.logo;
          } else {
            this.avatarUrl = '/assets/images/logo-icon.png';
          }

          this.builder_name = this.user.builder_account.builder_name;
        } else {
          this.avatarUrl = '/assets/images/logo-icon.png';
        }
        this.showMessage = false;
      },
      (err) => {
        this.showMessage = true;
        this.message = 'Invalid URL or URL has expired';
      }
    );
  }

  textLink() {
    const body = {
      email: this.body.email,
      phone: this.text_phone,
      new_user_token: this.body.new_user_token,
    };
    this.dataService.sendAppLink(body).subscribe(
      (data: any) => {
        this.validation_error = '';
        this.success = true;
        swal({
          text: 'Text sent!',
        });
      },
      (err) => {
        this.validation_error = err.error.error;
      }
    );
  }

  setBuyerType(value) {
    this.body.buyer_type = value;
    for (const type of this.buyerTypes) {
      if (type.value === value) {
        this.buyer_type = type.label;
      }
    }
  }

  onLanguageSelect(event) {
    this.selectedPreferredLanguages = event.code;
  }

  onLanguageDeSelect() {
    this.selectedPreferredLanguages = null;
  }

  save() {
    this.loading = true;
    if (this.isFirstBuyer === true) {
      this.body.buyer_type = 'first-time';
    }
    this.body.subscribed = this.subscribed;
    this.body.firm_possession = this.isFirmPossession;
    if (this.selectedPreferredLanguages) {
      this.body.preferred_languages = this.selectedPreferredLanguages;
    }

    this.dataService.resetPassword(this.body).subscribe(
      (data: any) => {
        if (
          this.user.roles.includes('customer_user') ||
          this.user.roles.includes('customer_user_additional')
        ) {
          this.loading = false;
          this.validation_error = '';
          this.success = true;
        } else {
          Swal({
            type: 'success',
            text: 'Password has been set successfully!',
          }).then((result) => {
            this.signInToApp();
          });
        }
      },
      (err) => {
        this.loading = false;

        this.validation_error = err.error.error;
      }
    );
  }
}
