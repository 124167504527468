import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import HelloSign from 'hellosign-embedded';
import { DataService } from 'src/app/_helpers/data.service';
import { ThemeOptions } from 'src/app/theme-options';
@Component({
  selector: 'app-provider-signature',
  templateUrl: './provider-signature.component.html',
  styleUrls: ['./provider-signature.component.sass'],
})
export class ProviderSignatureComponent implements OnInit {
  documentId: number;
  documentDetails: any = {};
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    public globals: ThemeOptions
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.documentId = +atob(params.get('id'));

      this.dataService
        .getDocumentTemplateDetails(this.documentId)
        .subscribe((data: any) => {
          this.documentDetails = data;
        });
    });
  }

  openSignModal() {
    const client = new HelloSign();

    let signature_url = '';

    this.dataService
      .getProviderSignatureUrl(this.documentId)
      .subscribe((data: any) => {
        signature_url = data.signature_url;
        client.open(data.signature_url, {
          clientId: this.globals.helloSignApi,
          testMode: false,
          debug: false,
          skipDomainVerification: true,
        });
      });

    client.on('sign', (data) => {



      this.dataService
        .setProviderSigned(this.documentId)
        .subscribe((data: any) => {
          this.documentDetails.status = data.status;
        });
    });
  }
}
