import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-enter-token-modal',
  templateUrl: './enter-token-modal.component.html',
  styleUrls: ['./enter-token-modal.component.sass'],
})
export class EnterTokenModalComponent implements OnInit {
  verify_token = { authy_token: '' };
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  logIn() {

    this.activeModal.close(this.verify_token);
  }
}
