import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { Constants } from './constants';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(
    public toasterService: ToastrService,
    private localStorageService: LocalStorageService,
    public router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const user = this.localStorageService.getUser();
    if (!req.url.includes('X-Amz-Signature') && req.url !== Constants.IP_URL) {
      if (user && user.authentication_token) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${user.authentication_token}`,
          },
          url: req.url + `?auth_token=${user.authentication_token}`,
        });
      }
    }

    return next.handle(req).pipe(
      tap(
        (event) => {},
        (error) => {
          if (error instanceof HttpErrorResponse) {

            let errorMessage = 'Something went wrong';

            if (error.status === 403) {
              errorMessage = 'Access Denied';
              this.router.navigate(['/unauthorised']);
            } else {
              if (error.error) {
                if (error.error.error_message) {
                  errorMessage = error.error.error_message;
                } else {
                  errorMessage = error.error.error;
                }
              } else {
                errorMessage = error.message;
              }
            }

            this.toasterService.error(errorMessage, 'Error', {
              closeButton: true,
            });
          }
        }
      )
    );
  }
}

export const apiProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: APIInterceptor,
  multi: true,
};
