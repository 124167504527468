import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { HomeServicePropertyModalComponent } from '../customer-home-services/home-service-property-modal/home-service-property-modal.component';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-deal-events-modal',
  templateUrl: './deal-events-modal.component.html',
  styleUrls: ['./deal-events-modal.component.sass'],
})
export class DealEventsModalComponent implements OnInit {
  @Input() info: any;

  event_flag: boolean;
  customer_flag = false;
  shouldDisplayButton = true;
  selectedCustomer: any = {};
  deal_url: any;
  loggedInUser: any = {};
  redeemed_deal = false;
  showRedeemButton = false;
  constructor(
    public activeModal: NgbActiveModal,
    private localStorageService: LocalStorageService,
    private dataService: DataService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.loggedInUser = this.localStorageService.getUser();

    if (
      this.loggedInUser.roles.includes('customer_user') ||
      this.loggedInUser.roles.includes('customer_user_additional')
    ) {
      this.customer_flag = true;
    }

    if (this.info.user_properties[0].added_to_calendar === true) {
      this.shouldDisplayButton = false;
    }

    this.setUser();
    if (this.info.deal.url) {
      this.deal_url = this.info.url;
    }

    if (this.info.deal.until_date) {
      this.event_flag = false;
    } else {
      this.event_flag = true;
      if (!this.info.deal.place_detail) {
        this.info.deal.place_detail = '';
      }
    }
    const date = this.info.deal.date;
    this.info.deal.date = moment(date).format('YYYY-MM-DD');
    const until_date = this.info.deal.until_date;
    this.info.deal.until_date = moment(until_date).format('YYYY-MM-DD');
    if (this.info.deal.deal_category === 'Home Services') {
      this.showRedeemButton = true;
    } else {
      if (this.info.user_properties && this.info.user_properties.length > 0) {
        this.redeemed_deal = this.info.user_properties[0].redeemed_deal;
        if (this.redeemed_deal) {
          this.showRedeemButton = false;
        } else {
          this.showRedeemButton = true;
        }
      }
    }
  }

  async setUser() {
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();
    if (
      this.loggedInUser.roles.includes('customer_user') ||
      this.loggedInUser.roles.includes('customer_user_additional')
    ) {
      this.dataService
        .markSeenUserPropertyDeals(this.selectedCustomer.id, this.info.deal.id)
        .subscribe((data: any) => {});
    }
  }

  onCancel(needToRefresh) {
    this.activeModal.close(needToRefresh);
  }

  showURL() {
    const url = this.info.deal.url;
    window.open(url, '_blank');
  }

  addToCalendar() {
    const body = {
      user_id: this.selectedCustomer.id,
      deal_id: this.info.deal.id,
    };

    this.dataService.addEventToCalendar(body).subscribe((data: any) => {
      Swal({
        type: 'success',
        text: 'Event has been added to your calendar!',
      });

      const event = this.info.deal;
      const trackData: any = {
        user_id: this.selectedCustomer.primary_user_id,
        event_name: event.title,
        event_description: event.details,
        event_date: event.date,
        event_location: event.place_detail.city,
        event_url: event.url,
        event_image: event.display_image,
        event_pdf: event.pdf_link,
        timestamp: moment().format(),
      };

      window.analytics.track('Event Added to Calendar', trackData);

      this.onCancel(true);
    });
  }

  getButtonText() {
    if (
      (!this.info.deal.deal_rate_exceptions ||
        this.info.deal.deal_rate_exceptions.length < 1) &&
      this.info.deal.url !== null &&
      this.info.deal.url !== ''
    ) {
      return 'More Details';
    } else {
      return 'Redeem';
    }
  }

  onSubmit() {
    const body = {
      user_id: this.selectedCustomer.id,
      deal_id: this.info.deal.id,
    };

    this.dataService.getUserDealDetails(body).subscribe((data: any) => {
      if (
        this.info.deal.url &&
        this.info.deal.pdf_link === null &&
        this.info.deal.deal_rate_exceptions.length === 0
      ) {
        const url = this.info.deal.url;
        window.open(url, '_blank');
      } else if (
        this.info.deal.deal_type === 'Deal' &&
        this.info.deal.provider !== null &&
        this.info.deal.deal_rate_exceptions.length > 0
      ) {
        if (this.info.user_properties.length >= 1) {
          this.onCancel(false);
          this.addService();
        }
      } else {
        Swal({
          type: 'success',
          text: 'Details have been emailed to your registered email address!',
        });

        this.onCancel(true);
      }
    });
  }

  addService() {
    const user_property_id = this.info.user_properties[0].user_property_id;
    const service_id = this.info.deal.deal_service.id;
    const body = {
      user_property_id,
      service_id,
      repeating: false,
    };
    this.dataService.addService(body).subscribe(
      (dataObj: any) => {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            user_property_service_id: dataObj.id,
            new_service: true,
          },
        };
        this.router.navigate(
          [
            'customer/' +
              this.selectedCustomer.id +
              '/home-services/home-service-details/',
          ],
          navigationExtras
        );
      },
      (error: any) => {}
    );
  }

  goToServiceDetails() {
    const service_id = this.info.deal.deal_service.id;
    const body = {
      user_id: this.selectedCustomer.id,
      service_id,
    };

    this.dataService
      .getProjectServicesUserProperties(this.selectedCustomer.id, service_id)
      .subscribe((data: any) => {
        if (data && data.length > 1) {
          const modalRef = this.modalService.open(
            HomeServicePropertyModalComponent,
            {
              size: 'lg',
            }
          );
          modalRef.componentInstance.serviceId = _.cloneDeep(service_id);
          modalRef.componentInstance.userPropertiesList = _.cloneDeep(data);
          modalRef.componentInstance.type = _.cloneDeep('on_demand');
        }
      });
  }

  onNotInterested() {
    const body = {
      user_id: this.selectedCustomer.id,
      deal_id: this.info.deal.id,
    };

    const options = {
      type: 'warning',
      title: 'Are you sure you are not interested?',
      confirmButtonText: 'Yes',
      showCancelButton: true,
    } as SweetAlertOptions;

    Swal(options).then((result) => {
      if (result.value) {
        this.dataService.hideUserPropertyDeal(body).subscribe((data: any) => {
          this.activeModal.close(true);
        });
      }
    });
  }
}
