import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { filterUserProperties } from '../../../../../AppPages/multi-use-components/multi-use-helpers';
@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
  public isActive: any;
  isStaff = false;
  isSearch = false;
  initial_reults = 3;
  value = '';
  recently_viewed: any = [];
  length: any;
  user_data: any = [];
  user: any;

  isSharedAccess = false;

  recently_viewed_user_subscription: any;
  search_user_subscription: any;
  @ViewChild('myDrop') myDrop: NgbDropdown;

  selectedFilter = { name: 'All', value: 'all' };
  filters = [
    { name: 'All', value: 'all' },
    { name: 'File ID', value: 'file_id' },
    { name: 'Name', value: 'name' },
    { name: 'Email', value: 'email' },
    { name: 'Phone', value: 'phone_number' },
    { name: 'Address', value: 'address' },
    { name: 'SO Number', value: 'so_number' },
  ];

  constructor(
    public dataService: DataService,
    private LocalStorageService: LocalStorageService,
    public router: Router
  ) {}

  ngOnInit() {
    this.user = this.LocalStorageService.getUser();
    if (this.user.roles.includes('gm') || this.user.roles.includes('csr')) {
      this.isStaff = true;
    }
    this.LocalStorageService.getSelectedCustomer().then((data: any) => {
      const selectedCustomer = data;
      if (selectedCustomer && selectedCustomer.roles) {
        if (
          !this.user.roles.includes('customer_user') &&
          !this.user.roles.includes('customer_user_additional') &&
          selectedCustomer.roles.includes('customer_user_additional')
        ) {
          this.isSharedAccess = true;
        }
      }
    });
  }

  applyFilter(filter) {
    this.selectedFilter = filter;
    this.onClick();
  }

  onFocusEvent($event) {
    if (this.search_user_subscription) {
      this.search_user_subscription.unsubscribe();
    }
    if (this.isStaff) {
      if (this.value === '') {
        this.recently_viewed_user_subscription = this.dataService
          .getRecentlyViewedUsers(this.user.id)
          .subscribe((data: any) => {
            this.user_data = data;
            this.isSearch = false;
            this.length = data.length;
            this.myDrop.open();
          });
      }
    }
  }

  goToServiceDetails(data) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        user_property_service_id: data.user_property_service_id,
      },
    };
    this.router
      .navigate(
        ['customer/' + data.user_id + '/home-services/home-service-details/'],
        navigationExtras
      )
      .then(() => {
        window.location.reload();
      });
  }

  goToCustomerProfile(data) {
    this.router.navigate(['/customer/' + data.id + '/profile/']).then(() => {
      window.location.reload();
    });
  }

  searchName(value: any) {
    this.user_data = [];
    this.value = value;
  }

  onClick() {
    if (this.recently_viewed_user_subscription) {
      this.recently_viewed_user_subscription.unsubscribe();
    }
    this.isActive = true;
    if (this.isStaff) {
      this.search_user_subscription = this.dataService
        .getSearchedResult(this.value, this.user.id, this.selectedFilter.value)
        .subscribe((data: any) => {
          this.user_data = data.users;
          if (!this.user_data) {
            this.user_data = [];
          }
          this.length =
          this.user_data.length;
          this.isSearch = true;
        });
    }
  }

  onSeeAll() {
    this.initial_reults = this.length;
  }

  getUpcomingPossessions(user) {
    const properties = filterUserProperties(user.user_properties);

    const possessions = [];
    for (const property of properties) {
      if (property.possession_date) {
        possessions.push(property.possession_date);
      }
    }

    const possessionString = 'Possessions: ' + possessions.join(' | ');
    return possessionString;
  }

  getPropertyUnits(user) {
    const properties = filterUserProperties(user.user_properties);
    const units = [];
    for (const property of properties) {
      if (property.property.unit_no) {
        let unit = property.property.unit_no.trim();
        if (unit[0] !== '#') {
          unit = '#' + property.property.unit_no.trim();
        }
        units.push(unit);
      }
    }
    const unitString = 'Units: ' + units.join(', ');
    return unitString;
  }
}
