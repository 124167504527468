import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
})
export class ImageCropperModalComponent implements OnInit {
  @Input() squareImage = false;
  @Input() roundCropper = false;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageFile: File;
  showCropper = false;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  save() {
    // Upload the image file
    this.activeModal.close({
      croppedImage: this.croppedImage,
      imageFile: this.imageFile,
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const imageBlob = event.file;
    const date = new Date().valueOf();
    const imageName = 'logo_' + date + '.png';
    this.imageFile = new File([imageBlob], imageName, { type: 'image/png' });
  }

  imageLoaded() {
    this.showCropper = true;

  }

  cropperReady() {

  }

  loadImageFailed() {

  }
}
