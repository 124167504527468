import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DataService } from 'src/app/_helpers/data.service';
import { FileUploadService } from 'src/app/_helpers/file-upload.service';
import { ImageUploadService } from 'src/app/_helpers/image-upload.service';
import { ImageCropperModalComponent } from 'src/app/AppPages/Elements/image-cropper/image-cropper-modal.component';
@Component({
  selector: 'app-upload-input',
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input.component.sass'],
})
export class UploadInputComponent implements OnInit {
  @Input() image: any = '';
  @Input() edit_id;
  @Input() edit_campaign;
  @Input() show_image;
  @Input() show_pdf;
  @Input() filename;
  @Input() broadcast_flag;
  @Input()
  provider_image;
  @Input()
  builder_image;

  updatedImageFile: File;
  uploadFile: File;
  closeResult: string;

  show_flag = false;
  file: any;

  @Output() change = new EventEmitter();

  @Output() onImageSelected = new EventEmitter();
  @Output() onDeleteDocument = new EventEmitter();
  constructor(
    private fileUploadService: FileUploadService,
    private imageUploadService: ImageUploadService,
    private modalService: NgbModal,
    private dataService: DataService
  ) {}

  fileChangeEvent(input: HTMLInputElement): void {
    this.file = input.files[0];

    if (this.file) {
      this.uploadFile = this.file;
      this.change.emit({ uploadFile: this.uploadFile });
    }
  }

  deleteRow(value) {

    if (value) {
      this.uploadFile = null;
      this.file = {};
      this.filename = null;
      this.onDeleteDocument.emit({ delete_document: true });
    }
  }

  openImageCropper() {
    const modalRef = this.modalService.open(ImageCropperModalComponent);
    modalRef.componentInstance.squareImage = _.cloneDeep(true);

    modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;

        this.image = result.croppedImage;
        this.updatedImageFile = result.imageFile;

        this.edit_campaign = 'edit';
        this.show_image = result.croppedImage;
        if (this.updatedImageFile && result.croppedImage) {
          this.onImageSelected.emit({ show_image: result.croppedImage });
          this.change.emit({ updatedImageFile: this.updatedImageFile });
        }
      },
      (reason) => {
        this.closeResult = `Dismissed ${reason}`;
      }
    );
  }

  selectImage(image) {
    this.edit_campaign = 'edit';
    this.show_image = image;
    this.onImageSelected.emit({ show_image: image });
    this.change.emit({ updatedImageFile: null });
  }

  showURL() {
    const url = this.show_pdf;
    window.open(url, '_blank');
  }

  ngOnInit() {




    if (this.show_pdf) {
      const filename = this.show_pdf.split('/').pop();

    }
  }

  uploadImage() {
    this.imageUploadService.uploadImage(
      this.updatedImageFile.name,
      this.updatedImageFile,
      'DEAL_IMAGE',
      this.edit_id
    );
  }
}
