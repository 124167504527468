import { NgReduxModule } from '@angular-redux/store';
import { DevToolsExtension, NgRedux } from '@angular-redux/store';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';

import { HttpClientModule } from '@angular/common/http';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AuthGuardService } from './_helpers/auth-guard.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VirtuoCommonModule } from './virtuo-common.module';
// LAYOUT

import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// APP PAGES

import { LoginComponent } from './AppPages/login/login.component';

import { EventService } from '../services/event.service';
import { SharedService } from '../services/shared.service';
import { ClientSignatureComponent } from './AppPages/client-signature/client-signature.component';
import { CustomerInvoiceComponent } from './AppPages/customer-invoice/customer-invoice.component';
import { DealRedeemedComponent } from './AppPages/deal-redeemed/deal-redeemed.component';
import { EnterTokenModalComponent } from './AppPages/login/enter-token-modal/enter-token-modal.component';
import { ForgotPasswordModalComponent } from './AppPages/login/forgot-password-modal/forgot-password-modal.component';
import { TestLoginComponent } from './AppPages/MCPortal/dashboard/test-login/test-login.component';
import { MobileSignatureComponent } from './AppPages/mobile-signature/mobile-signature.component';
import { NpsProviderComponent } from './AppPages/nps-provider/nps-provider.component';
import { NpsVirtuoComponent } from './AppPages/nps-virtuo/nps-virtuo.component';
import { PresetUserPasswordComponent } from './AppPages/preset-user-password/preset-user-password.component';
import { ProviderSignatureComponent } from './AppPages/provider-signature/provider-signature.component';
import { ResetForgotPasswordComponent } from './AppPages/reset-forgot-password/reset-forgot-password.component';
import { ResetPasswordModalComponent } from './AppPages/reset-user-password/reset-password-modal/reset-password-modal.component';
import { ResetUserPasswordComponent } from './AppPages/reset-user-password/reset-user-password.component';
import { UserOnboardingComponent } from './AppPages/user-onboarding/user-onboarding.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';

// New
import { PasswordResetModalComponent } from '../app/AppPages/MCPortal/customer/customer-my-profile/my-profile-modals/password-reset-modal/password-reset-modal.component';

declare global {
  interface Window {
    analytics: any;
    zE: any;
  }
}

@NgModule({
  declarations: [
    // LAYOUT

    AppComponent,
    PagesLayoutComponent,

    // APP PAGES

    LoginComponent,

    ResetUserPasswordComponent,
    PresetUserPasswordComponent,
    ForgotPasswordModalComponent,
    ResetForgotPasswordComponent,
    UserOnboardingComponent,
    EnterTokenModalComponent,
    ResetPasswordModalComponent,
    PasswordResetModalComponent,

    NpsProviderComponent,
    NpsVirtuoComponent,
    DealRedeemedComponent,

    MobileSignatureComponent,

    ProviderSignatureComponent,
    CustomerInvoiceComponent,
    ClientSignatureComponent,
    TestLoginComponent,
    UnauthorisedComponent,
  ],
  imports: [
    VirtuoCommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    HttpClientModule,
    NgxFileDropModule,
  ],
  providers: [AuthGuardService, EventService, SharedService],
  bootstrap: [AppComponent],
  entryComponents: [
    ForgotPasswordModalComponent,
    ResetPasswordModalComponent,
    EnterTokenModalComponent,
    // New
    PasswordResetModalComponent,
  ],
})
export class AppModule {
  constructor(
    private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension,
    config: NgbModalConfig
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : (f) => f]
    );
  }
}
