import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import { interval } from 'rxjs';

@Component({
  selector: 'app-undo-bottom-sheet',
  templateUrl: './undo-bottom-sheet.component.html',
  styleUrls: ['./undo-bottom-sheet.component.sass'],
})
export class UndoBottomSheetComponent implements OnInit {
  timeLeft = 5;
  interval;

  title: string;
  progressbarValue = 100;
  curSec = 0;
  constructor(
    private bottomSheetRef: MatBottomSheetRef<UndoBottomSheetComponent>,
    private _cdr: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  ngOnInit() {

    this.title = this.data.title;
    this.startTimer(5);
  }

  startTimer(seconds: number) {
    const time = seconds * 10;
    const timer$ = interval(100);

    this.interval = timer$.subscribe((sec) => {
      this.progressbarValue = 100 - (sec * 100) / time;
      this.curSec = sec;

      this._cdr.detectChanges();
      if (this.curSec === time) {
        this.clearBar();
      }
    });
  }

  ngOnDestroy() {
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

  clearBar(): void {
    this.bottomSheetRef.dismiss();
  }

  undo() {
    this.bottomSheetRef.dismiss({
      undo: true,
    });
  }
}
