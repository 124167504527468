import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stars-rating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.sass']
})
export class StarsRatingComponent  {


  @Input() stars: any;

  @Input() showBigStars: boolean;
  totalStars = 5;

  createRange(num) {
    const items: number[] = [];
    for (let i = 1; i <= num; i++) {
       items.push(i);
    }
    return items;
  }
}
