import { select } from '@angular-redux/store';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { SidebarDataService } from 'src/app/_helpers/sidebar-data.service';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from '../../../../_helpers/local-storage.service';
import { filterUserProperties } from '../../../../AppPages/multi-use-components/multi-use-helpers';
import { ThemeOptions } from '../../../../theme-options';

declare global {
  interface Window {
    analytics: any;
  }
}

@Component({
  selector: 'app-mc-sidebar',
  templateUrl: './mc-sidebar.component.html',
  styleUrls: ['./mc-sidebar.component.scss'],
})
export class McCustomerSidebarComponent implements OnInit {
  public extraParameter: any;
  private subscriptionName: Subscription;

  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private sidebarDataService: SidebarDataService
  ) {
    this.subscriptionName = this.sidebarDataService
      .getUpdate()
      .subscribe((message) => {
        // message contains the data sent from service

        this.selectedCustomer = message;
      });
  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  private innerHeight: number;
  activeId = 'dashboardsMenu';
  user: any;
  selectedCustomer: any;
  isGM = false;
  isCustomer = false;
  currentJustify = 'fill';
  user_properties = [];
  profile_image: any;
  first_name: any;
  hc_name: any;
  hc_number: any;
  day: any;
  title_flag = false;
  isAdmin = false;
  show_accounting_flag = false;
  project_image: any;
  project_name: any;
  isSharedAccess = false;
  weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  isBuilder = false;
  isStaging = false;
  toggleSidebarBtnFlag: boolean;
  hasRewards = false;

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  contactClicked() {
    window.analytics.track('Concierge Contact', {
      user_id: this.selectedCustomer.id,
      timestamp: moment().format(),
    });
  }

  goToProfile() {
    const id = this.selectedCustomer.id;
    this.router.navigate(['/customer/' + id + '/profile/']).then(() => {});
  }

  changeUrl(url) {
    this.router.navigate([url]).then(() => {});
  }

  toggleSidebarBtn() {
    this.toggleSidebarBtnFlag = !this.toggleSidebarBtnFlag;
    this.globals.isSidebarToggleFlag = this.toggleSidebarBtnFlag;
  }

  async ngOnInit() {
    this.isStaging = environment.staging;
    this.user = this.localStorageService.getUser();
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();

    this.project_image = this.selectedCustomer.project_details.project_image;
    this.project_name = this.selectedCustomer.project_details.alias_name;

    if (
      this.user.roles.includes('builder') ||
      this.user.roles.includes('additional_builder')
    ) {
      this.isBuilder = true;
      this.project_image = this.user.builder_accounts[0].logo;
      this.project_name = this.user.builder_accounts[0].builder_name.trim();
    }

    if (
      this.user.roles.includes('csr') ||
      this.user.roles.includes('gm') ||
      this.user.roles.includes('administrator')
    ) {
      this.show_accounting_flag = true;
    }
    const hc = this.user.hc;
    if (hc) {
      const href = this.router.url;

      if (hc.avatar) {
        this.profile_image = hc.avatar;
      }
      this.hc_name = hc.fullname;
      this.hc_number = hc.phone_number;
    }

    if (this.user.roles.includes('gm')) {
      this.isGM = true;
    }

    if (this.user.roles.includes('administrator')) {
      this.isAdmin = true;
    }

    if (
      this.user.roles.includes('customer_user') ||
      this.user.roles.includes('customer_user_additional')
    ) {
      if (this.selectedCustomer.id === this.user.id) {
        this.isCustomer = true;
      }
      this.user_properties = filterUserProperties(this.user.user_properties);
      for (const property of this.user_properties) {
        if (property.is_reward_eligible === true) {
          this.hasRewards = true;
        }
      }
    }

    if (
      !this.isCustomer &&
      this.selectedCustomer.roles.includes('customer_user_additional')
    ) {
      this.isSharedAccess = true;
    }

    const today = new Date();
    this.day = this.weekday[today.getDay()];
    if (this.globals.isGMActive) {
      this.activeId = 'GM';
    } else {
      this.activeId = 'MC';
    }

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1000) {
        this.globals.toggleSidebar = true;
      }
      this.innerHeight = window.innerHeight;
    });

    this.extraParameter =
      this.activatedRoute.snapshot.firstChild.data.extraParameter;

    this.toggleSidebarBtnFlag = this.globals.isSidebarToggleFlag;
  }

  public onChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'GM') {
      this.globals.isGMActive = true;
      this.globals.isAdminActive = false;
      this.router.navigate(['/gm-dashboard']);
    } else if ($event.nextId === 'MC') {
      this.globals.isAdminActive = false;
      this.globals.isGMActive = false;
      this.router.navigate(['/dashboard']);
    } else {
      this.globals.isGMActive = false;
      this.globals.isAdminActive = true;
      this.router.navigate(['/admin-dashboard']);
    }
  }

  public openGMDashboard() {
    this.router.navigate(['/gm-dashboard']);
  }

  public openMCDashboard() {
    if (this.isBuilder) {
      this.router.navigate(['/clients']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  public openAdminDashboard() {
    this.router.navigate(['/admin-dashboard']);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1000) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }

  toggleSidebarMobile() {
    if (window.innerWidth <= 1000 && this.isCustomer) {
      this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
    }
  }

  getCompanyInitials(companyName) {
    const nameArray = companyName.split(' ');
    let initials = nameArray[0].charAt(0);
    if (nameArray.length > 1) {
      initials += nameArray[nameArray.length - 1].charAt(0);
    }
    return initials;
  }
}
