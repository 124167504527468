import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-delete-campaign-modal',
  templateUrl: './delete-campaign-modal.component.html',
  styleUrls: ['./delete-campaign-modal.component.sass'],
})
export class DeleteCampaignModalComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {}

  onCancel() {
    this.activeModal.close(false);
  }

  onDelete() {
    this.activeModal.close(true);
  }
}
