import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private REST_API_SERVER = environment.rest_api_server;
  private REST_API_SERVER_V2 = environment.rest_api_server_v2;

  constructor(private httpClient: HttpClient) {}

  public getRestApiServerAddress() {
    return this.REST_API_SERVER;
  }

  // Common

  public login(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/sessions/login/',
      body
    );
  }

  // Provider

  public getProviderDetails(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/providers/' + id);
  }

  public getReports(params) {
    return this.httpClient.get(this.REST_API_SERVER + '/reports', {
      params,
    });
  }

  // Historical?
  public getTodoForUserProperty(todo_id, params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/master_todos/' + todo_id + '/user_property_todo',
      {
        params,
      }
    );
  }

  public getProviderJobs(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/providers/assigned_jobs',
      body
    );
  }

  public getProviderLostJobs(params) {
    return this.httpClient.get(this.REST_API_SERVER + '/providers/lost_jobs', {
      params,
    });
  }

  public getReferralProviderCustomers(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/providers/provider_customers',
      { params }
    );
  }

  public getProviderJobDetails(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/providers/assigned_job_details',
      { params }
    );
  }

  public getProviderServices(params) {
    return this.httpClient.get(this.REST_API_SERVER + '/provider_services', {
      params,
    });
  }

  public hasProfessionalServices(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/provider_services/has_professional_service',
      {
        params,
      }
    );
  }

  public getProviderServiceRates(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/provider_service_rates',
      {
        params,
      }
    );
  }

  public calculateProviderServiceRates(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/provider_service_rates/calculated_rate',
      body
    );
  }

  // Historical
  public activitiesCompleted(id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/activities/' + id + '/completed',
      body
    );
  }

  // Current - Aug 2023
  public taskComplete(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/master_todos/complete',
      body
    );
  }

  // Current - Aug 2023
  public taskDelete(body, task_id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/master_todos/' + task_id + '/delete_task',
      body
    );
  }

  public deleteMasterTodo(id, task_id) {
    const params = new HttpParams().set('id', id);
    return this.httpClient.delete(
      this.REST_API_SERVER + '/master_todos/' + task_id,
      { params }
    );
  }

  public addActivitiesComment(id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/activities/' + id + '/add_comments',
      body
    );
  }

  public loadActivityComments(id, body) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/activities/' + id + '/load_comments',
      body
    );
  }

  public markCommentsRead(id) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/activities/' + id + '/mark_comments_read',
      {}
    );
  }

  public loadDashboardActivities(hc_id) {
    const params = new HttpParams().set('hc_id', hc_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/activities/dashboard_tasks',
      { params }
    );
  }

  public loadDashboardDueActivities(hc_id) {
    const params = new HttpParams().set('hc_id', hc_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/activities/dashboard_due_tasks',
      { params }
    );
  }

  public getRepeatTypes() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/provider_services/repeat_types'
    );
  }

  public updateProviderServiceRates(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/provider_service_rates',
      body
    );
  }

  public createBroadcast(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/broadcasts', body);
  }

  public changeLeadType(user_id, reached_through) {
    const body = {
      user_id,
      reached_through,
    };
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/update_reached_through',
      body
    );
  }

  public updateBroadcast(body, broadcast_id) {
    const url = '/broadcasts/' + broadcast_id + '/update';
    return this.httpClient.put(this.REST_API_SERVER + url, body);
  }

  public accept_rewards(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_property_referrals/accept_rewards',
      body
    );
  }

  public updateProviderServiceException(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/provider_service_rates/exception_rates',
      body
    );
  }

  public addCustomerNotificationPreferences(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/customer_notification_preferences',
      body
    );
  }

  public loadMessageBoardComments(board_id) {
    const params = new HttpParams().set('board_id', board_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/get_message_board_comments',
      {
        params,
      }
    );
  }

  public loadMessageBoardCount(board_id) {
    const params = new HttpParams().set('board_id', board_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/unread_message_board_comments',
      {
        params,
      }
    );
  }

  public getPendingApprovalsCount() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/get_badge_counts'
    );
  }

  public onChangeChatToggleButton(board_id) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/mark_message_board_notification',
      {
        board_id,
      }
    );
  }

  public saveDeal(deal_id, user_id, builder_id) {
    return this.httpClient.post(this.REST_API_SERVER + '/campaigns/save_deal', {
      campaign_id: deal_id,
      user_id,
      builder_account_id: builder_id,
    });
  }

  onChangeRestrictToggleButton(id) {
    const body = {
      hc_id: id,
    };
    return this.httpClient.put(
      this.REST_API_SERVER + '/users/update_restriction',
      body
    );
  }

  public onChangeActivityToggleButton(activity_id) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/activities/mark_activities_notification',
      {
        activity_id,
      }
    );
  }

  public addMessagesComment(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/add_comment_on_message_board',
      body
    );
  }

  public markCommentsAsRead(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/read_message_board_comments',
      body
    );
  }

  public createProviderServiceException(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/provider_service_rates/exception_rates',
      body
    );
  }

  public setJobResponse(body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/set_job_response',
      body
    );
  }

  public setAvailability(body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/set_availability',
      body
    );
  }

  public markRerralServiceConfirmed(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_properties/to_dos/mark_service_confirmed',
      body
    );
  }

  public addABWNotes(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/add_abw_notes',
      body
    );
  }

  // Profile Notes ----------------------------------------------
  public getUserNotes(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(this.REST_API_SERVER + '/users/get_notes', {
      params,
    });
  }

  public addUserNote(body) {
    const params = new HttpParams()
      .set('user_id', body.user_id)
      .set('note', body.note)
      .set('note_type', body.note_type);
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/add_note',
      params
    );
  }

  public updateUserNote(body, note_id) {
    const params = new HttpParams()
      .set('user_id', body.user_id)
      .set('note', body.note)
      .set('note_type', body.note_type);
    return this.httpClient.patch(
      this.REST_API_SERVER + `/users/${note_id}/update_note`,
      params
    );
  }

  public deleteUserNote(body, note_id) {
    const params = new HttpParams()
      .set('user_id', body.user_id)
      .set('note_type', body.note_type);
    return this.httpClient.delete(
      this.REST_API_SERVER + `/users/${note_id}/delete_note`,
      { params }
    );
  }
  // End Profile Notes ------------------------------------------

  public addActivationNotes(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/add_activation_notes',
      body
    );
  }

  public getSignatureUrl(user_property_service_provider_document_template_id) {
    const params = new HttpParams().set(
      'user_property_service_provider_document_template_id',
      user_property_service_provider_document_template_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_service_providers/signature_url',
      {
        params,
      }
    );
  }

  public getClientSignatureUrl(
    user_property_service_provider_document_template_id
  ) {
    const params = new HttpParams().set(
      'user_property_service_provider_document_template_id',
      user_property_service_provider_document_template_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/client_signature_url',
      {
        params,
      }
    );
  }

  public previewDocument(signature_request_id) {
    const params = new HttpParams().set(
      'signature_request_id',
      signature_request_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/preview_document',
      {
        params,
      }
    );
  }

  public getDocumentTemplateDetails(id) {
    const params = new HttpParams().set(
      'user_property_service_provider_document_template_id',
      id
    );
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/document_template_details',
      {
        params,
      }
    );
  }

  public getDocumentTemplateDetailsForClient(id, user_id) {
    const params = new HttpParams()
      .set('user_property_service_provider_document_template_id', id)
      .set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/document_template_details_for_client',
      {
        params,
      }
    );
  }

  public getInvoiceDetails(id, user_id) {
    const params = new HttpParams()
      .set('user_property_service_provider_sign_invoice_id', id)
      .set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_service_providers/invoice_details',
      {
        params,
      }
    );
  }
  public getProviderSignatureUrl(
    user_property_service_provider_document_template_id
  ) {
    const params = new HttpParams().set(
      'user_property_service_provider_document_template_id',
      user_property_service_provider_document_template_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/provider_signature_url',
      {
        params,
      }
    );
  }

  public getSignatureUrlForMobile(
    auth_token,
    user_property_service_provider_document_template_id
  ) {
    const params = new HttpParams().set(
      'user_property_service_provider_document_template_id',
      user_property_service_provider_document_template_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/signature_url?auth_token=' +
        auth_token,
      {
        params,
      }
    );
  }

  public setProviderSignedInvoice(
    user_property_service_provider_sign_invoice_id
  ) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/set_provider_signed_invoice',
      {
        user_property_service_provider_sign_invoice_id,
      }
    );
  }

  public setSigned(user_property_service_provider_document_template_id) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_service_providers/set_signed',
      {
        user_property_service_provider_document_template_id,
      }
    );
  }

  public setClientSigned(user_property_service_provider_document_template_id) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/set_client_signed',
      {
        user_property_service_provider_document_template_id,
      }
    );
  }

  public setProviderSigned(
    user_property_service_provider_document_template_id
  ) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/set_provider_signed',
      {
        user_property_service_provider_document_template_id,
      }
    );
  }

  public setClientInvoiceSigned(
    user_property_service_provider_sign_invoice_id
  ) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/set_client_invoice_signed',
      {
        user_property_service_provider_sign_invoice_id,
      }
    );
  }

  public setClientInvoiceSignedStatus(
    user_property_service_provider_sign_invoice_id
  ) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/set_client_invoice_signed_status',
      {
        user_property_service_provider_sign_invoice_id,
      }
    );
  }

  public setSignedForMobile(
    auth_token,
    user_property_service_provider_document_template_id
  ) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/set_signed?auth_token=' +
        auth_token,
      {
        user_property_service_provider_document_template_id,
      }
    );
  }

  public requestEstimateAll(
    user_property_service_id,
    special_instructions = null
  ) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/request_estimate_all',
      {
        special_instructions,
      }
    );
  }

  public checkProviderAvailability(
    user_property_service_id,
    provider_location_id,
    special_instructions = null
  ) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/check_availability',
      {
        provider_location_id,
        special_instructions,
      }
    );
  }

  public referClient(user_property_service_id, provider_location_id) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/refer_client',
      {
        provider_location_id,
      }
    );
  }

  public recommendProvider(user_property_service_id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/recommend',
      body
    );
  }

  public selectServiceProvider(user_property_service_id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/select',
      body
    );
  }

  public updateProviderDetails(id, body) {
    return this.httpClient.put(this.REST_API_SERVER + '/providers/' + id, body);
  }

  public updateProviderServices(id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/providers/' + id + '/update_provider_services',
      body
    );
  }

  public updateBillingInfo(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/providers/update_provider_billing',
      body
    );
  }

  public createCustomInvoice(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/custom_invoices',
      body
    );
  }

  public updateCustomInvoice(body, invoice_id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/custom_invoices/' + invoice_id,
      body
    );
  }

  public addAccounting(data) {
    return this.httpClient.post(this.REST_API_SERVER + '/accountings', data);
  }

  public updateAccounting(data) {
    return this.httpClient.put(this.REST_API_SERVER + '/accountings', data);
  }

  public updateEstimate(data) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_services/update_service_estimate',
      data
    );
  }

  public updateProviderLocations(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/providers/update_provider_locations',
      body
    );
  }

  public addNewUserProperty(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_properties',
      body
    );
  }
  public addNewUserPropertiesList(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_properties/list',
      body
    );
  }

  // Historical?
  public getNextTodoForCustomer(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/activities/upcoming_todo',
      {
        params,
      }
    );
  }

  public updateUserProperty(user_property_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_properties/' + user_property_id,
      body
    );
  }

  public editUserPropertyKeyDates(user_property_id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_properties/' +
        user_property_id +
        '/edit_key_dates',
      body
    );
  }

  public getCommmunityResources(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_virtuo_library_resources/community_resources',
      {
        params,
      }
    );
  }

  public getResources(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_virtuo_library_resources/resources',
      {
        params,
      }
    );
  }

  public approveAccounting(accounting_id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/accountings/' + accounting_id + '/approve',
      {}
    );
  }

  public approveCustomInvoice(custom_invoice_id) {
    return this.httpClient.put(
      this.REST_API_SERVER +
        '/custom_invoices/' +
        custom_invoice_id +
        '/approve',
      {}
    );
  }

  public getAccountings(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(this.REST_API_SERVER + '/accountings', {
      params,
    });
  }

  public getRecipientsList(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/broadcasts/list_recipients',
      body
    );
  }

  // Builder
  public getClients(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/builder_accounts/clients',
      {
        params,
      }
    );
  }

  public getStaffList(params) {
    return this.httpClient.get(this.REST_API_SERVER_V2 + '/staff', {
      params,
    });
  }

  public recordEvent(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/builder_accounts/record_event',
      body
    );
  }

  public createNewStaff(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/users/staff', body);
  }

  // MC and User

  public getUserDetails(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/users/' + id);
  }

  public getUsersForMC(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/assigned_users',
      body
    );
  }

  public getLeadsUsers(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/leads_users',
      body
    );
  }

  public getUserStripeSetupIntent() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/get_setup_intent'
    );
  }

  public saveCardForCustomer(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/save_card/',
      body
    );
  }

  public getAvailableServices(user_property_id) {
    const params = new HttpParams().set('user_property_id', user_property_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/available_services',
      { params }
    );
  }

  public getMilestones(id, only_urgent) {
    const params = new HttpParams().set('only_urgent', only_urgent);
    return this.httpClient.get(this.REST_API_SERVER + '/milestones/' + id, {
      params,
    });
  }

  public getBuilderCampaigns(user_id, builder_account_id) {
    const params = new HttpParams()
      .set('user_id', user_id)
      .set('builder_account_id', builder_account_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_deals/builder_campaigns',
      {
        params,
      }
    );
  }

  public getUsersMaintenanceServices(user_id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/milestones/' + user_id + '/maintenance_services/'
    );
  }

  // Historical?
  public getAllDueTodos(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/common/all_due_todos/',
      body
    );
  }

  public getAllHCTodos(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/common/hc_todos/',
      body
    );
  }

  // Historical?
  public getAllLcTodos(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/common/lc_all_todos/',
      body
    );
  }

  public getUserAvailableServiceCategories(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/user_available_categories',
      { params }
    );
  }

  public getDealCategories() {
    return this.httpClient.get(this.REST_API_SERVER + '/deal_categories');
  }

  public getAssignedDealsByCategories(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_deals/get_deals_by_categories',
      { params }
    );
  }

  public getSavedDealsEvents(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_deals/saved_deals',
      { params }
    );
  }

  public updateRecentlyViewedUser(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/common/update_recently_viewed_user',
      body
    );
  }

  public getUnreadCommentCount(user_id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/milestones/' + user_id + '/unread_comments_count'
    );
  }

  public markCommentRead(task_id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/activities/' + task_id + '/mark_comments_read',
      body
    );
  }

  public getMatchesById(task_id, is_master_todo, user_property_id = null) {
    let params = new HttpParams().set(
      'is_master_todo',
      is_master_todo.toString()
    );

    if (user_property_id !== null) {
      params = params.set('user_property_id', user_property_id.toString());
    }
    return this.httpClient.get(
      this.REST_API_SERVER + '/master_todos/' + task_id + '/user_property_todo',
      { params }
    );
  }

  public getUserPropertyServiceId(user_property_service_id) {
    const params = new HttpParams().set('show_all', 'true');
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/matching_providers_list',
      {
        params,
      }
    );
  }

  public getAdhocQuotes(user_property_service_id) {
    const params = new HttpParams().set(
      'user_property_service_id',
      user_property_service_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/adhoc_estimates/estimates_for_service',
      {
        params,
      }
    );
  }

  public onBookQuoteSelected(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/tasks/select_service_provider',
      body
    );
  }

  public onConfirmPayment(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_services/payment_authorization',
      body
    );
  }

  public getAssignedTasks(user_id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/milestones/' + user_id + '/assigned_tasks'
    );
  }

  public getRecentlyViewedUsers(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/recently_viewed_users',
      {
        params,
      }
    );
  }

  public getQuotesFromMatches(user_property_service_id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/request_estimate_all',
      body
    );
  }

  public getServicesCustomer(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/services_by_categories',
      {
        params,
      }
    );
  }

  public createNewTask(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/tasks', body);
  }

  // Historical?
  public getAllUserTodos(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_properties/to_dos/all_user_todos/',
      { params }
    );
  }

  public getAllUserAppointments(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_properties/appointments/all_user_appointments/',
      { params }
    );
  }

  public deleteAppointment(appointment_id: any) {
    return this.httpClient.delete(
      this.REST_API_SERVER + '/user_properties/appointments/' + appointment_id
    );
  }

  getSearchedResult(search_value, staff_id, search_type) {
    const params = new HttpParams()
      .set('search_value', search_value)
      .set('staff_id', staff_id)
      .set('search_type', search_type);
    return this.httpClient.get(this.REST_API_SERVER_V2 + '/search', {
      params,
    });
  }

  public getServicesForPhase(id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/phases/' + id + '/services/'
    );
  }

  getPhasesForBuilderAccount(id) {
    const params = new HttpParams().set('builder_account_id', id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/phases/builder_account_phases',
      { params }
    );
  }

  public getPropertyServiceDetails(id) {
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_properties/' +
        id +
        '/property_service_details/'
    );
  }

  public updatePropertyServiceDetails(body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/user_property_service_details',
      body
    );
  }

  public addService(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_services/add_service',
      body
    );
  }

  public addServices(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_services/add_services',
      body
    );
  }

  public getProjects(builder_account_id = null) {
    let params = {};
    if (builder_account_id !== null) {
      params = new HttpParams().set('builder_account_id', builder_account_id);
    }
    return this.httpClient.get(
      this.REST_API_SERVER + '/projects/active_projects',
      {
        params,
      }
    );
  }

  public listProjects(builder_account_id = null) {
    let params = {};
    if (builder_account_id !== null) {
      params = new HttpParams().set('builder_account_id', builder_account_id);
    }
    return this.httpClient.get(
      this.REST_API_SERVER + '/projects/list_active_projects',
      {
        params,
      }
    );
  }

  public getProjectRewardsSettings(body) {
    let params = new HttpParams();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    return this.httpClient.get(
      this.REST_API_SERVER + '/projects/project_reward_settings',
      {
        params,
      }
    );
  }

  public deleteRewardProjectSetting(project_reward_setting_id) {
    const params = new HttpParams().set(
      'project_reward_setting_id',
      project_reward_setting_id
    );
    return this.httpClient.delete(
      this.REST_API_SERVER + '/projects/delete_project_reward_settings',
      {
        params,
      }
    );
  }

  public getPurchaseBoxes() {
    return this.httpClient.get(this.REST_API_SERVER + '/box_purchases');
  }

  public getDistributedBoxes() {
    return this.httpClient.get(this.REST_API_SERVER + '/box_distributions');
  }

  public getServiceLocationIdForBoxes(provider_location_id) {
    const params = new HttpParams().set(
      'provider_location_id',
      provider_location_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/providers/box_distributed_clients',
      { params }
    );
  }

  public getBoxInventories() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/box_distributions/boxes_inventory'
    );
  }

  public getVituoBoxProvider() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/providers/virtuo_boxes_providers'
    );
  }

  public createNewDistribution(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/box_distributions',
      body
    );
  }

  public addLostDamagedBoxes(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/box_distributions/add_lost_damaged',
      body
    );
  }

  public getDistributedBox(id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/box_distributions/' + id
    );
  }

  public editDistributedBox(body, id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/box_distributions/' + id,
      body
    );
  }

  public getHcList() {
    return this.httpClient.get(this.REST_API_SERVER + '/common/all_hcs');
  }

  public getHcUserList() {
    return this.httpClient.get(this.REST_API_SERVER_V2 + '/hc_users');
  }

  public getHcAndAdminList() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/all_hcs_and_admin'
    );
  }

  public getAdminList() {
    return this.httpClient.get(this.REST_API_SERVER + '/common/all_admins');
  }

  public getUsersForAdmin(user_id, status) {
    const params = new HttpParams()
      .set('user_id', user_id)
      .set('status', status);

    return this.httpClient.get(
      this.REST_API_SERVER + '/users/assigned_users_to_admin',
      {
        params,
      }
    );
  }

  // Historical?
  public getAdminTodos(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/common/all_admin_todos',
      body
    );
  }

  public getAllCustomersList(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/all_customers',
      body
    );
  }

  public getAllProviderNpsList() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/provider_ratings/all_provider_nps'
    );
  }

  public getAllVirtuoNpsList() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/surveys/all_virtuo_nps'
    );
  }

  public sendNpsEmail(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/surveys/trigger_nps',
      body
    );
  }

  public getCommunitiesForProject(project_id) {
    const params = new HttpParams().set('project_id', project_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/projects/project_communities',
      { params }
    );
  }

  public getCommunitiesByProject(project_id) {
    const params = new HttpParams().set('project_id', project_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/projects/get_project_communities',
      { params }
    );
  }

  public addCommentsToProviderNps(body, id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/provider_ratings/' + id + '/gm_comments',
      body
    );
  }

  public addCommentsToVirtuoNps(body, id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/surveys/' + id + '/gm_comments',
      body
    );
  }

  public getEmailTemplates() {
    return this.httpClient.get(this.REST_API_SERVER + '/email_templates');
  }

  public getBroadcastsList(builder_account_id) {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/broadcasts/get_broadcasts',
      { params }
    );
  }

  public getAllProjects(builder_account_id = '-1') {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(this.REST_API_SERVER + '/projects', {
      params,
    });
  }

  public getAllDealCategory() {
    return this.httpClient.get(this.REST_API_SERVER + '/deal_categories');
  }

  public getPresignedUrl(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/uploads/presigned_url',
      { params }
    );
  }

  public getTasksForUsers(user_id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/master_todos/get_tasks_for_users',
      { params: { user_id } }
    );
  }

  // Historical?
  public upload_todo_image(body: any) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/uploads/upload_todo_image',
      body
    );
  }

  public getPresignedAndPublicUrl(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/uploads/presigned_and_public_urls',
      { params }
    );
  }

  public saveBuilderReferralsDeatils(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/builder_accounts/add_referral_details',
      body
    );
  }

  public saveBuilderCustomerCareDeatils(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/builder_accounts/add_care_details',
      body
    );
  }

  public createNewUser(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/users', body);
  }

  public createNewUserRefactored(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/create_user_refactored',
      body
    );
  }

  public updateUser(user_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/users/' + user_id,
      body
    );
  }

  public getStatusList() {
    return this.httpClient.get(this.REST_API_SERVER + '/common/status_list');
  }
  public getAllMatchingProviders(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_services/matching_providers',
      { params }
    );
  }

  public getCustomerServiceDetails(user_property_service_id) {
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/service_details'
    );
  }

  public getServiceMatchingProviderDetails(user_property_service_id) {
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/matching_providers'
    );
  }

  public getServiceMatchingProviderListDetails(user_property_service_id) {
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/matching_providers_list'
    );
  }

  public getNonReferralsEstimate(user_property_service_id) {
    const params = new HttpParams().set(
      'user_property_service_id',
      user_property_service_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/adhoc_estimates/estimates_for_service',
      { params }
    );
  }

  public addEstimate(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/adhoc_estimates',
      body
    );
  }

  public updateEstimateHC(adhoc_estimate_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/adhoc_estimates/' + adhoc_estimate_id,
      body
    );
  }

  public getHcDetails(hc_id) {
    const params = new HttpParams().set('hc_id', hc_id);
    return this.httpClient.get(this.REST_API_SERVER + '/users/hc_details', {
      params,
    });
  }

  public deleteEstimateHC(adhoc_estimate_id) {
    return this.httpClient.delete(
      this.REST_API_SERVER + '/adhoc_estimates/' + adhoc_estimate_id
    );
  }

  public selectEstimateHC(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/adhoc_estimates/select_estimate',
      body
    );
  }

  public getAllTemplates() {
    return this.httpClient.get(this.REST_API_SERVER + '/templates');
  }

  public getMaintenanceTemplates() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/templates/maintenance_templates'
    );
  }

  // Historical?
  public getTodoTemplates() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/templates/todo_templates'
    );
  }

  public getAllServices() {
    return this.httpClient.get(this.REST_API_SERVER + '/services');
  }

  public getServicesList() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/services_list'
    );
  }

  public getServicesByVertical(property_vertical_id) {
    const params = new HttpParams().set(
      'property_vertical_id',
      property_vertical_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/services_by_vertical',
      { params }
    );
  }

  public createNewProject(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/projects', body);
  }
  public updateProject(project_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/projects/' + project_id,
      body
    );
  }

  public getProjectsForBuilder(builder_account_id) {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/projects/active_projects',
      {
        params,
      }
    );
  }

  public updateMilestoneForProject(body, milestone_id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/project_milestones/' + milestone_id,
      body
    );
  }

  public getProjectDetails(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/projects/' + id);
  }

  public getServiceTypes(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/rewards/eligible_services',
      {
        params,
      }
    );
  }

  public getSuggestedValues(
    provider_id,
    user_property_service_id,
    provider_type
  ) {
    const params = new HttpParams()
      .set('provider_id', provider_id)
      .set('user_property_service_id', user_property_service_id)
      .set('provider_type', provider_type);
    return this.httpClient.get(
      this.REST_API_SERVER + '/rewards/suggested_values',
      { params }
    );
  }

  public getServiceTypesForManager(user_id) {
    const params = new HttpParams()
      .set('user_id', user_id)
      .set('for_manager', 'true');
    return this.httpClient.get(
      this.REST_API_SERVER + '/rewards/eligible_services',
      {
        params,
      }
    );
  }

  public getPendingServiceTypes() {
    const params = new HttpParams().set('pending', 'true');
    return this.httpClient.get(
      this.REST_API_SERVER + '/rewards/filter_values',
      {
        params,
      }
    );
  }

  public getApprovedServiceTypes() {
    const params = new HttpParams().set('pending', 'false');
    return this.httpClient.get(
      this.REST_API_SERVER + '/rewards/filter_values',
      {
        params,
      }
    );
  }

  public createRewardClaim(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/rewards/submit_reward_claim',
      body
    );
  }

  public updateRewardClaim(body, rewards_claim_id) {
    return this.httpClient.patch(
      this.REST_API_SERVER +
        '/rewards/' +
        rewards_claim_id +
        '/update_submit_reward_claim',
      body
    );
  }

  public createRecurrenceDetails(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/recurrence_details',
      body
    );
  }

  public getPropertyVerticals() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/property_verticals'
    );
  }

  public getBuilderRoles() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/builder_accounts/builder_roles'
    );
  }

  public getPhasesByVerticalType(property_vertical_id) {
    const params = new HttpParams().set(
      'property_vertical_id',
      property_vertical_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/phases/property_vertical_phases',
      { params }
    );
  }

  public checkNewUserToken(new_user_token, email) {
    const params = new HttpParams()
      .set('new_user_token', new_user_token)
      .set('email', email);
    return this.httpClient.get(
      this.REST_API_SERVER + '/passwords/check_new_user_token',
      { params }
    );
  }

  public checkForgotPasswordToken(forgot_password_token, email) {
    const params = new HttpParams()
      .set('forgot_password_token', forgot_password_token)
      .set('email', email);
    return this.httpClient.get(
      this.REST_API_SERVER + '/passwords/check_forgot_password_token',
      { params }
    );
  }

  public sendForgotPasswordEmail(email) {
    const body = {
      email,
    };
    const params = new HttpParams().set('email', email);
    return this.httpClient.post(
      this.REST_API_SERVER + '/passwords/forgot_password',
      body
    );
  }
  public resetPassword(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/passwords/reset_password',
      body
    );
  }

  public SetIntroSeen(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/set_intro_seen',
      body
    );
  }

  public setHomeServiceIntro(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/set_home_phase_intro_seen',
      body
    );
  }

  public archiveProperty(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_properties/archive',
      body
    );
  }

  public addAppointmentforUser(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_properties/appointments',
      body
    );
  }

  public UpdateAppointmentforUser(id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_properties/appointments/' + id,
      body
    );
  }

  public sendAppLink(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/text_app_link',
      body
    );
  }

  public sendVideoSurveyLink(body, user_property_service_id) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/text_video_survey_link',
      body
    );
  }

  public getWelcomeCallDates(hc_id) {
    const params = new HttpParams().set('hc_id', hc_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/welcome_call_dates',
      { params }
    );
  }

  public getFollowupCallDates(hc_id) {
    const params = new HttpParams().set('hc_id', hc_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/follow_up_call_dates',
      { params }
    );
  }

  public getHcUrgentTasks(hc_id, page, items_per_page) {
    const params = new HttpParams()
      .set('hc_id', hc_id)
      .set('page', page)
      .set('items_per_page', items_per_page);
    return this.httpClient.get(
      this.REST_API_SERVER + '/milestones/hc_urgent_tasks',
      { params }
    );
  }

  public getAdminUrgentTasks(admin_id, page, items_per_page) {
    const params = new HttpParams()
      .set('admin_id', admin_id)
      .set('page', page)
      .set('items_per_page', items_per_page);
    return this.httpClient.get(
      this.REST_API_SERVER + '/milestones/admin_urgent_tasks',
      { params }
    );
  }

  public getHcMilestones(hc_id, page, pageSize) {
    const params = new HttpParams()
      .set('hc_id', hc_id)
      .set('page', page)
      .set('items_per_page', pageSize);
    return this.httpClient.get(
      this.REST_API_SERVER + '/milestones/hc_milestones',
      { params }
    );
  }

  public onCampaignArchive(campaign_id) {
    const params = new HttpParams().set('campaign_id', campaign_id);
    return this.httpClient.delete(this.REST_API_SERVER + '/campaigns/', {
      params,
    });
  }

  public getCampaignDetails(campaign_id) {
    const params = new HttpParams().set('campaign_id', campaign_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/campaigns/campaign_detail',
      {
        params,
      }
    );
  }

  // Historical
  public deleteTodo(todo_id: any) {
    return this.httpClient.delete(
      this.REST_API_SERVER + '/user_properties/to_dos/' + todo_id
    );
  }

  public deleteDocument(document_id: any) {
    return this.httpClient.delete(
      this.REST_API_SERVER + '/uploads/' + document_id
    );
  }

  public deleteCampaignDocument(deal_id: any) {
    return this.httpClient.delete(
      this.REST_API_SERVER + '/deals/' + deal_id + '/delete_campaign_document'
    );
  }

  public deleteDeal(deal_id: any) {
    return this.httpClient.delete(this.REST_API_SERVER + '/deals/' + deal_id);
  }

  public archiveDeals(deal_id: any, body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/deals/' + deal_id + '/archive',
      body
    );
  }

  public deleteCustomInvoice(id: any) {
    return this.httpClient.delete(
      this.REST_API_SERVER + '/custom_invoices/' + id
    );
  }

  public deleteAdditionalUser(user_id, additional_user_id) {
    const params = new HttpParams().set(
      'additional_user_id',
      additional_user_id
    );
    return this.httpClient.delete(this.REST_API_SERVER + '/users/' + user_id, {
      params,
    });
  }

  public deleteProviderExceptionRate(exception_date_range_id) {
    const params = new HttpParams().set(
      'exception_date_range_id',
      exception_date_range_id
    );
    return this.httpClient.delete(
      this.REST_API_SERVER + '/provider_service_rates/delete_exception_rate',
      {
        params,
      }
    );
  }

  public sendResetPasswordEmail(email) {
    const params = new HttpParams().set('email', email);
    return this.httpClient.get(
      this.REST_API_SERVER + '/passwords/send_reset_password_email',
      { params }
    );
  }

  public getHcActivities(hc_id, start_date, end_date) {
    const params = new HttpParams()
      .set('hc_id', hc_id)
      .set('start_date', start_date)
      .set('end_date', end_date);

    return this.httpClient.get(
      this.REST_API_SERVER + '/activities/hc_activities',
      { params }
    );
  }

  public getUserCalendarActivities(user_id, start_date, end_date) {
    const params = new HttpParams()
      .set('user_id', user_id)
      .set('start_date', start_date)
      .set('end_date', end_date);

    return this.httpClient.get(
      this.REST_API_SERVER + '/activities/user_activities',
      { params }
    );
  }

  public getCalendarProviderAvailability(
    user_property_service_id,
    start_date,
    end_date
  ) {
    const params = new HttpParams()
      .set('start_date', start_date)
      .set('end_date', end_date);

    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/calendar_provider_availability',
      { params }
    );
  }

  public getUserSelectedServices(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/user_selected_services',
      { params }
    );
  }

  public getReviews(provider_id) {
    const params = new HttpParams().set('provider_id', provider_id);
    return this.httpClient.get(this.REST_API_SERVER + '/provider_ratings', {
      params,
    });
  }

  public getHomePageData() {
    return this.httpClient.get(this.REST_API_SERVER + '/memberships/home');
  }

  public getServiceDetailById(service_id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/' + service_id + '/service_information'
    );
  }

  public createTaskChat(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/tasks/initiate_service',
      body
    );
  }

  public getMasterHm(template_id) {
    const params = new HttpParams().set('template_id', template_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/master_home_maintenance_tasks',
      {
        params,
      }
    );
  }

  // Historical?
  public getMasterTodo(template_id) {
    const params = new HttpParams().set('template_id', template_id);
    return this.httpClient.get(this.REST_API_SERVER + '/master_todos', {
      params,
    });
  }

  public createNewBoxPurchase(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/box_purchases', body);
  }

  public addNotesForPurchasedBox(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/box_purchases/add_notes',
      body
    );
  }

  public getPaymentHistory(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/accountings/payment_history',
      { params }
    );
  }

  public getPurchasedBoxDetails(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/box_purchases/' + id);
  }

  public editPurchasedBox(body, id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/box_purchases/' + id,
      body
    );
  }
  public createNewBoxDestribution(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/box_distributions',
      body
    );
  }

  getUsersDealsEvents(user_id) {
    const params = new HttpParams().set('user_id', user_id);

    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_deals/assigned_deals',
      {
        params,
      }
    );
  }

  getDealDetails(user_property_deal_id) {
    const params = new HttpParams().set(
      'user_property_deal_id',
      user_property_deal_id
    );

    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_deals/get_deal_details',
      {
        params,
      }
    );
  }

  public getServiceDetails(service_id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/' + service_id + '/all_service_details'
    );
  }

  public createReferralProgramForbuilder(builder_account_id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/builder_accounts/' +
        builder_account_id +
        '/referral_program',
      body
    );
  }

  public updateReferralProgramForbuilder(builder_account_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER +
        '/builder_accounts/' +
        builder_account_id +
        '/update_referral_program',
      body
    );
  }

  public getReferralProgramDetails(builder_account_id) {
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/builder_accounts/' +
        builder_account_id +
        '/referral_program_details'
    );
  }

  public createLoyaltyProgramForbuilder(builder_account_id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/builder_accounts/' +
        builder_account_id +
        '/loyalty_program',
      body
    );
  }

  public updateLoyaltyProgramForbuilder(builder_account_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER +
        '/builder_accounts/' +
        builder_account_id +
        '/update_loyalty_program',
      body
    );
  }

  public getLoyaltyProgramDetails(builder_account_id) {
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/builder_accounts/' +
        builder_account_id +
        '/loyalty_program_details'
    );
  }

  getUserDealDetails(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_deals/get_deal',
      body
    );
  }

  public addEventToCalendar(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_deals/add_to_calendar',
      body
    );
  }

  // Historical?
  public getMasterTodoDetails(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/master_todos/' + id);
  }

  public getInvoices(invoice_month, invoice_year) {
    const params = new HttpParams()
      .set('invoice_month', invoice_month)
      .set('invoice_year', invoice_year);

    return this.httpClient.get(this.REST_API_SERVER + '/invoices', { params });
  }

  public viewPDF(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/invoices/pdf', body);
  }

  public downloadPDF(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/invoices/pdf_download',
      body
    );
  }

  public sendToContact(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/invoices/send_to_contact',
      body
    );
  }

  public markAsSent(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/invoices/mark_as_sent',
      body
    );
  }

  public markRedeemedUserPropertyDeal(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_property_deals/mark_redeemed_url',
      body
    );
  }

  public hideUserPropertyDeal(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_deals/hide_deal',
      body
    );
  }

  public resendToContact(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/invoices/resend_to_contact',
      body
    );
  }

  public markPaid(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/invoices/mark_paid',
      body
    );
  }

  public getActivitiesDetails(params, id) {
    return this.httpClient.get(this.REST_API_SERVER + '/activities/' + id, {
      params,
    });
  }

  public getPendingApprovedRewards(body) {
    let params = new HttpParams();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    return this.httpClient.get(
      this.REST_API_SERVER + '/rewards/reward_claims',
      { params }
    );
  }

  public getUserDetailsAsRequired(body) {
    let params = new HttpParams();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    return this.httpClient.get(this.REST_API_SERVER + '/users', { params });
  }

  public deleteRewardClaim(reward_claim_id) {
    const params = new HttpParams().set('reward_claim_id', reward_claim_id);
    return this.httpClient.delete(
      this.REST_API_SERVER + '/rewards/delete_reward_claim',
      { params }
    );
  }

  public getRewardClaimDetails(reward_claim_id) {
    const params = new HttpParams().set('reward_claim_id', reward_claim_id);
    return this.httpClient.get(this.REST_API_SERVER + '/rewards/reward_claim', {
      params,
    });
  }

  public getCompanies() {
    return this.httpClient.get(this.REST_API_SERVER + '/companies');
  }

  public getServices() {
    return this.httpClient.get(this.REST_API_SERVER + '/services');
  }

  public getProviders() {
    return this.httpClient.get(this.REST_API_SERVER + '/providers');
  }

  public getProvidersForBuilder(builder_account_id) {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/providers/providers_for_builder',
      { params }
    );
  }

  public editServiceBlackoutdays(service_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/services/' + service_id + '/blackout_days',
      body
    );
  }

  public sendDownloadAppEmail(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/send_download_app_email',
      body
    );
  }

  public getActivityHistory(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/activity_history',
      { params }
    );
  }

  public getUserDetailsByEmail(email) {
    const params = new HttpParams().set('email', email);
    return this.httpClient.get(this.REST_API_SERVER + '/users/user_details', {
      params,
    });
  }

  public getFileHistory(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(this.REST_API_SERVER_V2 + '/files', {
      params,
    });
  }

  public getComments(id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/uploads/' + id + '/get_comments'
    );
  }

  public addComment(body, id) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/uploads/' + id + '/add_comment',
      body
    );
  }

  public deleteComment(comment_id, id) {
    const params = new HttpParams().set('comment_id', comment_id);
    return this.httpClient.delete(
      this.REST_API_SERVER + '/uploads/' + id + '/delete_comment',
      {
        params,
      }
    );
  }

  public getReceipts(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(this.REST_API_SERVER + '/users/receipts', {
      params,
    });
  }

  public getOptionList() {
    return this.httpClient.get(this.REST_API_SERVER + '/common/options_list');
  }

  public getLookupLanguages() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/virtuo_languages'
    );
  }

  public getBroadcastTriggerDateTypes() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/deals/trigger_date_types'
    );
  }

  public getXeroGlAccounts() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/xero_gl_accounts'
    );
  }

  public getStaffDetails(id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/' + id + '/staff'
    );
  }

  public updateStaffDetails(body, id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/users/' + id + '/staff',
      body
    );
  }

  public getEmailTemplateDetails(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/email_templates/' + id);
  }

  public sendPreviewEmail(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/email_templates/send_preview_email',
      body
    );
  }

  public getEmailTemplateOptionList() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/email_templates/options_list'
    );
  }

  public addNewEmailTemplate(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/email_templates',
      body
    );
  }

  public editEmailTemplate(body, id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/email_templates/' + id,
      body
    );
  }

  // Historical?
  public addNewTodo(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/master_todos', body);
  }

  // Historical?
  public editMasterTodo(id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/master_todos/' + id,
      body
    );
  }

  public editMasterHomeMaintenance(id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/master_home_maintenance_tasks/' + id,
      body
    );
  }

  public addNewHM(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/master_home_maintenance_tasks/',
      body
    );
  }

  public addTemplate(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/templates', body);
  }

  public editTemplate(template_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/templates/' + template_id,
      body
    );
  }

  public createNewProvider(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/providers', body);
  }

  public getDeals() {
    return this.httpClient.get(this.REST_API_SERVER + '/deals');
  }

  public getDealDeatails(id) {
    return this.httpClient.get(this.REST_API_SERVER + '/deals/' + id);
  }

  public getProductTypes() {
    return this.httpClient.get(this.REST_API_SERVER + '/product_types');
  }

  public getCommunities() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/all_communities'
    );
  }

  public newDeals(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/deals', body);
  }

  public applicableProperties(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/deals/applicable_clients',
      body
    );
  }

  public editDeals(edit_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/deals/' + edit_id,
      body
    );
  }

  public onCreateCampaign(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/campaigns', body);
  }

  public onUpdateCampaign(campaign_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/campaigns/' + campaign_id + '/update',
      body
    );
  }

  public getBuilderAccounts() {
    return this.httpClient.get(this.REST_API_SERVER + '/builder_accounts');
  }

  public listBuilderAccounts() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/builder_accounts/list_builder_accounts'
    );
  }

  public getActiveBuilderAccounts() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/builder_accounts/active_builder_accounts'
    );
  }

  public getProviderAccounts() {
    return this.httpClient.get(this.REST_API_SERVER + '/providers');
  }

  public getDealPreferences() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/deal_preferences/options'
    );
  }

  public createNewBuilderAccount(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/builder_accounts',
      body
    );
  }

  public updateBuilderAccount(builder_account_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/builder_accounts/' + builder_account_id,
      body
    );
  }

  public getBuilderDeals(builder_account_id) {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/deals/builder_account_deals',
      {
        params,
      }
    );
  }

  public getBuilderCampaignList(builder_account_id) {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/campaigns/get_campaigns',
      {
        params,
      }
    );
  }

  public getArchivedCampaigns(builder_account_id) {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/campaigns/get_archived_campaigns',
      {
        params,
      }
    );
  }

  public getProvidersDeals(provider_id) {
    const params = new HttpParams().set('provider_id', provider_id);
    return this.httpClient.get(this.REST_API_SERVER + '/deals/provider_deals', {
      params,
    });
  }

  public getBuilderAccountDetails(builder_account_id) {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/builder_accounts/builder_account_details',
      {
        params,
      }
    );
  }

  public deleteBuilderUser(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.delete(
      this.REST_API_SERVER + '/builder_accounts/delete_additional_builder',
      {
        params,
      }
    );
  }

  activeInactiveBuilderUser(body) {
    return this.httpClient.patch(
      this.REST_API_SERVER + '/builder_accounts/toggle_additional_builder',
      body
    );
  }

  getRewardsConfiguration() {
    return this.httpClient.get(this.REST_API_SERVER + '/rewards_calculations');
  }

  saveRewardsConfiguration(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/rewards_calculations',
      body
    );
  }

  updateRewardsConfiguration(body, id) {
    return this.httpClient.patch(
      this.REST_API_SERVER + '/rewards_calculations/' + id,
      body
    );
  }

  createProjectRewardSettings(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/projects/create_project_reward_settings',
      body
    );
  }

  updateProjectRewardSettings(body) {
    return this.httpClient.patch(
      this.REST_API_SERVER + '/projects/update_project_reward_settings',
      body
    );
  }

  // Historical?
  public updateTodo(id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_properties/to_dos/' + id,
      body
    );
  }

  public updateTask(body) {
    return this.httpClient.put(this.REST_API_SERVER + '/tasks', body);
  }

  public updateUserPropertyService(ups_id, up_id) {
    const body = { user_property_service_id: ups_id, user_property_id: up_id };
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_property_services/update_user_property',
      body
    );
  }

  // Historical?
  public createNewTodo(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_properties/to_dos',
      body
    );
  }

  public addToAccountWallet(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/account_wallets',
      body
    );
  }

  public spendAccountWallet(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/account_wallets/wallet_spendings',
      body
    );
  }

  public loadWalletDetails(id) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/' + id + '/load_wallet',
      {}
    );
  }

  public createNewDocumentTemplate(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/document_templates',
      body
    );
  }

  public updateDocumentTemplate(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/document_templates',
      body
    );
  }

  public getDocumentTemplates() {
    return this.httpClient.get(this.REST_API_SERVER + '/document_templates');
  }

  public archiveDocumentTemplates(template_id) {
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/document_templates/' +
        template_id +
        '/archive_template'
    );
  }

  // Historical?
  public changeTodoRemindHcStatus(todo_id, status) {
    const body = {
      todo_id,
      status,
    };

    return this.httpClient.put(
      this.REST_API_SERVER + '/user_properties/to_dos/change_remind_hc_status',
      body
    );
  }

  public updateUserPropertyServiceDetail(body) {
    return this.httpClient.put(
      this.REST_API_SERVER +
        '/user_property_services/update_user_property_service_detail',
      body
    );
  }

  public changeHc(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/change_hc',
      body
    );
  }

  public changeAssignee(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/activities/change_assignee',
      body
    );
  }

  public changeDueDate(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_properties/to_dos/change_due_date',
      body
    );
  }

  public setMaintenanceProfileType(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/set_maintenance_profile_type',
      body
    );
  }

  public getMasterHomeMaintenanceDetails(id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/master_home_maintenance_tasks/' + id
    );
  }

  public getXeroTaxTypes() {
    return this.httpClient.get(this.REST_API_SERVER + '/common/tax_options');
  }

  public setIncorrectContactDetails(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/incorrect_contact_details',
      body
    );
  }
  public getProjectServicesUserProperties(
    user_id,
    service_id,
    all_properties = false
  ) {
    const params = new HttpParams()
      .set('user_id', user_id)
      .set('service_id', service_id)
      .set('all_properties', all_properties.toString());

    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_properties/project_services_user_properties',
      { params }
    );
  }

  public getUserPropertySeviceDetails(id) {
    const params = new HttpParams().set('user_property_service_id', id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_services/service_details',
      { params }
    );
  }

  public getActiveServices(user_property_id, service_id) {
    const params = new HttpParams()
      .set('user_property_id', user_property_id)
      .set('service_id', service_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_services/active_services',
      { params }
    );
  }

  public enable_authy(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/sessions/enable_authy',
      body
    );
  }

  public disable_authy(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/sessions/disable_authy',
      body
    );
  }

  public verifyAuthyToken(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/sessions/verify_token',
      body
    );
  }

  public getUserProperty(id) {
    const params = new HttpParams().set('user_property_id', id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_properties/details',
      { params }
    );
  }

  public getUserPropertyServiceMatches(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_services/user_property_service_matches',
      { params }
    );
  }

  public getUserSubscriptions(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/user_selected_providers',
      { params }
    );
  }

  public getPastServices(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_service_providers/past_services',
      { params }
    );
  }

  public getPackagesList() {
    return this.httpClient.get(this.REST_API_SERVER + '/packages');
  }

  public createNewPackage(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/packages', body);
  }

  public archivePackage(package_id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/packages/' + package_id + '/archive_package',
      body
    );
  }

  public getArchivedPackages() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/packages/archive_packages'
    );
  }

  public getPackageDetails(package_id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/packages/' + package_id
    );
  }

  public updatePackageDetails(package_id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/packages/' + package_id,
      body
    );
  }

  public updatePackageLocation(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/packages/update_package_locations',
      body
    );
  }

  public getProvidersLocation(service_id) {
    const params = new HttpParams().set('service_id', service_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/providers/provider_list_by_locations',
      { params }
    );
  }

  public getUserActivationStatus(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/user_activation_status',
      { params }
    );
  }

  public sendInviteToProvider(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/send_invite_to_provider',
      body
    );
  }

  public sendInviteToBuilder(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/send_invite_to_partner',
      body
    );
  }

  public sendWelcomeCall(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/schedule_welcome_call_email',
      body
    );
  }

  public getPhases() {
    return this.httpClient.get(this.REST_API_SERVER + '/phases/');
  }

  public getPhasesList() {
    return this.httpClient.get(this.REST_API_SERVER + '/phases/list');
  }

  public getPhaseDetails(phase_id) {
    return this.httpClient.get(this.REST_API_SERVER + '/phases/' + phase_id);
  }

  public createNewPhase(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/phases', body);
  }

  public editPhase(body, phase_id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/phases/' + phase_id,
      body
    );
  }

  public getFolders(builder_account_id) {
    const params = new HttpParams().set(
      'builder_account_id',
      builder_account_id
    );
    return this.httpClient.get(this.REST_API_SERVER + '/resource_categories', {
      params,
    });
  }

  public addFolder(builder_account_id, category_name) {
    return this.httpClient.post(this.REST_API_SERVER + '/resource_categories', {
      builder_account_id,
      category_name,
    });
  }

  public getCompaniesList() {
    return this.httpClient.get(this.REST_API_SERVER + '/companies');
  }

  public getProductTypesForProject(project_id) {
    const params = new HttpParams().set('project_id', project_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/projects/product_types',
      { params }
    );
  }

  public getCompanyDetails(company_id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/companies/' + company_id
    );
  }

  public createNewCompany(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/companies', body);
  }

  public editCompany(body, company_id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/companies/' + company_id,
      body
    );
  }

  public getVerticalsList() {
    return this.httpClient.get(this.REST_API_SERVER + '/property_verticals');
  }

  public getVerticalDetails(vertical_id: any) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/property_verticals/' + vertical_id
    );
  }

  public createPropertyVertical(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/property_verticals',
      body
    );
  }

  public editPropertyVertical(body, vertical_id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/property_verticals/' + vertical_id,
      body
    );
  }

  public getAvailablePackagesList(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/packages/available_packages',
      { params }
    );
  }

  public getPackageMatchingUserProperties(user_id, package_id) {
    const params = new HttpParams()
      .set('user_id', user_id)
      .set('package_id', package_id);

    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_properties/package_matching_user_properties',
      { params }
    );
  }

  public orderPackage(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_packages/order_package',
      body
    );
  }

  public getOrderedPackagesList(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_packages/ordered_packages',
      { params }
    );
  }

  public getPackageSelectedServiceProvider(user_property_service_id) {
    const params = new HttpParams().set(
      'user_property_service_id',
      user_property_service_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_packages/selected_service_provider',
      { params }
    );
  }

  public cancelOrderedPackage(user_property_package_id) {
    const body = {};
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_packages/' +
        user_property_package_id +
        '/cancel_ordered_package',
      body
    );
  }

  public cancelServiceSubscription(user_property_service_id) {
    const body = {};
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/cancel_subscription',
      body
    );
  }

  public getCustomerLivingStyles() {
    return this.httpClient.get(this.REST_API_SERVER + '/common/living_styles');
  }

  public getUserAvailableServices(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/user_available_services',
      { params }
    );
  }

  public markSeenUserPropertyDeals(user_id, deal_id) {
    const body = {
      user_id,
      deal_id,
    };

    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_deals/mark_seen_user_property_deals',
      body
    );
  }

  public changeClientStatus(
    user_id,
    status_id,
    opt_out_reason,
    opt_out_other_reason
  ) {
    const body = {
      user_id,
      status_id,
      opt_out_reason,
      opt_out_other_reason,
    };

    return this.httpClient.post(
      this.REST_API_SERVER + '/users/change_client_status',
      body
    );
  }

  public changeAdmin(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/change_admin',
      body
    );
  }

  public getMetrics() {
    return this.httpClient.get(this.REST_API_SERVER + '/common/metrics');
  }

  public getProviderLocationDetails(provider_location_id) {
    const params = new HttpParams().set(
      'provider_location_id',
      provider_location_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER + '/providers/provider_location_details',
      { params }
    );
  }

  public submitProviderNps(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/provider_ratings',
      body
    );
  }

  public submitVirtuoNps(body) {
    return this.httpClient.post(this.REST_API_SERVER + '/surveys', body);
  }

  public getRecommendedHc(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/recommended_hc',
      body
    );
  }

  public getUserPropertyReferrals(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_referrals',
      { params }
    );
  }

  public getAccountWallets(builder_account_id, user_id) {
    const params = new HttpParams()
      .set('builder_account_id', builder_account_id)
      .set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/account_wallets/get_account_wallets',
      { params }
    );
  }

  public getAccountWalletsByUser(user_property_id) {
    const params = new HttpParams().set('user_property_id', user_property_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/account_wallets/get_account_wallets',
      { params }
    );
  }

  public getRecommendedAdmin(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/recommended_admin',
      body
    );
  }

  public excludedBuilderAccountProvider(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/builder_accounts/excluded_provider',
      body
    );
  }

  public preferredBuilderAccountProvider(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/builder_accounts/preferred_provider',
      body
    );
  }

  public checkCanRemoveProductType(product_type_id) {
    const params = new HttpParams().set('product_type_id', product_type_id);

    return this.httpClient.get(
      this.REST_API_SERVER + '/projects/can_remove_product_type',
      { params }
    );
  }

  public createAdditionalUser(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_additional_users/new_additional_user',
      body
    );
  }

  public createAdditionalBuilder(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/builder_accounts/new_additional_builder',
      body
    );
  }

  public updateAdditionalBuilder(body) {
    return this.httpClient.patch(
      this.REST_API_SERVER + '/builder_accounts/update_additional_builder',
      body
    );
  }

  public updateActivationData(
    user_id,
    activation_data,
    is_email_send,
    place_details
  ) {
    return this.httpClient.patch(
      this.REST_API_SERVER + '/users/activation_data',
      { user_id, activation_data, is_email_send, place_details }
    );
  }

  public getActivationData(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/activation_data',
      { params }
    );
  }

  public createAdditionalProvider(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/providers/new_additional_provider',
      body
    );
  }

  public editAdditionalUser(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_additional_users/edit_additional_user',
      body
    );
  }

  public uploadVideUrl(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/video_histories',
      body
    );
  }
  public getDocumentCategories() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/document_categories'
    );
  }

  public uploadUserDocument(data) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/uploads/upload_user_document',
      data
    );
  }

  public getDealProviders() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/providers/deal_providers'
    );
  }

  public getDealBuilderAccounts() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/builder_accounts/deal_builder_accounts'
    );
  }

  public getHcUserSelectedServices(hc_id) {
    const params = new HttpParams().set('hc_id', hc_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/services/mc_user_selected_services',
      { params }
    );
  }

  public getUserZendeskTickets(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(this.REST_API_SERVER + '/users/zen_tickets', {
      params,
    });
  }

  public getFlexTickets(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(this.REST_API_SERVER + '/users/flex_tickets', {
      params,
    });
  }

  public getTicketComments(ticket_id) {
    const params = new HttpParams().set('ticket_id', ticket_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/get_ticket_comments',
      { params }
    );
  }

  public getInvoiceSignatureUrl(
    user_property_service_provider_id,
    create_new = false
  ) {
    const params = new HttpParams()
      .set(
        'user_property_service_provider_id',
        user_property_service_provider_id
      )
      .set('create_new', create_new.toString());
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/invoice_signature_url',
      { params }
    );
  }

  public getClientInvoiceSignatureUrl(
    user_property_service_provider_id,
    create_new = false
  ) {
    const params = new HttpParams()
      .set(
        'user_property_service_provider_id',
        user_property_service_provider_id
      )
      .set('create_new', create_new.toString());
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/client_invoice_signature_url',
      { params }
    );
  }

  public getEmailHistory(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/email_conversations/customer_email_history',
      { params }
    );
  }

  public getMobileInvoiceSignatureUrl(
    auth_token,
    user_property_service_provider_id
  ) {
    const params = new HttpParams().set(
      'user_property_service_provider_id',
      user_property_service_provider_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_service_providers/invoice_signature_url?auth_token=' +
        auth_token,
      { params }
    );
  }

  public getNotificationTypes() {
    return this.httpClient.get(this.REST_API_SERVER + '/notification_types');
  }

  public getReceivableOutstandings() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/common/xero_active_receivable_outstandings'
    );
  }

  public viewJob(job_id) {
    return this.httpClient.post(
      this.REST_API_SERVER +
        '/user_property_service_providers/' +
        job_id +
        '/viewed',
      {}
    );
  }
  public getEmailDetails(id) {
    const params = new HttpParams().set('message_id', id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/email_conversations/message',
      { params }
    );
  }

  public getPendingReferralLeads(id) {
    const params = new HttpParams().set('builder_account_id', id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/referral_leads/pending_referral_leads/',
      { params }
    );
  }

  public getApprovedReferralLeads(id) {
    const params = new HttpParams().set('builder_account_id', id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/referral_leads/approved_referral_leads/',
      { params }
    );
  }

  public getReferralsList(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/account_wallets/get_account_wallets/',
      { params }
    );
  }

  public getUnapprovedProperties() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_properties/list_unapproved_properties/'
    );
  }

  public approvePropertyById(property_id) {
    const body = {
      user_property_id: property_id,
    };
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_properties/approve_property/',
      body
    );
  }

  public rejectPropertyById(property_id) {
    const body = {
      user_property_id: property_id,
    };
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_properties/reject_property/',
      body
    );
  }

  public getPropertyAreaCategories() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/property_area_categories/'
    );
  }

  public getProviderLocationRates(
    provider_location_id,
    user_property_service_id
  ) {
    const params = new HttpParams().set(
      'provider_location_id',
      provider_location_id
    );
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/updated_rates/',
      { params }
    );
  }

  public getGuidedTourItems(user_id) {
    const params = new HttpParams().set('user_id', user_id);

    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_guided_tour_items/customer_guided_tour_items',
      { params }
    );
  }

  public markCompleteGuidedTourItem(id) {
    const body = {
      id,
    };
    return this.httpClient.put(
      this.REST_API_SERVER + '/user_guided_tour_items/mark_complete',
      body
    );
  }

  public approveReferralLead(id, body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/referral_leads/' + id + '/approve',
      body
    );
  }

  public createNewVirtuoLibraryResource(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/virtuo_library_resources',
      body
    );
  }

  public updateVirtuoLibraryResource(id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/virtuo_library_resources/' + id,
      body
    );
  }

  public getVirtuoLibrary() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/virtuo_library_resources'
    );
  }

  public createCommunityContent(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/virtuo_library_resources/community_content',
      body
    );
  }

  public updateCommunityContent(id, body) {
    return this.httpClient.put(
      this.REST_API_SERVER +
        '/virtuo_library_resources/' +
        id +
        '/community_content',
      body
    );
  }

  public getCommunitContentList() {
    return this.httpClient.get(
      this.REST_API_SERVER + '/virtuo_library_resources/community_content'
    );
  }

  public getVirtuoLibraryDetails(id) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/virtuo_library_resources/' + id
    );
  }

  public getDriveways() {
    return this.httpClient.get(this.REST_API_SERVER + '/driveways');
  }

  public getLawnAreaRanges() {
    return this.httpClient.get(this.REST_API_SERVER + '/lawn_area_ranges');
  }

  public getLotTypes() {
    return this.httpClient.get(this.REST_API_SERVER + '/lot_types');
  }

  public updatePropertyMissingDetails(body) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/properties/update_missing_details',
      body
    );
  }
  public getUserVirtuoLibraryResources(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_virtuo_library_resources',
      { params }
    );
  }

  public getProviderUnavailableDates(provider_id) {
    const params = new HttpParams().set('provider_id', provider_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/provider_unavailable_dates',
      { params }
    );
  }

  public deleteProviderUnavailableDate(provider_blocked_date_range_id) {
    const params = new HttpParams().set(
      'provider_blocked_date_range_id',
      provider_blocked_date_range_id
    );
    return this.httpClient.delete(
      this.REST_API_SERVER +
        '/provider_unavailable_dates/delete_provider_unavailable_date',
      {
        params,
      }
    );
  }

  public deleteBroadcast(broadcast_id) {
    const params = new HttpParams().set('broadcast_id', broadcast_id);
    return this.httpClient.delete(this.REST_API_SERVER + '/broadcasts', {
      params,
    });
  }

  public getBroadcastDeatils(broadcast_id) {
    const params = new HttpParams().set('broadcast_id', broadcast_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/broadcasts/broadcast_detail',
      {
        params,
      }
    );
  }

  public createProviderUnavailabeDateObject(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/provider_unavailable_dates/block_dates',
      body
    );
  }

  public checkSelectedDatesAvailability(body) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/provider_unavailable_dates/check_service_dates',
      body
    );
  }

  public getServiceResources(service_id) {
    const params = new HttpParams().set('service_id', service_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/virtuo_library_resources/service_resources',
      { params }
    );
  }

  public includeStaffInRotation(user_id, include_in_rotation, type_of_user) {
    const body = {
      user_id,
      include_in_rotation,
      type_of_user,
    };

    return this.httpClient.patch(
      this.REST_API_SERVER_V2 + '/enable_rotation',
      body
    );
  }
  public markDealAsFavourite(data) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/user_property_deals/mark_favorite',
      data
    );
  }

  public changePrimaryUser(user_id) {
    return this.httpClient.post(
      this.REST_API_SERVER + '/users/change_primary_user',
      { user_id }
    );
  }

  public deleteVirtuoResource(id) {
    return this.httpClient.delete(
      this.REST_API_SERVER + '/virtuo_library_resources/' + id
    );
  }

  public recreateDocumentSignatureRequests(id) {
    return this.httpClient.put(
      this.REST_API_SERVER + '/document_templates/recreate_signature_request',
      { id }
    );
  }

  public getAvailableCreditsForService(user_property_service_id, provider_id) {
    const params = new HttpParams().set('provider_id', provider_id);
    return this.httpClient.get(
      this.REST_API_SERVER +
        '/user_property_services/' +
        user_property_service_id +
        '/total_remaining_credits',
      { params }
    );
  }

  public getUserPropertyServiceProviderDetails(
    user_property_service_id,
    provider_location_id
  ) {
    const params = new HttpParams()
      .set('user_property_service_id', user_property_service_id)
      .set('provider_location_id', provider_location_id);
    return this.httpClient.get(
      this.REST_API_SERVER + '/user_property_service_providers/all_details',
      { params }
    );
  }

  public getNonActives(params) {
    return this.httpClient.get(
      this.REST_API_SERVER + '/users/activation_leads',
      {
        params,
      }
    );
  }

  public incrementDecrementCalls(body) {
    return this.httpClient.patch(
      this.REST_API_SERVER + '/users/increment_decrement_calls',
      body
    );
  }

  // v2 APIs

  public getUsersLeads(body) {
    let params = new HttpParams();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        params = params.set(key, body[key]);
      }
    }
    return this.httpClient.get(this.REST_API_SERVER_V2 + '/leads', {
      params,
    });
  }

  public getMoveExpertList() {
    return this.httpClient.get(this.REST_API_SERVER_V2 + '/move_experts');
  }

  public clientOnboarding(
    body
  ) {
    return this.httpClient.patch(
      this.REST_API_SERVER_V2 + '/client_onboarding',
      body
    );
  }

  public getClientOnboardingDetails(user_id) {
    const params = new HttpParams().set('user_id', user_id);
    return this.httpClient.get(
      this.REST_API_SERVER_V2 + '/client_onboarding',
      { params }
    );
  }
}
