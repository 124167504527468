import { select } from '@angular-redux/store';
import { Component, HostBinding } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../../_helpers/local-storage.service';
import { ThemeOptions } from '../../../theme-options';

import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  heading = '';
  isVIP: boolean;
  isSharedAccess = false;
  isCustomer = false;
  isNotepad = false;
  constructor(
    public globals: ThemeOptions,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {
    if (
      this.router.url === '/dashboard' ||
      this.router.url === '/gm-dashboard'
    ) {
      this.showHeaderTabs = true;
    }

    this.heading = '';

    const user = this.localStorageService.getUser();
    this.localStorageService.getSelectedCustomer().then((data: any) => {
      if (window.location.pathname.includes('/notes')) {
        this.isNotepad = true;
      }
      const selectedCustomer = data;
      if (selectedCustomer && selectedCustomer.roles) {
        if (
          !user.roles.includes('customer_user') &&
          !user.roles.includes('customer_user_additional') &&
          selectedCustomer.roles.includes('customer_user_additional')
        ) {
          this.isSharedAccess = true;
        }
      }
      if (
        (user.roles.includes('customer_user') ||
          user.roles.includes('customer_user_additional')) &&
        selectedCustomer.id === user.id
      ) {
        this.isCustomer = true;
      } else {
        this.isCustomer = false;
      }
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let selectedCustomerID = -1;
        const parts = this.router.url.split('/');
        if (parts && parts.length > 2) {
          selectedCustomerID = Number(parts[2]);
        }

        if (
          this.router.url.startsWith('/customer') &&
          selectedCustomerID > -1 &&
          !isNaN(selectedCustomerID)
        ) {
          this.isCustomerDashboard = true;
          if (this.router.url.includes('/partner-reviews')) {
            this.route.queryParams.subscribe((params) => {
              const providerName = params.name;
            });
          } else if (this.router.url.includes('/property-details')) {
            let userPropertyID = this.router.url.substring(
              this.router.url.lastIndexOf('/') + 1
            );

            if (userPropertyID.includes('?')) {
              userPropertyID = userPropertyID.substring(
                0,
                userPropertyID.indexOf('?')
              );
            }
          } else {
            this.heading = '';
          }
        }
      }
    });
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;
  showHeaderTabs: boolean;
  isCustomerDashboard: boolean;
  @select('config') public config$: Observable<any>;

  switchGM() {
    this.router.navigate(['/gm-dashboard']);
    this.globals.isGMActive = true;
  }

  switchMC() {
    this.router.navigate(['/dashboard']);
    this.globals.isGMActive = false;
  }

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }

  openSideBar() {
    this.globals._opened = !this.globals._opened;
  }
}
