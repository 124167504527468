import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppsLayoutComponent } from './Layout/apps-layout/apps-layout.component';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

import {
  AuthGuardService,
  AuthGuardService as AuthGuard,
} from './_helpers/auth-guard.service';

import { ClientSignatureComponent } from './AppPages/client-signature/client-signature.component';
import { CustomerInvoiceComponent } from './AppPages/customer-invoice/customer-invoice.component';
import { DealRedeemedComponent } from './AppPages/deal-redeemed/deal-redeemed.component';
import { LoginComponent } from './AppPages/login/login.component';
import { TestLoginComponent } from './AppPages/MCPortal/dashboard/test-login/test-login.component';
import { MobileSignatureComponent } from './AppPages/mobile-signature/mobile-signature.component';
import { NpsProviderComponent } from './AppPages/nps-provider/nps-provider.component';
import { NpsVirtuoComponent } from './AppPages/nps-virtuo/nps-virtuo.component';
import { PresetUserPasswordComponent } from './AppPages/preset-user-password/preset-user-password.component';
import { ProviderAvailabilityComponent } from './AppPages/provider-availability/provider-availability.component';
import { ProviderSignatureComponent } from './AppPages/provider-signature/provider-signature.component';
import { ResetForgotPasswordComponent } from './AppPages/reset-forgot-password/reset-forgot-password.component';
import { ResetUserPasswordComponent } from './AppPages/reset-user-password/reset-user-password.component';
import { UserOnboardingComponent } from './AppPages/user-onboarding/user-onboarding.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
const routes: Routes = [
  {
    path: '',
    component: PagesLayoutComponent,
    children: [
      // No Header, Sidebar
      {
        path: '',
        component: LoginComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedRoles: ['customer_user', 'gm', 'csr'],
        },
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { extraParameter: 'dashboardsMenu' },
      },
      {
        path: 'test-login',
        component: TestLoginComponent,
        data: { extraParameter: 'dashboardsMenu' },
      },
      {
        path: 'reset-user-password/:email/:token',
        component: ResetUserPasswordComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'preset-user-password/:email/:token',
        component: PresetUserPasswordComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'forgot-password/:email/:token',
        component: ResetForgotPasswordComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'user-onboarding/:email/:token',
        component: UserOnboardingComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'nps-provider/:user_property_service_id/:provider_location_id/:user_id/:rating',
        component: NpsProviderComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'nps-virtuo/:user_property_id/:user_id/:rating',
        component: NpsVirtuoComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'nps-virtuo/home-services/:user_property_id/:user_id/:rating',
        component: NpsVirtuoComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'provider-availability/:provider_token/:job_id/:action',
        component: ProviderAvailabilityComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'mobile-signature',
        component: MobileSignatureComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'provider-signature/:id',
        component: ProviderSignatureComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'deal/:user_property_deal_id/redeem/:user_id',
        component: DealRedeemedComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'customer-invoice/:user_id/document/:invoice_id',
        component: CustomerInvoiceComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
      {
        path: 'customer-signature/:user_id/document/:id',
        component: ClientSignatureComponent,
        data: { extraParameter: 'dashboardMenu' },
      },
    ],
  },
  {
    path: 'unauthorised',
    component: UnauthorisedComponent,
    data: { extraParameter: 'dashboardsMenu' },
  },
  {
    // With Tabbed Sidebar - Home for Staff
    path: '',
    component: PagesLayoutComponent,
    loadChildren: './AppPages/MCPortal/mc.module#MCModule',
    canActivate: [AuthGuard],
  },
  {
    // Header, Sidebar for Service Provider
    path: '',
    component: BaseLayoutComponent,
    loadChildren:
      './AppPages/ServiceProviderPortal/providers.module#ProvidersModule',
    canActivate: [AuthGuard],
  },
  {
    // Header, Sidebar for Customer
    path: '',
    component: AppsLayoutComponent,
    loadChildren: './AppPages/MCPortal/customer/customer.module#CustomerModule',
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
