import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-provider-blocked-dates-picker',
  templateUrl: './provider-blocked-dates-picker.component.html',
  styles: [
    `
      .bg-light {
        background-color: var(--white) !important;
      }
      .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }

      .custom-day.focused {
        background-color: var(--l-grey);
      }

      .custom-day.range,
      .custom-day:hover {
        background-color: rgb(2, 117, 216);
        color: var(--white);
      }

      .custom-day.faded {
        background-color: rgba(2, 117, 216, 0.5);
      }
    `,
  ],
})
export class ProviderBlockedDatesPickerComponent implements OnInit {
  @Input()
  selectedDate: Date;

  @Input()
  selectedFromDate: Date;

  @Input()
  selectedToDate: Date;

  @Input()
  selectedEndRepeatDate: Date;

  @Input()
  range: boolean;

  @Input()
  repeatTypes: any;

  @Input()
  providerUnavailableDate: any;

  @Input()
  providerServices: any;

  @Input()
  providerLocations: any = [];

  fromDate: NgbDate;

  toDate: NgbDate;
  endRepeatDate: NgbDate;

  dateRange: {};

  model: NgbDateStruct;

  step1 = true;
  step2 = false;
  step3 = false;
  date: { year: number; month: number };
  public isCollapsed = false;
  displayMonths = 2;
  navigation = 'select';
  showWeekNumbers = false;
  delete_flag = false;
  hoveredDate: NgbDate;

  constructor(
    public activeModal: NgbActiveModal,
    calendar: NgbCalendar,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.providerUnavailableDate.provider_blocked_date_range_id) {
      this.delete_flag = true;
    }
    if (this.selectedDate) {
      this.model = {
        day: this.selectedDate.getUTCDate(),
        month: this.selectedDate.getUTCMonth() + 1,
        year: this.selectedDate.getUTCFullYear(),
      };
    }
    if (this.selectedFromDate != null) {
      this.fromDate = new NgbDate(
        this.selectedFromDate.getUTCFullYear(),
        this.selectedFromDate.getUTCMonth() + 1,
        this.selectedFromDate.getUTCDate()
      );
    }

    if (this.selectedToDate != null) {
      this.toDate = new NgbDate(
        this.selectedToDate.getUTCFullYear(),
        this.selectedToDate.getUTCMonth() + 1,
        this.selectedToDate.getUTCDate()
      );
    } else {
      this.toDate = null;
    }

    if (this.selectedEndRepeatDate != null) {
      this.endRepeatDate = new NgbDate(
        this.selectedEndRepeatDate.getUTCFullYear(),
        this.selectedEndRepeatDate.getUTCMonth() + 1,
        this.selectedEndRepeatDate.getUTCDate()
      );
    } else {
      this.endRepeatDate = null;
    }

    this.step1 = true;

    if (this.providerLocations) {
      for (const i of this.providerLocations) {
        if (
          this.providerUnavailableDate &&
          this.providerUnavailableDate.enabled_locations &&
          this.providerUnavailableDate.enabled_locations.includes(i.id)
        ) {
          i.enabled = true;
        } else {
          i.enabled = false;
        }
      }
    }

    if (this.providerUnavailableDate != null) {
      // Set value of enum instead of string
      if (
        this.providerUnavailableDate &&
        this.providerUnavailableDate.provider_blocked_date_range
      ) {
        this.providerUnavailableDate.provider_blocked_date_range.repeat_type =
          this.repeatTypes[
            this.providerUnavailableDate.provider_blocked_date_range.repeat_type
          ];
      }
    }
  }

  firstNext() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
  }

  next() {
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
  }

  backToFirst() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
  }

  back() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
  }

  connector(date) {
    if (date) {
      return '-';
    } else {
      return '';
    }
  }

  getDateString(date, format) {
    if (date != null) {
      return this.datePipe.transform(this.getDisplayObj(date), format);
    } else {
      return '';
    }
  }

  getDisplayObj(date) {
    if (date != null) {
      return new Date(date.year, date.month - 1, date.day);
    } else {
      return '';
    }
  }

  save() {
    if (this.range) {
      const enabledLocations = [];
      if (this.providerLocations) {
        for (const i of this.providerLocations) {
          if (i.enabled == true) {
            enabledLocations.push(i.id);
          }
        }
      }
      this.dateRange = {
        enabledLocations,
        selectedFromDate: this.getDisplayObj(this.fromDate),
        selectedToDate: this.getDisplayObj(this.toDate),
        endRepeatDate: this.getDisplayObj(this.endRepeatDate),
        providerUnavailableDate: this.providerUnavailableDate,
      };
      this.activeModal.close(this.dateRange);
    } else {
      this.activeModal.close(this.getDisplayObj(this.model));
    }
  }

  getService(provider_service_id) {
    if (this.providerServices) {
      const service = this.providerServices.filter((element) => {
        return element.provider_service_id == provider_service_id;
      });
      return service[0].phase_name + ' - ' + service[0].service_name;
    } else {
      return (
        this.providerUnavailableDate.service.phase_name +
        ' - ' +
        this.providerUnavailableDate.service.name
      );
    }
  }

  onServiceChanged(index) {
    const selectedService = this.providerServices[index];

    const baseObject = {
      provider_service_id: selectedService.provider_service_id,
      provider_blocked_date_range: {},
    };

    this.providerUnavailableDate = baseObject;
  }

  toggle($event, index) {
    this.providerLocations[index].enabled =
      !this.providerLocations[index].enabled;
  }

  getEnabledLocations() {
    const arr = [];
    for (const area of this.providerLocations) {
      if (area.enabled) {
        arr.push(area);
      }
    }
    return arr;
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  onDeleteException() {
    const options = {
      type: 'warning',
      title: 'Delete these blocked dates?',
      confirmButtonText: 'Delete',
      showCancelButton: true,
    } as SweetAlertOptions;
    Swal(options).then((result) => {
      if (result.value) {
        this.activeModal.close({
          provider_blocked_date_range_id:
            this.providerUnavailableDate.provider_blocked_date_range_id,
        });
      }
    });
  }
}
