import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';

@Component({
  selector: 'app-home-service-property-modal',
  templateUrl: './home-service-property-modal.component.html',
  styleUrls: ['./home-service-property-modal.component.sass'],
})
export class HomeServicePropertyModalComponent implements OnInit {
  @Input() userPropertiesList: any = [];

  @Input() type: string;

  @Input() serviceId: number;

  @Input() packageId = -1;

  @Input() service_details: any = [];
  @Input() service: any;

  @Input() package: any;
  sub_services: any;
  minDate: any = {};
  start_date: any;
  selectedCustomer: any;
  selectedUserProperty: any;
  disable_button: boolean;
  user_property_data: any;
  selectedSubService: any = [];
  property_package_price: any;
  dropdownSettings: IDropdownSettings = {};
  date_flag = false;
  disable_date = false;
  disable_properties = false;
  task_name: any;

  @Input() is_customer = false;
  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.setMilestoneDateServiceDetals(this.service_details);
    this.selectedCustomer =
      await this.localStorageService.getSelectedCustomer();

    if (this.userPropertiesList.length === 1 && this.service) {
      if (!['Utilities', 'Internet & TV'].includes(this.service.name)) {
        this.selectUserProperty(this.userPropertiesList[0]);
      }
    }

    if (this.service && this.service.services.length > 1) {
      this.disable_properties = true;
      this.sub_services = this.service.services;
    }

    const date = new Date();

    if (this.packageId === -1) {
      date.setDate(date.getDate() + 7);
      this.start_date = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
      const blackout_days = this.service.services[0].blackout_days;
      this.minDate = this.formatDate(
        moment().add(blackout_days, 'days'),
        'MMM DD YYYY'
      );
    } else {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      day = date.getDate() === 15 || date.getDate() > 15 ? 1 : 15;
      if (day === 1) {
        month = date.getMonth() + 2;
        if (month === 12) {
          year = date.getFullYear() + 1;
        }
      }

      this.start_date = {
        year,
        month,
        day,
      };
    }

    if (
      this.service &&
      this.service.services &&
      this.service.services.length > 0
    ) {
      const label = this.service.services[0].service_label;
      if (['virtuo_eco_friendly_boxes'].includes(label)) {
        this.setDefaultEcoBoxesDates();
      }
    }

    this.dropdownSettings = {
      singleSelection: true,
      defaultOpen: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      enableCheckAll: false,
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };
  }

  setMilestoneDateServiceDetals(service_details) {
    const datesArr = [];
    const otherArr = [];
    for (let i = 0; i < service_details.length; i++) {
      service_details[i].title = service_details[i].detail;
      if (service_details[i].data_type === 'MilestoneDate') {
        datesArr.push(service_details[i]);
      } else {
        otherArr.push(service_details[i]);
      }
    }

    this.service_details = datesArr.concat(otherArr);
  }

  getMinDate(serviceDetail) {
    const date = new Date();
    let blackout_days = 0;
    if (this.service.services.length > 1) {
      const selected_service = this.service.services.filter((item: any) => {
        return item.id === serviceDetail.service_id;
      });
      blackout_days = selected_service[0].blackout_days
        ? selected_service[0].blackout_days
        : 0;
    } else {
      blackout_days = this.service.services[0].blackout_days
        ? this.service.services[0].blackout_days
        : 0;
    }
    date.setDate(new Date().getDate() + blackout_days);
    this.minDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    if (['move_in_date'].includes(serviceDetail.label)) {
      const details = this.service_details.filter((detail: any) => {
        return detail.label === 'move_out_date';
      });
      if (typeof details[0].detail === 'object') {
        this.minDate = {
          year: details[0].detail.year,
          month: details[0].detail.month,
          day: details[0].detail.day,
        };
      }
    }
    return this.minDate;
  }
  dateChanged(serviceDetails) {
    if (serviceDetails.label === 'move_out_date') {
      const details = this.service_details.filter((detail: any) => {
        return detail.label === 'move_in_date';
      });
      if (typeof serviceDetails.detail === 'object') {
        details[0].detail = serviceDetails.detail;
      }
    }
  }
  disableField(serviceDetail) {
    if (!['move_in_date', 'move_out_date'].includes(serviceDetail.label)) {
      if (this.service && this.service.services.length > 1) {
        this.disable_date = !this.date_flag ? true : false;
      }
    } else {
      if (serviceDetail.label === 'move_out_date') {
        this.disable_date = false;
      }
      if (serviceDetail.label === 'move_in_date') {
        const details = this.service_details.filter((detail: any) => {
          return detail.label === 'move_out_date';
        });
        if (typeof details[0].detail !== 'object') {
          this.disable_date = true;
        } else {
          this.disable_date = false;
        }
      }
    }
    return this.disable_date;
  }

  getServiceDetails(service) {
    this.dataService.getServiceDetails(service.id).subscribe((data: any) => {
      this.task_name = data.name;
      this.service_details = _.cloneDeep(data.service_details);

      this.setMilestoneDateServiceDetals(data.service_details);
      if (
        ['Utilities', 'Internet & TV'].includes(this.service.name) &&
        this.selectedUserProperty.possession_date
      ) {
        this.setDefaultServiceDates();
      }
    });
  }

  setDefaultEcoBoxesDates() {
    for (let j = 0; j < this.service_details.length; ++j) {
      let delivery_date;
      let pickup_date;
      if (this.service_details[j].data_type === 'MilestoneDate') {
        if (['delivery_date'].includes(this.service_details[j].label)) {
          delivery_date = moment().add(2, 'days');
          this.service_details[j].detail = this.formatDate(
            delivery_date,
            'MMM DD YYYY'
          );
        }

        if (['pickup_date'].includes(this.service_details[j].label)) {
          pickup_date = delivery_date.add(3, 'weeks');
          this.service_details[j].detail = this.formatDate(
            pickup_date,
            'MMM DD YYYY'
          );
        }
      }
    }
  }

  setDefaultServiceDates() {
    const today = moment();
    for (let j = 0; j < this.service_details.length; ++j) {
      if (this.service_details[j].data_type === 'MilestoneDate') {
        const date = moment(
          this.selectedUserProperty.possession_date,
          'MMM DD YYYY'
        );
        if (date.isAfter(today) || date.isSame(today, 'day')) {
          this.service_details[j].detail = this.formatDate(
            this.selectedUserProperty.possession_date,
            'MMM DD YYYY'
          );
        } else {
          const tomorrow = moment().add(1, 'days');
          this.service_details[j].detail = this.formatDate(
            tomorrow,
            'MMM DD YYYY'
          );
        }
      }
    }

    if (this.selectedUserProperty.possession_dat) {
      this.start_date = this.formatDate(
        this.selectedUserProperty.possession_date,
        'MMM DD YYY'
      );
    }
  }

  formatDate(date, format) {
    if (date !== null) {
      const dateObject = moment(date, format).toDate();

      return new NgbDate(
        dateObject.getFullYear(),
        dateObject.getMonth() + 1,
        dateObject.getDate()
      );
    } else {
      return '';
    }
  }

  isDisabled = (date: NgbDate, current: { month: number }) =>
    date.day !== 1 && date.day !== 15

  orderPackage() {
    if (this.start_date) {
      if (this.userPropertiesList.length === 1) {
        this.selectedUserProperty = this.userPropertiesList[0];
      }
      const date = new Date(
        this.start_date.year,
        this.start_date.month - 1,
        this.start_date.day
      );

      this.start_date = moment(date).format('YYYY-MM-DD');

      const body = {
        user_property_id: this.selectedUserProperty.id,
        package_id: this.packageId,
        start_date: this.start_date,
      };

      this.dataService.orderPackage(body).subscribe((data: any) => {
        this.activeModal.close(data);
      });
    }
  }

  updatePropertyServiceDetails(user_property_service_id) {
    const details = [];
    for (let i = 0; i < this.service_details.length; ++i) {
      const detail_object = this.service_details[i];

      let detail = detail_object.detail;
      const detailArray = [];

      if (detail_object.data_type === 'MilestoneDate') {
        if (detail_object.detail !== null && detail_object.detail !== '') {
          const date = new Date(
            detail_object.detail.year,
            detail_object.detail.month - 1,
            detail_object.detail.day
          );
          detail = moment(date).format('MMM DD YYYY');
        }
      } else if (detail_object.default_value === 'from_property_details') {
        // for data which is preset 'from_property_details', we need to preserve it.
        for (
          let i = 0;
          i < this.user_property_data.service_details.length;
          i++
        ) {
          const service_detail_object =
            this.user_property_data.service_details[i];
          if (service_detail_object.service_detail_id === detail_object.id) {
            detail = service_detail_object.detail;
          }
        }
      } else {
        detail = '';
      }

      details.push({
        service_detail_id: detail_object.id,
        detail,
        user_property_service_id,
      });
    }

    const body = {
      should_create_yembo_url: false,
      details,
    };

    this.dataService
      .updatePropertyServiceDetails(body)
      .subscribe((data: any) => {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            user_property_service_id: this.user_property_data.id,
            new_service: true,
          },
        };

        this.router.navigate(
          [
            'customer/' +
              this.selectedCustomer.id +
              '/home-services/home-service-details/',
          ],
          navigationExtras
        );

        this.activeModal.close(navigationExtras);
      });
  }

  onItemSelect(event) {
    this.date_flag = true;
    this.disable_date = false;
    this.dataService
      .getProjectServicesUserProperties(this.selectedCustomer.id, event.id)
      .subscribe((data: any) => {
        this.userPropertiesList = data;
        this.disable_properties = false;
        if (this.userPropertiesList && this.userPropertiesList.length > 0) {
          this.selectedUserProperty = this.userPropertiesList[0];
        } else {
          this.selectedUserProperty = null;
        }
        this.getServiceDetails(event);
      });
  }

  onItemDeSelect($event) {
    this.date_flag = false;
    if (this.selectedSubService && this.selectedSubService.length === 0) {
      this.disable_properties = true;
      this.selectedUserProperty = {};
    }
  }

  selectUserProperty(user_property) {
    this.selectedUserProperty = user_property;
    this.property_package_price = user_property.price_for_package;
    if (this.packageId === -1) {
      if (
        ['Utilities', 'Internet & TV'].includes(this.service.name) &&
        this.selectedUserProperty.possession_date
      ) {
        this.setDefaultServiceDates();
      }
    }
  }

  orderService() {
    this.disable_button = true;
    let repeating = false;
    if (this.type === 'subscription') {
      repeating = true;
    }
    let service_id: any;
    if (this.sub_services) {
      service_id = this.selectedSubService[0].id;
    } else {
      service_id = this.serviceId;
    }

    if (this.userPropertiesList.length === 1) {
      this.selectedUserProperty = this.userPropertiesList[0];
    }

    if (this.is_customer) {
      const body = {
        user_property_id: this.selectedUserProperty.id,
        service_id,
        repeating,
      };

      this.addService(body);
    } else {
      const service_name = this.service.name;
      const serviceBody = {
        user_property_id: this.selectedUserProperty.id,
        service_id,
        repeating,
        name: service_name,
        task_name: this.task_name,
      };
      this.activeModal.close({
        serviceBody,
        serviceDetails: this.service_details,
      });
    }
  }

  addService(body) {
    if (this.service.recurring && this.start_date) {
      const date = new Date(
        this.start_date.year,
        this.start_date.month - 1,
        this.start_date.day
      );

      body.start_date = moment(date).format('YYYY-MM-DD');
    }

    this.dataService.addService(body).subscribe(
      (data: any) => {
        this.user_property_data = data;
        this.updatePropertyServiceDetails(data.id);
      },
      (error: any) => {
        this.disable_button = false;
      }
    );
  }

  isDatesFilled() {
    for (let i = 0; i < this.service_details.length; i++) {
      if (this.service_details[i].data_type === 'MilestoneDate') {
        const detail = this.service_details[i].detail;

        if (typeof detail === 'object') {
          if (!detail.year || !detail.month || !detail.day) {
            return false;
          }
        } else {
          return false;
        }
      }
    }
    return true;
  }

  addCardDetails() {
    this.activeModal.close({ navigate_to_profile: true });
  }
}
