import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-day-exception',
  templateUrl: './day-exception.component.html',
  styleUrls: ['./day-exception.component.sass'],
})
export class DayExceptionComponent implements OnInit {
  @Input()
  day: string;

  @Input()
  rate: 45;

  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  save() {
    this.activeModal.close({ day: this.day, rate: this.rate });
  }
}
