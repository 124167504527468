import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';

@Component({
  selector: 'app-rate-calculator-modal',
  templateUrl: './rate-calculator-modal.component.html',
})
export class RateCalculatorModalComponent implements OnInit {
  @Input()
  providerServiceRate: any;

  @Input()
  currency: any;

  @Input()
  service_rate_description: any;

  service_details = [];
  total_sq_ft: any;
  no_of_months: any;
  standard_rate: any;
  deep_cleaning_cost: any;
  monthly_cost: any;
  weekly_cost: any;
  bi_weekly_cost: any;
  rate_type: any;
  showTotalSqFt = false;
  showNoOfMonths = false;
  areaDependentServices = [
    'furnace_care',
    'window_washing',
    'move_out_clean',
    'house_cleaning',
  ];
  monthDependentServices = ['portable_container'];

  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.getServiceDetails(this.providerServiceRate.service.id);
  }

  calculate() {
    const body = {
      provider_service_id: this.providerServiceRate.provider_service_id,
      provider_standard_rate: this.providerServiceRate.standard_rate,
      provider_location_id: this.providerServiceRate.provider_location_id,
      total_sq_ft: this.total_sq_ft,
      no_of_months: this.no_of_months,
      service_details: this.service_details,
      provider_service_rate: this.providerServiceRate,
    };
    this.dataService
      .calculateProviderServiceRates(body)
      .subscribe((data: any) => {
        this.standard_rate = data.calculated_cost;
        this.rate_type = data.display_rate_type;

        if (data.monthly_cost) {
          this.monthly_cost = data.monthly_cost;
        }
        if (data.bi_weekly_cost) {
          this.bi_weekly_cost = data.bi_weekly_cost;
        }
        if (data.weekly_cost) {
          this.weekly_cost = data.weekly_cost;
        }
        if (data.deep_cleaning_cost) {
          this.deep_cleaning_cost = data.deep_cleaning_cost;
        }
      });
  }

  getServiceDetails(service_id) {
    const service_detail_array = [];
    this.dataService.getServiceDetails(service_id).subscribe((data: any) => {
      for (const i of data.service_details) {
        const service_detail = i;
        if (
          service_detail.detail !== 'Service window' &&
          service_detail.label !== 'service_time' &&
          service_detail.label !== 'notes' &&
          service_detail.label !== 'delivery_window' &&
          service_detail.label !== 'pickup_window' &&
          service_detail.label !== 'origin_access_type' &&
          service_detail.label !== 'destination_access_type' &&
          service_detail.label !== 'total_sq_ft' &&
          (service_detail.label === null ||
            !service_detail.label.startsWith('yembo_')) &&
          service_detail.data_type !== 'MilestoneDate' &&
          service_detail.data_type !== 'UserPropertySelect' &&
          service_detail.data_type !== 'DateArray' &&
          service_detail.data_type !== 'Date'
        ) {
          if (service_detail.data_type === 'Calculated') {
            service_detail.data_type = 'Double';
          }
          service_detail_array.push({
            can_read: true,
            can_write: true,
            service_details_options: service_detail.options,
            service_detail_data_type: service_detail.data_type,
            service_detail_id: service_detail.id,
            service_detail_label: service_detail.label,
            display_title: service_detail.detail,
            detail: null,
          });
        }
      }

      if (this.areaDependentServices.includes(data.label)) {
        this.showTotalSqFt = true;
      } else {
        this.showTotalSqFt = false;
      }

      if (this.monthDependentServices.includes(data.label)) {
        this.showNoOfMonths = true;
      } else {
        this.showNoOfMonths = false;
      }
      this.service_details = service_detail_array;
    });
  }

  isEmpty() {
    let disabled_flag = false;
    if (
      this.providerServiceRate.selected_additional_rates.length > 0 ||
      this.providerServiceRate.selected_additional_services.length > 0
    ) {
      for (const rate of this.providerServiceRate.selected_additional_rates) {
        disabled_flag = rate.standard_rate !== null ? true : false;
        if (!disabled_flag) {
          return !disabled_flag;
        }
      }
      for (const service of this.providerServiceRate
        .selected_additional_services) {
        disabled_flag = service.standard_rate !== null ? true : false;
        if (!disabled_flag) {
          return !disabled_flag;
        }
      }
      return !disabled_flag;
    } else {
      return false;
    }
  }
}
