import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';
import { LocalStorageService } from 'src/app/_helpers/local-storage.service';
import { UploadService } from 'src/app/_helpers/upload.service';
@Component({
  selector: 'app-record-video-modal',
  templateUrl: './record-video-modal.component.html',
})
export class RecordVideoModal implements OnInit {
  list = [
    { stage_id: 1, stage_name: 'Let\'s get started' },
    { stage_id: 2, stage_name: 'Breaking Ground' },
    { stage_id: 3, stage_name: 'Framing' },
    { stage_id: 4, stage_name: 'Drywall' },
    { stage_id: 5, stage_name: 'Millwork (Finishing & Cabinets)' },
    { stage_id: 6, stage_name: 'Paint' },
    { stage_id: 7, stage_name: 'Tile' },
    { stage_id: 8, stage_name: 'Flooring' },
    { stage_id: 9, stage_name: 'Lighting' },
    { stage_id: 10, stage_name: 'Finals' },
    { stage_id: 11, stage_name: 'Pre-Possession Orientation' },
    { stage_id: 12, stage_name: 'Possession Day!' },
    { stage_id: 13, stage_name: 'After Possession' },
  ];
  selectedListItem: any = { stage_id: null, stage_name: '' };
  constructor(public activeModal: NgbActiveModal) {}

  async ngOnInit() {}

  onListSelect(listItem) {
    this.selectedListItem = listItem;
  }

  onSave() {
    this.activeModal.close(this.selectedListItem);
  }
}
