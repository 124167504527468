import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import Utils from 'src/app/AppPages/Elements/utils';
import swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-key-dates',
  templateUrl: './key-dates.component.html',
  styleUrls: ['./key-dates.component.sass'],
})
export class KeyDatesComponent implements OnInit {
  @Input() user_property_status: any;
  @Input() keyDates: any;
  @Input() disabledFields = false;

  @Input() isBuilderPortal = false;
  @Output() keyDatesChange = new EventEmitter();

  @Input() source = false;

  constructor() {}
  possession_date: NgbDate;
  move_out_date: NgbDate;
  target_possession_date: NgbDate;
  move_in_date: NgbDate;

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.keyDates.possession_date) {
      const possession_date = this.keyDates.possession_date.split('T', 1);
      this.possession_date = Utils.formatDateToNgbDate(
        possession_date[0],
        'YYYY-MM-DD'
      );

    }

    if (this.keyDates.target_possession_date) {
      const target_possession_date = this.keyDates.target_possession_date.split(
        'T',
        1
      );
      this.target_possession_date = Utils.formatDateToNgbDate(
        target_possession_date[0],
        'YYYY-MM-DD'
      );

    }

    if (this.keyDates.move_in_date) {
      const move_in_date = this.keyDates.move_in_date.split('T', 1);
      this.move_in_date = Utils.formatDateToNgbDate(
        move_in_date[0],
        'YYYY-MM-DD'
      );

    }

    if (this.keyDates.move_out_date) {
      const move_out_date = this.keyDates.move_out_date.split('T', 1);
      this.move_out_date = Utils.formatDateToNgbDate(
        move_out_date[0],
        'YYYY-MM-DD'
      );

    }
  }

  checkPhase(phase_name) {
    for (const obj of this.user_property_status) {
      if (obj.phase === phase_name) {
        return true;
      }
    }
    return false;
  }

  targetDateChanged() {
    if (
      this.target_possession_date &&
      typeof this.target_possession_date === 'object'
    ) {
      if (this.possession_date !== null && this.move_in_date !== null) {
        const options = {
          type: 'warning',
          text: 'You have a move date selected, please confirm that it is still accurate',
          confirmButtonText: 'Confirm',
          showCancelButton: true,
        } as SweetAlertOptions;

        swal(options).then((result) => {
          if (result.value) {
            this.keyDates.target_possession_date = Utils.getDateFromNgbDate(
              this.target_possession_date
            );

            this.keyDates.possession_date = null;
            this.possession_date = null;
          } else {
            this.target_possession_date = null;
            this.keyDates.target_possession_date = this.target_possession_date;
          }
        });
      } else {
        this.keyDates.target_possession_date = Utils.getDateFromNgbDate(
          this.target_possession_date
        );

        this.keyDates.possession_date = null;
        this.possession_date = null;
      }
    } else {
      this.target_possession_date = null;
      this.keyDates.target_possession_date = this.target_possession_date;
    }
  }

  possessionDateChanged() {
    if (this.possession_date && typeof this.possession_date === 'object') {
      if (this.keyDates.possession_date !== null && this.move_in_date !== null) {
        const options = {
          type: 'warning',
          text: 'You have a move date selected, please confirm that it is still accurate',
          confirmButtonText: 'Confirm',
          showCancelButton: true,
        } as SweetAlertOptions;

        swal(options).then((result) => {
          if (result.value) {
            this.keyDates.possession_date = Utils.getDateFromNgbDate(
              this.possession_date
            );

            this.keyDates.target_possession_date = null;
            this.target_possession_date = null;
          } else {
            this.possession_date = Utils.formatDateToNgbDate(
              this.keyDates.possession_date,
              'YYYY-MM-DD'
            );
          }
        });
      } else {
        this.keyDates.possession_date = Utils.getDateFromNgbDate(
          this.possession_date
        );

        this.keyDates.target_possession_date = null;
        this.target_possession_date = null;
      }
    } else {
      this.possession_date = null;
      this.keyDates.possession_date = this.possession_date;
    }
  }

  toggleMoveInDate(id) {

    if (!this.possession_date && !this.target_possession_date) {

    } else {
      id.toggle();
    }
  }

  moveInDateChanged() {
    const date = this.possession_date
      ? this.possession_date
      : this.target_possession_date
      ? this.target_possession_date
      : null;


    if (date !== null) {
      if (
        Utils.getDateFromNgbDate(this.move_in_date) <
        Utils.getDateFromNgbDate(date)
      ) {
        const str = this.possession_date
          ? 'Possession'
          : this.target_possession_date
          ? 'Target Possession'
          : 'Possession';
        swal({
          type: 'warning',
          text: 'Move-In Date is predating ' + str + ' Date',
        });
      }
      this.keyDates.move_in_date = Utils.getDateFromNgbDate(this.move_in_date);
    } else {
      if (this.move_in_date && typeof this.move_in_date === 'object') {
        this.keyDates.move_in_date = Utils.getDateFromNgbDate(
          this.move_in_date
        );

      } else {
        this.move_in_date = null;
        this.keyDates.move_in_date = this.move_in_date;
      }
    }
  }

  dateChanged() {
    if (this.possession_date && typeof this.possession_date === 'object') {
      this.keyDates.possession_date = Utils.getDateFromNgbDate(
        this.possession_date
      );

      this.keyDates.target_possession_date = null;
      this.target_possession_date = null;
    } else {
      this.possession_date = null;
      this.keyDates.possession_date = this.possession_date;
    }

    if (
      this.target_possession_date &&
      typeof this.target_possession_date === 'object'
    ) {
      this.keyDates.target_possession_date = Utils.getDateFromNgbDate(
        this.target_possession_date
      );

    } else {
      this.target_possession_date = null;
      this.keyDates.target_possession_date = this.target_possession_date;
    }

    if (this.move_out_date && typeof this.move_out_date === 'object') {
      this.keyDates.move_out_date = Utils.getDateFromNgbDate(
        this.move_out_date
      );

    } else {
      this.move_out_date = null;
      this.keyDates.move_out_date = this.move_out_date;
    }



    if (this.move_in_date && typeof this.move_in_date === 'object') {
      this.keyDates.move_in_date = Utils.getDateFromNgbDate(this.move_in_date);

    } else {
      this.move_in_date = null;
      this.keyDates.move_in_date = this.move_in_date;
    }

    this.keyDatesChange.emit(this.keyDates);
  }
}
