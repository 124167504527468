import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { DataService } from 'src/app/_helpers/data.service';
@Component({
  selector: 'app-builder-project-input',
  templateUrl: './builder-project-input.component.html',
  styleUrls: ['./builder-project-input.component.sass'],
})
export class BuilderProjectInputComponent implements OnInit {
  @Input() is_builder: boolean;
  @Input() is_provider: boolean;
  @Input() deal_project_preference_ids;
  @Input() edit_id = -1;
  @Input() builder_id = -1;
  @Input() are_projects_included = true;
  showProjects: boolean;
  projectList: any = [];
  projects: any = [];
  @Input()
  sortedProjects: any = [];
  exclude_projects = true;
  @Output() change = new EventEmitter();
  has_any_project_selected = false;
  @Input() show_error = false;
  @Input() error: any;
  checked_projects: any = [];
  show_error_msg = false;
  builder_accounts: any = [];
  constructor(private dataService: DataService) {}

  ngOnInit() {

    if (this.deal_project_preference_ids.length > 0) {
      this.showProjects = true;
    }
    if (this.edit_id !== -1 && !this.are_projects_included) {
      let project = [];
      project = this.sortedProjects.filter((item: any) => {
        return item.selected === true;
      });
      if (project.length > 0) {
        this.showProjects = true;
      }
    }

    if (this.edit_id !== -1) {

      this.setProjects();
    }
  }

  ngOnChanges() {
    this.projects = this.sortedProjects;
    const builders = _.uniq(_.map(this.sortedProjects, 'builder_account_id'));
    for (const i of builders) {
      const account: any = {};
      const projects = this.sortedProjects.filter((data: any) => {
        return data.builder_account_id === i;
      });
      account.id = builders[i];
      account.projects = projects;
      if (projects && projects.length > 0) {
        account.name = projects[0].builder_account.builder_name;
      }
      account.is_collapsed = false;
      this.builder_accounts.push(account);
    }

    if (this.edit_id !== -1 && !this.are_projects_included) {
      let project = [];
      project = this.sortedProjects.filter((item: any) => {
        return item.selected === false;
      });
      if (project.length === 0) {
        this.has_any_project_selected = true;
        this.change.emit({
          are_projects_included: this.are_projects_included,
          deal_project_preference_ids: this.deal_project_preference_ids,
          has_any_project_selected: this.has_any_project_selected,
          show_error: this.show_error,
          checked_projects: this.checked_projects,
          target_projects: this.showProjects,
        });
      }

      let selected_project = [];
      selected_project = this.sortedProjects.filter((item: any) => {
        return item.selected === true;
      });

      if (selected_project.length > 0) {
        this.showProjects = true;
      }
    }
  }

  getProjects() {
    this.dataService.getAllProjects().subscribe((data: any) => {

      this.projectList = data;



      if (this.builder_id !== -1) {
        this.sortedProjects = data.filter((element) => {
          return element.builder_account_id === this.builder_id;
        });
      } else {
        this.sortedProjects = data.filter((element) => {
          return true;
        });
      }

      this.projects = this.sortedProjects;

      for (const i of this.sortedProjects) {
        if (this.deal_project_preference_ids.includes(i.id)) {
          i.selected = true;
        } else {
          i.selected = false;
        }
      }


    });
  }

  toggleTargetProjects($event) {

    this.showProjects = $event.checked;
    this.show_error = false;
    if ($event.checked === false) {
      this.deal_project_preference_ids = [];
      for (const i of this.sortedProjects) {
        i.selected = false;
      }
      this.change.emit({
        deal_project_preference_ids: this.deal_project_preference_ids,
        target_projects: this.showProjects,
        are_projects_included: this.are_projects_included,
        show_error: this.show_error,
        checked_projects: this.checked_projects,
      });
    } else {
      this.change.emit({
        deal_project_preference_ids: this.deal_project_preference_ids,
        target_projects: this.showProjects,
        are_projects_included: this.are_projects_included,
        show_error: this.show_error,
        checked_projects: this.checked_projects,
      });
    }
  }

  selectedProject(value, i, $event) {

    const project = {
      project_id: value.id,
      checked: $event.checked,
    };
    const index_id = this.checked_projects.indexOf(value.id);
    if ($event.checked) {
      this.sortedProjects[i].selected = true;
    } else {
      this.sortedProjects[i].selected = false;
    }
    if (this.edit_id === -1) {

      if (this.are_projects_included) {
        const index = this.deal_project_preference_ids.indexOf(
          value.id
        );
        if (index === -1) {
          const index_con = this.deal_project_preference_ids.length;
          this.deal_project_preference_ids[index_con] = value.id;
        } else {
          this.deal_project_preference_ids.splice(index, 1);
        }
      } else {
        const index = this.deal_project_preference_ids.indexOf(
          value.id
        );
        if ($event.checked) {
          if (index !== -1) {
            this.deal_project_preference_ids.splice(index, 1);
          } else {
            for (const i of this.sortedProjects) {
              if (i.id !== value.id) {
                this.deal_project_preference_ids.push(i.id);
              }
            }
          }
          this.has_any_project_selected = true;
        } else {

          if (index === -1) {
            const index_con = this.deal_project_preference_ids.length;
            this.deal_project_preference_ids[index_con] = value.id;
          }
        }
      }
    } else {


      if (this.are_projects_included) {
        const index = this.deal_project_preference_ids.indexOf(
          value.id
        );

        if (index === -1) {
          const index_con = this.deal_project_preference_ids.length;
          this.deal_project_preference_ids[index_con] = value.id;
        } else {
          this.deal_project_preference_ids.splice(index, 1);
        }

      } else {
        const index = this.deal_project_preference_ids.indexOf(
          value.id
        );
        if ($event.checked) {
          if (index !== -1) {
            this.deal_project_preference_ids.splice(index, 1);
          } else {
            for (const i of this.sortedProjects) {
              if (i.id !== value.id) {
                this.deal_project_preference_ids.push(i.id);
              }
            }
          }
          this.has_any_project_selected = true;
        } else {

          if (index === -1) {
            const index_con = this.deal_project_preference_ids.length;
            this.deal_project_preference_ids[index_con] = value.id;
          }
        }
      }
    }

    this.checked_projects = this.sortedProjects.filter((item: any) => {
      return item.selected === true;
    });

    if (!this.are_projects_included && this.show_error) {
      if (this.checked_projects.length === this.sortedProjects.length) {
        this.show_error = true;
      } else if (this.checked_projects && this.checked_projects === 0) {
        this.show_error = true;
      } else {
        this.show_error = false;
      }
    } else if (this.are_projects_included && this.show_error) {
      if (this.checked_projects && this.checked_projects === 0) {
        this.show_error = true;
      } else {
        this.show_error = false;
      }
    }

    this.change.emit({
      deal_project_preference_ids: this.deal_project_preference_ids,
      project,
      are_projects_included: this.are_projects_included,
      has_any_project_selected: this.has_any_project_selected,
      show_error: this.show_error,
      checked_projects: this.checked_projects,
      target_projects: this.showProjects,
    });
  }

  searchProject(value) {


    this.sortedProjects = this.projects.filter((element) => {
      return element.name.toLowerCase().search(value.toLowerCase()) !== -1;
    });


  }

  setProjects() {
    const arr = [];
    for (const i of this.sortedProjects) {
      if (this.deal_project_preference_ids.includes(i.id)) {
        i.selected = true;
      }
      arr.push(i);
    }

    this.sortedProjects = arr;


    this.checked_projects = this.deal_project_preference_ids;
    this.change.emit({
      are_projects_included: this.are_projects_included,
      has_any_project_selected: this.has_any_project_selected,
      checked_projects: this.checked_projects,
      show_error: this.show_error,
      target_projects: this.showProjects,
      deal_project_preference_ids: this.deal_project_preference_ids,
    });

  }

  radioChange(event) {


    if (
      this.deal_project_preference_ids &&
      this.deal_project_preference_ids.length > 0
    ) {
      let project_ids = [];

      // this means, include projects
      project_ids = this.sortedProjects.filter((project: any) => {
        return !this.deal_project_preference_ids.includes(project.id);
      });

      this.deal_project_preference_ids = _.uniq(_.map(project_ids, 'id'));
      this.has_any_project_selected = true;
    } else {
      if (this.are_projects_included) {
        if (this.checked_projects.length > 0) {
          this.deal_project_preference_ids = _.uniq(
            _.map(this.sortedProjects, 'id')
          );
        }
      } else {
        if (this.deal_project_preference_ids.length === 0) {
          this.deal_project_preference_ids = _.uniq(
            _.map(this.sortedProjects, 'id')
          );
        }

      }
    }

    this.change.emit({
      are_projects_included: this.are_projects_included,
      deal_project_preference_ids: this.deal_project_preference_ids,
      has_any_project_selected: this.has_any_project_selected,
      show_error: this.show_error,
      checked_projects: this.checked_projects,
      target_projects: this.showProjects,
    });
  }

  isSelectedProjects() {
    let hasProjects = false;
    if (!this.show_error) {
      // do nothing
    } else {
      if (this.are_projects_included) {
        if (this.deal_project_preference_ids.length === 0) {
          hasProjects = true;
        }
      } else {
        if (this.checked_projects && this.checked_projects.length === 0) {
          hasProjects = true;
        } else if (this.checked_projects.length === this.sortedProjects.length) {
          hasProjects = true;
        }
      }
    }
    this.show_error_msg = hasProjects;
    return hasProjects;
  }
}
