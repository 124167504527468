import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/_helpers/data.service';

@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.sass'],
})
export class ForgotPasswordModalComponent implements OnInit {
  email = '';
  server_error = '';
  showMessage: boolean;
  formGroup: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    private dataService: DataService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  sendForgotPasswordEmail() {

    this.dataService.sendForgotPasswordEmail(this.email).subscribe(
      (data: any) => {

        this.showMessage = true;
      },
      (err: any) => {

        this.server_error = err.error.error;
      }
    );
  }
}
