import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/_helpers/data.service';

@Component({
  selector: 'app-provider-availability',
  templateUrl: './provider-availability.component.html',
  styleUrls: ['./provider-availability.component.sass'],
})
export class ProviderAvailabilityComponent implements OnInit {
  valid_url = true;
  providerToken: string;
  jobId: number;
  showYesButton: boolean;
  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {

      this.providerToken = params.get('provider_token');
      this.jobId = +atob(params.get('job_id'));
      const action = atob(params.get('action'));

      if (action === 'yes') {
        this.showYesButton = true;
      }
      const req = new HttpParams().set('id', atob(params.get('job_id')));

      this.dataService.getProviderJobDetails(req).subscribe((data: any) => {
        const token = data.provider.user.provider_token;
        if (
          data.display_status !== 'Awaiting Availability' ||
          token !== this.providerToken
        ) {
          this.valid_url = false;
        }
      });
    });
  }

  setAvailability(isAvailable) {

    const body = {
      id: this.jobId,
      availability: isAvailable,
    };

    this.dataService.setAvailability(body).subscribe((data: any) => {

      this.submitted = true;
    });
  }
}
