import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-deal-preview',
  templateUrl: './deal-preview.component.html',
  styleUrls: ['./deal-preview.component.sass'],
})
export class DealPreviewComponent implements OnInit {
  @Input()
  edit_id: any;

  @Input()
  dealDetails: any;

  @Input()
  show_image: any;

  @Input()
  builders_logo: any;

  @Input()
  title: any;

  @Input()
  description: any;

  currentJustify = 'start';

  constructor() {}

  ngOnInit() {

  }
}
